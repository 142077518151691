import { T_Team } from '../../../../../globalTypes';

export const drawWelcomeMessage = ({ team }: { team?: T_Team }) => {
    if (!team) return 'Welkom bij de Cash Quiz!';
    const { teamName } = team;
    let text = 'Welkom [$team], veel succes!';
    if (!teamName) {
        return 'Welkom bij de Cash Quiz!';
    }
    text = text.replace('[$team]', teamName?.name);
    if (team.teamName?.name.toLowerCase().startsWith('de')) {
        text = text.replace('De', '');
    }
    if (team.teamName?.name.toLowerCase().startsWith('het')) {
        text = text.replace('Het', '');
    }
    return text;
};
