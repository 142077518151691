import { useEffect } from 'react';
import { VideoPlayer } from 'src/components/video/VideoPlayer';
import { sectionIsQuestion } from 'src/functions';
import { useAppDispatch, useNextEvent, useSection } from 'src/hooks';
import { setInteraction } from 'src/store';

export const QuestionVideoPage = () => {
    const currentQuestion = useSection();
    const dispatch = useAppDispatch();
    const castNextEvent = useNextEvent();

    useEffect(() => {
        dispatch(
            setInteraction({ interactionKey: 'nextButton', change: 'close' }),
        );
        return () => {
            dispatch(
                setInteraction({
                    interactionKey: 'nextButton',
                    change: 'open',
                }),
            );
        };
    }, [currentQuestion]);
    if (!sectionIsQuestion(currentQuestion) || !currentQuestion.videoURL) {
        castNextEvent();
        dispatch(
            setInteraction({
                interactionKey: 'nextButton',
                change: 'open',
            }),
        );
        return null;
    }
    return <VideoPlayer url={currentQuestion.videoURL} autoplay/>;
};
