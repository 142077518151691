import { ComponentProps, useEffect } from 'react';
import { TeamPicker } from 'src/components/quiz/decisionDrawPage/TeamPicker';
import { QuestionAnswerDisplay } from 'src/components/quiz/questionAnswerPage/QuestionAnswerDisplay';
import { castNextEvent, sectionIsQuestion } from 'src/functions';
import { T_DecisionPhase } from '../../../../../../globalTypes';
import { Image } from '../../../../components/base/Image';
import {
    useAppDispatch,
    useDecisionQuestion,
    useIntroText,
    useSection,
    useSocket,
} from '../../../../hooks';
import { handleQuestionTime } from '../../../../store/thunks/handleQuestionTime';

//Component used to display a question WITH the answers. When this page is visible, players may respond in socket version. For
//local version, the screen will transfer to "QuestionResponsePage" in order to allow the guest lecturer to enter the responses.
export type T_QuestionAnswerPhases = ComponentProps<
    typeof QuestionAnswersPage
>['phase'];
export const QuestionAnswersPage = ({
    phase,
}: {
    phase:
        | 'question'
        | 'answerReveal'
        | 'questionWhatsapp'
        | Omit<T_DecisionPhase, 'decision'>;
}) => {
    const socket = useSocket();
    const dispatch = useAppDispatch();
    const currentQuestion = useSection();
    const decisionQuestion = useDecisionQuestion();
    const inDecision = phase.includes('decision');

    const introText = useIntroText();

    useEffect(() => {
        dispatch(handleQuestionTime());
    }, []);

    if (!sectionIsQuestion(currentQuestion) && !decisionQuestion) {
        castNextEvent({ socket, dispatch });
        return null;
    }
    if (
        (!inDecision &&
            !decisionQuestion &&
            !sectionIsQuestion(currentQuestion)) ||
        currentQuestion?.sectionType === 'Slide'
    )
        return null;
    const showIntroText =
        sectionIsQuestion(currentQuestion) &&
        introText &&
        currentQuestion?.displayIntroWithQuestion &&
        currentQuestion?.introTextSize;
    return (
        <>
            {sectionIsQuestion(currentQuestion) &&
                !currentQuestion.introText &&
                currentQuestion?.imageURL &&
                currentQuestion.imagePosition === 'Question' && (
                    <Image imageURL={currentQuestion.imageURL} />
                )}

          
            <QuestionAnswerDisplay
                phase={phase}
                inDecision={inDecision}
                decisionQuestion={decisionQuestion}
                currentQuestion={currentQuestion}
            />
            {phase === 'decisionDraw' && <TeamPicker />}
        </>
    );
};
