export type T_IntroTitle = { text: string; underlined?: string };
export const introLessMainTitles: T_IntroTitle[] = [
    {
        text: 'Klaar voor de volgende vraag?',
        underlined: 'volgen',
    },
    {
        text: 'Op naar de volgende vraag!',
        underlined: 'gende',
    },
];
export const introLessMainTitlesFirstQuestion: T_IntroTitle[] = [
    {
        text: 'Hier komt de eerste vraag!',
        underlined: 'eerste',
    },
];

export const introMainTitles: T_IntroTitle[] = [
    {
        text: 'Lees allemaal mee',
        underlined: 'allemaal',
    },
    {
        text: 'Lees allemaal mee',
        underlined: 'allemaal',
    },
];
