import { settings } from 'src/config/settings';
import { arrayKeepNumbers } from 'src/functions/helpers';
import {
    T_DatabaseResponseCorrectType,
    T_QuestionType,
    T_QuestionTypeData,
    T_TeamResponse,
} from '../../../../../globalTypes';
type T_DrawnAnswer = { text: string; color: 'Correct' | 'Partial' | 'Wrong' };
const noAnswer: T_DrawnAnswer = { text: '-', color: 'Wrong' };

export const drawTeamAnswer = ({
    teamResponse,
    questionType,
    typeData,
}: {
    teamResponse?: T_TeamResponse | null;
    questionType: T_QuestionType;
    typeData?: T_QuestionTypeData;
}): T_DrawnAnswer => {
    if (
        !teamResponse ||
        (!teamResponse.response && teamResponse.response !== 0) ||
        !typeData
    ) {
        return noAnswer;
    }
    const color = teamResponse.isCorrect === 'YES' ? 'Correct' : 'Wrong';
    switch (questionType) {
        case 'Open':
            switch (teamResponse.isCorrect as T_DatabaseResponseCorrectType) {
                case 'YES':
                    return { text: 'Goed', color: 'Correct' };
                case 'NO':
                case 'PARTIAL':
                default:
                    return {
                        text: 'Fout',
                        color:
                            teamResponse.isCorrect === 'PARTIAL'
                                ? 'Partial'
                                : 'Wrong',
                    };
            }
        case 'MPC':
            const MPCResponse = arrayKeepNumbers(teamResponse?.response)[0];
            const { MPCLetterArray } = settings;
            if (!MPCResponse) return noAnswer;
            return {
                text: MPCLetterArray[MPCResponse - 1],
                color,
            };
        case 'Closed':
        case 'Thesis':
            const closedResponse = teamResponse.response as number;
            const responseText =
                closedResponse === 1
                    ? typeData.closedLeft
                    : closedResponse === 2
                    ? typeData.closedRight
                    : ('Geen antwoord' as string);
            if (!responseText || responseText === 'Geen antwoord')
                return noAnswer;
            return {
                text: responseText,
                color,
            };
        case 'Guess':
            const guessResponse = arrayKeepNumbers(teamResponse?.response)[0];
            if (!guessResponse || isNaN(guessResponse)) return noAnswer;
            return {
                text: guessResponse.toString(),
                color,
            };
        default:
            return noAnswer;
    }
};
