import { questionPhases } from 'src/config/ui/phases';
import store from 'src/store';
import { T_Phase, T_Quiz, T_Stage } from '../../../../globalTypes';
import { getFirstStage } from './getFirstStage';
import { getNextStage } from './getNextStage';
import { getQuizCounts } from './getQuizCounts';

export type T_CheckingStage =
    | (Omit<T_Stage, 'phase'> & { phase: T_Phase | 'goNextSection' })
    | null;
export const getPreviousStage = (quiz: T_Quiz) => {
    const { quizState } = store.getState();
    const { currentModuleId, currentSectionId, quizPhase } = quizState;

    if (!quiz)
        return {
            moduleId: null,
            sectionId: null,
            phase: 'quizTips',
        };
    const { totalQuestionCount, thesisCount } = getQuizCounts(quiz);
    const firstStage = getFirstStage(quiz);
    let stageHistory: T_CheckingStage[] = [firstStage];
    if (
        firstStage?.moduleId === currentModuleId &&
        firstStage?.sectionId === currentSectionId &&
        firstStage?.phase === quizPhase
    ) {
        return {
            moduleId: null,
            sectionId: null,
            phase: 'quizTips',
        };
    }

    for (
        let i = 0;
        i < (totalQuestionCount + thesisCount) * questionPhases.length;
        i++
    ) {
        if (
            currentModuleId === stageHistory[i]?.moduleId &&
            currentSectionId === stageHistory[i]?.sectionId &&
            quizPhase === stageHistory[i]?.phase
        ) {
            const returnScoreReveal =
                stageHistory[i - 1]?.phase === 'sectionTransition';
            return {
                ...stageHistory[i - 1],
                ...(returnScoreReveal && {
                    ...stageHistory[i - 2],
                }),
            };
        }
        stageHistory = [...stageHistory, getNextStage(quiz, stageHistory[i])];
    }
};
