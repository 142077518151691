import { T_NormalQuestion, T_Section } from '../../../../../globalTypes';
import {
    getQuestionCountById,
    getThesisCountById,
    getQuizCounts,
    sectionIsQuestion,
} from '../../../functions';
import { getQuestionTextFromType } from '../../../functions/getters/getQuestionTextFromType';
import { usePhase, useQuiz, useSection } from '../../../hooks';
import { Picture } from '../../base/picture/Picture';

export const QuestionCounter = ({
    text,
    next,
    nextSection,
}: {
    text?: string;
    next?: boolean;
    nextSection?: T_Section;
}) => {
    const quiz = useQuiz();
    const currentQuestion = useSection();
    const phase = usePhase();
    if (
        !quiz ||
        !currentQuestion ||
        !sectionIsQuestion(currentQuestion) ||
        (next && !nextSection) ||
        (next && !sectionIsQuestion(currentQuestion))
    )
        return null;

    const checkedQuestion = next
        ? (nextSection as T_NormalQuestion)
        : currentQuestion;
    const { totalQuestionCount, thesisCount } = getQuizCounts(quiz);
    const currentQuestionCount = getQuestionCountById(
        quiz,
        checkedQuestion.questionId,
    );
    const currentThesisCount = getThesisCountById(
        quiz,
        checkedQuestion.questionId,
    );
    const { type } = currentQuestion;
    const questionType = getQuestionTextFromType(type)
        ?.replace(' ', '')
        .toLowerCase();
    if (
        (phase === 'sectionTransition' && !next)
    )
        return null;
    return (
        <div className="question-counter">
            <span className="counter-text">
                <span>{currentQuestion.type === 'Thesis' ? currentThesisCount : currentQuestionCount}</span>/
                <span>{currentQuestion.type === 'Thesis' ? thesisCount : totalQuestionCount}</span>
            </span>
            <span className="counter-type">
                <Picture src={`/questionTypes/${questionType}`} />
            </span>
        </div>
    );
};
