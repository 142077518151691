import React from 'react';
import styled from 'styled-components';
import { T_Phase } from '../../../../../globalTypes';
import { usePhase } from '../../../hooks';
export const MainWrapper = ({
    children,
    quizIsLocal,
}: {
    children: React.ReactNode;
    quizIsLocal: boolean;
}) => {
    const phase = usePhase();
    return (
        <Wrapper id="main-wrapper" phase={phase} quizIsLocal={quizIsLocal}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.main<{
    phase: T_Phase | undefined;
    quizIsLocal: boolean;
}>`
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    align-items: center;
    //TODO: header height en footer gelijke hoogte geven
    //203px is the height of the header (88px) and footer (115px)
    height: calc(70% - 203px);
    justify-content: center;
    padding: ${props => {
        switch (props.phase) {
            case 'quizSelection':
                return '0px';
            default:
                return '0 var(--layout-gap)';
        }
    }};
`;
