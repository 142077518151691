import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { T_RootState, selectInteractionCooldown } from 'src/store';
import styled from 'styled-components';
import { RowInformation } from './RowInformation';
import { T_ListInformation } from './SchoolList';
import './style.scss';

export const ListRow = ({
    listInformation,
    totalListCount,
    index,
    reducer,
    cardSelector,
}: {
    listInformation: T_ListInformation;
    totalListCount: number;
    index: { latest: number; current: number };
    reducer: ActionCreatorWithPayload<any>;
    cardSelector: () => (state: T_RootState) => number | null | undefined;
}) => {
    const { id, title, tags, cardColor } = listInformation;
    const { latest } = index;
    const dispatch = useAppDispatch();
    const selectedCardId = useAppSelector(cardSelector());
    const interactionCooldown = useAppSelector(selectInteractionCooldown());
    const ref = useRef<HTMLDivElement>(null);
    const isSelected: boolean = selectedCardId === id;
    const otherIsSelected: boolean =
        selectedCardId !== undefined &&
        Number.isInteger(selectedCardId) &&
        !isSelected;

    const onClick = () => {
        if (interactionCooldown) return;
        dispatch(reducer(id));
    };
    return (
        <AnimatePresence>
            <AnimatedCard
                totalListCount={totalListCount}
                isSelected={isSelected}
                otherIsSelected={otherIsSelected}
                cardColor={cardColor}
                index={latest}
                key={id}
                ref={ref}
                onClick={() => onClick()}
                transition={{ duration: 0.2, ease: 'easeOut' }}
            >
                <RowInformation
                    tags={tags}
                    title={title}
                    isSelected={isSelected}
                    otherIsSelected={otherIsSelected}
                    index={latest}
                />
            </AnimatedCard>{' '}
        </AnimatePresence>
    );
};

const Wrapper = styled.div`
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    scroll-behavior: smooth;
    word-wrap: break-word;
    overflow-wrap: break-word;
`;

const AnimatedCard = motion(Wrapper);
