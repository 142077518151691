import { getResponseIndices } from 'src/functions/getters/getResponseIndices';
import { T_AppDispatch } from '..';
import { T_RawState, T_TeamResponse } from '../../../../globalTypes';
import { handlePostRequest } from './handlePostRequest';

export const postResponses = (responses: T_TeamResponse[]) => async (
    dispatch: T_AppDispatch,
    getState: () => T_RawState,
) => {
    try {
        const gameId = getState().quizState?.gameId;
        const quiz = getState().quizState?.quiz;
        if (!quiz) throw new Error('No quiz found');
        if (!gameId) throw new Error('No gameId found');
        const indexedResponses = getResponseIndices({ quiz, responses });
        dispatch(
            handlePostRequest({
                postData: { responses: indexedResponses, gameId },
                endPoint: '/responses/postTeamResponses',
            }),
        );
    } catch (error) {
        console.log(error);
    }
};
