import { T_Question, T_Team, T_TeamResponse } from '../../../../globalTypes';
export const getTeamResult = (
    team: T_Team,
    question: T_Question,
): T_TeamResponse | undefined => {
    const { responses } = team;
    const { questionId } = question;
    return responses.find(
        (response: T_TeamResponse) => response.questionId === questionId,
    );
};
