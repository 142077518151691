import { useEffect } from 'react';

export const useNumberControls = ({
    numberEvents,
}: {
    numberEvents: { number: number; event: () => void }[];
}) => {
    const activeNumbers = numberEvents.map(({ number }) => number);
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const number = Number(e.key);
            if (activeNumbers.includes(number)) {
                numberEvents.find(({ number: n }) => n === number)?.event();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);
};
