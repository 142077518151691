//Welcome, dear stranger, to the FooterNextButton component
//This component is responsible for rendering the next button in the footer
//It is used in the Footer component
//It is used in the RenderedQuizPage component
//It is used in the QuizPage component
//It is used in the TeamPage component
//It is used in the QuestionAnswersPage component
//It is used in the QuestionResponsePage component
//It is used in the DecisionResponsePage component
//It is used in the DecisionQuestionPage component
//It is used in so many components you might break the app
import {
    allTeamsHaveResponded,
    castEvent,
    castNextEvent,
    createLocalEvent,
    createSocketEvent,
    drawNextButtonText,
    sectionIsQuestion,
} from 'src/functions';
import { wait } from 'src/functions/helpers/wait';
import { drawStartButton } from 'src/functions/ui/drawers/drawStartButton';
import {
    useAppDispatch,
    useAppSelector,
    useEvent,
    useInteractionOpen,
    useNavigateLevel,
    useQuizIsLocal,
    useSection,
    useSocket,
    useTeamCount,
    useTeams,
    useTimer,
} from 'src/hooks';
import {
    T_RootState,
    forceConnect,
    selectActiveLectureLevel,
    setLocalTeams,
    setPhase,
    setQuizFromQuizId,
    setQuizIsLocalChosen,
    setWarningPopUp, setLectureIsTrial, setLectureIsWithoutCode,
} from 'src/store';
import { T_NormalQuestion, T_Phase, T_Team } from '../../../../../globalTypes';
import { FooterButton } from './FooterButton';

import { useIsPreview } from 'src/hooks/time/useIsPreview';

export const FooterNextButton = ({ phase }: { phase?: T_Phase }) => {
    const dispatch = useAppDispatch();
    const socket = useSocket();
    const selectedQuizId = useAppSelector(
        (state: T_RootState) => state.quizState.selectedQuizId,
    );
    const selectedQuiz = useAppSelector(state => {
        return state.quizState.quizList?.find(
            quiz => selectedQuizId === quiz.id,
        );
    });
    const showFooterButton = useAppSelector(
        state => state.appState.showFooterButton,
    );
    const roomId = useAppSelector(state => state.socketState.roomId);
    const teamCount = useTeamCount();
    const teams = useTeams();
    const section = useSection();
    const timer = useTimer();
    const currentQuestion = useSection();
    const quizIsLocal = useQuizIsLocal();
    const quizIsLocalChosen = useAppSelector(
        state => state.quizState.quizIsLocalChosen,
    );
    const activeSchool = useAppSelector(
        state => state.appState.activeLectureId,
    );
    const activeLevel = useAppSelector(selectActiveLectureLevel());
    const videoIsPlaying = useInteractionOpen('videoPlaying');

    const isDateInRange = useIsPreview();

    const navigate = useNavigateLevel();
    const createRoomEvent = useEvent({
        reducer: setPhase,
        endPoint: 'createRoom',
        payload: 'lobby',
        socketPayload: selectedQuizId,
    });
    if (!showFooterButton) return null;
    if (phase?.endsWith('-images')) {
        return (
            <FooterButton
                onClickEvent={() => {
                    castNextEvent({ socket, dispatch });
                }}
                text={'Verder'}
            />
        );
    }
    switch (phase) {
        case 'quizSelection':
            const quizIsSelected =
                selectedQuizId !== null && selectedQuizId !== undefined;
            return (
                <FooterButton
                    disableWrapperAnimation={true}
                    key={quizIsSelected.toString()}
                    text="Verder"
                    isActive={quizIsSelected}
                    onClickEvent={async () => {
                        dispatch(setQuizFromQuizId());
                        if (selectedQuiz && selectedQuiz?.isNotLocal) {
                            dispatch(setQuizIsLocalChosen(true));
                            dispatch(forceConnect());
                            createRoomEvent();
                            dispatch(setPhase('lobby'));
                            await wait(250);
                            navigate('/quiz-digibord');
                            return;
                        }
                        dispatch(setPhase('localToggle'));
                    }}
                />
            );

        case 'quizThanks':
            return (
                <FooterButton
                    disableWrapperAnimation={true}
                    text="Naar het begin"
                    additionalText="Nog een keer spelen?"
                    onClickEvent={() => {
                        if (isDateInRange) {
                            dispatch(setPhase('trialStart'));
                            dispatch(setLectureIsTrial(true));
                            dispatch(setLectureIsWithoutCode(true));
                            navigate('/speel-proef-versie');
                        }
                        if (!isDateInRange) {
                            dispatch(setPhase('codeStart'));
                            navigate('/selection');
                        }
                    }}
                />
            );
        case 'localToggle':
            return (
                <FooterButton
                    disableWrapperAnimation={true}
                    key={quizIsLocalChosen.toString()}
                    text="Verder"
                    isActive={quizIsLocalChosen}
                    onClickEvent={() => {
                        createRoomEvent();
                        navigate('../quiz-digibord');
                    }}
                />
            );
        case 'lobby':
            const teamCountSelected = teamCount !== undefined && teamCount > 0;
            return (
                <FooterButton
                    disableWrapperAnimation={true}
                    isActive={teamCountSelected}
                    onClickEvent={() => {
                        if (!teamCount || (!quizIsLocal && !roomId)) return;
                        castEvent(createLocalEvent(dispatch, setLocalTeams));
                        castEvent(
                            createLocalEvent(dispatch, setPhase, 'quizTips'),
                        );
                        castEvent(
                            createSocketEvent(socket, 'startRoom', roomId),
                        );
                    }}
                    text={drawStartButton({ teamCount })}
                />
            );
        case 'lectureSelection':
            const lectureIsSelected = activeSchool !== null;
            return (
                <FooterButton
                    disableWrapperAnimation={true}
                    isActive={lectureIsSelected}
                    onClickEvent={() => {
                        dispatch(setPhase('quizSelection'));
                    }}
                    text="Verder"
                />
            );
        case 'trialStart':
            const levelIsSelected = activeLevel !== null;
            return (
                <FooterButton
                    disableWrapperAnimation={true}
                    isActive={levelIsSelected}
                    onClickEvent={() => {
                        if (activeLevel === 'PO') {
                            dispatch(setPhase('quizSelection'));
                            navigate('../selection');
                        } else {
                            if (activeLevel) {
                                window.location.href = `https://cashquiz-${activeLevel.toLowerCase()}.bankvoordeklas.nl/?oefensessie`;
                            }
                        }
                    }}
                    text="Verder"
                />
            );
        case 'quizFirstReward':
        case 'quizFirstQuestionIntro':
        case 'quizTips':
        case 'quizIntroVideo':
        case 'quizIntro':
        case 'questionIntro':
        case 'questionWhatsappInteraction':
        case 'question':
        case 'slide':
        case 'answerReveal':
        case 'scoreReveal':
        case 'questionVideo':
        case 'questionOutro':
        case 'decision':
        case 'decisionQuestion':
        case 'decisionQuestionReveal':
        case 'decisionDraw':
        case 'questionOutroMobile':
        case 'quizOutro':
        case 'quizResults':
        case 'quizWrapUp':
        case 'sectionTransition':
            return (
                <FooterButton
                    disableButtonAnimation={true}
                    disableWrapperAnimation={phase === 'quizOutro'}
                    phase={phase}
                    isGhost={
                        (phase === 'question' && timer > 0) ||
                        (phase === 'decisionQuestion' && timer > 0)
                    }
                    additionalText={phase === 'question' && 'Iedereen klaar?'}
                    onClickEvent={() => {
                        if (phase === 'questionVideo' && videoIsPlaying) {
                            dispatch(
                                setWarningPopUp({
                                    onAccept: 'next',
                                    onReject: 'end',
                                    text: 'Wil je echt verder?',
                                    acceptText: 'Ja',
                                    rejectText: 'Nee',
                                    invertedPosition: true,
                                }),
                            );
                            return;
                        }
                        castNextEvent({ socket, dispatch });
                    }}
                    text={drawNextButtonText({
                        phase,
                        section,
                    })}
                />
            );
        case 'questionVideo':
        case 'questionWhatsapp':
            return (
                <FooterButton
                    disableButtonAnimation={true}
                    onClickEvent={() => {
                        castNextEvent({ socket, dispatch });
                    }}
                    text={'Start tijd'}
                />
            );
        case 'decisionResponse':
        case 'questionResponse':
            const onClick = (): void => {
                if (
                    teams &&
                    sectionIsQuestion(currentQuestion) &&
                    !allTeamsHaveResponded({ teams, currentQuestion })
                ) {
                    dispatch(
                        setWarningPopUp({
                            onAccept: 'next',
                            onReject: 'end',
                            text:
                                'Nog niet ieder antwoord is verstuurd, toch verder gaan?',
                            acceptText: 'Ja, ga verder',
                            rejectText: 'Nee, ga terug',
                            invertedPosition: true,
                        }),
                    );
                    return;
                }
                castNextEvent({ socket, dispatch });
            };
            if (
                (!currentQuestion || !sectionIsQuestion(currentQuestion)) &&
                phase !== 'decisionResponse'
            )
                return null;
            if (!teams) return null;
            return (
                <FooterButton
                    key={phase}
                    disableButtonAnimation={true}
                    isGhost={
                        phase !== 'decisionResponse'
                            ? !allTeamsHaveResponded({
                                  teams,
                                  currentQuestion,
                              } as { teams: T_Team[]; currentQuestion: T_NormalQuestion })
                            : !allTeamsHaveResponded({
                                  teams,
                                  currentQuestion,
                                  decision: true,
                              } as { teams: T_Team[]; currentQuestion: T_NormalQuestion; decision: true })
                    }
                    onClickEvent={() => onClick()}
                    additionalText="Alle antwoorden ingevuld?"
                    text={drawNextButtonText({
                        phase,
                        section,
                    })}
                />
            );
        default:
            return null;
    }
};
