import { FC, useEffect } from 'react';
import { usePhase, useQuizIsLocal } from '../../hooks';

import { useNavigate } from 'react-router-dom';
import { TeamsStatus } from 'src/components';
import { KeyControls } from 'src/components/controls/KeyControls';
import { teamResultBoxPhases } from 'src/config/ui/phases';
import { CentralWrapper } from '../../layout/styledcomponents/wrappers/CentralWrapper';
import { RenderedQuizPage } from './quizPage/RenderedQuizPage';

export const QuizPage: FC = () => {
    const phase = usePhase();
    const navigate = useNavigate();
    const quizIsLocal = useQuizIsLocal();
    useEffect(() => {
        if (!phase || ['quizSelection', 'preStart'].includes(phase)) {
            navigate('..');
        }
    });
    return (
        <>
            <CentralWrapper enableAutoAnimation={true}>
                <KeyControls disableScroll={true} />
                <RenderedQuizPage phase={phase} />
            </CentralWrapper>
            {!quizIsLocal && teamResultBoxPhases.includes(phase) && (
                <TeamsStatus />
            )}
        </>
    );
};
