import { useSection } from '../quiz';
import { useTeams } from './useTeams';

export const useCorrectTeams = () => {
    const teams = useTeams();
    const currentSection = useSection();
    if (!teams) return [];
    const allTeams = teams
        ?.map(team => {
            const response = team.responses.find(
                response => response.sectionId === currentSection?.id,
            );
            if (!response) return null;
            const {isCorrect} = response
            return {
                id: team.id,
                isCorrect: 
                    isCorrect === 'YES' ||  isCorrect === 'WON_GUESS'
            };
        })
        .filter(team => team !== null && team.isCorrect === true);
    return allTeams.map(team => team?.id);
};
