import classNames from 'classnames';
import styled from 'styled-components';
import { T_TextSize } from '../../../../../globalTypes';

export const InfoText = ({
    text,
    subText,
    textSize,
}: {
    text?: JSX.Element;
    textSize?: T_TextSize;
    subText?: JSX.Element;
}) => {
    return (
        <InfoTextWrapper
            className={classNames({
                'size-28': textSize === 'small',
                'size-32': textSize === 'medium' || !textSize,
                'size-36': textSize === 'large',
            })}
        >
            <>{text}</>
            {subText && <p>{subText}</p>}
        </InfoTextWrapper>
    );
};

const InfoTextWrapper = styled.div`
    *:not(:last-child) {
        margin-bottom: 24px;
    }
`;
