import { useInteractionOpen } from 'src/hooks';
import { useArrowNavigation } from 'src/hooks/controls/useArrowNavigation';
import { useForcedNavigation } from 'src/hooks/controls/useForcedNavigation';
import { useScrollControls } from 'src/hooks/controls/useScrollControls';

export const KeyControls = ({ disableScroll }: { disableScroll?: boolean }) => {
    const forceForward = useForcedNavigation('forward');
    const forceBack = useForcedNavigation('back');
    const warningPopUpOpen = useInteractionOpen('warningPopUp');
    useArrowNavigation();
    useScrollControls({
        scrollEvent: scrollY => {
            if (disableScroll) return;
            if (scrollY > 0) {
                forceBack(warningPopUpOpen);
            }
            if (scrollY < 0) {
                forceForward(warningPopUpOpen);
            }
        },
        dependencies: [warningPopUpOpen],
    });
    return null;
};
