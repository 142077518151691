import classNames from 'classnames';
import { motion } from 'framer-motion';
import { drawTeamAnswer } from 'src/functions/ui/drawers/drawTeamAnswer';
import { usePhase } from 'src/hooks';
import styled from 'styled-components';
import {
    T_QuestionType,
    T_QuestionTypeData,
    T_TeamResponse,
} from '../../../../../globalTypes';
import { getTeamColor } from '../../../functions/ui/getters/getTeamColor';

export const TeamAnswer = ({
    teamResponse,
    questionType,
    typeData,
    teamId,
}: {
    teamResponse?: T_TeamResponse | null;
    questionType: T_QuestionType;
    typeData?: T_QuestionTypeData;
    teamId: number;
}) => {
    const phase = usePhase();
    const { text } =
        phase === 'quizFirstReward'
            ? { text: '' }
            : drawTeamAnswer({
                  teamResponse,
                  questionType,
                  typeData,
              });
    return (
        <ScoreNumberTeamColor
            className={classNames('score-number-team-color', {
                'font-sm':
                    questionType === 'Closed' && teamResponse?.response === 2,
            })}
            $teamId={teamId}
        >
            {text}
        </ScoreNumberTeamColor>
    );
};

const ScoreNumberTeamColor = styled(motion.span)<{
    $teamId: number;
}>`
    //color: ${props => getTeamColor(props.$teamId)};
`;
