import {
    T_DatabaseResponseCorrectType,
    T_NormalQuestion,
    T_Question,
    T_TeamResponse,
} from '../../../../../globalTypes';
import { arrayKeepNumbers } from '../arrays/arrayKeepNumbers';
import { calculatePointsPerAnswer } from './calculatePointsPerAnswer';
//this function awards points based on the response given in the teamResponse. The question is required in order to determine the type of question and to
//provide the amount of points that are awarded.
export const calculateAwardedPoints = (
    question: T_Question,
    teamResponse: Pick<T_TeamResponse, 'response'>,
): { points: number; isCorrect: T_DatabaseResponseCorrectType } => {
    const { points, isCorrect } = getAwardedPoints(question, teamResponse);
    return { points: points <= 0 ? 0 : points, isCorrect };
};
export const getAwardedPoints = (
    question: T_Question,
    teamResponse: Pick<T_TeamResponse, 'response'>,
): { points: number; isCorrect: T_DatabaseResponseCorrectType } => {
    const { response } = teamResponse;
    switch (question.type) {
        case 'MPC': {
            const { correctAnswers } = question;
            if (!Array.isArray(response) || !correctAnswers)
                return { points: 0, isCorrect: 'NO' };
            const pointsPerAnswer = calculatePointsPerAnswer(
                question as T_NormalQuestion<'MPC'>,
            );
            const correctResponses = arrayKeepNumbers(response).filter(
                (_response: number) => {
                    return correctAnswers.includes(_response);
                },
            );
            const points = correctResponses.length * pointsPerAnswer;
            return {
                points,
                isCorrect: points > 0 ? 'YES' : 'NO',
                //Use the stuff below whenever multiple correct answers are required again
                //I DONT REMEMBER WHY I DID THIS KEKW
                // points >= correctAnswers.length * pointsPerAnswer
                //     ? 'YES'
                //     : points > 0
                //     ? 'PARTIAL'
                //     : 'NO',
            };
        }
        case 'Open': {
            const desiredAnswers = (question as T_NormalQuestion<'Open'>)
                .typeData?.desiredAnswers;
            if (typeof response !== 'number' || !desiredAnswers)
                return { points: 0, isCorrect: 'NO' };
            const pointsPerAnswer = calculatePointsPerAnswer(
                question as T_NormalQuestion<'Open'>,
            );
            const points = pointsPerAnswer * response;
            return {
                points,
                isCorrect:
                    points >= desiredAnswers * pointsPerAnswer
                        ? 'YES'
                        : points > 0
                        ? 'PARTIAL'
                        : 'NO',
            };
        }
        case 'Closed': {
            if (
                ((question as T_NormalQuestion<'Closed'>).typeData
                    ?.leftCorrect === true &&
                    response === 1) ||
                (!(question as T_NormalQuestion<'Closed'>).typeData
                    ?.leftCorrect &&
                    response === 2)
            ) {
                return {
                    points: question.points,
                    isCorrect: 'YES',
                };
            }
            return { points: 0, isCorrect: 'NO' };
        }
        case 'Poll':
        case 'Thesis':
        case 'Guess': {
            //0 for Guess questions since the points are distributed for guess questions later in the reducer, we
            //set these to 0 in order to ensure no one is falsely retrieving points.
            return { points: 0, isCorrect: null };
        }
        case 'Decision':
            return { points: 0, isCorrect: 'NO' };
        case 'Start':
            return { points: 10, isCorrect: 'YES' };
    }
};
