import { sectionIsQuestion } from 'src/functions/checkers';
import { getNextPhase, getQuestionCountById } from 'src/functions/getters';
import { getFinalSectionId } from 'src/functions/getters/getFinalSectionId';
import store from 'src/store';
import {
    T_NormalQuestion,
    T_QuizPhase,
    T_Section,
} from '../../../../../globalTypes';

//retrieves the corresponding text for the next button based on state
export const drawNextButtonText = ({
    phase,
    section,
}: {
    phase: T_QuizPhase | undefined;
    section?: T_Section;
}): string => {
    if (phase === 'decision') return 'Toon beslissingsvraag';
    if (!phase) return 'Verder';

    const nextPhase = getNextPhase(phase, section);

    switch (nextPhase) {
        case 'answerReveal':
            return 'Toon antwoord';
        case 'scoreReveal':
            const { quizState, appState } = store.getState();
            const { config } = appState;
            if (!quizState.quiz) return 'Toon score';
            const questionCount = getQuestionCountById(
                quizState.quiz,
                (section as T_NormalQuestion)?.questionId || 0,
            );
            if (
                !questionCount ||
                config.scoreRevealIndices.includes(questionCount)
            ) {
                return 'Toon tussenscore';
            }
            return 'Toon score';
        case 'quizIntroVideo':
            return 'Naar introductie';
        case 'questionOutro':
            return 'Toelichting';
        
        default:
            break;
    }
    switch (phase) {
        case 'questionResponse':
        case 'decisionResponse':
            if (sectionIsQuestion(section) && section?.type === 'Open') {
                return 'Toon toelichting';
            }
            return 'Toon antwoord';

        case 'quizIntro':
            return 'Start quiz';
        case 'quizFirstQuestionIntro':
            return 'Toon video'
        case 'sectionTransition':
                // TODO : dit moet nog confitioneel alleen voor video vragen
                return 'Start video';
        
            
        case 'quizOutro':
            return 'Toon eindscores';
        case 'quizResults':
            return 'Afronden';
        case 'quizWrapUp':
            return 'Toon eindstand';
        case 'questionVideo':
            if (sectionIsQuestion(section) && !section?.introText) {
                return 'Toon vraag';
            }
            return 'Verder';
        case 'questionIntro':
        case 'questionWhatsappInteraction':
            if (section && section.type === 'Thesis') {
                return 'Toon stelling';
            }
            return 'Toon vraag';
        case 'scoreReveal':
            if (sectionIsQuestion(section)) {
                const quiz = store.getState().quizState.quiz;
                if (getFinalSectionId(quiz) === section.id)
                    return 'Afronden';
            }
            return 'Verder';
        
        case 'slide':
        case 'question':
        case 'decisionQuestion':
        default:
            return 'Verder';
    }
};