import { drawTeamEnterAnswerText } from 'src/functions/ui/drawers/drawTeamEnterAnswerText';
import { T_DecisionQuestion, T_Question } from '../../../../../../globalTypes';
import { questionIsNotDecisionQuestion } from '../../../../functions/checkers/questionIsNotDecisionQuestion';
import { usePhase, useQuizIsLocal } from '../../../../hooks';
import {
    ClosedResponseSection,
    GuessResponseSection,
    MPCResponseSection,
    OpenResponseSection,
    OpenValidationSection,
} from './index';

export const ResponseSection = <T extends boolean>({
    question,
    inDecision,
}: {
    question: T extends true ? T_DecisionQuestion : T_Question;
    inDecision?: T;
}) => {
    const quizIsLocal = useQuizIsLocal();
    const currentPhase = usePhase();
    if (inDecision) {
        return (
            <GuessResponseSection
                question={question}
                inDecision={currentPhase === 'decisionResponse'}
            />
        );
    }
    if (
        questionIsNotDecisionQuestion(question) &&
        !quizIsLocal &&
        question.type === 'Open'
    ) {
        return <OpenValidationSection question={question} />;
    }
    if (quizIsLocal) {
        if (!questionIsNotDecisionQuestion(question)) return null;
        switch (question.type) {
            case 'Open':
                return <OpenResponseSection question={question} />;
            case 'Guess':
                return <GuessResponseSection question={question} />;
            case 'Thesis':
            case 'Closed':
                return <ClosedResponseSection />;
            case 'MPC':
                return <MPCResponseSection question={question} />;
            default:
                return null;
        }
    }
    if (!quizIsLocal) {
        return <>{drawTeamEnterAnswerText()}</>;
    }

    return null;
};
