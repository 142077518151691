import { T_DecisionQuestion, T_Question } from '../../../../globalTypes';

//dirty type helper to distinguish between Question and T_DecisionQuestion, mostly used when there needs to be a seperation of Guess and DecisionQuestions
export const questionIsNotDecisionQuestion = (
    question: T_Question | T_DecisionQuestion,
): question is T_Question => {
    if (!question) return false;
    return (
        'sectionType' in question &&
        (question.sectionType === 'Question' ||
            question.sectionType === 'WAQuestion' ||
            question.sectionType === 'MMQuestion')
    );
};
