import store, {
    T_AppDispatch,
    decreaseButtonCooldown,
    decreaseInteractionCooldown,
} from '..';

export const handleInteractionTimer = () => async (
    dispatch: T_AppDispatch,
    getState: typeof store.getState,
) => {
    try {
        const {
            interactionCooldown,
            buttonCooldown,
        }: {
            interactionCooldown: number;
            buttonCooldown: number;
        } = getState().appState;
        if (interactionCooldown >= 0) {
            dispatch(decreaseInteractionCooldown());
        }
        if (buttonCooldown > 0) {
            dispatch(decreaseButtonCooldown());
        }
    } catch (error) {
        console.log(error);
    }
};
