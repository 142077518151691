import { motion } from 'framer-motion';
const MotionButton = motion.button;
export const VideoButton = ({
    showPlayButton,
    showReplayButton,
    isPlaying,
    pauseVideo,
    playVideo,
    replayVideo,
}: {
    showPlayButton: boolean;
    showReplayButton: boolean;
    isPlaying: boolean;
    pauseVideo: () => void;
    playVideo: () => void;
    replayVideo: () => void;
}) => {
    const showButton = showPlayButton || showReplayButton;
    return (
        <>
            <MotionButton
                initial={{
                    opacity: 1,
                }}
                //LAZY ASS CODING
                animate={{
                    opacity: showReplayButton
                        ? 1
                        : showPlayButton
                        ? 1
                        : showButton
                        ? 0.3
                        : 0,
                    y: showButton ? 0 : 20,
                }}
                whileHover={{
                    scale: 1.1,
                    opacity: 1,
                    transition: {
                        duration: 0.2,
                        ease: 'easeOut',
                    },
                }}
                exit={{
                    opacity: 0,
                    y: 20,
                    transition: {
                        duration: 0.45,
                        ease: 'easeOut',
                    },
                }}
                className="video-play cursor-pointer"
                onClick={() => {
                    if (!showButton) return;
                    if (showPlayButton || showReplayButton) {
                        if (isPlaying && !showReplayButton) {
                            pauseVideo();
                        }
                        if (!isPlaying && !showReplayButton) {
                            playVideo();
                        }
                        if (showReplayButton) {
                            replayVideo();
                        }
                    }
                }}
            >
                {!isPlaying && !showReplayButton && (
                    <svg
                        className="play-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 15.96 19.93"
                    >
                        <path d="M15.24 8.72a1.45 1.45 0 0 1 0 2.5L2.18 19.73c-.69.41-1.58.17-1.99-.52-.13-.23-.19-.48-.19-.74V1.45C0 .65.65 0 1.45 0c.26 0 .51.06.74.19L15.24 8.7Z" />
                    </svg>
                )}
                {isPlaying && !showReplayButton && (
                    <svg
                        className={isPlaying ? 'pause-btn' : 'pause-btn'}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52.77 70"
                    >
                        <path d="M0 0h15v80H0zM27.77 0h15v80h-15z"></path>
                    </svg>
                )}
                {showReplayButton && (
                    <svg
                        className="replay-icon"
                        viewBox="0 0 18 21"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M9 21C7.75 21 6.57933 20.7627 5.488 20.288C4.39667 19.8133 3.44667 19.1717 2.638 18.363C1.82933 17.5543 1.18767 16.6043 0.713 15.513C0.238333 14.4217 0.000666667 13.2507 0 12H2C2 13.95 2.67933 15.6043 4.038 16.963C5.39667 18.3217 7.05067 19.0007 9 19C10.95 19 12.6043 18.321 13.963 16.963C15.3217 15.605 16.0007 13.9507 16 12C16 10.05 15.321 8.396 13.963 7.038C12.605 5.68 10.9507 5.00067 9 5H8.85L10.4 6.55L9 8L5 4L9 0L10.4 1.45L8.85 3H9C10.25 3 11.421 3.23767 12.513 3.713C13.605 4.18833 14.555 4.83 15.363 5.638C16.171 6.446 16.8127 7.396 17.288 8.488C17.7633 9.58 18.0007 10.7507 18 12C18 13.25 17.7627 14.421 17.288 15.513C16.8133 16.605 16.1717 17.555 15.363 18.363C14.5543 19.171 13.6043 19.8127 12.513 20.288C11.4217 20.7633 10.2507 21.0007 9 21Z" />
                    </svg>
                )}
            </MotionButton>
        </>
    );
};
