import { AnimatePresence } from 'framer-motion';
import { ComponentProps } from 'react';
import { VideoButton } from './VideoButton';

export const VideoOverlay = ({
    onMouseEnter,
    onMouseLeave,
    className,
    showButton,
    ...props
}: {
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    className?: string;
    showButton?: boolean;
} & ComponentProps<typeof VideoButton>) => {
    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={className}
        >
            <AnimatePresence>
                {showButton && <VideoButton {...props} />}
            </AnimatePresence>
        </div>
    );
};
