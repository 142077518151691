import { FC } from 'react';
import { usePhase } from '../../hooks';
import { CentralWrapper } from '../../layout/styledcomponents/wrappers/CentralWrapper';
import { RenderedSelectionPage } from './selectionPage/RenderedSelectionPage';
export const SelectionPage: FC = () => {
    const quizPhase = usePhase();
    return (
        <CentralWrapper>
            <RenderedSelectionPage quizPhase={quizPhase} />
        </CentralWrapper>
    );
};
