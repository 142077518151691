import { getHexContrast, getHexShadow } from 'src/functions';
import { getTeamColor } from 'src/functions/ui/getters/getTeamColor';
import { teamStyle } from 'src/layout/teamStyle';
import styled from 'styled-components';
import { usePhase, useSelfTeam } from '../../../hooks';
import { TeamHeaderLogo } from './TeamHeaderLogo';
export const TeamHeader = () => {
    const team = useSelfTeam();
    const teamColor = getTeamColor(team?.id || 1);
    const phase = usePhase();
    const showTeamName = team?.teamName?.id !== 0 && team?.teamName;
    return (
        <header>
            <StyledSocketWrapper
                className="socket-logo-wrapper font-sans"
                $nameChosen={
                    team?.teamName?.id !== 0 && team?.teamName !== undefined
                }
                $teamColor={teamColor}
            >
                {showTeamName && (
                    <>
                        <StyledTeamName $teamColor={teamColor}>
                            {phase !== 'lobby' && (
                                <strong>Team {team.id}</strong>
                            )}
                        </StyledTeamName>
                        <StyledTeamName $teamColor={teamColor}>
                            {team?.teamName?.name}
                        </StyledTeamName>
                    </>
                )}
                {!team?.teamName?.id && <TeamHeaderLogo />}
            </StyledSocketWrapper>
        </header>
    );
};

const StyledSocketWrapper = styled.div<{
    $nameChosen?: boolean;
    $teamColor?: string;
}>`
    background: ${props => {
        if (props.$nameChosen) {
            if (props.$teamColor) {
                return props.$teamColor;
            }
            return teamStyle.pageFooter;
        }
        return teamStyle.pageBackground;
    }};
    border-color: ${props => {
        if (props.$nameChosen) {
            if (props.$teamColor) {
                return getHexShadow(props.$teamColor);
            }
            return teamStyle.pageFooter;
        }
        return teamStyle.pageBackground;
    }};
`;

const StyledTeamName = styled.p<{ $teamColor?: string }>`
    color: ${props => {
        if (props.$teamColor) {
            return getHexContrast(props.$teamColor);
        }
        return teamStyle.pageFooter;
    }};
`;
