import { NavigateFunction } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import store, {
    T_AppDispatch,
    continueTime,
    resetInteractions,
    setPhase,
} from '..';
import { appStorage, castNextEvent, castPreviousEvent } from '../../functions';
import { castEndEvent } from '../../functions/events/castEndEvent';
import { handleGameExit } from './handleGameExit';

export const handleWarningPopUp = (
    type: 'accept' | 'reject',
    { socket, navigate }: { socket: Socket; navigate?: NavigateFunction },
) => async (dispatch: T_AppDispatch, getState: typeof store.getState) => {
    try {
        const { warningPopUp } = getState().appState.interactions;
        const { quizIsLocal } = getState().quizState;
        if (type === 'accept') {
            switch (warningPopUp.options.onAccept) {
                case 'next':
                    castNextEvent({ socket, dispatch, forced: true });
                    break;
                case 'gameEnd':
                    castEndEvent(socket, dispatch);
                    break;
                case 'endQuiz':
                    dispatch(handleGameExit());
                    if (navigate) {
                        if (!quizIsLocal) {
                            setPhase('quizSelection');
                        }
                        navigate('/selection');
                    }
                    break;
                case 'leaveLobby':
                    appStorage.clear();
                    dispatch(setPhase('quizSelection'));
                    if (socket) {
                        socket.emit('endLobby');
                    }
                    if (navigate) {
                        navigate('/selection');
                    }
                    break;
                case 'previous':
                    castPreviousEvent({ socket, dispatch, forced: true });
                    break;
            }
        }
        if (type === 'reject') {
            switch (warningPopUp.options.onReject) {
                case 'continueQuestion':
                    dispatch(continueTime());
                    break;
                case 'end':
                default:
                    break;
            }
        }
        dispatch(resetInteractions());
    } catch (error) {
        console.log(error);
    }
};
