import classNames from 'classnames';
import { motion } from 'framer-motion';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { T_RawTeamResponse } from '../../../../../../../globalTypes';
import { GuessReduceButton } from './GuessReduceButton';
import './style.scss';

export type GuessNumberInputBoxProps = ComponentProps<
    typeof GuessNumberInputBox
>;
export const GuessNumberInputBox = ({
    quizIsLocal,
    selfHasResponded,
    value,
    unitMaxLength,
    setValue,
    rawTeamResponse,
    number,
    span,
    unit,
}: {
    quizIsLocal: boolean;
    selfHasResponded: boolean;
    value: number;
    unit?: string;
    unitMaxLength: number | undefined;
    rawTeamResponse: null | T_RawTeamResponse | undefined;
    setValue: Function;
    number: number | null;
    span?: number | null;
}) => {
    const onNumberClick = (_number: number | null) => {
        if (!quizIsLocal && selfHasResponded) return;

        if (
            value &&
            unitMaxLength &&
            value.toString().length >= unitMaxLength &&
            _number !== null
        )
            return;
        if (value === 0 && _number !== null) {
            setValue(_number);
            return;
        }
        if (_number === null && rawTeamResponse) {
            setValue(Math.floor(Number(rawTeamResponse.response) / 10));
            return;
        }
        if (_number === null) {
            setValue((value: number) => Math.floor(value / 10));
            return;
        }
        setValue((value: number) => {
            const newValue = value * 10 + _number;
            if (unit === '%' && newValue.toString().length >= 4) return value;
            return newValue;
        });
    };
    if (number === null) {
        return <GuessReduceButton onNumberClick={onNumberClick} />;
    }
    const styledNumberBoxClassnames = classNames('guess-number-box', {
        span: span,
    });
    return (
        <StyledNumberBox
            className={styledNumberBoxClassnames}
            key={number}
            onClick={() => onNumberClick(number)}
        >
            {number || number === 0 ? number : 'MIN'}
        </StyledNumberBox>
    );
};

const StyledNumberBox = styled(motion.div)<{
    $incorrect?: boolean;
    correct?: boolean;
}>`
    border-radius: 8px;
    border: 1px solid #5b5b5b;
    background: #f2f2f2;
    color: #5b5b5b;
    box-shadow: 0px 2px 0px 0px #3b3b3b;
`;
