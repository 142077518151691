import { ClosedOptionsWrapper } from 'src/components/quiz/questionAnswerPage/AnswerDisplay/Closed/ClosedOptionsWrapper';
import { ClosedDisplay } from '../../../questionAnswerPage/AnswerDisplay/Closed';

export const ClosedResponseSection = () => {
    return (
        <ClosedOptionsWrapper>
            <ClosedDisplay />
        </ClosedOptionsWrapper>
    );
};
