import React from 'react';

import styled from 'styled-components';

export const WidthWrapper = ({
    children,
    full,
    value,
}: {
    children: React.ReactNode;
    full?: boolean;
    value?: string;
}) => {
    let width = '50%';
    if (full) width = '100%';
    if (value) width = value;
    return <Wrapper width={width}>{children}</Wrapper>;
};

const Wrapper = styled.div<{ width: string }>`
    width: ${props => (props.width ? props.width : '100%')};
    display: flex;
    justify-content: center;
`;
