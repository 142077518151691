import classNames from 'classnames';
import { motion } from 'framer-motion';
import { getHexShadow } from 'src/functions/ui/getters/getHexShadow';
import styled from 'styled-components';
import { CardIcon } from './CardIcon';

export const CardInformation = ({
    title,
    index,
    isSelected,
    otherIsSelected,
    yOffset,
    cardColor,
    totalQuizCount,
    icon,
    titleClassName,
}: {
    title: string;
    index: number;
    isSelected: boolean;
    otherIsSelected: boolean;
    yOffset: number;
    cardColor?: string;
    totalQuizCount?: number;
    icon?: string;
    titleClassName?: string;
}) => {
    return (
        <CardWrapper
            yOffset={yOffset}
            index={index}
            isSelected={isSelected}
            otherIsSelected={otherIsSelected}
            cardColor={cardColor}
            className={classNames('quiz-card-information', {
                'cursor-pointer':
                    typeof totalQuizCount === 'number' && totalQuizCount <= 6,
            })}
        >
            {icon && <CardIcon icon={icon} />}
            <div className="quiz-text-wrapper title">
                <MotionDiv className="flex items-center justify-center">
                    <div
                        className={classNames(
                            '_size-32 _rinput flex justify-center items-center',
                            {
                                selected: isSelected,
                                aselected: !isSelected,
                            },
                        )}
                    >
                        <MotionInput
                            animate={{
                                scale: isSelected ? 1 : 0,
                            }}
                            transition={{
                                duration: 0.35,
                                ease: 'easeInOut',
                            }}
                            type="radio"
                            className={classNames(
                                ' _size-16 rounded-full mt-auto',
                                {
                                    'bg-nassau': isSelected,
                                },
                            )}
                        />
                    </div>
                </MotionDiv>
                <p className={classNames('quiz-card-title', titleClassName)}>
                    {title}
                </p>
            </div>
            {/*{tags && <CardTags tags={tags} />}*/}
        </CardWrapper>
    );
};

const CardWrapper = styled.div<{
    index: number;
    isSelected: boolean;
    otherIsSelected: boolean;
    yOffset: number;
    cardColor?: string;
}>`
    //padding: 32px;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    background: ${props => {
        const { cardColor } = props;
        const gradients = [`#FAF8E1`, `#E9FBFB`, `#fdeded`];
        return cardColor || gradients[props.index % gradients.length];
    }};
    --card-color: ${props => {
        const { cardColor } = props;
        const gradients = [`#08DACD`, `#FF5C00`, `#DD85C2`].map(color => {
            return getHexShadow(color, 25);
        });
        return (
            getHexShadow(cardColor, 35) ||
            gradients[props.index % gradients.length]
        );
    }};
    border-color: var(--card-color);
    border-bottom-style: solid;
    border-bottom-width: 6px;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 24px;
`;

//PIGEONS
const MotionDiv = motion.div;
const MotionInput = motion.input;
