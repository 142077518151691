import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Page, pageConfig } from '../config/routerPages';
//Router used to direct components to a certain path
export const Router = () => {
    const { routerPages } = pageConfig;
    return (
        <Routes>
            {routerPages.map((page: Page, index: number) => {
                const { Component }: { Component: FC } = page;
                return (
                    <Route
                        key={page.path}
                        path={`${page.path}/*`}
                        element={<Component key={page.path + index} />}
                    />
                );
            })}
        </Routes>
    );
};
