import { useQuestionCount } from 'src/hooks';

export const TeamQuestionCount = () => {
    const { currentQuestionCount } = useQuestionCount();
    return (
        <p>
            Geef antwoord op{' '}
            <strong className="no-wrap">vraag {currentQuestionCount}</strong>
        </p>
    );
};
