import { arrayKeepNumbers, sectionIsQuestion } from '../../../../functions';
import {
    useAppDispatch,
    useAppSelector,
    useLock,
    usePhase,
    useSection,
    useSelfHasResponded,
    useSelfResponse,
} from '../../../../hooks';
import { selectRawTeamResponse, setRawTeamResponse } from '../../../../store';
import { StyledTeamButton } from './base/StyledTeamButton';

export const TeamMPCOptionBox = ({
    letter,
    id,
    revealAnswer,
}: {
    revealAnswer: boolean;
    letter: string;
    id: number;
}) => {
    const { lock } = useLock();
    const rawTeamResponse = useAppSelector(selectRawTeamResponse());
    const selfHasResponded = useSelfHasResponded();
    const selfResponse = useSelfResponse();
    const dispatch = useAppDispatch();
    const currentSection = useSection();
    const phase = usePhase();
    let isCorrectAnswer: boolean = false;

    if (sectionIsQuestion(currentSection) && currentSection?.correctAnswers) {
        const { correctAnswers } = currentSection;
        isCorrectAnswer = correctAnswers.includes(id);
        // correctAnswer = currentSection?.correctAnswers[0] as number;
    }
    // const isCorrectAnswer = correctAnswer === id;
    // useEffect(() => {
    //     if (phase !== 'question' || !rawTeamResponse) return;
    //     castResponseEvent({
    //         rawTeamResponse,
    //         selfHasResponded,
    //         currentSection,
    //         decision,
    //         socket,
    //     });
    // }, [rawTeamResponse]);
    const selected =
        (Array.isArray(rawTeamResponse?.response) &&
            arrayKeepNumbers(rawTeamResponse?.response).includes(id)) ||
        (Array.isArray(selfResponse?.response) &&
            arrayKeepNumbers(selfResponse?.response).includes(id));
    const onClickEvent = async (id: number) => {
        lock();
        if (selfHasResponded || phase !== 'question') return;
        dispatch(setRawTeamResponse({ type: 'MPC', response: id }));
    };
    return (
        <StyledTeamButton
            isCorrectAnswer={isCorrectAnswer}
            revealAnswer={revealAnswer}
            selected={selected}
            className="button"
            onClick={() => onClickEvent(id)}
        >
            {letter}
        </StyledTeamButton>
    );
};
