import { getNextOrder, getNextPhase } from '.';
import { T_Module, T_Quiz, T_Section } from '../../../../globalTypes';

export const getFirstStage = (quiz: T_Quiz) => {
    const firstSection = quiz.modules
        .find((_module: T_Module, index: number) => {
            return _module.order === getNextOrder<T_Module>(quiz.modules, null);
        })
        ?.sections.find(
            (section: T_Section, index: number, array: T_Section[]) => {
                return section.order === getNextOrder<T_Section>(array, null);
            },
        );
    if (!firstSection) return null;
    const nextPhase = getNextPhase('quizTips', firstSection);
    if (nextPhase !== 'goNextSection') {
        return {
            moduleId: firstSection.moduleId,
            sectionId: firstSection.id,
            phase: nextPhase,
        };
    }
    return null;
};
