import { T_Answer, T_Question } from '../../../../../../../globalTypes';
import { settings } from '../../../../../config/settings';
import { MPCOptionBox } from './MPCOptionBox';
import { MPCOptionsWrapper } from './MPCOptionsWrapper';

export const MPCResponseSection = (props: {
    question: T_Question;
    key?: string;
}) => {
    const { question } = props;
    const { MPCLetterArray } = settings;

    const answerPossibilities = question.answers?.map(
        (answer: T_Answer, index: number) => {
            return (
                <MPCOptionBox
                    key={answer.id}
                    letter={MPCLetterArray[index]}
                    id={answer.id}
                />
            );
        },
    );
    return (
        <>
            <MPCOptionsWrapper>{answerPossibilities}</MPCOptionsWrapper>
        </>
    );
};
