import styled from 'styled-components';

export const RowInformation = ({
    title,
    index,
    isSelected,
    otherIsSelected,
    tags,
}: {
    title: string;
    index: number;
    isSelected: boolean;
    otherIsSelected: boolean;
    tags?: string[];
}) => {
    return (
        <RowWrapper
            index={index}
            isSelected={isSelected}
            otherIsSelected={otherIsSelected}
        >
            <p className="school-title" id="cypress-school-title">
                {title}
            </p>
            {tags &&
                tags.map((value: string, index: number) => {
                    return (
                        <p key={value + index} className="tag">
                            {value}
                        </p>
                    );
                })}
        </RowWrapper>
    );
};

const RowWrapper = styled.div<{
    index: number;
    isSelected: boolean;
    otherIsSelected: boolean;
}>`
    padding: 16px 24px;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    transition: background 0.2s ease-in-out;
    background: ${props => {
        if (props.isSelected) {
            return 'rgba(22, 145, 190, 0.08)';
        }
    }};
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    bottom: 0;
    left: 0;
    .arrow {
        margin-bottom: -8px;
        transition: min-width 0.2s ease-in-out;
        min-width: ${props => {
            if (props.isSelected) {
                return '40px';
            }
            return '0';
        }};
        overflow: hidden;
        svg {
            width: 100%;
            padding-left: 16px;
            path {
                stroke: #ff7c03 !important;
                fill: #ff7c03 !important;
            }
        }
    }
`;
