import { selectActiveLectureId, setActiveLectureId } from '../../../store';

import { SchoolList } from 'src/components/base/verticalList/SchoolList';
import { drawSchoolInformation } from 'src/functions/ui/drawers/drawSchoolInformation';
import { useSchoolList } from 'src/hooks';

export const LectureSelectionPage = () => {
    const schoolList = useSchoolList();

    if (!schoolList) {
        return null;
    }
    const list = drawSchoolInformation(schoolList);
    return (
        <SchoolList
            cardSelector={selectActiveLectureId}
            reducer={setActiveLectureId}
            list={list}
            heading="Selecteer jouw gastles"
        />
    );
};
