import { useEffect } from 'react';
import { useAppDispatch, useDecision } from 'src/hooks';
import { postEndResponses } from 'src/store/thunks/postEndResponses';
import { TeamResults } from '../../../components/quiz/quizOutroPage/TeamResults';
import './style.scss';

export const QuizOutroPage = () => {
    const decision = useDecision();
    const dispatch = useAppDispatch();
    //LAZY ASS CODING:
    useEffect(() => {
        dispatch(postEndResponses());
    }, []);
    return (
        <div className="winner-screen">
            <TeamResults decision={decision} />
        </div>
    );
};
