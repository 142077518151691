import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useState } from 'react';
import { T_QuizInformation } from 'src/functions';
import { T_RootState } from 'src/store';
import { ListCard } from './ListCard';
import { ListTableWrapper } from './ListTableWrapper';
import './style.scss';

export type T_ListInformation = Omit<
    T_QuizInformation,
    'moduleTitles' | 'difficulty' | 'description' | 'icon' | 'yOffset'
> & {
    icon?: string;
    titleClassName?: string;
};
export const ListScroll = ({
    list,
    heading,
    reducer,
    cardSelector,
}: {
    list: T_ListInformation[];
    heading: { text: string; underlined: string };
    reducer: ActionCreatorWithPayload<number | null>;
    cardSelector: () => (state: T_RootState) => number | null | undefined;
}) => {
    const [mouseScroll, setMouseScroll] = useState<boolean>(false);
    const listTable = [...list]
        .sort((listA, listB) => {
            if (!listA.ordering || !listB.ordering) return 0;
            return listA.ordering - listB.ordering;
        })
        .map((_list: T_ListInformation, index: number, array) => {
            const listInformation = { ..._list, originalIndex: index };
            const { id, title, titleClassName } = listInformation;
            return (
                <ListCard
                    titleClassName={titleClassName}
                    cardSelector={cardSelector}
                    reducer={reducer}
                    totalListCount={list.length}
                    index={{
                        latest: listInformation.originalIndex || index,
                        current: index,
                    }}
                    key={id}
                    listInformation={listInformation}
                    fill={title === 'fill'}
                    totalQuizCount={array.length}
                />
            );
        });
    return (
        <>
            <ListTableWrapper
                heading={heading}
                totalQuizCount={list.length}
                mouseScroll={mouseScroll}
                setMouseScroll={setMouseScroll}
            >
                {listTable}
            </ListTableWrapper>
        </>
    );
};
