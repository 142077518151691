import { SoundPlayer } from 'src/components/sound/SoundPlayer';
import { useInteractionOpen, usePhase, useQuizIsLocal } from 'src/hooks';
import { Footer } from '../../components/footer/Footer';
import { Header } from '../../components/header';
import { AbsoluteInteractions } from '../../components/interactions/AbsoluteInteractions';
import { MainWrapper } from '../../layout/styledcomponents/wrappers/MainWrapper';
import { Router } from '../../router/Router';
import { SocketReceiver } from '../../socket/SocketReceiver';

export const HostRolePage = () => {
    const quizIsLocal = useQuizIsLocal();
    const reconnectInteraction = useInteractionOpen('reconnect');
    const phase = usePhase();
    const showHeader = phase !== 'preStart';
    const showFooter = !reconnectInteraction && phase !== 'preStart';
    return (
        <>
            {showHeader && <Header isQuiz />}
            <MainWrapper quizIsLocal={quizIsLocal}>
                {!quizIsLocal && <SocketReceiver />}
                <Router />
            </MainWrapper>
            {showFooter && <Footer />}
            <SoundPlayer />
            <AbsoluteInteractions />
        </>
    );
};
