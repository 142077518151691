import { T_DecisionQuestion, T_Team } from '../../../../../globalTypes';

//Compares the teams with each other in other to deduce who should receive points. Points update in real time.
export const calculateDecisionPoints = (
    teams: T_Team[],
    question: T_DecisionQuestion,
) => {
    const currentQuestion = question;
    const correctResponse = currentQuestion.correctAnswers;
    if (!correctResponse) return;
    const foundResponses = teams
        .filter((team: T_Team) => team.inDecision)
        .map((team: T_Team) => {
            return {
                value: Number(team.decisionResponse),
                teamId: team.id,
            };
        });
    const deltaArray = foundResponses
        .filter(
            (
                response:
                    | { value: number | string; teamId: number }
                    | undefined,
            ) => {
                if (!response) return false;
                return true;
            },
        )
        .map(
            (
                response:
                    | { value: number | string; teamId: number }
                    | undefined,
            ) => {
                return {
                    delta: Math.abs(
                        Number(correctResponse) - (response?.value as number),
                    ),
                    teamId: response?.teamId,
                };
            },
        )
        .sort(
            (
                response: {
                    delta: number;
                    teamId: number | undefined;
                },
                _response: {
                    delta: number;
                    teamId: number | undefined;
                },
            ) => {
                return response.delta - _response.delta;
            },
        );
    let smallestDelta: number | null = null;
    const awardedTeams = deltaArray
        .filter((response: { delta: number; teamId: number | undefined }) => {
            const { delta, teamId } = response;
            if (teamId === undefined || !response) return false;
            if (smallestDelta === null) {
                smallestDelta = delta;
                return true;
            }
            if (smallestDelta === delta) {
                return true;
            }
            return false;
        })
        .map((response: { teamId: number | undefined }) => {
            return response.teamId;
        });
    return teams.map((team: T_Team) => {
        if (!team.inDecision) return team;
        if (awardedTeams.includes(Number(team.id))) {
            return { ...team, wonDecision: true };
        }
        return { ...team, wonDecision: false };
    });
};
