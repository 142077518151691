import { useNavigate } from 'react-router-dom';
import { appStorage } from '../../functions';
import { useAppDispatch, useQuizIsLocal, useSocket } from '../../hooks';
import { resetAllState, setInteraction } from '../../store';
import { Button } from '../base/buttons';

export const RejectButton = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const quizIsLocal = useQuizIsLocal();
    const socket = useSocket();
    const rejectReconnect = () => {
        dispatch(
            setInteraction({ interactionKey: 'reconnect', change: 'close' }),
        );
        const { socketId: oldSocketId } = appStorage.retrieveSocketStorage();
        appStorage.clear();
        dispatch(resetAllState());
        navigate('..');
        if (!quizIsLocal && oldSocketId) {
            socket.emit('rejectReconnect', { oldSocketId });
        }
    };
    return (
        <Button
            isActive={true}
            buttonType="sleeping"
            className="button inactive"
            text={'Nee, stop quiz'}
            onClick={rejectReconnect}
        />
    );
};
