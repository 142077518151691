import { useNavigate } from 'react-router-dom';
import { appStorage } from '../../functions';
import { useAppDispatch, useSocket } from '../../hooks';
import { forceConnect, setInteraction } from '../../store';
import { Button } from '../base/buttons';

export const AcceptButton = (props: { status: string | undefined }) => {
    const { status } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const socket = useSocket();
    const acceptReconnect = () => {
        dispatch(
            setInteraction({ interactionKey: 'reconnect', change: 'close' }),
        );
        if (status === 'host') {
            dispatch(forceConnect());
            const {
                socketId,
                roomId,
                originalSocketId,
            } = appStorage.retrieveSocketStorage();
            if (!socketId || !roomId) return;
            if (socketId) {
                socket.emit('reconnect', {
                    oldSocketId: socketId,
                    roomId,
                    originalSocketId,
                });
            }
        }
        //TODO: return generateThemeList if theme system is ever used again
        // quiz && dispatch(generateThemeList(quiz));
        navigate('../quiz-digibord');
    };

    return (
        <Button
            buttonType="primary"
            text={'Ja, speel verder'}
            onClick={acceptReconnect}
        />
    );
};
