import classNames from 'classnames';
import { useEffect } from 'react';
import { LazyPhaseMessage } from 'src/components/messages/LazyPhaseMessage';
import { settings } from 'src/config/settings';
import { sectionIsQuestion } from 'src/functions';
import { allMPCAnswersAreCorrect } from 'src/functions/checkers/allMPCAnswersAreCorrect';
import { setLazyMessage } from 'src/store';
import {
    T_Answer,
    T_NormalQuestion,
    T_QuizPhase,
} from '../../../../../../../globalTypes';
import {
    useAppDispatch,
    useSection,
    useTimedEffect,
} from '../../../../../hooks';
import { MPCOption } from './MPCOption';
import './style.scss';
export const MPCDisplay = ({ phase: currentPhase }: { phase: T_QuizPhase }) => {
    const { MPCLetterArray, revealAnswerTime } = settings;
    const revealAnswer = useTimedEffect(revealAnswerTime);
    const currentQuestion = useSection<T_NormalQuestion<'MPC'>>();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (allMPCAnswersAreCorrect(currentQuestion) && revealAnswer) {
            dispatch(
                setLazyMessage({
                    type: 'phase',
                    phase: 'answerReveal',
                    text: 'Alle antwoorden zijn goed!',
                }),
            );
        }
    }, [revealAnswer]);
    if (!currentQuestion || !sectionIsQuestion(currentQuestion)) return null;
    const { isGridDisplayed } = currentQuestion.typeData || {};
    const options = currentQuestion.answers?.map(
        (answer: T_Answer, index: number) => {
            const props = {
                isGridDisplayed,
                key: answer.id,
                text: answer.text,
                letter: MPCLetterArray[index],
                answersTextSize: currentQuestion.answersTextSize,
            };
            if (
                currentPhase === 'question' ||
                currentPhase === 'questionWhatsapp'
            ) {
                return <MPCOption {...props} />;
            }
            if (currentPhase === 'answerReveal') {
                if (
                    currentQuestion.correctAnswers?.includes(answer.id) &&
                    revealAnswer
                ) {
                    return <MPCOption {...props} correct />;
                }
                if (!revealAnswer) {
                    return <MPCOption {...props} />;
                }
                return <MPCOption {...props} correct={false} />;
            }
            return null;
        },
    );

    return (
        <>
            <div
                className={classNames({
                    'mpc-options-wrapper': !isGridDisplayed,
                    'mpc-options-grid': isGridDisplayed,
                })}
            >
                {options}
            </div>
            <LazyPhaseMessage className="bottom-0 h2" />
        </>
    );
};
