//returns the dutch numeral from a number up to 10
export const getNumeralFromNumber = (number?: number) => {
    if (!number) return '';
    switch (number) {
        case 1:
            return 'een';
        case 2:
            return 'twee';
        case 3:
            return 'drie';
        case 4:
            return 'vier';
        case 5:
            return 'vijf';
        case 6:
            return 'zes';
        case 7:
            return 'zeven';
        case 8:
            return 'acht';
        case 9:
            return 'negen';
        case 10:
            return 'tien';
    }
};
