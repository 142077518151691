import { settings } from 'src/config/settings';
import { drawOpenResponseBox } from 'src/functions/ui/drawers/drawOpenResponseBox';
import { T_TeamResponse } from '../../../../../../../globalTypes';
import {
    calculateAwardedPoints,
    getCurrentSection,
    sectionIsQuestion,
} from '../../../../../functions';
import {
    useAppDispatch,
    useAppSelector,
    useQuizIsLocal,
    useSocket,
    useTeams,
} from '../../../../../hooks';
import { findAvailableTeamId, setTeamResponse } from '../../../../../store';
import { StyledOptionBox } from '../base/OptionBox';

export const OpenNumberBox = ({
    number,
    desiredAnswers,
}: {
    number: number;
    desiredAnswers: number;
}) => {
    const { responseDelayTime } = settings;
    const currentQuestion = getCurrentSection();
    const selectedTeamId = useAppSelector(
        state => state.quizState.selectedTeamId,
    );
    const quizIsLocal = useQuizIsLocal();
    const socket = useSocket();
    const teams = useTeams();
    const selected =
        //TODO: THIS LINE WAS COMMENTED OUT ON 10-01-2024 IN ORDER TO ADRESS THE OPEN RESPONSE DOUBLE ANSWER BUG IN UI
        //BUT WHY WAS THIS LINE PLACED IN THE FIRST PLACE??? BRUCEW
        // number === Number(rawTeamResponse?.response) ||
        //KEKW
        teams
            ?.find(team => team.id === selectedTeamId)
            ?.responses.find((response: T_TeamResponse) => {
                return response.sectionId === currentQuestion?.id;
            })?.response === number;
    const dispatch = useAppDispatch();
    if (!sectionIsQuestion(currentQuestion)) return null;

    const onClickEvent = () => {
        dispatch(
            setTeamResponse({
                response: {
                    response: number,
                    questionType: 'Open',
                },
            }),
        );
        if (!quizIsLocal) {
            const { points, isCorrect } = calculateAwardedPoints(
                currentQuestion,
                {
                    response: number,
                },
            );
            if (!selectedTeamId) return;
            socket.emit('teamSetOpenPoints', {
                sectionId: currentQuestion.id,
                questionId: currentQuestion.questionId,
                response: number,
                points,
                isCorrect,
                teamIndex: selectedTeamId,
            });
        }
        setTimeout(() => {
            dispatch(findAvailableTeamId());
        }, responseDelayTime);
        return;
    };

    return (
        <StyledOptionBox selected={selected} onClick={() => onClickEvent()}>
            {drawOpenResponseBox({ number, desiredAnswers })}
        </StyledOptionBox>
    );
};
