import { useAppDispatch, useNavigateLevel } from 'src/hooks';
import { setPhase } from 'src/store';
import { T_QuizPhase } from '../../../../../../globalTypes';

export const NoCodeButton = ({
    text,
    phase,
    href,
    className,
    onClick,
}: {
    href?: string;
    phase?: T_QuizPhase;
    text: string;
    className?: string;
    onClick?: () => void;
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigateLevel();
    const onClickEvent = () => {
        onClick && onClick();
        if (!phase || !href) return;
        dispatch(setPhase(phase));
        navigate(href);
    };
    return (
        <button onClick={() => onClickEvent()} className={className}>
            {text}
        </button>
    );
};
