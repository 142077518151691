import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { settings } from 'src/config/settings';
import {
    useAppDispatch,
    useAppSelector,
    useLock,
    useSelfHasResponded,
} from 'src/hooks';
import { T_NormalQuestion } from '../../../../../../globalTypes';
import { selectRawTeamResponse, setRawTeamResponse } from '../../../../store';
import { TeamConfirmResponseButton } from './TeamConfirmResponseButton';

export const TeamOpenResponseSection = (props: {
    question: T_NormalQuestion;
}) => {
    const rawTeamResponse = useAppSelector(selectRawTeamResponse());
    const selfHasResponded = useSelfHasResponded();
    const [input, setInput] = useState<string>(
        Array.isArray(rawTeamResponse?.response) &&
            typeof rawTeamResponse?.response[0] === 'string'
            ? rawTeamResponse?.response[0]
            : '',
    );
    const dispatch = useAppDispatch();
    const { lock } = useLock();
    const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        lock();
        if (e.target.value.length - 1 >= settings.openQuestionMaxInputLength)
            return;
        setInput(
            e.target.value.replace(
                /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                '',
            ),
        );
    };
    useEffect(() => {
        dispatch(setRawTeamResponse({ type: 'Open', response: input }));
    }, [input]);

    return (
        <>
            <textarea
                spellCheck={false}
                value={input}
                className={classNames('socket-input', {
                    inActive: selfHasResponded,
                })}
                disabled={selfHasResponded}
                onChange={e => onInputChange(e)}
                ref={ref => ref && ref.focus()}
                onFocus={e =>
                    e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length,
                        e.currentTarget.value.length,
                    )
                }
            />
            <p className="socket-input-length">
                {input.length + '/' + settings.openQuestionMaxInputLength}
            </p>
            <TeamConfirmResponseButton />
        </>
    );
};
