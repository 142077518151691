import { useAppDispatch } from 'src/hooks';
import { useNumberControls } from 'src/hooks/controls/useNumberControls';
import { setLocalTeams, setTeamCount } from 'src/store';

export const NumberTeamControls = () => {
    const dispatch = useAppDispatch();
    useNumberControls({
        numberEvents: [
            ...[1, 2, 3, 4, 5, 6, 7, 8, 9].map(number => ({
                number,
                event: () => {
                    dispatch(setTeamCount(number));
                    dispatch(setLocalTeams());
                },
            })),
            {
                number: 0,
                event: () => {
                    dispatch(setTeamCount(10));
                    dispatch(setLocalTeams());
                },
            },
        ],
    });

    return null;
};
