import {
    T_DecisionQuestion,
    T_NormalQuestion,
    T_Question,
} from '../../../../../../../globalTypes';
import { ConfirmResponseButton } from '../ConfirmResponseButton';
import { GuessNumberInput } from './GuessNumberInput';
import { GuessResponseWrapper } from './GuessResponseWrapper';

//warning: used on teamPage AND hostPage.
export const GuessResponseSection = ({
    question,
    inDecision,
}: {
    question: T_Question | T_DecisionQuestion;
    inDecision?: boolean;
}) => {
    return (
        <GuessResponseWrapper>
            <GuessNumberInput
                question={question as T_NormalQuestion<'Guess'>}
                inDecision={inDecision}
            />
            <ConfirmResponseButton />
        </GuessResponseWrapper>
    );
};
