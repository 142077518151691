import { T_SchoolData } from '../../../../../globalTypes';
import { formatLecture } from './formatLecture';

export const formatLectures = ({
    mbo,
    po,
    vo,
}: {
    mbo: { msg: string } | T_SchoolData<'MBO'>[];
    po: { msg: string } | T_SchoolData<'PO'>[];
    vo: { msg: string } | T_SchoolData<'VO'>[];
}): T_SchoolData<'All'>[] | null => {
    let newMbo: T_SchoolData<'MBO' | 'All'>[] = [];
    let newPo: T_SchoolData<'PO' | 'All'>[] = [];
    let newVo: T_SchoolData<'VO' | 'All'>[] = [];
    if ('msg' in mbo && 'msg' in po && 'msg' in vo) return null;
    if (!('msg' in mbo)) {
        newMbo = mbo.map(data => ({
            ...data,
            level: 'MBO',
            lectures: data.lecture?.map(lecture => formatLecture(lecture)),
        }));
    }
    if (!('msg' in po)) {
        newPo = po
            .map(data => ({
                ...data,
                level: 'PO',
                lectures: data.lecture?.map(lecture => formatLecture(lecture)),
            }))
            .filter(data => data.school_name !== 'Testschool');
    }
    if (!('msg' in vo)) {
        newVo = vo.map(data => ({
            ...data,
            level: 'VO',
            lectures: data.lecture?.map(lecture => formatLecture(lecture)),
        }));
    }
    return [...newMbo, ...newPo, ...newVo].map((data, index) => ({
        school_name: data.school_name,
        lectures: data.lectures,
        level: data.level,
        id: index,
    }));
};

//: T_SchoolData<'All'>
