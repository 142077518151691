import { useEffect } from 'react';
import { getTimeColor } from 'src/functions/ui/getters/getTimeColor';
import styled from 'styled-components';
import {
    useAppDispatch,
    useAppSelector,
    usePhase,
    useSocket,
} from '../../hooks';
import {
    selectButtonCooldown,
    selectInteractionCooldown,
    selectTimerState,
} from '../../store';
import { handleInteractionTimer } from '../../store/thunks/handleInteractionTimer';
import { handleTimer } from '../../store/thunks/handleTimer';
import { TimerBar } from './TimerBar';
import './style.scss';

export const Timer = (props: { interaction?: boolean }) => {
    const { interaction } = props;
    const socket = useSocket();
    const dispatch = useAppDispatch();
    const timerState = useAppSelector(selectTimerState());
    const { timer: currentTime, timeActive } = timerState || {};
    const phase = usePhase();
    const interactionCooldown = useAppSelector(selectInteractionCooldown());
    const buttonCooldown = useAppSelector(selectButtonCooldown());
    useEffect(() => {
        if (interaction || !timeActive) return;
        const timer = setTimeout(() => {
            dispatch(handleTimer(socket));
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [currentTime, phase, timeActive]);
    useEffect(() => {
        const interactionsTimer = setTimeout(() => {
            dispatch(handleInteractionTimer());
        }, 100);
        return () => {
            clearTimeout(interactionsTimer);
        };
    }, [interactionCooldown, buttonCooldown]);
    if ((phase !== 'question' && phase !== 'decisionQuestion') || interaction)
        return null;
    return (
        <div className="timer-wrapper">
            <TimerBar />
            <TimeDisplay time={currentTime}>{currentTime}</TimeDisplay>
        </div>
    );
};

const TimeDisplay = styled.p<{ time?: number }>`
    font-family: 'Epilogue', sans-serif;
    color: ${props => getTimeColor({ time: props.time, theme: props.theme })};
    font-weight: 800;
    font-size: 40px;
`;
