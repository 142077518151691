import { useState } from 'react';
import { MockMobile } from 'src/components/mockMobile/MockMobile';
import { MockMobileHitboxes } from 'src/components/mockMobile/MockMobileHitboxes';
import { MockMobileImage } from 'src/components/mockMobile/MockMobileImage';
import { useSection } from 'src/hooks';

export const QuestionOutroMobilePage = () => {
    const [currentPageIndex, setCurrentPage] = useState<number>(0);
    const section = useSection();
    if (!section || section.sectionType !== 'MMQuestion') {
        throw new Error(
            'QuestionOutroMobilePage rendered outside of MMQuestion',
        );
    }
    const currentMockPage =
        currentPageIndex < section.mockPages.length
            ? section.mockPages[currentPageIndex]
            : section.mockPages.slice(-1)[0];
    return (
        <>
            <MockMobile>
                <MockMobileImage src={currentMockPage.src} />
                <MockMobileHitboxes
                    setCurrentPage={setCurrentPage}
                    hitboxes={currentMockPage.hitboxes}
                />
            </MockMobile>
        </>
    );
};
