import './App.css';
import './layout/scss/index.scss';

import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { TransitionWebEntry } from './components/motion';
import { defaultColors } from './config/settings';
import { useAppDispatch, useRole } from './hooks';
import { useIsPreview } from './hooks/time/useIsPreview';
import { RolePage } from './pages/rolepages';
import { handleAppArrival, setPhase } from './store';
function App() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const role = useRole();
    const [searchParams] = useSearchParams();
    const isPreview = useIsPreview();
    const location = useLocation();

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function(event) {
            window.history.pushState(null, document.title, window.location.href);
            // alert('Gebruik game navigatie');
        });
    }, [location]);

    useEffect(() => {
        dispatch(handleAppArrival({ navigate, searchParams, isPreview }));
    }, []);

    return (
        <ThemeProvider theme={defaultColors}>
            <TransitionWebEntry>
                <RolePage role={role} />
            </TransitionWebEntry>
        </ThemeProvider>
    );
}

export default App;
