import { motion } from 'framer-motion';
import React, { Dispatch, useRef } from 'react';
import { defaultScaleAnimation } from 'src/config/animation/animations';
import { useAppSelector, usePhase } from 'src/hooks';
import { selectInteractionCooldown } from 'src/store';
import styled from 'styled-components';
import { MainTitle } from '../typography/MainTitle';

export const ListTableWrapper = ({
    children,
    mouseScroll,
    setMouseScroll,
    totalQuizCount,
    heading,
}: {
    children: React.ReactNode;
    mouseScroll: boolean;
    setMouseScroll: Dispatch<React.SetStateAction<boolean>>;
    totalQuizCount: number;
    heading: { text: string; underlined: string };
}) => {
    const phase = usePhase();
    const interactionCooldown = useAppSelector(selectInteractionCooldown());
    const scrollReference = useRef<HTMLDivElement>(null);
    return (
        <>
            <MotionMainTitle
                {...defaultScaleAnimation}
                className={'h1'}
                text={heading.text}
                underlined={heading.underlined}
            />
            <AnimatedTableWrapper
                {...defaultScaleAnimation}
                className="select-list-table-wrapper"
                ref={scrollReference}
                totalQuizCount={totalQuizCount}
                phase={phase}
                onMouseDown={() => setMouseScroll(true)}
                onMouseUp={() => setMouseScroll(false)}
                onMouseLeave={() => setMouseScroll(false)}
                onMouseMove={(e: React.MouseEvent<Element, MouseEvent>) => {
                    if (
                        !mouseScroll ||
                        isNaN(e.movementX) ||
                        isNaN(e.movementY)
                    )
                        return;
                    const { movementX } = e;
                    if (
                        movementX === 0 ||
                        (interactionCooldown && interactionCooldown > 0)
                    )
                        return;
                    if (movementX > 0 && scrollReference.current) {
                        scrollReference.current.scrollLeft -= movementX;
                    }
                    if (movementX < 0 && scrollReference.current) {
                        scrollReference.current.scrollLeft -= movementX;
                    }
                }}
            >
                {children}
            </AnimatedTableWrapper>
        </>
    );
};

const Wrapper = styled.div<{
    totalQuizCount: number;
}>`
    display: flex;
    justify-content: ${props => {
        if (props.totalQuizCount <= 5) {
            return 'center';
        }
        return 'flex-start';
    }};
    @media (min-width: 2000px) {
        justify-content: ${props => {
            if (props.totalQuizCount <= 6) {
                return 'center';
            }
            return 'flex-start';
        }};
    }
    cursor: ${props => {
        if (props.totalQuizCount <= 6) {
            return '';
        }
        return 'grab';
    }};
    gap: 24px;
    flex-direction: row;
    height: 100%;
    max-height: 310px;
    width: 100%;
    //overflow-x: ${props => {
        if (props.totalQuizCount < 5) {
            return 'hidden';
        }
        return 'scroll';
    }};
    align-items: flex-end;
    margin: 40px 0 0 0;
    position: relative;
`;

const AnimatedTableWrapper = motion(Wrapper);
const MotionMainTitle = motion(MainTitle);
