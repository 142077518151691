import { T_NormalQuestion } from '../../../../../../globalTypes';
import { TeamClosedResponseOptionBox } from './TeamClosedResponseOptionBox';
import { TeamConfirmResponseButton } from './TeamConfirmResponseButton';

export const TeamClosedResponseSection = ({
    question,
    revealAnswer,
}: {
    question: T_NormalQuestion;
    revealAnswer: boolean;
}) => {
    const answerPossibilities = [
        <TeamClosedResponseOptionBox
            revealAnswer={revealAnswer}
            text={(question as T_NormalQuestion<'Closed'>).typeData?.closedLeft}
            id={1}
            key={1}
        />,
        <TeamClosedResponseOptionBox
            revealAnswer={revealAnswer}
            text={
                (question as T_NormalQuestion<'Closed'>).typeData?.closedRight
            }
            id={2}
            key={2}
        />,
    ];
    return (
        <>
            <div className="button-group-gv">{answerPossibilities}</div>{' '}
            <TeamConfirmResponseButton />
        </>
    );
};
