import { T_AppDispatch, handlePostRequest, setToken } from '..';

export const verifyToken = () => async (dispatch: T_AppDispatch) => {
    try {
        dispatch(
            handlePostRequest<string>({
                postData: 'test',
                endPoint: '/creator/getSelf',
                token: true,
                dataReducer: setToken,
            }),
        );
    } catch (error) {
        console.log(error);
    }
};
