import { useEffect, useState } from 'react';
import { MainTitle } from 'src/components/base/typography/MainTitle';
import { settings } from 'src/config/settings';
import { renderDecisionText } from 'src/functions/ui/renderers/renderDecisionText';
import { InfoTextWrapper } from 'src/layout/styledcomponents/wrappers/typography/InfoText';
import { setShowFooterButton } from 'src/store';
import {
    useAppDispatch,
    useQuizIsLocal,
    useTeams,
    useTimedCallback
} from '../../../../hooks';
import { SectionTransitionPage } from '../transition';

export const QuizDecision = ({ isDraw }: { isDraw?: boolean }) => {
    const [showQuestion, setShowQuestion] = useState<boolean>(false);
    const { sectionTransitionTime } = settings;
    useTimedCallback({
        callback: () => {
            dispatch(setShowFooterButton(true));
            setShowQuestion(true);
        },
        duration: sectionTransitionTime,
    });
    const teams = useTeams();
    const quizIsLocal = useQuizIsLocal();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setShowFooterButton(true));
    }, []);
    if (!showQuestion) {
        return <SectionTransitionPage inDecision={true} />;
    }
    return (
        <>
            <MainTitle text="Beslissingsvraag" />
            <InfoTextWrapper phase={'decision'} className="info-text info-text-decision ">
                {renderDecisionText({ teams, quizIsLocal })}
            </InfoTextWrapper>
        </>
    );
};