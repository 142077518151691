import {
    T_DecisionQuestion,
    T_Section,
    T_Slide,
} from '../../../../globalTypes';

export const sectionIsSlide = (
    section?: T_Section | T_DecisionQuestion,
): section is T_Slide => {
    if (!section) return false;
    return 'sectionType' in section && section.sectionType === 'Slide';
};
