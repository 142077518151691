import { createContext } from 'react';
import io, { Socket } from 'socket.io-client';
import {
    T_ClientToServerEvents,
    T_ServerToClientEvents,
} from '../../../globalTypes';
import { hostURL, port } from '../config/config';

export const socket: Socket<
    T_ServerToClientEvents,
    T_ClientToServerEvents
> = io(hostURL + port, {
    transports: ['websocket'],
    autoConnect: false,
    withCredentials: true,
});
export const SocketContext = createContext(socket);
