import { T_ListInformation } from 'src/components/base/list';

export const schoolLevelList: T_ListInformation[] = [
    {
        titleClassName: 'size-24',
        id: 1,
        title: 'Basisonderwijs',
        ordering: 1,
        icon: 'notebook.png',
    },
    {
        titleClassName: 'size-22',
        id: 3,
        title: 'Voortgezet Onderwijs',
        ordering: 2,
        icon: 'book.png',
    },
    {
        titleClassName: 'size-24',
        id: 2,
        title: 'MBO',
        ordering: 3,
        icon: 'laptop.png',
    },
];
