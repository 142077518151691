import { useWakeLock } from 'react-screen-wake-lock';

//Hook that locks the screen for teamPlayers, so that they won't leave when the device is locked
export const useLock = () => {
    const { request, released } = useWakeLock();
    const wakeLockScreen = async () => {
        try {
            if (!released) return;
            await request();
        } catch (err) {
            console.error(err);
        }
    };
    return { lock: () => wakeLockScreen() };
};
