import { T_QuestionType, T_SchoolLevel } from '../../../globalTypes';

export const appConfig = {
    maxTeams: 10,
    displayedTeamNames: 3,
    scoreRevealIndices: [3, 6, 8],
} as const;

export const questionOptions: readonly {
    value: T_QuestionType;
    text: string;
}[] = [
    {
        value: 'Start',
        text: 'Start',
    },
    {
        value: 'Open',
        text: 'Open vraag',
    },
    {
        value: 'Closed',
        text: 'Gesloten vraag',
    },
    {
        value: 'MPC',
        text: 'Meerkeuzevraag',
    },
    {
        value: 'Guess',
        text: 'Schattingsvraag',
    },
    {
        value: 'Thesis',
        text: 'Stelling',
    },
] as const;

export const questionTypes = [
    'Start',
    'Guess',
    'MPC',
    'Closed',
    'Open',
    'Thesis',
    'Poll',
    'Decision',
] as const;

export const schoolLevels: readonly T_SchoolLevel[] = ['MBO', 'PO', 'VO'];
