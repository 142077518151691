import nvbLogo from 'src/assets/logos/NVB_logo.svg';
export const NVBLogo = () => {
    return (
        <div className="logo">
            <p>Een initatief van</p>
            <img
                id="otto"
                alt="Logo Nederlandse Vereniging van Banken"
                src={nvbLogo}
                style={{
                    width: '96px',
                }}
            />
        </div>
    );
};
