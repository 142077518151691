import { T_AppDispatch, handleGetRequest, setQuiz } from '..';
import { T_Quiz } from '../../../../globalTypes';

export const getQuizById = (id: number) => async (
    dispatch: T_AppDispatch,
    getState: Function,
) => {
    try {
        dispatch(handleGetRequest<T_Quiz>(`/quiz/getQuizById/${id}`, setQuiz));
    } catch (error) {
        console.log(error);
    }
};
