import { useEffect } from 'react';
import { T_Team } from '../../../../../../globalTypes';
import { useAppDispatch, useTeams } from '../../../../hooks';
import { findAvailableTeamId } from '../../../../store';
import { TeamSelectionBox } from './TeamSelectionBox';
import './style.scss';
//list of all teams which the guest lecturer can press in order to select a team
export const TeamsSelection = () => {
    const teams = useTeams();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(findAvailableTeamId());
    }, []);

    const TeamSelectionBoxes = teams?.map((team: T_Team) => {
        return <TeamSelectionBox id={+team.id} key={team.id} />;
    });
    return (
        <div className="footer-wrapper">
            <div className="team-inner-wrapper ">{TeamSelectionBoxes}</div>
        </div>
    );
};
