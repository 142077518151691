import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useSectionTransition } from 'src/hooks/quiz/useSectionTransition';
import { RewardDisplayCircle } from '../../../../components/header/rewardDisplayCircle/RewardDisaplyCircle';
import './style.scss';

export const SectionTransitionPage = ({
    inDecision,
}: {
    inDecision?: boolean;
}) => {
    const {
        questionCount,
        totalQuestionCount,
        currentThesisCount,
        thesisCount,
        questionText,
        reward,
        startAnimation,
    } = useSectionTransition(inDecision);

    const questionTextAsClassName = questionText
        ?.toLowerCase()
        .replace(' ', '-');
    return (
        <div className={`transition-wrapper ${questionTextAsClassName}`}>
            <img src="" alt="" />
            {!inDecision && (
                <>
                    {questionTextAsClassName !== 'stelling' ? (
                    <>
                        <div className="current-question-wrapper">
                            <div className="current-number">
                                <strong>{questionCount}/</strong>
                            </div>
                            <div className="total-number">{totalQuestionCount}</div>
                        </div>
                    </>
                    ) : (
                        <>
                            <div className="current-question-wrapper">
                                <div className="current-number">
                                    <strong>{currentThesisCount}/</strong>
                                </div>
                                <div className="total-number">{thesisCount}</div>
                            </div>
                        </>
                    )}
                    {questionTextAsClassName !== 'stelling' && (
                        <motion.div className={classNames('quizmaster-wrapper')}>
                            <span>Verdien</span>
                            <RewardDisplayCircle points={reward} animate={true} />
                        </motion.div>
                    )}
                </>
            )}
        </div>
    );
};
