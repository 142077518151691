import { MainTitle } from 'src/components';
import { VideoPlayer } from 'src/components/video/VideoPlayer';

export const QuizIntroVideoPage = () => {
    return (
        <div className="intro-video-page">
            <MainTitle text="Wat doet een bank?" className="mb-24" />
            <VideoPlayer url="/generic/watDoetEenBank.mp4" posterImage="/assets/watDoetEenBank.jpg"/>
        </div>
    );
};
