import { getHexContrast } from 'src/functions';
import { useLock, useSocket } from 'src/hooks';
import styled from 'styled-components';
import { T_TeamName } from '../../../../../globalTypes';

export const TeamNameCard = ({
    teamName,
    index,
}: {
    teamName: T_TeamName;
    index: number;
}) => {
    const socket = useSocket();
    const { lock } = useLock();
    const onClick = async () => {
        socket.emit('setTeamName', teamName.id);
        await lock();
    };
    return (
        <StyledTeamNameSelection
            onClick={() => onClick()}
            className={`socket-custom-button color${index}`}
            $backgroundColor={'#F3E689' || 'var(--color-primary)'}
        >
            {teamName.name}
        </StyledTeamNameSelection>
    );
};
const StyledTeamNameSelection = styled.div<{
    $backgroundColor: string;
}>`
    background-color: ${props => props.$backgroundColor};
    color: ${props => {
        return getHexContrast(props.$backgroundColor);
    }};
`;
