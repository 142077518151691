import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useSocket } from '../../../hooks';
import {
    forceConnect,
    selectStorageValues,
    setInteraction,
} from '../../../store';

import { useEffect } from 'react';
import { PopScreenWrapper } from 'src/components/interactions/popScreens/PopScreenWrapper';
import { Reconnect } from '../../../components/reconnect';
import { appStorage } from '../../../functions';

export const ReconnectPage = () => {
    const { status } = useParams();
    const storedValues = useAppSelector(selectStorageValues());
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const socket = useSocket();
    useEffect(() => {
        dispatch(
            setInteraction({ interactionKey: 'reconnect', change: 'open' }),
        );
        if (status === 'team') {
            dispatch(forceConnect());
            const {
                socketId,
                roomId,
                originalSocketId,
            } = appStorage.retrieveSocketStorage();
            if (
                !socketId ||
                !roomId ||
                (storedValues && storedValues.socketId === socketId)
            )
                return;
            if (socketId) {
                socket.emit('reconnect', {
                    oldSocketId: socketId,
                    roomId,
                    originalSocketId,
                });
                navigate('../team');
            }
        }
    }, []);
    if (!status) return null;
    return (
        <PopScreenWrapper>
            <div className="wrapper s">
                <h2 className="center">
                    Wil je de cash quiz <br /> verder spelen?
                </h2>
                <div className="button-wrapper">
                    <Reconnect.RejectButton />
                    <Reconnect.AcceptButton status={status} />{' '}
                </div>
            </div>
        </PopScreenWrapper>
    );
};
