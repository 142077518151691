import { useSearchParams } from 'react-router-dom';
import { isValidSchoolLevel } from 'src/functions/checkers/isValidSchoolLevel';
import { T_SchoolLevel } from '../../../../globalTypes';

export const useSchoolLevel = () => {
    const [searchParams] = useSearchParams();
    const level = searchParams.get('level');
    if (isValidSchoolLevel(level as T_SchoolLevel)) {
        return level as T_SchoolLevel;
    }

    return null;
};
