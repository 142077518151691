import { T_Module, T_Quiz, T_Section } from '../../../../globalTypes';
import { sectionIsQuestion } from '../checkers';
import { getVideoCount } from '../ui/getters/getVideoCount';

//retrieves relevant numbers (e.g amount of questions, amount of thesi) that are used in UI
export const getQuizCounts = (
    quiz: T_Quiz,
): {
    totalQuestionCount: number;
    thesisCount: number;
    moduleCount: number;
    videoCount: number;
} => {
    let totalQuestionCount = 0;
    let thesisCount = 0;
    let videoCount = getVideoCount(quiz);
    const moduleCount = quiz.modules.length;
    quiz.modules.map((module: T_Module) => {
        totalQuestionCount =
            totalQuestionCount +
            module.sections.filter(
                (section: T_Section) =>
                    sectionIsQuestion(section) && section?.type !== 'Thesis',
            ).length;
        thesisCount =
            thesisCount +
            module.sections.filter(
                (section: T_Section) =>
                    sectionIsQuestion(section) && section.type === 'Thesis',
            ).length;
    });
    return { totalQuestionCount, thesisCount, moduleCount, videoCount };
};
