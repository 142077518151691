import { sectionIsQuestion } from 'src/functions';
import { T_NormalQuestion } from '../../../../globalTypes';
import { useTeams } from '../teams';
import { useSection } from './useSection';

export const useThesis = () => {
    const thesis = useSection<T_NormalQuestion<'Thesis'>>();
    const teams = useTeams();

    if (
        !thesis ||
        !sectionIsQuestion(thesis) ||
        thesis.type !== 'Thesis' ||
        !teams
    )
        return;
    const thesisResponses = teams.map(team => {
        const teamResponses = team.responses.find(
            response => response.sectionId === thesis.sectionId,
        );
        if (!teamResponses) return;
        return teamResponses.response as number;
    });
    const count = thesisResponses.reduce(
        (acc, curr) => {
            if (curr === 1) acc[0]++;
            if (curr === 2) acc[1]++;
            return acc;
        },
        [0, 0],
    );
    return {
        text: thesis.text,
        time: thesis.time,
        leftText: thesis.typeData?.closedLeft,
        rightText: thesis.typeData?.closedRight,
        leftCount: count[0],
        rightCount: count[1],
    };
};
