import { getThemeColor } from 'src/functions';
import styled from 'styled-components';
import './style.scss';
export const TeamText = ({ teamId }: { teamId: number }) => {
    return <StyledText className="team-text">Team {teamId}</StyledText>;
};

const StyledText = styled.p`
    color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'primary',
        })};
`;
