import { hostURL } from '../config/config';

export const getRequestToken = async <T>(
    endPoint: string,
    token: string,
): Promise<T> => {
    const response = await fetch(`${hostURL}${endPoint}`, {
        headers: { Authorization: `Bearer ${token}` },
    })
        .then(response => {
            if (!response.ok) {
                return response.json() as Promise<T>;
            }
            return response.json() as Promise<T>;
        })
        .catch(error => {
            console.log(error);
            throw new Error(error);
        });
    return response;
};
