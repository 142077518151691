import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { settings } from 'src/config/settings';
import { getRandomInteger } from 'src/functions/getters/getRandomInteger';
import { getGuessIncrement } from 'src/functions/ui/getters/getGuessIncrement';
import { useQuizIsLocal, useSocket, useTimedEffect } from 'src/hooks';
import { useCorrectTeams } from 'src/hooks/teams/useCorrectTeams';
import { PrimaryText } from 'src/layout/styledcomponents/wrappers/typography/PrimaryText';
import { T_NormalQuestion, T_Phase } from '../../../../../../../globalTypes';
import './style.scss';
export const GuessAnswerReveal = ({
    question,
    phase,
}: {
    question: Partial<T_NormalQuestion<'Guess'>>;
    phase?: T_Phase;
}) => {
    const [displayedAnswer, setDisplayedAnswer] = useState<number>(0);
    const [endOfAnimation, setEndOfAnimation] = useState<boolean>(false);
    const quizIsLocal = useQuizIsLocal();
    const socket = useSocket();
    const correctTeams = useCorrectTeams();
    const { revealAnswerTime } = settings;
    const startTimer = useTimedEffect(revealAnswerTime);
    useEffect(() => {
        if (
            !startTimer ||
            Number.isInteger(question.correctAnswers) ||
            !question.correctAnswers
        )
            return;

        const [correctAnswer] = question.correctAnswers;
        if (endOfAnimation) {
            setDisplayedAnswer(correctAnswer);
            return;
        }
        const interval = setInterval(() => {
            const increment = getGuessIncrement({
                displayedAnswer,
                correctAnswer,
            });
            setDisplayedAnswer(prev => prev + increment);
        }, getRandomInteger(15, 55));
        if (displayedAnswer >= correctAnswer) {
            setDisplayedAnswer(correctAnswer);
            setEndOfAnimation(true);

            return () => interval && clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [startTimer, displayedAnswer]);
    useEffect(() => {
        if (!endOfAnimation) return;
        if (!quizIsLocal) {
            socket.emit(
                'teamsSetGuessCorrect',
                correctTeams.filter(value => value !== undefined) as number[],
            );
        }
    }, [endOfAnimation]);
    if (!question.typeData || question.type !== 'Guess') return null;
    console.log(question.type);
    const { unit, unitPrepend } = question.typeData;
    const responseUnit = unit ? unit : '';
    const displayedResponse = unitPrepend
        ? `${responseUnit?.toString()}${Number(displayedAnswer)}`
        : `${Number(displayedAnswer)}${responseUnit?.toString()}`;
    
    if(phase === 'decisionQuestionReveal'){
        return (
            <div
                className={classNames('guess-answer-reveal-wrapper')}
            >
                    <PrimaryText type="h2">{displayedResponse}</PrimaryText>
            </div>
        );
    }else{
        return (
            <div
                className={classNames('guess-answer-reveal-wrapper', {
                    'mt-64': phase !== 'decisionDraw',
                })}
            >
            
                <div className="number-wrapper">
                    <PrimaryText type="h2">{displayedResponse}</PrimaryText>
                </div>
            </div>
        );
    }
    
        
};
