export const drawOpenQuestionText = ({
    teamId,
    desiredAnswers,
}: {
    teamId: number;
    desiredAnswers: number;
}): string => {
    if (desiredAnswers === 1) {
        return `Is het antwoord van team ${teamId} /n goed of fout?`;
    }
    return `Hoeveel juiste antwoorden heeft team ${teamId}?`;
};
