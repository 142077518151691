import { useNavigate } from 'react-router-dom';
import {
    useAppDispatch,
    useAppSelector,
    useEnter,
    useSocket,
} from '../../../hooks';
import { resetInteractions, selectInteractions } from '../../../store';
import { handleWarningPopUp } from '../../../store/thunks/handleWarningPopUp';
import { Button } from '../../base/buttons';
import './style.scss';
export const AcceptButton = () => {
    const dispatch = useAppDispatch();
    const socket = useSocket();
    const warningPopUp = useAppSelector(selectInteractions('warningPopUp'));
    const navigate = useNavigate();
    useEnter(() => {
        dispatch(handleWarningPopUp('accept', { socket, navigate }));
    }, [warningPopUp]);
    if (!warningPopUp?.isOpen || !warningPopUp.options.acceptText) {
        dispatch(resetInteractions());
        return null;
    }
    return (
        <>
            <Button
                buttonType="primary"
                text={warningPopUp.options.acceptText}
                onClick={() =>
                    dispatch(handleWarningPopUp('accept', { socket, navigate }))
                }
            />
        </>
    );
};
