import { T_TeamName } from '../../../../../globalTypes';
import { TeamNameCard } from './TeamNameCard';

export const TeamNameSelection = ({
    names,
    color,
}: {
    names?: T_TeamName[];
    color?: string;
}) => {
    if (!names) return null;
    return (
        <div className="socket-buttons-container">
            {names.map((name: T_TeamName, index: number) => {
                return (
                    <TeamNameCard key={name.id} teamName={name} index={index} />
                );
            })}
        </div>
    );
};
