import { AnimatePresence, motion } from 'framer-motion';
import { getHexContrast } from 'src/functions';
import styled from 'styled-components';

export const Announcement = ({
    message,
    index,
    teamColor,
}: {
    message: string;
    index?: number;
    teamColor?: string;
}) => {
    return (
        <AnimatePresence>
            <AnimatedAnnouncement
                className="font-sans announcer weight-400"
                index={index}
                teamColor={teamColor}
                initial={{ x: 50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                key={teamColor}
                exit={{ x: 50, opacity: 0 }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
            >
                {message}
            </AnimatedAnnouncement>
        </AnimatePresence>
    );
};
const StyledAnnouncement = styled.div<{ index?: number; teamColor?: string }>`
    color: ${props => {
        if (!props.teamColor) return getHexContrast('#08DACD');
        return getHexContrast(props.teamColor);
    }};
    background-color: ${props => {
        if (!props.teamColor) return '#08DACD';
        return props.teamColor;
    }};
    border: ${props => {
        if (!props.teamColor) return `1px solid ${getHexContrast('#08DACD')}`;
        return `1px solid ${getHexContrast(props.teamColor)}`;
    }};

    margin-left: ${props => {
        if (!props.index) {
            return 'auto';
        }
        if (props.index % 2) {
            return '';
        }
        return 'auto';
    }};
    text-align: ${props => {
        if (!props.index) {
            return 'right';
        }
        if (props.index % 2) {
            return 'left';
        }
        return 'right';
    }};
    margin-right: ${props => {
        if (!props.index) {
            return '';
        }
        if (props.index % 2) {
            return 'auto';
        }
        return '';
    }};
`;

const AnimatedAnnouncement = motion(StyledAnnouncement);
