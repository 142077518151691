import classNames from 'classnames';
import { T_Question } from '../../../../../globalTypes';
import { ScorebarWrapper } from './ScoreBarWrapper';
import { ScoreBars } from './ScoreBars';

export const ScoreDisplay = ({
    currentQuestion,
    revealScore,
    isQuizFirstReward,
}: {
    currentQuestion: T_Question;
    revealScore?: boolean;
    isQuizFirstReward?: boolean;
}) => {
    return (
        <div
            className={classNames('score-absolute', {
                ' bottom': revealScore,
                center: !revealScore,
            })}
        >
            <ScorebarWrapper>
                <ScoreBars
                    currentQuestion={currentQuestion}
                    revealScore={revealScore}
                    isQuizFirstReward={isQuizFirstReward}
                />
            </ScorebarWrapper>
        </div>
    );
};