import { drawJoinMessage } from 'src/functions/ui/drawers/drawJoinMessage';
import { getTeamColor } from 'src/functions/ui/getters/getTeamColor';
import { useTeams } from 'src/hooks';
import { T_Team } from '../../../../../globalTypes';
import { Announcement } from './Announcement';
import { AnnouncerWrapper } from './AnnouncerWrapper';
import { NoTeamAnnouncer } from './NoTeamAnnouncer';
import './style.scss';
export const Announcer = () => {
    const teams = useTeams();

    if (!teams)
        return (
            <AnnouncerWrapper empty={true}>
                <NoTeamAnnouncer />
            </AnnouncerWrapper>
        );
    const messages = teams
        .filter((team: T_Team) => {
            if (team.teamName?.id) {
                return true;
            }
            return false;
        })
        .map((team: T_Team) => {
            return {
                message: drawJoinMessage({ team }),
            };
        });

    return (
        <AnnouncerWrapper>
            {messages.map(({ message }: { message: string }, index: number) => {
                return (
                    <Announcement
                        teamColor={getTeamColor(index + 1)}
                        index={index + 1}
                        key={message}
                        message={message}
                    />
                );
            })}
        </AnnouncerWrapper>
    );
};
