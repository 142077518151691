import {
    LobbyPage,
    QuestionAnswersPage,
    QuestionIntroPage,
    QuestionResponsePage,
    QuestionVideoPage,
    QuizIntroPage,
    QuizOutroPage,
    QuizResultsPage,
    QuizThanksPage,
    QuizWrapUpPage,
    ScoreRevealPage,
    SectionFirstQuestion,
    SectionTransitionPage,
    WhatsappInteraction,
} from 'src/pages/subpages';
import { QuizIntroVideoPage } from 'src/pages/subpages/quiz/QuizIntroVideoPage';
import { QuizTipsPage } from 'src/pages/subpages/quiz/QuizTipsPage';
import { QuizDecision } from 'src/pages/subpages/quiz/decision/QuizDecision';
import { QuestionOutroMobilePage } from 'src/pages/subpages/quiz/mockMobile/QuestionOutroMobilePage';
import { QuestionImagesPage } from 'src/pages/subpages/quiz/question/QuestionImagesPage';
import { QuestionOutroPage } from 'src/pages/subpages/quiz/question/QuestionOutroPage';
import { SlidePage } from 'src/pages/subpages/quiz/slide/SlidePage';
import { T_Phase } from '../../../../../globalTypes';

export const RenderedQuizPage = ({ phase }: { phase: T_Phase | undefined }) => {
    if (phase?.endsWith('-images')) {
        return <QuestionImagesPage />;
    }
    if (phase?.endsWith('-video')) {
        return <QuestionVideoPage />;
    }
    switch (phase) {
        //Early quiz related
        case 'lobby':
            return <LobbyPage />;
        case 'quizTips':
            return <QuizTipsPage />;
        case 'quizIntroVideo':
            return <QuizIntroVideoPage />;
        case 'quizIntro':
            return <QuizIntroPage />;
        case 'quizFirstReward':
            return <ScoreRevealPage phase={phase} />;

        //Question related
        case 'questionIntro':
            return <QuestionIntroPage />;
        case 'questionVideo':
            return <QuestionVideoPage />;
        case 'question':
        case 'answerReveal':
        case 'decisionQuestion':
        case 'decisionQuestionReveal':
        case 'decisionDraw':
        case 'questionWhatsapp':
            return <QuestionAnswersPage phase={phase} />;
        case 'questionResponse':
        case 'decisionResponse':
            return <QuestionResponsePage phase={phase} />;
        case 'questionOutro':
            return <QuestionOutroPage />;
        case 'questionOutroMobile':
            return <QuestionOutroMobilePage />;
        case 'scoreReveal':
            return <ScoreRevealPage phase={phase} />;

        //Slide related
        case 'slide':
            return <SlidePage />;

        //Whatsapp Question
        case 'questionWhatsappInteraction':
            return <WhatsappInteraction />;

        //End quiz related
        case 'quizResults':
            return <QuizResultsPage />;
        case 'quizOutro':
            return <QuizOutroPage />;
        case 'quizWrapUp':
                return <QuizWrapUpPage />;
        case 'quizThanks':
            return <QuizThanksPage />;

        //decision question related
        case 'decision':
            return <QuizDecision />;

        case 'quizFirstQuestionIntro':
            return <SectionFirstQuestion />;

        //theme transition screen, is actually an announcement screen since we got rid of themeTransitions
        case 'sectionTransition':
            return <SectionTransitionPage />;
        default:
            return null;
    }
};
