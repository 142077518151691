import { T_Phase } from '../../../../../globalTypes';

export const drawTeamNoScreenText = ({ phase }: { phase: T_Phase }) => {
    switch (phase) {
        case 'questionVideo':
            return 'Bekijk de video op het digibord';
        default:
            return 'Kijk naar het digibord';
    }
};
