import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useAppSelector, usePhase } from 'src/hooks';
//LAC MESSAGE
const MotionParagraph = motion.p;
export const LazyPhaseMessage = ({ className }: { className?: string }) => {
    const message = useAppSelector(state => state.appState.lazyMessage);
    const { phase: _phase } = message;
    const phase = usePhase();
    if (!_phase || _phase !== phase) return null;
    return (
        <MotionParagraph
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={classNames('absolute', className)}
        >
            {message.text}
        </MotionParagraph>
    );
};
