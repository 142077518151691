export const useInDateRange = ({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    return currentDate >= start && currentDate <= end;
};
