import { Socket } from 'socket.io-client';
import { T_RawTeamResponse, T_Section } from '../../../../globalTypes';
import { sectionIsQuestion } from '../checkers';
import { castEvent } from './castEvent';
import { createSocketEvent } from './createSocketEvent';

export const castResponseEvent = ({
    selfHasResponded,
    decision,
    currentSection,
    socket,
    rawTeamResponse,
}: {
    selfHasResponded: boolean;
    decision?: boolean;
    currentSection?: T_Section;
    socket: Socket;
    rawTeamResponse?: T_RawTeamResponse | null;
}) => {
    if (selfHasResponded) return;
    if (decision) {
        castEvent(
            createSocketEvent(
                socket,
                'teamSetDecisionResponse',
                rawTeamResponse,
            ),
        );
        return;
    }
    if (!sectionIsQuestion(currentSection)) return;

    castEvent(
        createSocketEvent(socket, 'teamSetResponse', {
            ...rawTeamResponse,
            questionId: currentSection.questionId,
            sectionId: currentSection.id,
        }),
    );
};
