import { T_AppDispatch } from '../../store';

import { T_DatabaseGame } from '../../../../globalTypes';
import { startGame } from '../../store/thunks/handleGameStart';
import { Event } from './castEvent';

export const createStartEvent = (
    dispatch: T_AppDispatch,
    payload: T_DatabaseGame,
): Event => {
    return {
        socket: null,
        dispatch,
        reducer: startGame,
        endPoint: null,
        reducerPayload: payload,
    };
};
