import classNames from 'classnames';
import React from 'react';

export const AnnouncerWrapper = ({
    children,
    empty = false,
}: {
    children?: React.ReactNode;
    empty?: boolean;
}) => {
    return (
        <div
            className={classNames('announcer-wrapper', {
                'not-empty': !empty,
            })}
        >
            {children}
        </div>
    );
};
