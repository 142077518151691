import { QRCodeSVG } from 'qrcode.react';
import { localURL } from '../../../config/config';

export const QRCode = (props: {
    roomId: string;
    large?: boolean;
    onClick?: Function;
}) => {
    const { roomId, large } = props;
    return (
        <>
            <QRCodeSVG
                width={large ? '50%' : '100%'}
                height={large ? '100%' : '100%'}
                value={localURL + '/team/' + roomId}
                includeMargin={true}
                style={{
                    height: large ? '100%' : '',
                    backgroundColor: 'white',
                    border: '2px solid #f5f3f3',
                    borderRadius: large ? '0px' : '2%',
                }}
            />
        </>
    );
};
