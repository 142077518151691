//returns either black or white based on the input
export const getHexContrast = (hexCode: string): string => {
    // Convert the hexCode to RGB values
    const r = parseInt(hexCode.slice(1, 3), 16);
    const g = parseInt(hexCode.slice(3, 5), 16);
    const b = parseInt(hexCode.slice(5, 7), 16);

    // Calculate the relative luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Choose black or white based on the luminance
    return luminance > 0.5 ? 'black' : 'white';
};
