import styled from 'styled-components';

export const IntroWrapper = styled.div`
    position: relative;

    h1 {
        margin-bottom: 24px;
    }

    .image-wrapper {
        background: #fffaf2;
        position: absolute;
        top: 60%;
        width: 100%;
        left: -50%;
        transform: translateY(-57%);
        z-index: -1;
        img {
            width: 200%;
            mix-blend-mode: darken;
            object-fit: contain;
        }
    }
`;
