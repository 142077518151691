import { motion } from 'framer-motion';
import { getThemeColor } from 'src/functions';
import { useQuizIsLocal } from 'src/hooks';
import styled from 'styled-components';
import { T_TeamName } from '../../../../../globalTypes';
import { getTeamColor } from '../../../functions/ui/getters/getTeamColor';

export const TeamDisplay = ({
    id,
    teamName,
}: {
    id: number;
    teamName?: T_TeamName;
}) => {
    const quizIsLocal = useQuizIsLocal();
    const displayTeamName = !quizIsLocal && teamName;
    return (
        <StyledTeamDisplay
            className="team-display"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            $quizIsLocal={quizIsLocal}
            $id={id}
        >
            <span>Team {id}</span>
            {displayTeamName && (
                <span className="no-break">{teamName?.name}</span>
            )}
        </StyledTeamDisplay>
    );
};

const StyledTeamDisplay = styled(motion.div)<{
    $selected?: boolean;
    $quizIsLocal?: boolean;
    $id: number;
}>`
    display: flex;
    flex-direction: column;
    color: ${props => getTeamColor(props.$id)};
    bottom: ${props =>
        props.$quizIsLocal ? 'calc(50% + 10px)' : 'calc(20% + 20px)'};
    .no-break {
        color: ${props => getTeamColor(props.$id)};
    }
`;
