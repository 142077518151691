const SELF_URL = process.env.REACT_APP_SELF_URL;
const HOST_URL = process.env.REACT_APP_HOST_URL;
const SELF_URL_DEV = process.env.REACT_APP_SELF_URL_DEV;
const HOST_URL_DEV = process.env.REACT_APP_HOST_URL_DEV;
const ENVIRONMENT = process.env.NODE_ENV;

const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;
export const SELF_PORT =
    ENVIRONMENT === 'production'
        ? process.env.REACT_APP_SELF_PORT
        : process.env.REACT_APP_SELF_PORT_DEV;
export const port = SERVER_PORT ? ':' + Number(SERVER_PORT) : '';
export const hostURL =
    ENVIRONMENT === 'production'
        ? `https://${HOST_URL}`
        : `http://${HOST_URL_DEV}${port}`;
export const localURL =
    ENVIRONMENT === 'production'
        ? `${SELF_URL}`
        : `http://${SELF_URL_DEV}:${SELF_PORT}`;
export const selfURL =
    ENVIRONMENT === 'production'
        ? `https://${SELF_URL}:${SELF_PORT}`
        : `http://${SELF_URL_DEV}:${SELF_PORT}`;

export const darkURL = process.env.REACT_APP_DARK_URL;
