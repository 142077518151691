import { Socket } from 'socket.io-client';
import { T_ClientToServerEndPoints } from '../../../../globalTypes';
import store, { T_AppDispatch } from '../../store';

//If there is a socket connection we emit something to the server
//If there is no socket connection we change the state cause the quiz is ran local
export type Event = {
    socket: Socket | null; //socket retrieved from useSocket
    endPoint: T_ClientToServerEndPoints | null; //endpoint that is used WITH socket. Null when used to cast event that is LOCAL only
    dispatch: T_AppDispatch | null; //dispatch from useAppDispatch
    reducer: Function | null; //reducer function that is responsible for our state when NOT using socket
    payload?: any; //payload that is used to change state, forwarded through server when there is a socket connection
    socketPayload?: any; //overwrites payload for socket
    reducerPayload?: any; //overwrites payload for reducer
};
export const castEvent = (event: Event): void => {
    const {
        socket,
        dispatch,
        reducer,
        endPoint,
        payload,
        socketPayload,
        reducerPayload,
    } = event;
    const { quizIsLocal } = store.getState().quizState;

    if (socket && endPoint && !quizIsLocal) {
        socket.emit(endPoint, socketPayload ? socketPayload : payload);
        return;
    }
    if (dispatch && reducer) {
        dispatch(reducer(reducerPayload ? reducerPayload : payload));
        return;
    }
};
