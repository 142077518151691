import { drawAnswerRevealText } from 'src/functions/ui/drawers/drawAnswerRevealText';
import { useSelfTeam, useTeamResponse } from 'src/hooks';
import { T_QuizPhase } from '../../../../../../globalTypes';

export const TeamResponseRevealText = ({
    phase,
    revealTimer,
}: {
    phase: T_QuizPhase;
    revealTimer: boolean;
}) => {
    const selfTeam = useSelfTeam();
    const teamResponse = useTeamResponse({
        id: selfTeam?.id || 0,
        entireResponse: true,
    });
    if (phase !== 'answerReveal' || !revealTimer) return null;

    return (
        <>
            <p className="center socket-answer-reveal">
                {drawAnswerRevealText(teamResponse)}
            </p>
        </>
    );
};
