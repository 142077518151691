import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import {
    useAppDispatch,
    useAppSelector,
    useDimensionsEffect,
    useInteractionCooldown,
} from 'src/hooks';
import { T_RootState } from 'src/store';
import styled from 'styled-components';
import { CardInformation } from './CardInformation';
import { T_ListInformation } from './ListScroll';
import './style.scss';

export const ListCard = ({
    listInformation,
    totalListCount,
    fill,
    index,
    reducer,
    cardSelector,
    totalQuizCount,
    titleClassName,
}: {
    listInformation: T_ListInformation;
    totalListCount: number;
    fill?: boolean;
    index: { latest: number; current: number };
    reducer: ActionCreatorWithPayload<number | null>;
    cardSelector: () => (state: T_RootState) => number | null | undefined;
    totalQuizCount: number;
    titleClassName?: string;
}) => {
    const { id, title, icon, tags, iconOffset, cardColor } = listInformation;
    const { latest } = index;
    const scrollDirection = useAppSelector(
        state => state.quizState.quizScrollDirection,
    );
    const dispatch = useAppDispatch();
    const selectedCardId = useAppSelector(cardSelector());
    const interactionCooldown = useInteractionCooldown();
    const quizCardWidth = useAppSelector(
        state => state.appState.interactionDimensions.quizCard.width,
    );
    const ref = useRef<HTMLDivElement>(null);
    useDimensionsEffect({
        condition: fill,
        interactionKey: 'quizCard',
        ref,
        dimensions: {
            width: true,
        },
    });
    const isSelected: boolean = selectedCardId === id;
    const otherIsSelected: boolean =
        selectedCardId !== undefined &&
        Number.isInteger(selectedCardId) &&
        !isSelected;

    const onClick = () => {
        if (interactionCooldown) return;
        dispatch(reducer(id));
    };
    const xOffset =
        (quizCardWidth && quizCardWidth / 2) || window.innerWidth * 0.1;
    return (
        <AnimatePresence>
            <AnimatedCard
                className={classNames('list-card')}
                totalListCount={totalListCount}
                isSelected={isSelected}
                otherIsSelected={otherIsSelected}
                cardColor={cardColor}
                index={latest}
                type={fill ? 'filler' : 'card'}
                key={id}
                ref={ref}
                onClick={() => onClick()}
                initial={{
                    maxHeight: 280,
                }}
                animate={{
                    maxHeight: isSelected ? 310 : 280,
                }}
                exit={{
                    x: scrollDirection === 'left' ? -xOffset : xOffset,
                }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
            >
                {fill || (
                    <>
                        <CardInformation
                            titleClassName={titleClassName}
                            icon={icon}
                            totalQuizCount={totalQuizCount}
                            yOffset={iconOffset || 30}
                            title={title}
                            isSelected={isSelected}
                            otherIsSelected={otherIsSelected}
                            index={latest}
                            cardColor={cardColor}
                        />
                    </>
                )}
            </AnimatedCard>
        </AnimatePresence>
    );
};

const Wrapper = styled.div<{
    type: 'filler' | 'card';
    totalListCount: number;
    index: number;
    isSelected: boolean;
    otherIsSelected: boolean;
}>`
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 300px;
    width: 100%;
    max-width: 320px;
    height: 100%;
    max-height: 280px;
    scroll-behavior: smooth;
    word-wrap: break-word;
    overflow-wrap: break-word;
    &:last-child {
        margin-right: var(--layout-gap);
    }
    &:first-child {
        margin-left: var(--layout-gap);
    }
`;

const AnimatedCard = motion(Wrapper);
