import { T_TeamName } from '../../../../../globalTypes';

//converts an array with numbers to a string with the textual representation of the teams
export const getTextualTeams = (
    teams: (number | T_TeamName)[] | undefined,
): string => {
    if (!teams) return '';
    let teamsString = '';
    if (teams.length === 1) {
        teamsString = 'T_Team ' + teams[0];
    }
    teams.map((team: number | T_TeamName, index: number) => {
        const lastEntry = index === teams.length - 1;
        const secondLastEntry = index === teams.length - 2;
        const nextText = lastEntry ? '' : secondLastEntry ? ' en ' : ', ';
        if (typeof team === 'number') {
            teamsString += 'team ' + team + nextText;
            return;
        }
        teamsString += team.name + nextText;
    });
    return teamsString;
};
