import classNames from 'classnames';
import React from 'react';
import {
    T_QuestionType,
    T_QuizPhase,
    T_SlideType,
} from '../../../../../../../globalTypes';

export const QuestionResponseWrapper = ({
    children,
    questionTextWide,
    questionType,
}: {
    children: React.ReactNode;
    phase: T_QuizPhase;
    questionType: T_QuestionType | T_SlideType;
    questionTextWide: boolean | null;
}) => {
    return (
        <div
            className={classNames('question-response-wrapper', {
                medium: !questionTextWide,
                wide: questionTextWide && questionType !== 'Guess',
                guess: questionType === 'Guess',
            })}
        >
            {children}
        </div>
    );
};
