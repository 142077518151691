import { T_Team, T_TeamResponse } from '../../../../globalTypes';

export const getResponseByQuestionId = ({
    team,
    questionId,
}: {
    team: T_Team;
    questionId: number;
}) => {
    const foundResponse = team.responses.find((response: T_TeamResponse) => {
        return response.questionId === questionId;
    });
    return foundResponse;
};
