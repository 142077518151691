import classNames from 'classnames';

type SelectionButtonProps = {
    text: string;
    onClick: () => void;
    selected: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;
export const SelectionButton = ({
    text,
    className,
    selected,
    ...props
}: SelectionButtonProps) => {
    return (
        <button
            className={classNames(className, 'selection-button', {
                active: selected,
                inActive: !selected,
            })}
            {...props}
        >
            {text}
        </button>
    );
};
