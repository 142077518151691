import { noQuizInfoPhases } from 'src/config/ui/phases';
import { usePhase } from 'src/hooks';
import { QuestionCounterWrapper } from './QuestionCounterWrapper';
import { QuestionCounter } from './index';

export const QuizHeader = () => {
    const phase = usePhase();
    if (noQuizInfoPhases.includes(phase)) return null;

    return (
        <div className="quiz-info-wrapper">
            <QuestionCounterWrapper>
                <QuestionCounter text={'Vraag'} />
            </QuestionCounterWrapper>
        </div>
    );
};
