import classNames from 'classnames';

export const StartButton = ({ onClickEvent, text, cq23 }: { onClickEvent: () => void, text:string, cq23:boolean }) => {

    const buttonClassNames = classNames('button primary extra-color', { cq23: cq23 })

    return (
        <>
            <button className={buttonClassNames} onClick={() => onClickEvent()}>
                {text}
                <svg
                    width="72"
                    height="37"
                    viewBox="0 0 72 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="9.35962"
                        cy="18.0061"
                        r="2.5"
                        transform="rotate(0.139607 9.35962 18.0061)"
                        fill="#F9F9F9"
                    />
                    <circle
                        cx="16.2141"
                        cy="18.0061"
                        r="2.5"
                        transform="rotate(0.139607 16.2141 18.0061)"
                        fill="#F9F9F9"
                    />
                    <circle
                        cx="2.50611"
                        cy="18.0061"
                        r="2.5"
                        transform="rotate(0.139607 2.50611 18.0061)"
                        fill="#F9F9F9"
                    />
                    <path
                        d="M24.4563 15.6309C23.0756 15.6309 21.9563 16.7501 21.9563 18.1309C21.9563 19.5116 23.0756 20.6309 24.4563 20.6309L24.4563 15.6309ZM24.4563 20.6309L66.7932 20.6309L66.7932 15.6309L24.4563 15.6309L24.4563 20.6309Z"
                        fill="#F9F9F9"
                    />
                    <path
                        d="M69.1689 18.0874C64.8888 15.7092 55.0147 9.27196 49.7593 2.54883"
                        stroke="#F9F9F9"
                        strokeWidth="5"
                        strokeLinecap="round"
                    />
                    <path
                        d="M41.4181 33.5256C45.6402 28.5346 57.1011 18.4594 69.1675 18.0873"
                        stroke="#F9F9F9"
                        strokeWidth="5"
                        strokeLinecap="round"
                    />
                </svg>
            </button>
        </>
    );
};
