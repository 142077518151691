import { getQuestionCountById, getThesisCountById } from 'src/functions';
import { getQuestionTextFromType } from 'src/functions/getters/getQuestionTextFromType';
import { T_Question, T_Quiz } from '../../../../globalTypes';
import { useAppDispatch } from '../redux';
import { useSocket } from '../socket';
import { useTimedEffect } from '../time';
import { useNextSection } from './useNextSection';
import { useQuestionCount } from './useQuestionCount';
import { useQuiz } from './useQuiz';

export const useSectionTransition = (
    inDecision?: boolean,
): {
    startAnimation: boolean;
    questionCount: number;
    currentThesisCount: number;
    thesisCount: number;
    totalQuestionCount: number;
    questionText: string | undefined;
    reward: number | undefined;
} => {
    const dispatch = useAppDispatch();
    const socket = useSocket();
    const nextSection = useNextSection<T_Question>();
    const startAnimation = useTimedEffect(500);
    const { totalQuestionCount, thesisCount } = useQuestionCount();
    const quiz = useQuiz() as T_Quiz;
    // Hier zit sectionTransition timeOut

    // useTimedCallback({
    //     duration: settings.sectionTransitionTime,
    //     callback: () =>
    //         !inDecision && castNextEvent({ socket, dispatch, forced: true }),
    // });
    const currentQuestionCount = getQuestionCountById(quiz, nextSection?.questionId);
    const currentThesisCount = getThesisCountById(quiz, nextSection?.questionId);
    const questionText = getQuestionTextFromType(nextSection?.type, inDecision);
    return {
        startAnimation,
        questionCount: currentQuestionCount,
        currentThesisCount,
        thesisCount,
        totalQuestionCount,
        questionText,
        reward: nextSection?.points,
    };
};
