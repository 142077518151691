import { postRequestToken } from 'src/api/postRequestToken';
import {
    T_AppDispatch,
    T_RootState,
    setMessage,
    startAppLoading,
    stopAppLoading,
} from '..';
import { T_ServerResponse } from '../../../../globalTypes';
import { postRequest } from '../../api/postRequest';

export const handlePostRequest = <T, U = any>({
    postData,
    endPoint,
    dataReducer,
    token,
    errorMessage,
}: {
    postData: U;
    endPoint: string;
    dataReducer?: Function;
    token?: boolean;
    errorMessage?: string;
}) => async (dispatch: T_AppDispatch, getState: () => T_RootState) => {
    try {
        dispatch(startAppLoading());
        const foundToken = getState().appState.token;
        let response: T_ServerResponse<T> | null = null;
        if (token && foundToken) {
            response = await postRequestToken<T_ServerResponse<T>>(
                `${endPoint}`,
                postData,
                foundToken,
            );
        }
        if (!token) {
            response = await postRequest<T_ServerResponse<T>>({
                endPoint: `${endPoint}`,
                data: postData,
            });
        }
        if (response?.message) {
            dispatch(setMessage(response.message));
        }
        if (typeof response?.data === 'string') {
            dispatch(setMessage(response.data));
        }
        if (dataReducer) {
            dispatch(dataReducer(response?.data));
        }
        dispatch(stopAppLoading());
    } catch (error) {
        if (errorMessage) {
            dispatch(setMessage(errorMessage));
        }
        dispatch(stopAppLoading());
    }
};
