import React from 'react';

export const TeamResponseWrapper = ({
    children,
    ...rest
}: {
    children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <div className="socket-container" {...rest}>
            {children}
        </div>
    );
};
