import { DataFromFormNoCode } from 'src/pages/subpages';
import { T_SchoolData } from '../../../../globalTypes';

export const parseFormToNoCodeLecture = (
    dataForm: DataFromFormNoCode,
): T_SchoolData => {
    const {
        schoolName,
        guestLecturerName,
        postalCode,
        teacherName,
        teacherEmail,
        bank,
        guestLecturerEmail,
        schoolLevel,
    } = dataForm || {};
    const getClassLevel = (dataForm: DataFromFormNoCode) => {
        switch (dataForm.schoolLevel) {
            case 'PO': {
                return dataForm.classLevel;
            }
            case 'VO': {
                return `${dataForm.classLevel} ${dataForm.level}`;
            }
            case 'MBO': {
                return `${dataForm.classLevel} ${dataForm.fieldOfStudy}`;
            }
        }
    };
    return {
        school_name: schoolName,
        level: schoolLevel,
        id: 0,
        lectures: [
            {
                id: 0,
                bank,
                yearData: getClassLevel(dataForm),
                firstname: guestLecturerName,
                email: guestLecturerEmail,
                postcode: postalCode,
                teacher: teacherName,
                email_teacher: teacherEmail,
                school_name: schoolName,
                prefix: null,
                lastname: null,
            },
        ],
    };
};
