import { hostURL } from '../config';

export const getRequest = async <T>(
    endPoint: string,
    isExternal?: boolean,
): Promise<T> => {
    const response = await fetch(
        `${isExternal ? '' : hostURL}${endPoint}`,
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json() as Promise<T>;
    });
    return response;
};
