import { settings } from 'src/config/settings';
import { getHexShadow } from 'src/functions';
import styled from 'styled-components';
import { T_Team } from '../../../../globalTypes';

export const DemoScoreBox = ({ team }: { team: T_Team }) => {
    const { MPCLetterArray } = settings;
    const { responses } = team || { responses: null };
    const foundResponse = responses[0]?.response || 'X';
    const letter =
        Array.isArray(foundResponse) && typeof foundResponse[0] === 'number'
            ? MPCLetterArray[foundResponse[0] - 1]
            : 'X';
    return (
        <div className="demo-result-box">
            <div className="demo-result-letter-box-wrapper">
                <div className="demo-result-letter-box">{letter}</div>{' '}
            </div>
            <StyledDiv color={team.teamColor} className="demo-result-index-box">
                <p>{team.id}</p>
            </StyledDiv>
        </div>
    );
};

const StyledDiv = styled.div<{
    color?: string;
}>`
    background-color: ${props => props.color || 'white'};
    border-color: ${props => getHexShadow(props.color) || 'white'};
`;
