import classNames from 'classnames';
import { motion } from 'framer-motion';
import { ComponentProps } from 'react';
import { CardBoardInstructions } from './CardboardInstructions';
import { DeviceInstructions } from './DeviceInstructions';
import './style.scss';

export type SelectCardProps = ComponentProps<typeof SelectCard>;
export const SelectCard = ({
    isSelected,
    text,
    onClickEvent,
    type,
    svg,
}: {
    isSelected: boolean;
    text: string;
    onClickEvent: () => void;
    svg: React.ReactNode;
    type: 'Digitaal' | 'Bordjes';
}) => {
    return (
        <div
            className={classNames({
                selectedCard: isSelected,
                unselectedCard: !isSelected,
                devices: type === 'Bordjes',
                cardboard: type === 'Digitaal',
            })}
            onClick={onClickEvent}
        >
            <>
                <div
                    className={classNames('svgWrapper', {
                        digital: type === 'Bordjes',
                        local: type === 'Digitaal',
                    })}
                >
                    {svg}
                </div>
                {type === 'Digitaal' && <DeviceInstructions />}
                {type === 'Bordjes' && <CardBoardInstructions />}
            </>
            <div className={classNames(
                     'flex w-full select-wrapper',
                     {
                         digital: type === 'Digitaal',
                         local: type === 'Bordjes',
                     },
                 )}>
                <MotionDiv className="flex items-center justify-center">
                    <div
                        className={classNames(
                            '_size-32 _rinput flex justify-center items-center',
                            {
                                selected: isSelected,
                                aselected: !isSelected,
                            },
                        )}
                    >
                        <MotionInput
                            animate={{
                                scale: isSelected ? 1 : 0,
                            }}
                            transition={{
                                duration: 0.35,
                                ease: 'easeInOut',
                            }}
                            type="radio"
                            className={classNames(
                                ' _size-16 rounded-full mt-auto',
                                {
                                    'bg-nassau': isSelected,
                                },
                            )}
                        />
                    </div>
                </MotionDiv>
                <MotionH3
                    transition={{
                        duration: 0.35,
                        ease: 'easeInOut',
                    }}
                    className={classNames('cardText grow', {
                        digital: type === 'Digitaal',
                        local: type === 'Bordjes',
                    })}
                >
                    {text}
                </MotionH3>
            </div>
        </div>
    );
};

const MotionDiv = motion.div;
const MotionH3 = motion.h3;
const MotionInput = motion.input;
