import { T_AppDispatch, setPhase, setTeamDecision } from '..';

export const handleDecision = (teamIndices: number[]) => async (
    dispatch: T_AppDispatch,
) => {
    try {
        if (!teamIndices) {
            dispatch(setPhase('quizResults'));
            return;
        }
        dispatch(setTeamDecision(teamIndices));
        dispatch(setPhase('decision'));
    } catch (error) {
        console.log(error);
    }
};
