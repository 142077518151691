import { T_Team } from '../../../../globalTypes';
import { calculateFinalScore } from '../helpers/calculations/calculateFinalScore';

export const gameHasWinner = <T extends boolean | undefined = undefined>(
    teams: T_Team[],
    returnIds?: T,
): T extends true ? number[] : boolean => {
    const scores = calculateFinalScore(teams);
    const sortedScores = scores.sort((a, b) => b.score - a.score);
    const highestScores = sortedScores.filter(score => {
        return score.score >= sortedScores[0].score;
    });
    if (returnIds) {
        return highestScores.map(score => score.id) as any;
    }
    return (highestScores.length === 1) as any;
};
