import { motion } from 'framer-motion';
import { useAppSelector } from 'src/hooks';
import { TitleLogo } from './TitleLogo';
const MotionDiv = motion.div;
export const HeaderLogo = () => {
    const isTrial = useAppSelector(state => state.appState.lectureIsTrial);
    return (
        <>
            <MotionDiv
                initial={{
                    opacity: 0,
                    y: isTrial ? 20 : -20,
                }}
                animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                        duration: 0.4,
                        ease: 'easeOut',
                    },
                }}
                className="header-logo-wrapper"
            >
                {isTrial && (
                    <MotionDiv
                        className="trial-className"
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 100,
                            transition: {
                                duration: 0.4,
                                ease: 'easeOut',
                            },
                        }}
                    >
                        <motion.span
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 100,
                            }}
                        >
                            Oefenversie
                        </motion.span>
                    </MotionDiv>
                )}
                {isTrial || <TitleLogo />}
            </MotionDiv>
        </>
    );
};
// {

// }
