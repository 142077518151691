import { Socket } from 'socket.io-client';
import { settings } from 'src/config/settings';
import { T_AppDispatch, T_RootState, decreaseTime, setTime } from '..';
import {
    castNextEvent,
    getCurrentSectionInState,
    sectionIsQuestion,
} from '../../functions';

export const handleTimer = (socket: Socket) => async (
    dispatch: T_AppDispatch,
    getState: () => T_RootState,
) => {
    try {
        const { timerState, quizState } = getState();
        const { timer }: { timer: number } = timerState;
        const { quizPhase: phase, quizIsLocal } = quizState;
        const currentQuestion = getCurrentSectionInState(quizState);
        //Stop is there is no question or if the timer is 0
        if (!phase || (!timer && timer !== 0)) return;
        //If we are in the question phase, we set the timer to the question time to prevent 0 from appearing in the UI
        if (phase === 'questionIntro' || phase === 'decision') {
            if (!sectionIsQuestion(currentQuestion)) {
                if (phase === 'decision') {
                    dispatch(setTime(settings.decisionTime));
                }
                return;
            }
            dispatch(setTime(currentQuestion?.time));
            return;
        }
        //When the timer hits 0 we go to the next stage
        if (
            timer === 0 &&
            (phase === 'question' || phase === 'decisionQuestion')
        ) {
            castNextEvent({ socket, dispatch });
            return;
        }
        //Ensure we are not going to the next phase in the phases after the timer
        if (
            (quizIsLocal && phase === 'questionResponse') ||
            (!quizIsLocal && phase === 'answerReveal') ||
            phase === 'decisionResponse' ||
            phase === 'decisionQuestionReveal'
        )
            return;
        //Decrease the timer if we are in the questionAnswers phase (or decisionQuestion phase which is very, very similar)
        if (phase === 'question' || phase === 'decisionQuestion') {
            dispatch(decreaseTime());
            return;
        }
    } catch (error) {
        console.log(error);
    }
};
