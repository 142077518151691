import React from 'react';
import styled from 'styled-components';

export const ImageWrapper = ({ children }: { children?: React.ReactNode }) => {
    return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
    max-width: 600px;
    max-height: 600px;
`;
