import React, { useEffect } from 'react';
import { setInteractionDimensions } from 'src/store';
import {
    T_AppState,
    T_BooleanDimensions,
    T_Dimensions,
} from '../../../../globalTypes';
import { useAppDispatch } from '../redux/useAppDispatch';
import useWindowDimensions from './useWindowDimensions';

export const useDimensionsEffect = ({
    interactionKey,
    ref,
    dimensions,
    condition,
}: {
    interactionKey?: keyof T_AppState['interactionDimensions'];
    ref: React.RefObject<HTMLDivElement>;
    dimensions: T_BooleanDimensions;
    condition?: boolean; 
}) => {
    const dispatch = useAppDispatch();
    const windowDimensions = useWindowDimensions();
    useEffect(() => {
        if (
            !ref ||
            !ref.current ||
            !interactionKey ||
            (!condition && condition !== undefined)
        )
            return;

        const reference = ref.current.getBoundingClientRect();
        const updatedDimensions: T_Dimensions = {
            xLeft: dimensions.xLeft ? reference.x : undefined,
            yTop: dimensions.yTop ? reference.y : undefined,
            xRight: dimensions.xRight
                ? reference.x + reference.width
                : undefined,
            yBottom: dimensions.yBottom
                ? reference.y + reference.height
                : undefined,
            width: dimensions.width ? reference.width : undefined,
            height: dimensions.height ? reference.height : undefined,
        };
        dispatch(
            setInteractionDimensions({
                interactionKey,
                dimensions: updatedDimensions,
            }),
        );
    }, [ref.current, windowDimensions]);
};
