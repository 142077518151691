import { T_Question, T_Team } from '../../../../globalTypes';
import { getResponseByQuestionId } from '../getters/getResponseByQuestionId';
export const teamHasResponseToQuestion = ({
    team,
    question,
    decision,
    forStatusBox,
}: {
    team: T_Team;
    question: T_Question | false;
    decision?: boolean;
    forStatusBox?: boolean;
}): boolean => {
    if (decision) {
        return team.decisionResponse !== 0;
    }
    if (!question) return false;
    const foundResponse = getResponseByQuestionId({
        team,
        questionId: question.questionId,
    });
    if (
        foundResponse?.questionType === 'Open' &&
        team.socketId &&
        foundResponse.response === -1
    ) {
        return forStatusBox || false;
    }
    if (!foundResponse) return false;
    return true;
};
