import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { boxColors, mainColors } from 'src/config/settings';
import styled from 'styled-components';
import {
    T_DecisionQuestion,
    T_NormalQuestion,
} from '../../../../../../../globalTypes';
import {
    arrayKeepNumbers,
    getThemeColor,
    questionIsNotDecisionQuestion,
} from '../../../../../functions';
import {
    useAppDispatch,
    useAppSelector,
    usePhase,
    useQuizIsLocal,
    useSelfHasResponded,
    useSelfResponse,
} from '../../../../../hooks';
import {
    selectRawTeamResponse,
    setRawTeamResponse,
} from '../../../../../store';
import { GuessNumberInputBox } from './GuessNumberInputBox';
import { GuessNumberRow } from './GuessNumberRow';

export const GuessNumberInput = ({
    question,
    inDecision,
    revealAnswer,
}: {
    question: T_NormalQuestion<'Guess'> | T_DecisionQuestion;
    inDecision?: boolean;
    revealAnswer?: boolean;
}) => {
    const phase = usePhase();
    const dispatch = useAppDispatch();
    const rawTeamResponse = useAppSelector(selectRawTeamResponse());
    const quizIsLocal = useQuizIsLocal();
    const [value, setValue] = useState<number>(
        !quizIsLocal &&
            Array.isArray(rawTeamResponse?.response) &&
            typeof rawTeamResponse?.response[0] === 'number'
            ? rawTeamResponse?.response[0]
            : 0,
    );
    const selfHasResponded = useSelfHasResponded();
    const selfResponse = useSelfResponse();
    const correctAnswer = arrayKeepNumbers(question.correctAnswers)[0];
    const selfAnswer = arrayKeepNumbers(selfResponse?.response)[0];
    const selfIsCorrect = selfAnswer === correctAnswer;
    useEffect(() => {
        dispatch(
            setRawTeamResponse({
                type: inDecision ? 'Decision' : 'Guess',
                response: value,
            }),
        );
    }, [value]);
    useEffect(() => {
        if (!rawTeamResponse) {
            setValue(0);
        }
        setValue(arrayKeepNumbers(rawTeamResponse?.response)[0] || 0);
    }, [rawTeamResponse]);
    let unitPrepend: boolean | undefined = undefined;
    let unit: string | undefined = '';
    let unitMaxLength: number | undefined = 10;
    if (questionIsNotDecisionQuestion(question) && question.typeData) {
        const {
            unitPrepend: _unitPrepend,
            unit: _unit,
            unitMaxLength: _unitMaxLength,
        } = question.typeData;
        unitPrepend = _unitPrepend;
        unit = _unit;
        unitMaxLength = _unitMaxLength;
    }
    if (!questionIsNotDecisionQuestion(question)) {
        unitPrepend = question.unitPrepend;
        unit = question.unit;
        unitMaxLength = question.unitMaxLength;
    }
    const props = {
        quizIsLocal,
        selfHasResponded,
        value,
        unitMaxLength,
        setValue,
        rawTeamResponse,
    };
    const numberRows = [1, 4, 7];
    const NumberInputBoxes = [...numberRows, 0, null].map(
        (number: number | null, index: number) => {
            if (number === null) {
                return null;
            }
            if (number === 0) {
                return (
                    <div className="guess-number-row" key={index}>
                        <GuessNumberInputBox
                            {...props}
                            span={2}
                            number={0}
                            unit={unit}
                        />
                        <GuessNumberInputBox
                            {...props}
                            number={null}
                            unit={unit}
                        />
                    </div>
                );
            }

            if (numberRows.includes(number)) {
                return (
                    <div key={number} className="guess-number-row">
                        <GuessNumberRow
                            unit={unit}
                            {...props}
                            key={number}
                            number={number}
                        />
                    </div>
                );
            }
            return null;
        },
    );

    const getGuessText = () => {
        let response = '_';
        const responseArray =
            phase === 'answerReveal'
                ? arrayKeepNumbers(selfResponse?.response)
                : arrayKeepNumbers(rawTeamResponse?.response);

        const foundResponse = responseArray[0];
        if (foundResponse) {
            response = foundResponse.toString();
        }
        return response;
    };

    const styledInputClassnames = classNames('guess-input', {
        flicker: getGuessText() === '_',
    });

    return (
        <>
            <StyledInput
                $revealAnswer={revealAnswer}
                $selfIsCorrect={selfIsCorrect}
                className={styledInputClassnames}
                type="string"
                value={getGuessText()}
                readOnly
            />
            {NumberInputBoxes}
        </>
    );
};

const StyledInput = styled(motion.input)<{
    $revealAnswer?: boolean;
    $selfIsCorrect?: boolean;
}>`
    background: ${props => {
        if (props.$revealAnswer && props.$selfIsCorrect) {
            return boxColors.correct;
        }
        if (props.$revealAnswer && !props.$selfIsCorrect) {
            return boxColors.incorrect;
        }
        return mainColors.primary;
    }};
    border-radius: 0px;
    border: 1px solid
        ${props =>
            getThemeColor({
                theme: props.theme,
                themeType: 'colors',
                themeColor: 'primary',
            })};
    color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'white',
        })};
`;
