import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { settings } from 'src/config/settings';
import { getRandomInteger } from 'src/functions';
import { useTimedEffect } from 'src/hooks';

//used in ClosedOption
export const ThesisCount = ({
    count = 0,
    setThesisIsRevealed,
}: {
    count?: number;
    setThesisIsRevealed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { revealAnswerTime } = settings;
    const [displayedCount, setDisplayedCount] = useState<number>(0);
    const startTimer = useTimedEffect(revealAnswerTime);
    useEffect(() => {
        if (!startTimer) return;
        if (displayedCount >= count) {
            setThesisIsRevealed(true);
            return;
        }
        const interval = setInterval(() => {
            setDisplayedCount(prev => prev + 1);
        }, getRandomInteger(150, 255));
        if (displayedCount >= count) {
            setDisplayedCount(count);
            return () => clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [startTimer, displayedCount]);
    return (
        <motion.p
            initial={{
                y: -3,
                opacity: 0.9,
                scale: 0.95,
            }}
            animate={{
                y: 0,
                opacity: 1,
                scale: 1,
            }}
            key={displayedCount}
        >
            {displayedCount}
        </motion.p>
    );
};
