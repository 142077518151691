import { T_Quiz } from '../../../../../globalTypes';
import { getQuizCounts } from '../../getters';

export const drawQuizIntro = (quiz: T_Quiz): string => {
    const { totalQuestionCount, videoCount, thesisCount } = getQuizCounts(quiz);
    if (!totalQuestionCount)
        return '@[bLET OP]@: Deze quiz heeft geen vragen. Er lijkt iets mis te zijn gegaan. Probeer de quiz opnieuw te starten.';
    return `Deze quiz bestaat uit @[b${totalQuestionCount} ${
        totalQuestionCount > 1 ? 'vragen' : 'vraag'
    }]@${
        thesisCount > 0 ? ` en @[b${thesisCount} stellingen]@` : ''
    }. ${
        videoCount > 0 ? 'De filmpjes ondersteunen de vragen.' : ''
    } Elk goed antwoord levert geld op. /nHet team dat het meeste geld verdient door de vragen goed te beantwoorden is de winnaar van de Cash Quiz.  `;
};
