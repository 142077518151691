export const GuessReduceButton = ({
    onNumberClick,
}: {
    onNumberClick: (number: number | null) => void;
}) => {
    return (
        <div onClick={() => onNumberClick(null)} className="guess-min-button">
            <svg
                width="52"
                height="35"
                viewBox="0 0 52 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill="#5B5B5B"
                    d="M48.8 0H18.18c-1.295 0-3.068.776-3.933 1.733L.627 16.643c-.87.951-.83 2.474.084 3.378L14.16 33.345c.92.91 2.727 1.65 4.022 1.65H48.8c1.296 0 2.356-1.05 2.356-2.333V2.333C51.155 1.05 50.096 0 48.8 0Z"
                ></path>
                <path
                    stroke="#F2F2F2"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="m23.438 10.001 16.345 16.345m-15.909.001L40.22 10"
                ></path>
            </svg>
        </div>
    );
};
