const main = [
    'Sluit alle lopende programma’s op de computer af voordat je de quiz start.',
    'Gebruik bij voorkeur de browser Google Chrome.',
    'Hou je vragenboekje bij de hand en noteer de tussenstand per groepje op papier (loopt het digibord vast dan kun je de scores handmatig verder bijhouden en het winnende groepje doorgeven via info@bankvoordeklas.nl).',
    'Zet het digibord in ‘Volledig scherm modus’.',
];
const code = [
    'Er is een code verstuurd naar het emailadress van de gastdocent, vul deze in.',
    'Geen code ontvangen? Speel dan zonder code. Er wordt dan geen kans gemaakt op de landelijke prijzen.',
];

export const tips = {
    code,
    main,
};
