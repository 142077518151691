export const outroTitles = [
    {
        text: 'Hoe zit dat dan?',
        underlined: 'Hoe zit dat',
    },
    {
        text: 'Hoezo dan?',
        underlined: 'Hoezo',
    },
    {
        text: 'Huh? Leg uit!',
        underlined: 'Leg uit',
    },
];
