import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { T_SocketState } from '../../../../globalTypes';
import { appStorage } from '../../functions';

const initialState: T_SocketState = {
    connected: false,
    modals: [],
};

export const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        setIsConnected: state => {
            state.connected = true;
        },
        setIsDisconnected: state => {
            state.connected = false;
        },
        resetSocketState: () => {
            return initialState;
        },
        setSocketId: (state, action: PayloadAction<string>) => {
            state.socketId = action.payload;
            if (!action.payload) return;
            appStorage.saveSocketId(action.payload);
        },
        setOriginalSocketId: (state, action: PayloadAction<string>) => {
            appStorage.saveOriginalSocketId(action.payload);
        },
        setRoomId: (state, action: PayloadAction<string>) => {
            state.roomId = action.payload;
            if (!action.payload) return;
            appStorage.saveRoomId(action.payload);
        },
        setTeamId: (state, action: PayloadAction<number>) => {
            state.teamId = action.payload;
            if (!action.payload) return;
            appStorage.saveTeamIndex(action.payload);
        },
        setStorage: (
            state,
            action: PayloadAction<{
                teamId?: number;
                roomId: string;
                socketId: string;
                isHost?: boolean;
            }>,
        ) => {
            const { teamId, roomId, socketId, isHost } = action.payload;
            appStorage.saveSocketId(socketId);
            appStorage.saveRoomId(roomId);
            state.roomId = roomId;
            state.socketId = socketId;

            if (!teamId) {
                if (isHost) {
                    appStorage.saveHost();
                }
                return;
            }
            appStorage.saveTeamIndex(teamId);
            state.teamId = teamId;
        },
        addModal: (
            state,
            action: PayloadAction<{ id: string; message: string }>,
        ) => {
            state.modals = [...state.modals, action.payload];
        },
        removeModal: (state, action: PayloadAction<string>) => {
            state.modals = state.modals.filter(value => {
                if (value.id === action.payload) return false;
                return true;
            });
        },
    },
});

export const {
    resetSocketState,
    setIsConnected,
    setIsDisconnected,
    setSocketId,
    setOriginalSocketId,
    setRoomId,
    setTeamId,
    setStorage,
    addModal,
    removeModal,
} = socketSlice.actions;

export default socketSlice.reducer;
