import { settings } from 'src/config/settings';
import { getThemeColor } from 'src/functions';
import { getOptionBackgroundColor } from 'src/functions/ui/getters/getOptionBackgroundColor';
import { getOptionTextColor } from 'src/functions/ui/getters/getOptionTextColor';
import {
    useAppDispatch,
    usePhase,
    useQuizIsLocal,
    useTimedConditionalEffect,
    useTimedEffect,
} from 'src/hooks';

import classNames from 'classnames';
import { useState } from 'react';
import { findAvailableTeamId, setTeamResponse } from 'src/store';
import styled from 'styled-components';
import { T_Phase } from '../../../../../../../globalTypes';
import { ThesisCount } from '../Thesis/ThesisCount';
import './style.scss';

export const ClosedOption = ({
    text,
    correct,
    right,
    selected,
    thesis,
    thesisData,
}: {
    text: string;
    correct?: boolean;
    right?: boolean;
    selected?: boolean;
    thesis?: boolean;
    thesisData?: { count: number; isHighestCount: boolean; draw?: boolean }; //used to display the number underneath the thesis options in answerReveal
}) => {
    const quizIsLocal = useQuizIsLocal();
    const { responseDelayTime, revealAnswerTime, thesisDelayTime } = settings;
    const revealAnswer = useTimedEffect(revealAnswerTime);
    const { isHighestCount, draw } = thesisData || {};
    const [thesisIsRevealed, setThesisIsRevealed] = useState(false);
    const thesisHasOutcome = useTimedConditionalEffect({
        duration: thesisDelayTime,
        condition: thesisIsRevealed,
    });
    const dispatch = useAppDispatch();
    const phase = usePhase();
    const onClickEvent = (id: number) => {
        if (!quizIsLocal || phase !== 'questionResponse') return;
        dispatch(
            setTeamResponse({
                response: {
                    questionType: thesis ? 'Thesis' : 'Closed',
                    response: id,
                },
            }),
        );
        setTimeout(() => {
            dispatch(findAvailableTeamId());
        }, responseDelayTime);
    };
    return (
        <StyledClosedOption
            correct={correct}
            selected={selected}
            revealAnswer={revealAnswer}
            onClick={() => onClickEvent(right ? 2 : 1)}
            phase={phase}
            className={classNames('closed-option', {
                'right-option': right && phase !== 'questionResponse',
                'question-response': phase === 'questionResponse',
            })}
            thesis={thesis && phase === 'answerReveal'}
            thesisHasOutcome={
                draw !== true && isHighestCount && thesisHasOutcome
            }
        >
            {text}
            {thesis && phase === 'answerReveal' && thesisData && (
                <ThesisCount
                    setThesisIsRevealed={setThesisIsRevealed}
                    count={thesisData.count}
                />
            )}
        </StyledClosedOption>
    );
};
const StyledClosedOption = styled.div<{
    phase?: T_Phase;
    selected?: boolean;
    onClick: () => void;
    correct?: boolean;
    revealAnswer?: boolean;
    thesis?: boolean;
    thesisHasOutcome?: boolean;
}>`
    ${props => {
        const revealAnswer =
            props.phase === 'answerReveal' ? props.revealAnswer || false : true;
        const { correct, theme, selected } = props;
        switch (props.phase) {
            case 'answerReveal':
                return `
          background: ${
              props.thesisHasOutcome
                  ? getThemeColor({
                        theme: props.theme,
                        themeType: 'colors',
                        themeColor: 'input',
                    })
                  : getOptionBackgroundColor({
                        revealAnswer,
                        correct: correct || false,
                        theme,
                    })
          };
          color: ${
              props.thesisHasOutcome
                  ? getThemeColor({
                        theme: props.theme,
                        themeType: 'colors',
                        themeColor: 'white',
                    })
                  : getOptionTextColor({
                        revealAnswer,
                        correct: correct || false,
                        theme,
                    })
          };
        `;
            case 'questionResponse':
                return `
            background: ${getOptionBackgroundColor({
                revealAnswer,
                selected: selected || false,
                theme,
            })};
            color: ${getOptionTextColor({
                revealAnswer,
                selected: selected || false,
                theme,
            })};
          `;
            default:
                return `background: ${getThemeColor({
                    theme: props.theme,
                    themeType: 'colors',
                    themeColor: 'coolBackground',
                })};`;
        }
    }}
    border-width: 1px;
    border-style: solid;
    border-color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'primary',
        })};
    gap: ${props => (props.thesis ? '16px' : '32px')};
    box-shadow: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'boxShadows',
            themeColor: 'primary',
        })};
    justify-content: ${props => (props.thesis ? 'space-between' : 'center')};
    height: auto;
`;

export default StyledClosedOption;
