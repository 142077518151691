import { useDispatch } from 'react-redux';
import { castNextEvent } from 'src/functions';
import { useSocket } from '../socket/useSocket';
import { useInDateRange } from '../time/useInDateRange';

export const useNextEvent = (forced?: boolean) => {
    const isPreview = useInDateRange({
        startDate: '2025-01-01',
        endDate: '2030-01-01',
    });
    const socket = useSocket();
    const dispatch = useDispatch();
    return () =>
        castNextEvent({ socket, dispatch, forced: forced || isPreview });
};
