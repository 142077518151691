import { useThesis } from 'src/hooks/quiz/useThesis';
import { ClosedOption } from '../Closed';
import { ClosedOptionsWrapper } from '../Closed/ClosedOptionsWrapper';

//only reveals the OUTCOME not the possibilities. The possibilities are handled by ClosedDisplay.
export const ThesisDisplay = () => {
    const currentThesis = useThesis();
    const { leftText, rightText, leftCount, rightCount } = currentThesis || {
        leftCount: 0,
        rightCount: 0,
    };
    const leftIsHighest = leftCount > rightCount;
    const rightIsHighest = rightCount > leftCount;
    return (
        <ClosedOptionsWrapper className="answer-reveal">
            <ClosedOption
                text={leftText || 'Eens'}
                thesis
                thesisData={{
                    count: leftCount,
                    isHighestCount: leftIsHighest,
                    ...(!leftIsHighest && !rightIsHighest && { draw: true }),
                }}
            />
            <ClosedOption
                text={rightText || 'Oneens'}
                thesis
                thesisData={{
                    count: rightCount,
                    isHighestCount: rightIsHighest,
                    ...(!leftIsHighest && !rightIsHighest && { draw: true }),
                }}
            />
        </ClosedOptionsWrapper>
    );
};
