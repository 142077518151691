import { motion } from 'framer-motion';
import { defaultScaleAnimation } from 'src/config/animation/animations';
import { PrimaryText } from 'src/layout/styledcomponents/wrappers/typography/PrimaryText';
import styled from 'styled-components';
import { useAppSelector } from '../../../hooks';
import { selectInteractions } from '../../../store';
import { QRSection } from './QRSection';

const MotionDiv = motion.div;
export const ScanSection = ({ roomId }: { roomId: string }) => {
    const isOpen = useAppSelector(selectInteractions('largeQR'))?.isOpen;
    return (
        <>
            <Wrapper className="column-left w-6_10" open={isOpen}>
                {
                    <PrimaryText
                        quizIsLocal={false}
                        type="h3"
                        className="font-sans weight-400"
                    >
                        Één iemand per team logt in met de{' '}
                        <span className="font-bungee">QR code</span><br/>
                        of via <span className="font-bungee">cashquiz.bankvoordeklas.nl/team/{roomId}</span>
                    </PrimaryText>
                }
                <MotionDiv {...defaultScaleAnimation} className="link-wrapper">
                    <QRSection roomId={roomId} />
                    {
                        <>
                            <PrimaryText
                                quizIsLocal={false}
                                type="span"
                                className="font-bungee center h3"
                            >
                            
                            </PrimaryText>
                            
                        </>
                    }
                </MotionDiv>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div<{
    open: boolean | undefined;
}>`
    opacity: ${props => (props.open ? 0 : 1)};
    transition: opacity 0.1s ease-in-out;
`;
