import { Socket } from 'socket.io-client';
import { T_ClientToServerEndPoints } from '../../../../globalTypes';
import { Event } from './castEvent';
//Used to create an event in socket ONLY components
export const createSocketEvent = (
    socket: Socket,
    endPoint: T_ClientToServerEndPoints,
    socketPayload?: any,
): Event => {
    return { socket, dispatch: null, reducer: null, endPoint, socketPayload };
};
