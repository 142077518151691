import { hostURL } from '../config/config';

export const postRequestToken = async <T>(
    endPoint: string,
    data: any,
    token: string,
): Promise<T> => {
    const response = await fetch(`${hostURL}${endPoint}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ data }),
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return response.json() as Promise<T>;
    });
    return response;
};
