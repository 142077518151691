import { settings } from 'src/config/settings';
import { T_AppDispatch, resetTime, setTime } from '..';
import { T_RawState } from '../../../../globalTypes';
import { getCurrentSectionInState, sectionIsQuestion } from '../../functions';

//Enforces the start of the timer when necessary
export const handleQuestionTime = () => async (
    dispatch: T_AppDispatch,
    getState: () => T_RawState,
) => {
    try {
        const { quizState } = getState();
        if (!quizState) return;
        const question = getCurrentSectionInState(quizState);
        const currentPhase = quizState.quizPhase;
        if (currentPhase === 'decisionQuestion') {
            dispatch(setTime(settings.decisionTime));
        }
        if (currentPhase === 'scoreReveal') {
            dispatch(resetTime());
        }
        if (!question || !sectionIsQuestion(question)) return;
        dispatch(setTime(question.time));
    } catch (error) {
        console.log(error);
    }
};
