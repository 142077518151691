import { RequireOnlyOne, T_ThemeState } from '../../../../../globalTypes';
import { getThemeColor } from './getThemeColor';
export const getOptionBackgroundColor = ({
    theme,
    revealAnswer,
    selected,
    correct,
}: { theme: T_ThemeState; revealAnswer?: boolean } & RequireOnlyOne<{
    selected?: boolean;
    correct?: boolean;
}>) => {
    if (revealAnswer === false) {
        return getThemeColor({
            theme: theme,
            themeType: 'colors',
            themeColor: 'coolBackground',
        });
    }
    if (correct) {
        return getThemeColor({
            theme: theme,
            themeType: 'colors',
            themeColor: 'correct',
        });
    }
    if (selected) {
        return getThemeColor({
            theme: theme,
            themeType: 'colors',
            themeColor: 'input',
        });
    }
    return getThemeColor({
        theme: theme,
        themeType: 'colors',
        themeColor: 'coolBackground',
    });
};
