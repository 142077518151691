import { PrimaryText } from 'src/layout/styledcomponents/wrappers/typography/PrimaryText';
import { useAppDispatch, useInteractionOpen } from '../../../hooks';
import { setInteraction } from '../../../store';
import { QRCode } from './QRcode';

export const QRSection = ({ roomId }: { roomId: string }) => {
    const dispatch = useAppDispatch();
    const largeQRIsOpen = useInteractionOpen('largeQR');
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '250px',
                justifyContent: 'center',
                height: '100%',
                gap: '25px',
            }}
            onClick={() =>
                dispatch(
                    setInteraction({
                        interactionKey: 'largeQR',
                        change: 'open',
                    }),
                )
            }
        >
            <div
                className="flex column"
                style={{
                    opacity: largeQRIsOpen ? 0 : 1,
                }}
            >
                <QRCode roomId={roomId} />
                <PrimaryText
                    quizIsLocal={false}
                    className="mt-24 font-sans size-16 weight-700 center"
                >
                    Klik om te vergroten
                </PrimaryText>
            </div>
        </div>
    );
};
