import { useState } from 'react';
import { useSocket } from '../../hooks';
import { SocketReceiver } from '../../socket/SocketReceiver';
import { Button } from '../base/buttons';

export const Server = () => {
    const socket = useSocket();
    const onClickEvent = () => {
        socket.emit('getState');
    };
    const setTestState = useState<any>('KLIK')[1];
    return (
        <>
            <Button
                text={'getState'}
                onClick={onClickEvent}
                buttonType="primary"
            />
            <SocketReceiver testSetter={setTestState} />
        </>
    );
};
