import { ListScroll } from 'src/components/base/list/ListScroll';
import { drawQuizInformation } from '../../../functions';
import {
    getQuizList,
    selectQuizList,
    selectSelectedQuizId,
    setQuizId,
} from '../../../store';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useGuestCode } from 'src/hooks';

// Define a type for the quiz levels
type QuizLevel = 'PO' | 'MBO' | 'VO' | null;

export const QuizSelectionPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const guestLecturerCode = useGuestCode();
    const lectureIsWithoutCode = useAppSelector(
        state => state.appState.lectureIsWithoutCode,
    );
    const activeLevel = useAppSelector(
        state => state.appState.activeLectureLevel,
    );
    
    useEffect(() => {
        if (!guestLecturerCode && !lectureIsWithoutCode) {
            navigate('../');
        }
    }, [guestLecturerCode]);
    useEffect(() => {
        dispatch(getQuizList());
    }, []);
    const quizList = useAppSelector(selectQuizList());
    if (!quizList) {
        return null;
    }

    const quizes = drawQuizInformation(quizList);
    
   // Function or mapping to determine the heading text based on activeLevel
   const getHeadingText = (level: QuizLevel): string => {
        switch (level) {
            case 'PO':
                return 'In welke groep ga je de quiz spelen?';
            case 'MBO':
                return 'Welke quiz wil je spelen?';
            case 'VO':
                return 'Welke quiz wil je spelen?';
            default:
                return 'Welke quiz wil je spelen?'; // Default text
        }
    };
    const getHeadingTextUnderline = (level: QuizLevel): string => {
        switch (level) {
            case 'PO':
                return 'In welke groep';
            case 'MBO':
                return 'Welke quiz';
            case 'VO':
                return 'Welke quiz';
            default:
                return 'Welke quiz'; // Default text
        }
    };
 
    return (
        <ListScroll
            cardSelector={selectSelectedQuizId}
            reducer={setQuizId}
            list={quizes}
            heading={{
                text: getHeadingText(activeLevel),
                underlined: getHeadingTextUnderline(activeLevel),
            }}
        />
    );
};
