import { boxColors } from 'src/config/settings';
import styled from 'styled-components';

export const StyledTeamButton = styled.button<{
    selected: boolean;
    revealAnswer: boolean;
    isCorrectAnswer: boolean;
}>`
    background: ${props => {
        const { revealAnswer, isCorrectAnswer, selected } = props;
        if (revealAnswer) {
            if (selected && isCorrectAnswer) {
                return boxColors.correct;
            }
            if (selected && !isCorrectAnswer) {
                return boxColors.incorrect;
            }
        }
        if (!revealAnswer) {
            if (selected) {
                return boxColors.selected;
            }
        }
        return boxColors.unselected;
    }};
    box-shadow: ${props => {
        const { revealAnswer, isCorrectAnswer, selected } = props;
        if (revealAnswer) {
            return '0px 0px 0px 0px';
        }
        if (!revealAnswer) {
            if (selected) {
                return '0px 0px 0px 0px';
            }
        }
        return '0px 2px 0px 0px';
    }};

    color: ${props => (props.selected ? '#FCFEFF' : '#263F47')};
`;
