import { getOptionBackgroundColor } from 'src/functions/ui/getters/getOptionBackgroundColor';
import { getOptionTextColor } from 'src/functions/ui/getters/getOptionTextColor';
import styled from 'styled-components';
import { getThemeColor } from '../../../../../functions';

export const StyledOptionBox = styled.div<{
    selected?: boolean;
    onClick?: () => void;
    maxHeight?: string;
    maxWidth?: string;
}>`
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: ${props => {
        return getOptionTextColor({
            selected: props.selected || false,
            theme: props.theme,
        });
    }};
    transition: background-color 0.2s ease-in-out;
    background-color: ${props =>
        getOptionBackgroundColor({
            selected: props.selected || false,
            theme: props.theme,
        })};
    min-height: 120px;
    min-width: 180px;
    height: fit-content;
    max-height: ${props => props.maxHeight || '180px'};
    max-width: ${props => props.maxWidth || ''};
    font-size: 32px;
    font-style: normal;
    line-height: normal;
    gap: 32px;
    box-shadow: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'boxShadows',
            themeColor: 'primary',
        })};
    border: 1px solid ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'primary',
        })};
}
`;
