import { ComponentProps } from 'react';
import { images } from 'src/config/mediaConfig';

export const Picture = ({
    src,
    alt,
    ...rest
}: { src?: string } & ComponentProps<'img'>) => {
    return (
        <picture>
            <source srcSet={`${images}${src}.webp`} type="image/webp" />
            <source srcSet={`${images}${src}.png`} type="image/png" />
            <img {...rest} alt={alt || ''} src={`${images}${src}.png`} />
        </picture>
    );
};
