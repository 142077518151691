import { InfoText } from 'src/layout/styledcomponents/wrappers/typography/InfoText';
import type { T_Question } from '../../../../../../../globalTypes';
import { useAppSelector } from '../../../../../hooks';
import { selectOpenReponseFromTeam } from '../../../../../store';
import { OpenResponseSection } from './OpenResponseSection';

export const OpenValidationSection = ({question}: { question: T_Question}) => {
    const selectedResponse = useAppSelector(selectOpenReponseFromTeam());
    if (question.type !== 'Open')return null;
    return (
        <>
            <InfoText className="mb-32 open-response-text" text={<>"{selectedResponse}"</>} />
            <OpenResponseSection question={question} />
        </>
    );
};
