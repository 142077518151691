import {
    T_AppDispatch,
    clearToken,
    setErrorCode,
    setMessage,
    startAppLoading,
    stopAppLoading,
} from '..';
import { T_ServerResponse } from '../../../../globalTypes';
import { getRequest, getRequestToken } from '../../api';
import { appStorage } from '../../functions';

export const handleGetRequest = <T>(
    endpoint: string,
    dataReducer?: Function | null,
) => async (dispatch: T_AppDispatch, getState: Function) => {
    try {
        dispatch(startAppLoading());
        const token = appStorage.retrieveToken();
        const response = token
            ? await getRequestToken<T_ServerResponse<T>>(`${endpoint}`, token)
            : await getRequest<T_ServerResponse<T>>(`${endpoint}`);

        if (response.message === 'Verlopen token') {
            dispatch(clearToken());
        }
        if (response.message) {
            dispatch(setMessage(response.message));
        }
        if (response.errorCode) {
            dispatch(setErrorCode(response.errorCode));
        }
        if (dataReducer) {
            dispatch(dataReducer(response.data));
        }
        dispatch(stopAppLoading());
    } catch (error) {
        console.log(error);
        dispatch(stopAppLoading());
    }
};
