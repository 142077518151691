import { Fragment } from 'react';
import { renderDrawNewLines } from './renderDrawNewLines';
import { renderNoWrap } from './renderNoWrap';

export const renderText = ({ text }: { text: string }) => {
    const wrappedText = renderDrawNewLines({ text });
    const linedText = wrappedText.props.children.map(
        (child: JSX.Element, index: number) => {
            return (
                <Fragment key={index}>
                    {renderNoWrap({ element: child })}
                </Fragment>
            );
        },
    );
    return <>{linedText}</>;
};
