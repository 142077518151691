import { T_Team } from '../../../../../globalTypes';
import { getTextualTeams } from '../getters';
import { getNumeralFromNumber } from '../getters/getNumeralFromNumber';

export const renderDecisionText = ({
    teams,
    quizIsLocal,
}: {
    teams?: T_Team[] | null;
    quizIsLocal: boolean;
}) => {
    if (!teams) return <>Er volgt nu een beslissingsvraag.</>;
    const mappedTeams = teams?.map((team: T_Team) => {
        if (!quizIsLocal && team.teamName) return team.teamName;
        return team.id;
    });
    return (
        <>
            Er is een gelijkspel tussen de volgende teams:{' '}
            {getTextualTeams(mappedTeams)}. Deze{' '}
            {getNumeralFromNumber(mappedTeams?.length)} teams zullen een laatste
            schattingsvraag krijgen. Het team dat het meest in de buurt zit
            wint! Bij gelijke antwoorden is het team met het snelste antwoord de
            winnaar.
        </>
    );
};
