import { Timer } from '..';
import { QREnlarged } from './QREnlarged';
import { PopUp } from './PopUp';
import { WarningPopUp } from './WarningPopUp';

export const AbsoluteInteractions = () => {
    return (
        <>
            <Timer interaction />
            <PopUp />
            <QREnlarged />
            <WarningPopUp />
        </>
    );
};
