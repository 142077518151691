import { T_RawState, T_Team, T_TeamResponse } from '../../../../globalTypes';

import { getCurrentSectionInState, sectionIsQuestion } from '../../functions';

export const selectSocketState = () => (state: T_RawState) => {
    return state.socketState;
};

export const selectRoomId = () => (state: T_RawState) => {
    return state.socketState?.roomId;
};

export const selectOwnSocketId = () => (state: T_RawState) => {
    return state.socketState?.socketId;
};

//Checks whether a team has locally responded to a question, not to be used for a guest lecturer
export const selectSelfHasResponded = () => (state: T_RawState) => {
    if (!state.quizState || state.quizState.quizIsLocal) return false;
    if (state.quizState.quizInDecision) {
        const currentTeam = state.quizState.teams?.find(
            (team: T_Team) => team.socketId === state.socketState?.socketId,
        );
        if (!currentTeam || !currentTeam.inDecision) return false;
        return currentTeam.decisionResponse !== 0;
    }
    const { quizState } = state;
    const currentQuestion = getCurrentSectionInState(quizState);
    if (!sectionIsQuestion(currentQuestion)) return false;
    const foundResponse = state.quizState.teams
        ?.find((team: T_Team) => team.socketId === state.socketState?.socketId)
        ?.responses.find((response: T_TeamResponse) => {
            return response.questionId === currentQuestion.questionId;
        });
    if (!foundResponse) return false;
    return true;
};

//selects the known values from state that are shared with the local storage from the team's client
export const selectStorageValues = () => (state: T_RawState) => {
    const { socketState } = state;
    if (!socketState) return undefined;
    const { roomId, socketId, teamId } = socketState;
    return { roomId, socketId, teamId };
};

