import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { castPreviousEvent } from 'src/functions';
import { useSocket } from '../socket/useSocket';

export const usePreviousEvent = (forced?: boolean) => {
    const socket = useSocket();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return () => castPreviousEvent({ socket, dispatch, navigate });
};
