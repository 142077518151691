import {
    HomePage,
    QuizPage,
    RenderedTeamPage,
    SelectionPage,
} from '../pages/mainpages';

import { FC } from 'react';
import { PreviewPage } from 'src/pages/mainpages/PreviewPage';
import { NoCodeInputPage, TeamJoinPage } from 'src/pages/subpages';
import { TrialSelectionPage } from 'src/pages/subpages/selection/TrialSelectionPage';
import { TeamEndPage } from 'src/pages/subpages/team/TeamEndPage';
import { Server } from '../components/startPage/StartList';
import { ReconnectPage } from '../pages/mainpages/reconnectPage/ReconnectPage';

export type Page = {
    Component: FC;
    path: string;
};

export const routerPages: { Component: FC; path: string }[] = [
    {
        //Page used for the main page, where everyone arrives
        Component: HomePage,
        path: '/',
    },
    {
        //Page used for the main page, where everyone arrives
        Component: HomePage,
        path: '/:level',
    },
    {
        //Page used for the guest lecturer
        Component: SelectionPage,
        path: '/selection',
    },
    {
        //Page used for the guest lecturer
        Component: QuizPage,
        path: '/quiz-digibord',
    },
    {
        //Page used for the view of the students, only when socket is enabled
        Component: RenderedTeamPage,
        path: '/team',
    },
    {
        //Page used for the view of the students, only when socket is enabled. Has roomParams.
        Component: TeamJoinPage,
        path: '/speel/',
    },

    {
        //Page used for the view of the students, only when socket is enabled. Has roomParams.
        Component: TeamEndPage,
        path: '/einde-quiz/',
    },
    {
        //Page used for no code input
        Component: NoCodeInputPage,
        path: '/speel-zonder-code',
    },
    {
        //Page used for no code input
        Component: TrialSelectionPage,
        path: '/speel-proef-versie',
    },
    {
        //Page used for no code input
        Component: TrialSelectionPage,
        path: '/?oefensessie',
    },
    {
        //Page used for the view of the students, only when socket is enabled. Has roomParams.
        Component: TeamJoinPage,
        path: '/team/:roomId',
    },
    {
        //Page used for the view of the students, only when socket is enabled
        Component: Server,
        path: '/getState',
    },
    {
        //Page used for the view of the students, only when socket is enabled
        Component: PreviewPage,
        path: '/preview/:quizId/:moduleId/:sectionId',
    },
    {
        Component: ReconnectPage,
        path: '/herverbinden/:status',
    },
];

export const pageConfig = {
    routerPages,
};
