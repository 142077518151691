import { InputType } from '../NoCodeStartPage';
import { T_InputFieldProps } from './EntryField';
import { Error } from './Error';

export const InputField = ({
    field,
    fieldKey,
    register,
    error,
    tabIndex,
    setter,
}: T_InputFieldProps<InputType>) => {
    return (
        <div className="form-field" key={field.label}>
            <label>{field.label}</label>
            <input
                tabIndex={tabIndex}
                id={fieldKey}
                {...register(fieldKey)}
                type="text"
                alt={field.label}
                name={fieldKey}
            />
            <Error error={error} />
        </div>
    );
};
