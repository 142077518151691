import React from 'react';
import { getThemeColor } from 'src/functions/ui/getters/getThemeColor';
import styled from 'styled-components';
import { T_Phase } from '../../../../globalTypes';
import { usePhase, useQuizIsLocal } from '../../hooks';

export const HeaderWrapper = (props: { children?: React.ReactNode }) => {
    const phase = usePhase();
    const quizIsLocal = useQuizIsLocal();
    return (
        <Wrapper
            quizIsLocal={quizIsLocal}
            className="header-wrapper priority-100"
            phase={phase}
        >
            {props.children}
        </Wrapper>
    );
};

const Wrapper = styled.header<{
    phase: T_Phase | undefined;
    quizIsLocal?: boolean;
}>`
    padding: var(--layout-gap);
    display: flex;
    flex-direction: row;
    flex-basis: 0;
    align-items: center;
    min-height: 104px;
    justify-content: center;
    color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'primary',
        })};
`;
