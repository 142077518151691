import { T_Phase } from '../../../../../globalTypes';

export const drawBackButtonText = ({
    phase,
}: {
    phase: T_Phase | undefined;
}): string => {
    switch (phase) {
        case 'lectureSelection':
            return 'Stoppen';
        default:
            return 'Terug';
    }
};
