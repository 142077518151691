import { parseSchoolLevel } from 'src/functions/helpers/parseSchoolLevel';
import { T_AppDispatch, handleGetRequest, setQuizList } from '..';
import { T_Quiz, T_RawState } from '../../../../globalTypes';

//__TODO__ ALLEEN TITEL, ICON EN TAGS FETCHEN IPV HELE QUIZ
export const getQuizList = () => async (
    dispatch: T_AppDispatch,
    getState: () => T_RawState,
) => {
    try {
        const activeLevel = parseSchoolLevel(
            getState().appState?.activeLectureLevel,
        );
        dispatch(
            handleGetRequest<{ quizes: T_Quiz[]; message: any }>(
                `/quiz/getQuizList${activeLevel ? `/${activeLevel}` : ''}`,
                setQuizList,
            ),
        );
    } catch (error) {
        console.log(error);
    }
};
