import { T_ThemeColors, T_ThemeState } from '../../../../../globalTypes';

export const getThemeColor = <T extends keyof T_ThemeColors>({
    theme,
    themeColor,
    themeType,
}: {
    theme: T_ThemeState | Omit<T_ThemeState, 'themeHistory' | 'inTransition'>;
    themeColor: keyof T_ThemeColors[T];
    themeType?: T;
}): string | never => {
    const _theme = theme.main;
    const foundColor = _theme[themeType ? themeType : 'colors'][themeColor];
    if (typeof foundColor === 'number') throw new Error('ID is not assignable');
    if (typeof foundColor === 'string') return foundColor;
    throw new Error('No color found');
};
