import { T_Module, T_Quiz } from '../../../../globalTypes';

export const getModuleFromQuiz = ({
    quiz,
    moduleId,
}: {
    quiz: T_Quiz;
    moduleId: number;
}) => {
    return quiz.modules.find((module: T_Module) => {
        return module.id === moduleId;
    });
};
