import { teamColors } from 'src/config/settings';

export const getTeamColor = (id: number) => {
    const colors = Object.keys(teamColors);
    if (id >= 0) {
        const colorValue = (id - 1) % 4;
        const colorName = colors[colorValue];
        return teamColors[colorName];
    }
    if (id > 10) return teamColors[0];
    return undefined;
};
