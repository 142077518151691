import { settings } from 'src/config/settings';
import { sectionIsQuestion } from 'src/functions';
import { useSection, useSelfTeam, useTimedConditionalEffect } from 'src/hooks';
import { TeamNoScreenPage } from 'src/pages/subpages';
import { T_Phase, T_PreQuizPhases } from '../../../../../../globalTypes';
import '../style.scss';
import { TeamResponseRenderer } from './TeamResponseRenderer';
import { TeamResponseRevealText } from './TeamResponseRevealText';
import { TeamResponseWrapper } from './TeamResponseWrapper';

export const TeamResponseSection = ({
    phase,
}: {
    phase?: Exclude<T_Phase, T_PreQuizPhases>;
}) => {
    const section = useSection();
    const selfTeam = useSelfTeam();
    const { revealAnswerTime } = settings;
    const revealTimer = useTimedConditionalEffect({
        condition: phase === 'answerReveal',
        duration: revealAnswerTime,
    });

    if (
        phase !== 'question' &&
        phase !== 'answerReveal' &&
        phase !== 'decisionQuestion'
    )
        return null;
    if (
        (sectionIsQuestion(section) &&
            section.type === 'Guess' &&
            phase === 'answerReveal') ||
        (phase.includes('decision') && selfTeam?.inDecision === false)
    )
        return <TeamNoScreenPage />;

    return (
        <TeamResponseWrapper>
            <TeamResponseRenderer
                inDecision={phase.includes('decision')}
                section={section}
                revealTimer={revealTimer}
                phase={phase}
            />
            <TeamResponseRevealText revealTimer={revealTimer} phase={phase} />
        </TeamResponseWrapper>
    );
};
