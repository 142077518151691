import { hostURL } from '../config/config';

export const postRequest = async <T>({
    endPoint,
    data,
    isExternal,
}: {
    endPoint: string;
    data: any;
    isExternal?: boolean;
}): Promise<T> => {
    const response = await fetch(`${!isExternal && hostURL}${endPoint}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return response.json() as Promise<T>;
    });
    return response;
};
