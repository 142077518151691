import { Socket as T_Socket } from 'socket.io-client';
import { settings } from 'src/config/settings';
import {
    castEvent,
    createSocketEvent,
    createStageEvent,
    gameHasWinner,
    getCurrentSectionInState,
    getDecisionTeams,
    getLowestDecisionTime,
    getNextStage,
    getTeamResult,
    parseBooleanToNumber,
    sectionIsQuestion,
} from '..';
import {
    T_QuestionType,
    T_QuizPhase,
    T_Team,
    T_TeamResponse,
} from '../../../../globalTypes';
import store, {
    T_AppDispatch,
    handleDecision,
    pauseTime,
    postResponses,
    postStage,
    setButtonCooldown,
    setLazyMessage,
    setWarningPopUp,
} from '../../store';
import { createStartEvent } from './createStartEvent';

//Logic that is used to move between pages, calculates what the next stage is going to be
export const castNextEvent = ({
    socket,
    dispatch,
    forced,
    forcedByTimer,
}: {
    socket: T_Socket;
    dispatch: T_AppDispatch;
    forced?: boolean;
    forcedByTimer?: boolean; //boolean used in handleTimer cause I was not sure whether a simple forced would be enough
}) => {
    const _buttonCooldown = settings.buttonCooldown;
    const { quizState, socketState, timerState, appState } = store.getState();
    const teams = store.getState().quizState.teams;
    const currentQuestion = getCurrentSectionInState(
        store.getState().quizState,
    );
    const { quiz, quizPhase: currentPhase, quizIsLocal, teamCount } = quizState;
    if (!quiz) return;
    const { roomId } = socketState;
    const nextStage = getNextStage(quiz);
    if (!nextStage) return;
    const { moduleId, sectionId, phase: nextPhase } = nextStage;
    const { buttonCooldown } = appState;
    if (buttonCooldown > 0 && !forced) return;
    dispatch(setButtonCooldown(_buttonCooldown));
    dispatch(setLazyMessage({ type: 'initialState' }));
    //Add the game to the database if we move to the quizTips page
    if (currentPhase === 'quizTips') {
        //Adds the game to the database
        castEvent(
            createStartEvent(dispatch, {
                session_id: roomId || null,
                id: undefined,
                quiz_id: quiz.id,
                quiz_is_local: parseBooleanToNumber(quizIsLocal),
                team_count: teamCount || 0,
                quiz_phase: currentPhase,
                module_id: null,
                section_id: null,
            }),
        );
        if (!quizIsLocal) {
            socket.emit('resetOriginalSocketIds');
        }
        //TODO: return generateThemeList when theme system is ever used again
        // dispatch(generateThemeList(quiz));
    }
    //If we are in the question phase, we ask the guest lecturer if he wants to continue when there is time remaining
    if (currentPhase === 'question' || currentPhase === 'decisionQuestion') {
        if (
            timerState.timer > 0 &&
            !appState.interactions.warningPopUp.isOpen
        ) {
            if (forcedByTimer) {
                const newStage = {
                    moduleId,
                    sectionId,
                    phase: nextPhase as T_QuizPhase,
                };
                castEvent(createStageEvent(socket, dispatch, newStage));
                dispatch(postStage(newStage));
                if (teams && sectionIsQuestion(currentQuestion)) {
                    const responseArray = teams
                        .map((team: T_Team): T_TeamResponse | undefined => {
                            const foundResponse = getTeamResult(
                                team,
                                currentQuestion,
                            );
                            if (!foundResponse) return undefined;
                            return {
                                teamId: team.id,
                                sectionId: currentQuestion.sectionId,
                                questionId: currentQuestion.questionId,
                                questionType: currentQuestion.type,
                                response: foundResponse.response,
                                points: foundResponse.points,
                                isCorrect: foundResponse.isCorrect,
                            };
                        })
                        .filter(
                            <T extends T_TeamResponse>(
                                teamResponse: T_TeamResponse | undefined,
                            ): teamResponse is T => {
                                return teamResponse !== undefined;
                            },
                        );

                    dispatch(postResponses(responseArray));
                }
                return;
            }
            dispatch(
                setWarningPopUp({
                    onAccept: 'next',
                    onReject: 'continueQuestion',
                    acceptText: 'Ja, ga verder',
                    rejectText: 'Nee, ga terug',
                    text: `Er is nog tijd over, weet je zeker dat je verder wil gaan?`,
                    invertedPosition: true,
                }),
            );
            dispatch(pauseTime());
            return;
        }
    }

    if (
        nextPhase === 'answerReveal' ||
        (currentPhase === 'questionResponse' &&
            currentQuestion?.type === 'Open') ||
        nextPhase === 'decisionQuestionReveal'
    ) {
        //Results are posted when we are in the answerReveal screen

        let responseArray: T_TeamResponse[] = [];
        if (nextPhase !== 'decisionQuestionReveal') {
            if (
                !teams ||
                !currentQuestion ||
                !sectionIsQuestion(currentQuestion)
            )
                return;
            responseArray = teams
                .map((team: T_Team): T_TeamResponse | undefined => {
                    const foundResponse = getTeamResult(team, currentQuestion);
                    if (!foundResponse) return;
                    return {
                        teamId: team.id,
                        sectionId: currentQuestion.sectionId,
                        questionId: currentQuestion.questionId,
                        questionType: currentQuestion.type,
                        response: foundResponse.response,
                        points: foundResponse.points,
                        isCorrect: foundResponse.isCorrect,
                    };
                })
                .filter(
                    <T extends T_TeamResponse>(
                        teamResponse: T_TeamResponse | undefined,
                    ): teamResponse is T => {
                        return teamResponse !== undefined;
                    },
                );
        }
        if (nextPhase === 'decisionQuestionReveal') {
            const decisionQuestion = store.getState().quizState.quiz
                ?.decisionQuestion;
            const decisionTeams = teams?.filter(
                (team: T_Team) => team.inDecision,
            );
            if (!decisionTeams) return;
            const winners = decisionTeams.filter(
                (team: T_Team) => team.wonDecision,
            );
            if (winners.length > 1 && quizIsLocal) {
                const drawStage = {
                    moduleId: null,
                    sectionId: null,
                    phase: 'decisionDraw' as T_QuizPhase,
                };
                castEvent(createStageEvent(socket, dispatch, drawStage));
                dispatch(postStage(drawStage));
                return;
            }
            if (winners.length > 1 && !quizIsLocal) {
                const winnerTeam = getLowestDecisionTime(decisionTeams);
                responseArray = decisionTeams?.map((team: T_Team) => {
                    const _team = {
                        teamId: team.id,
                        sectionId: 0,
                        questionId: decisionQuestion?.id || 0,
                        questionType: 'Decision' as T_QuestionType,
                        response: team?.decisionResponse || 0,
                        points: parseBooleanToNumber(team.wonDecision || false),
                    };
                    if (team.id === winnerTeam?.id) {
                        return {
                            ..._team,
                            isCorrect: 'WON_GUESS',
                        };
                    }
                    return {
                        ..._team,
                        isCorrect: 'LOST_GUESS',
                    };
                });
            }
            if (winners.length <= 1) {
                responseArray = decisionTeams?.map((team: T_Team) => {
                    return {
                        teamId: team.id,
                        sectionId: 0,
                        questionId: decisionQuestion?.id || 0,
                        questionType: 'Decision',
                        response: team?.decisionResponse || 0,
                        points: parseBooleanToNumber(team.wonDecision || false),
                        isCorrect: team.wonDecision
                            ? 'WON_GUESS'
                            : 'LOST_GUESS',
                    };
                });
            }
        }
        dispatch(postResponses(responseArray));
    }
    if (nextPhase === 'goNextSection' || !nextPhase) return;
    let newStage = { moduleId, sectionId, phase: nextPhase };
    if (nextPhase === 'quizWrapUp') {
        //Check if a decision question is necessary
        const teams = store.getState().quizState.teams;
        if (!teams) return;
        const gameHasEnd = gameHasWinner(teams);
        //If it is the first time the quizOutro is seen and a decision question is activated
        if (!gameHasEnd && currentPhase && !currentPhase.includes('decision')) {
            const decisionTeams = getDecisionTeams(teams);
            if (quizIsLocal && decisionTeams) {
                //local games are dispatched right away
                dispatch(handleDecision(decisionTeams));
            }
            if (!quizIsLocal && decisionTeams) {
                //for socket games we cast a socket event
                castEvent(
                    createSocketEvent(socket, 'startDecision', decisionTeams),
                );
            }
            newStage = {
                moduleId: null,
                sectionId: null,
                phase: 'decision' as T_QuizPhase,
            };
        }
        if (!gameHasEnd && currentPhase =='quizWrapUp' ) {
            newStage = {
                moduleId: null,
                sectionId: null,
                phase: 'quizOutro' as T_QuizPhase,
            };
        }
    }
    castEvent(createStageEvent(socket, dispatch, newStage));
    dispatch(postStage(newStage));
};
