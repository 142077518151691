import { useAppDispatch, useTimedCallback } from 'src/hooks';
import { removeModal } from 'src/store';

export const TeamModal = ({
    modal: { id, message },
}: {
    modal: { id: string; message: string };
}) => {
    const dispatch = useAppDispatch();
    useTimedCallback({
        duration: 10000,
        callback: () => dispatch(removeModal(id)),
    });
    return <p className="team-code-error">{message}</p>;
};
