import classNames from 'classnames';
import { settings } from 'src/config/settings';

export const MockMobileEdge = ({ side }: { side: 'top' | 'bottom' }) => {
    return (
        <div
            className={classNames('mockMobileEdge', side)}
            style={{
                width: settings.mockMobileSize.x,
            }}
        />
    );
};
