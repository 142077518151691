import classNames from 'classnames';
import { T_Answer, T_NormalQuestion } from '../../../../../../globalTypes';
import { settings } from '../../../../config/settings';
import { TeamConfirmResponseButton } from './TeamConfirmResponseButton';
import { TeamMPCOptionBox } from './TeamMPCOptionBox';
export const TeamMPCResponseSection = ({
    question,
    revealAnswer,
}: {
    revealAnswer: boolean;
    question: T_NormalQuestion;
}) => {
    const { MPCLetterArray } = settings;
    const totalIndices = question.answers?.length;
    const answerPossibilitiesFirstRow = question.answers
        ?.filter(
            (answer: T_Answer, index: number) =>
                (index <= 2 && totalIndices === 3) ||
                (totalIndices === 4 && index <= 1),
        )
        .map((answer: T_Answer, index: number) => {
            return (
                <TeamMPCOptionBox
                    revealAnswer={revealAnswer}
                    key={index}
                    letter={MPCLetterArray[index]}
                    id={answer.id}
                />
            );
        });

    const answerPossibilitiesSecondRow = question.answers
        ?.filter(
            (answer: T_Answer, index: number) =>
                totalIndices === 4 && index > 1,
        )
        .map((answer: T_Answer, index: number) => {
            return (
                <TeamMPCOptionBox
                    revealAnswer={revealAnswer}
                    key={index}
                    letter={MPCLetterArray[index + 2]}
                    id={answer.id}
                />
            );
        });
    const showSecondRow = answerPossibilitiesSecondRow?.length !== 0;
    return (
        <>
            <div
                className={classNames('socket-button-group', {
                    'gap-16': showSecondRow,
                })}
            >
                {answerPossibilitiesFirstRow}
            </div>
            {answerPossibilitiesSecondRow?.length !== 0 && (
                <div
                    className={classNames('socket-button-group mt-16', {
                        'gap-16': showSecondRow,
                    })}
                >
                    {answerPossibilitiesSecondRow}
                </div>
            )}
            <TeamConfirmResponseButton
                className={classNames({
                    'w-full mt-16': showSecondRow,
                })}
            />
        </>
    );
};
