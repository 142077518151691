import { T_AppDispatch, setPhase } from '..';
import { T_RawState } from '../../../../globalTypes';
import { handlePostRequest } from './handlePostRequest';

export const handleGameExit = () => async (
    dispatch: T_AppDispatch,
    getState: () => T_RawState,
) => {
    dispatch(setPhase('localToggle'));
    try {
        const id = getState().quizState?.gameId;
        const sessionId = getState().quizState?.sessionId;
        await dispatch(
            handlePostRequest({
                postData: { id, sessionId },
                endPoint: '/games/forceExit',
            }),
        );
    } catch (error) {
        console.log(error);
    }
};
