import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TeamModals } from 'src/components/team/teamDisplay/TeamModals';
import { appStorage } from 'src/functions';
import { useAppSelector, usePhase, useSelfTeam } from 'src/hooks';
import { useConnection } from 'src/hooks/socket/useConnection';
import { T_Phase, T_PreQuizPhases } from '../../../../../globalTypes';
import { WhatsappInteraction } from '../../subpages/quiz/whatsapp/WhatsappInteraction';
import { TeamPage } from './TeamPage';

//Page used to render the screens visible to participants of the quiz
export const RenderedTeamPage = () => {
    const phase = usePhase<
        Exclude<T_Phase, Exclude<T_PreQuizPhases, 'preStart'>>
    >();
    const selfTeam = useSelfTeam();
    const navigate = useNavigate();
    const connection = useConnection();
    const socketState = useAppSelector(state => state.socketState);
    const { socketId } = socketState;
    const { socketId: _socketId } = appStorage.retrieveSocketStorage();
    const [teamIsDisconnected, setTeamIsDisconneted] = useState<boolean>(false);

    useEffect(() => {
        if (phase === 'preStart') {
            navigate('/team');
        }
    }, [phase]);
    useEffect(() => {
        if (socketId && socketId !== _socketId) {
            setTeamIsDisconneted(true);
            return;
        }
        setTeamIsDisconneted(false);
    }, [socketId, _socketId]);

    const showReconnecter =
        selfTeam?.teamName?.id !== 0 &&
        phase !== 'preStart' &&
        (!connection || teamIsDisconnected);
    const showTeamNameSelection =
        !selfTeam?.teamName?.id && selfTeam?.sentTeamNames;
    switch (phase) {
        case 'questionWhatsappInteraction':
        case 'questionWhatsapp':
            return <WhatsappInteraction isTeamPage={true} />;
        case 'preStart':
            return null;
        default:
            return (
                <>
                    <TeamModals />
                    <TeamPage
                        showTeamNameSelection={showTeamNameSelection}
                        showReconnecter={showReconnecter}
                        phase={phase}
                        team={selfTeam}
                    />
                </>
            );
    }
};
