import { useSchoolLevel } from '../menu/useSchoolLevel';
import { useInDateRange } from './useInDateRange';

export const useIsPreview = (): boolean => {
    const level = useSchoolLevel();
    const isPreview =
        useInDateRange({
            startDate: '2024-03-01',
            endDate: '2024-03-07',
        });
    return isPreview;
};
