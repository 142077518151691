import { QuestionCounter } from 'src/components/header';
import { TeamResponseSection } from 'src/components/team/teamResponsePage/responseSection/TeamResponseSection';
import { TeamWrapper } from 'src/layout/styledcomponents/wrappers/TeamWrapper';
import { TeamNameSelection } from 'src/pages/subpages/team/TeamNameSelection';
import {
    T_Phase,
    T_PreQuizPhases,
    T_Team,
    T_TeamName,
} from '../../../../../globalTypes';
import { RenderedHeroContent } from './RenderedHeroContent';
import { TeamHeroWrapper } from './TeamHeroWrapper';
import { TeamReconnecter } from './TeamReconnecter';

export const TeamPage = ({
    phase,
    showTeamNameSelection,
    showReconnecter,
    team,
}: {
    phase: Exclude<T_Phase, T_PreQuizPhases>;
    showTeamNameSelection: false | T_TeamName[] | undefined;
    showReconnecter: boolean;
    team?: T_Team;
}) => {
    return (
        <TeamWrapper>
            <TeamHeroWrapper key={phase}>
                <QuestionCounter text={'Vraag'} />
                <RenderedHeroContent />
                {showTeamNameSelection && (
                    <TeamNameSelection
                        names={team?.sentTeamNames}
                        color={team?.teamColor}
                    />
                )}
                <TeamResponseSection phase={phase} />
                {showReconnecter && (
                    <TeamReconnecter
                        text={
                            'Vernieuw deze pagina als er geen verbinding meer is' +
                            (phase === 'question'
                                ? ' of als het antwoord niet verstuurd wordt'
                                : '')
                        }
                    />
                )}
            </TeamHeroWrapper>
        </TeamWrapper>
    );
};
