import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { mediaUrl } from 'src/config/mediaConfig';
import { settings } from 'src/config/settings';
import { useAppDispatch, useTimedEffect } from 'src/hooks';
import { setInteraction } from 'src/store';
import { useIdle } from './../../hooks/controls/useIdle';
import { VideoOverlay } from './VideoOverlay';
import './style.scss';


export const VideoPlayer = ({
    url,
    externalUrl,
    autoplay,
    posterImage
}: {
    url: string;
    autoplay?: boolean;
    externalUrl?: string;
    posterImage?: string;
}) => {
    const dispatch = useAppDispatch();
    const videoRef = useRef<HTMLVideoElement>(null);
    const [loaded, isLoaded] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const [showReplayButton, setShowReplayButton] = useState<boolean>(false);
    const isIdle = useIdle(3000);

    const playVideo = () => {
        if (isPlaying) return;
        videoRef.current?.play();
        setIsPlaying(true);
        setShowReplayButton(false);
    };
    const pauseVideo = () => {
        if (!isPlaying) return;
        videoRef.current?.pause();
        setIsPlaying(false);
        setShowReplayButton(false);
    };
    const onReplayClick = () => {
        if (!videoRef.current) return;
        videoRef.current.currentTime = 0;
        playVideo();
    };

    useEffect(() => {
        const onFullscreenChange = () => {
            setIsFullscreen(Boolean(document.fullscreenElement));
        };
        document.addEventListener('fullscreenchange', onFullscreenChange);
        //playVideo();
        return () => {
            pauseVideo();
            document.removeEventListener(
                'fullscreenchange',
                onFullscreenChange,
            );
        };
    }, []);

    useEffect(() =>{
        if(autoplay){
            setIsPlaying(true)
        }
    },[])
    useEffect(() => {
        dispatch(
            setInteraction({
                interactionKey: 'videoPlaying',
                change: isPlaying ? 'open' : 'close',
            }),
        );
    }, [isPlaying]);
    useEffect(() => {
        if (typeof window === 'undefined') return;
        if (isFullscreen) {
            videoRef.current?.requestFullscreen({ navigationUI: 'hide' });
            return;
        }
    }, [isFullscreen]);
    const hidePlayButton = useTimedEffect(settings.hidePlayButtonTime, [
        showReplayButton,
    ]);
    const showPlayButton = !showReplayButton;
    const showButton = !hidePlayButton || showReplayButton || !isPlaying;
    return (
        <>
            <div
                className={classNames('video-player', {
                    'mouse-idle': isIdle,
                    invisible: !loaded,
                })}
            >
                <video
                    autoPlay={
                            autoplay
                            ? autoplay
                            : false

                    }
                    onLoadedData={() => isLoaded(true)}
                    onEnded={() => {
                        setIsPlaying(false);
                        setShowReplayButton(true);
                    }}
                    disableRemotePlayback={true}
                    poster={
                            posterImage
                                ? posterImage
                                : `/assets/images/maxresdefault.jpg`
                    }
                    ref={videoRef}
                >
                    <source
                        src={
                            externalUrl
                                ? externalUrl
                                : `${mediaUrl}videos/${url}`
                        }
                        type="video/mp4"
                    />
                </video>
            </div>
            <VideoOverlay
                className="video-overlay"
                showButton={true}
                showPlayButton={showPlayButton}
                showReplayButton={showReplayButton}
                isPlaying={isPlaying}
                pauseVideo={pauseVideo}
                playVideo={playVideo}
                replayVideo={onReplayClick}
            />
        </>
    );
};
