import { sectionIsQuestion } from 'src/functions';
import { selectClosedResponseFromTeam } from 'src/store';
import { T_NormalQuestion } from '../../../../../../../globalTypes';
import { useAppSelector, usePhase, useSection } from '../../../../../hooks';
import { ClosedOption } from './ClosedOption';
import './style.scss';
//used for closed AND thesis questions
export const ClosedDisplay = () => {
    const currentQuestion = useSection<T_NormalQuestion<'Closed'>>();
    const currentPhase = usePhase();
    const rawTeamResponse = useAppSelector(selectClosedResponseFromTeam());

    if (
        !sectionIsQuestion(currentQuestion) ||
        !currentQuestion.typeData?.closedLeft ||
        !currentQuestion.typeData?.closedRight
    ) {
        return null;
    }

    if (currentPhase === 'questionResponse') {
        const leftSelected =
            typeof rawTeamResponse === 'number' && rawTeamResponse === 1;
        const rightSelected =
            typeof rawTeamResponse === 'number' && rawTeamResponse === 2;
        return (
            <>
                <ClosedOption
                    text={currentQuestion.typeData?.closedLeft}
                    selected={leftSelected}
                />
                <ClosedOption
                    text={currentQuestion.typeData?.closedRight}
                    right
                    selected={rightSelected}
                />
            </>
        );
    }
    if (currentPhase === 'answerReveal') {
        if (currentQuestion.typeData?.leftCorrect) {
            return (
                <>
                    <ClosedOption
                        text={currentQuestion.typeData?.closedLeft}
                        correct
                    />
                    <ClosedOption
                        right
                        text={currentQuestion.typeData?.closedRight}
                        correct={false}
                    />
                </>
            );
        }
        return (
            <>
                <ClosedOption
                    text={currentQuestion.typeData?.closedLeft}
                    correct={false}
                />
                <ClosedOption
                    right
                    text={currentQuestion.typeData?.closedRight}
                    correct
                />
            </>
        );
    }
    return (
        <>
            <ClosedOption text={currentQuestion.typeData?.closedLeft} />
            <ClosedOption
                text={currentQuestion.typeData?.closedRight}
                right
            />{' '}
        </>
    );
};
