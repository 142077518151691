import {
    T_QuizImagePhase,
    T_QuizPhase,
    T_Section,
} from '../../../../globalTypes';

import store from '../../store';
import {
    sectionIsMockMobileQuestion,
    sectionIsQuestion,
    sectionIsWhatsappQuestion,
} from '../checkers';
import { getFinalSectionId } from './getFinalSectionId';

export const getNextPhase = (
    _currentPhase: T_QuizPhase | 'endOfSection' | T_QuizImagePhase,
    section: T_Section | undefined,
): T_QuizPhase | 'goNextSection' | T_QuizImagePhase => {
    const { quizIsLocal } = store.getState().quizState;
    let currentPhase = _currentPhase;
    //image logic
    if (
        sectionIsQuestion(section) &&
        section.questionImages &&
        currentPhase !== 'endOfSection' &&
        !currentPhase.includes('images')
    ) {
        const allQuestionImagePhases = section.questionImages?.map(
            questionImage => {
                return questionImage.phase;
            },
        );
        if (allQuestionImagePhases?.includes(currentPhase as T_QuizPhase)) {
            return `${currentPhase}-images` as T_QuizImagePhase;
        }
    }
    if (currentPhase.includes('images')) {
        currentPhase = currentPhase.split('-')[0] as T_QuizPhase;
    }

    //decision logic
    if (!section) {
        if (currentPhase.includes('decision')) {
            switch (currentPhase) {
                case 'decision':
                    return 'decisionQuestion';
                case 'decisionQuestion':
                    if (quizIsLocal) {
                        return 'decisionResponse';
                    }
                    return 'decisionQuestionReveal';
                case 'decisionResponse':
                    return 'decisionQuestionReveal';
                case 'decisionQuestionReveal':
                    return 'quizWrapUp';
                default:
                    return 'quizOutro';
            }
        }
        if (currentPhase === 'quizWrapUp') {
            return 'quizOutro';
        }
        if (currentPhase === 'quizOutro') {
            return 'quizResults';
        }
        if (currentPhase === 'quizResults') {
            return 'quizThanks';
        }
        
        return 'quizWrapUp';
    }
    //slide logic
    if (section.sectionType === 'Slide') {
        return 'slide';
    }

    //question logic
    switch (currentPhase) {
        case 'quizTips':
            return 'quizIntroVideo';
        case 'quizIntroVideo':
            return 'quizIntro';
        case 'quizIntro':
            return 'quizFirstReward';
        case 'quizFirstReward':
            return 'quizFirstQuestionIntro';
        case 'quizFirstQuestionIntro':
        case 'endOfSection':
            if (section.videoURL) {
                return 'questionVideo';
            }
            return 'questionIntro';
        case 'questionVideo':
            if (!section.introText) {
                return 'question';
            }
            return 'questionIntro';
        case 'questionIntro':
            if (sectionIsWhatsappQuestion(section) && !quizIsLocal) {
                return 'questionWhatsappInteraction';
            }
            return 'question';
        case 'questionWhatsappInteraction':
            return 'questionWhatsapp';
        case 'questionWhatsapp':
            return 'question';
        case 'question':
            if (quizIsLocal || (!quizIsLocal && section.type === 'Open')) {
                return 'questionResponse';
            }
            return 'answerReveal';
        case 'questionResponse':
            if (section.type === 'Open') {
                if (section.outroText) {
                    return 'questionOutro';
                }
                return 'scoreReveal';
            }
            return 'answerReveal';
        case 'questionOutro':
            if (section.type === 'Thesis') {
                return 'sectionTransition';
            }
            if (sectionIsMockMobileQuestion(section)) {
                return 'questionOutroMobile';
            }
            return 'scoreReveal';
        case 'answerReveal':
            if (section.outroText) {
                return 'questionOutro';
            }
            if (section.type === 'Thesis') {
                return 'sectionTransition';
            }
            return 'scoreReveal';
        case 'questionOutroMobile':
            return 'scoreReveal';
        case 'scoreReveal':
            const { quiz } = store.getState().quizState;
            if (quiz) {
                const finalSectionId = getFinalSectionId(quiz);
                if (finalSectionId === section.id) {
                    return 'goNextSection';
                }
            }
            return 'sectionTransition';
        case 'sectionTransition':
            return 'goNextSection';
    
        default:
            return 'question';
    }
};