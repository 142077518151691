import { T_Team } from '../../../../../globalTypes';

export const calculateFinalScore = (teams: T_Team[]) => {
    return teams.map((team: T_Team) => {
        const { responses } = team;
        if (!responses || responses.length === 0)
            return { id: team.id, score: 0 };
        const score = responses
            .map(response => response.points)
            .reduce((previousValue, currentValue) => {
                if (!previousValue)
                    return 0 + (currentValue ? currentValue : 0);
                if (!currentValue) return previousValue + 0;
                return previousValue + currentValue;
            }, 0);
        return {
            id: team.id,
            score: score ? Number(score) : 0,
            wonDecision: team.wonDecision,
        };
    });
};
