import styled from 'styled-components';

//warning: used on teamPage AND hostPage
export const GuessResponseWrapper = ({
    children,
    asTeam,
}: {
    children: React.ReactNode;
    asTeam?: boolean; //whether to display this component as a team component or a host component
}) => {
    return (
        <StyledGuessResponseWrapper
            asTeam={asTeam}
            className="guess-responses-wrapper"
        >
            {children}
        </StyledGuessResponseWrapper>
    );
};

const StyledGuessResponseWrapper = styled.div<{
    asTeam?: boolean;
}>`
    scale: ${props => {
        if (props.asTeam) {
            return '0.8';
        }
        return '1';
    }};
`;
