import React from 'react';
import styled from 'styled-components';
import { T_Phase } from '../../../../globalTypes';
import { usePhase, useQuizIsLocal } from '../../hooks';

export const HeaderInnerWrapper = (props: { children?: React.ReactNode }) => {
    const phase = usePhase();
    const quizIsLocal = useQuizIsLocal();
    return (
        <Wrapper quizIsLocal={quizIsLocal} phase={phase}>
            {props.children}
        </Wrapper>
    );
};

const Wrapper = styled.div<{
    phase: T_Phase | undefined;
    quizIsLocal?: boolean;
}>`
    flex: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
