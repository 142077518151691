import { DependencyList, useEffect } from 'react';

//Hook that handles what to do when the ENTER button is pressed on the keyboard
export const useEnter = (
    callback: () => void,
    dependencies?: DependencyList,
) => {
    useEffect(() => {
        const handleKeyPress = (event: { key: string }) => {
            if (event.key === 'Enter') {
                callback();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [callback, ...(dependencies ? dependencies : [])]);
};
