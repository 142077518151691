import {
    T_EntryStart,
    T_EntryMPC,
    T_EntryOpen,
    T_EntryPoll,
    T_EntryThesis,
    T_NormalQuestion,
    T_TeamResponse,
} from '../../../../globalTypes';
import { settings } from '../../config/settings';
import { useDecision } from '../decision/useDecision';
import { useSection } from '../quiz/useSection';
import { useAppSelector } from '../redux/useAppSelector';

type T_ReturnType<T> = T extends true
    ? T_TeamResponse | undefined | null
    :
          | string
          | null
          | number
          | boolean
          | T_EntryStart
          | T_EntryMPC
          | T_EntryThesis
          | T_EntryPoll
          | T_EntryOpen
          | undefined;
export const useTeamResponse = <T extends boolean = false>({
    id,
    entireResponse,
}: {
    id: number;
    entireResponse?: T;
}): T_ReturnType<T> => {
    const inDecision = useDecision();
    const currentSection = useSection();
    const foundTeam = useAppSelector(state =>
        state.quizState.teams?.find(team => team.id === id),
    );
    if (inDecision) {
        if (!foundTeam?.inDecision) return null;
        if (foundTeam.decisionResponse === 0) return null;
        return foundTeam?.decisionResponse as T_ReturnType<T>;
    }
    if (!foundTeam || !currentSection) return null;
    const foundResponse = foundTeam?.responses.find(
        (response: T_TeamResponse) => {
            return response.sectionId === currentSection.id ? response : null;
        },
    );
    if (!entireResponse) {
        switch (currentSection.type) {
            case 'MPC':
                const { MPCLetterArray } = settings;
                return MPCLetterArray[
                    (foundResponse?.response as number) - 1
                ] as T_ReturnType<T>;
            case 'Closed':
            case 'Thesis':
                if (foundResponse?.response === 1)
                    return (currentSection as T_NormalQuestion<
                        'Closed' | 'Thesis'
                    >).typeData?.closedLeft as T_ReturnType<T>;
                if (foundResponse?.response === 2)
                    return (currentSection as T_NormalQuestion<
                        'Closed' | 'Thesis'
                    >).typeData?.closedRight as T_ReturnType<T>;
                return null;
            case 'Open':
                if (
                    (currentSection as T_NormalQuestion<'Open'>).typeData
                        ?.desiredAnswers === 1
                ) {
                    if (foundResponse?.response === 0) {
                        return 'Fout' as T_ReturnType<T>;
                    }
                    if (foundResponse?.response === 1) {
                        return 'Goed' as T_ReturnType<T>;
                    }
                }
                return foundResponse?.response as T_ReturnType<T>;
            case 'Guess':
            case 'Start':
                return foundResponse?.response as T_ReturnType<T>;
            default:
                return null;
        }
    }
    return foundResponse as T_ReturnType<T>;
};
