import { usePhase, useQuizIsLocal } from 'src/hooks';
import { TeamsSelection, Timer } from '..';
import { FooterBackButton } from '../base/buttons/FooterBackButton';
import { FooterNextButton } from '../base/buttons/FooterNextButton';
import { FooterWrapper } from './FooterWrapper';
import './style.scss';

export const Footer = () => {
    const phase = usePhase();
    const quizIsLocal = useQuizIsLocal();
    const showTeamsSelection = [
        'questionResponse',
        'decisionResponse',
    ].includes(phase);
    return (
        <FooterWrapper quizIsLocal={quizIsLocal}>
            <FooterBackButton phase={phase} />
            <Timer />
            {showTeamsSelection && <TeamsSelection />}
            <FooterNextButton phase={phase} />
        </FooterWrapper>
    );
};
