import { MockMobileEdge } from './MockMobileEdge';
import { MockMobileWrapper } from './MockMobileWrapper';

export const MockMobile = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <MockMobileEdge side="top" />
            <MockMobileWrapper>{children}</MockMobileWrapper>
            <MockMobileEdge side="bottom" />
        </>
    );
};
