import { T_Quiz } from '../../../../../globalTypes';

export const getVideoCount = (quiz: T_Quiz) => {
    let videoCount = 0;
    quiz.modules.forEach(module => {
        module.sections.forEach(section => {
            if (section.videoURL) {
                videoCount++;
            }
        });
    });
    return videoCount;
};
