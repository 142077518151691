import { StartPage } from 'src/components/startPage/StartPage';
import { CentralWrapper } from 'src/layout/styledcomponents';

//Page where the user arrives ('/')
export const HomePage = () => {
    return (
        <CentralWrapper>
            <StartPage />
        </CentralWrapper>
    );
};
