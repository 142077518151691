import { T_Question, T_Team, T_TeamResponse } from '../../../../../globalTypes';

import { getTeamResultInPoints } from '../..';

//used to calculate the difference in points, based on question
//compares the latest points to the previous obtained points in total
export const calculateDeltaScore = ({
    teams,
    question,
}: {
    teams: T_Team[];
    question: T_Question;
}) => {
    return teams.map((team: T_Team) => {
        const { responses } = team;
        const recentPoints = getTeamResultInPoints({ team, question });
        if (!responses || responses.length === 0)
            return { id: Number(team.id), oldScore: 0, recentPoints };
        const oldScore = responses
            .map((response: T_TeamResponse, index: number) => {
                if (index === responses.length - 1) return 10; //bgein the quiz with 10 points!
                return response.points;
            })
            .reduce(
                (
                    previousValue: number | undefined,
                    currentValue: number | undefined,
                ) => {
                    if (!previousValue)
                        return 0 + (currentValue ? currentValue : 0);
                    if (!currentValue) return previousValue + 0;
                    return previousValue + currentValue;
                },
                0,
            );

        return { id: Number(team.id), oldScore, recentPoints };
    });
};
