import { T_AppState } from '../../../../globalTypes';
import { useAppSelector } from '../redux/useAppSelector';

//returns true when any of the interactions are open, but ignores headerDrawer
//when passed a value, it will only check for that interation
export const useInteractionOpen = (
    interaction?: keyof T_AppState['interactions'],
) => {
    const interactions = useAppSelector(state => state.appState.interactions);
    if (interaction) {
        return interactions[interaction].isOpen;
    }
    const filteredInteractions = {
        ...interactions,
        headerDrawer: { isOpen: false },
        nextButton: { isOpen: false },
    };
    const interactionArray = Object.values(filteredInteractions);
    return interactionArray.some(interaction => interaction.isOpen);
};
