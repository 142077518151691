import {
    T_AppDispatch,
    resetAppState,
    resetQuizState,
    resetThemeState,
} from '..';
import { T_RawState } from '../../../../globalTypes';

export const resetAllState = () => async (
    dispatch: T_AppDispatch,
    getState: () => T_RawState,
) => {
    try {
        dispatch(resetAppState());
        dispatch(resetQuizState());
        dispatch(resetThemeState());
    } catch (error) {
        console.log(error);
    }
};
