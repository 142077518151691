import { useEffect } from 'react';
import { resetTime } from 'src/store';
import { useAppDispatch } from '../redux/useAppDispatch';

export const useTimerReset = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(resetTime());
    }, []);
};
