import { sectionIsQuestion } from 'src/functions';
import { T_QuizPhase } from '../../../../../globalTypes';
import { useSection } from '../../../hooks';
import { ClosedDisplay } from './AnswerDisplay/Closed/ClosedDisplay';
import { ClosedOptionsWrapper } from './AnswerDisplay/Closed/ClosedOptionsWrapper';
import { GuessDisplay } from './AnswerDisplay/Guess/GuessDisplay';
import { MPCDisplay } from './AnswerDisplay/MPC/MPCDisplay';
import { OpenDisplay } from './AnswerDisplay/Open/OpenDisplay';
import { ThesisDisplay } from './AnswerDisplay/Thesis/ThesisDisplay';

export const AnswerDisplay = ({ phase }: { phase: T_QuizPhase }) => {
    const currentQuestion = useSection();
    if (['decisionQuestionReveal', 'decisionDraw'].includes(phase)) {
        return <GuessDisplay />;
        
    }
    if (!currentQuestion || !sectionIsQuestion(currentQuestion)) return null;
    switch (currentQuestion.type) {
        case 'MPC':
            return <MPCDisplay phase={phase} />;
        case 'Closed':
            return (
                <ClosedOptionsWrapper>
                    <ClosedDisplay />
                </ClosedOptionsWrapper>
            );
        case 'Thesis':
            return <ThesisDisplay />;
        case 'Guess':
            return <GuessDisplay />;
        case 'Open':
            return (
                <>
                    <OpenDisplay />
                </>
            );
        default:
            return null;
    }
};
