import { StartPage } from 'src/components/startPage/StartPage';
import {
    CodeInputPage,
    LocalTogglePage,
    NoCodeInputPage,
    QuizSelectionPage,
} from 'src/pages/subpages';
import { LectureSelectionPage } from 'src/pages/subpages/selection/LectureSelectionPage';
import { T_Phase } from '../../../../../globalTypes';

export const RenderedSelectionPage = ({
    quizPhase,
}: {
    quizPhase: T_Phase | undefined;
}) => {
    switch (quizPhase) {
        case 'preStart':
            return <StartPage />;
        case 'codeStart':
            return <CodeInputPage />;
        case 'noCodeStart':
            return <NoCodeInputPage />;
        case 'lectureSelection':
            return <LectureSelectionPage />;
        case 'quizSelection':
            return <QuizSelectionPage />;
        case 'localToggle':
            return <LocalTogglePage />;
        default:
            return null;
    }
};
