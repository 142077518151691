import { ProgressBar } from './ProgressBar';
import { WhiteBar } from './WhiteBar';

export const TimerBar = () => {
    return (
        <div>
            Resterende tijd
            <div className="progress-wrapper">
                <WhiteBar />
                <ProgressBar />
            </div>
        </div>
    );
};
