import { setShowMockPage } from 'src/store';
import { useAppDispatch, useTimedEffect } from '../../hooks';

export const WhatsappMessage = ({
    sender,
    src,
    link,
    interval,
    message,
    emoji,
    time,
    isTeamPage,
}: {
    sender: string | 'self';
    sameSenderAsPrevious?: boolean;
    src?: string;
    link?: { title: string; description: string; meta: string; url: string };
    interval: number;
    message: string;
    emoji?: string;
    time: number;
    isTeamPage?: boolean;
}) => {
    const showMessage = useTimedEffect(10);
    const date = new Date(time + interval).toTimeString().split(':');
    const dispatch = useAppDispatch();
    return (
        <div>
            <div
                onClick={() =>
                    isTeamPage && link?.url && dispatch(setShowMockPage(true))
                }
                className={`msg ${showMessage && 'show'} ${link &&
                    'msg-has-att'}`}
            >
                {src && link && (
                    <div className="msg-attachment">
                        <div className="attachment-image">
                            <img src={src} width="920" height="498" />
                        </div>
                        <div className="attachment-meta">
                            <div className="meta-title">{link.title}</div>
                            <div className="meta-desc">
                                <span>{link.description}</span>
                            </div>
                            <div className="meta-link">{link.meta}</div>
                        </div>
                    </div>
                )}
                {src && link && (
                    <span className="msg-inline-link">{link.url}</span>
                )}
                {message && <>{message} </>}
                {emoji && <span className={`emoji ${emoji}`} />}
                {interval && (
                    <span className="msg-time">
                        {date[0]}:{date[1]}
                        {sender === 'self' && (
                            <span className="msg-status status-received status-read"></span>
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};
