import { useEffect } from 'react';
import { settings } from 'src/config/settings';
import { tips } from 'src/config/ui/tips';
import { selectInteractions, setPopUp } from 'src/store';
import { usePhase } from '../quiz/usePhase';
import { useAppDispatch } from '../redux/useAppDispatch';
import { useAppSelector } from '../redux/useAppSelector';

export const usePopUp = () => {
    const dispatch = useAppDispatch();
    const popUp = useAppSelector(selectInteractions('popUp'));
    const phase = usePhase();
    useEffect(() => {
        if (!phase || !settings.tipsPhases.includes(phase)) return;
        switch (phase) {
            case 'codeStart':
                dispatch(setPopUp(tips.code));
                break;
            case 'quizIntro':
            case 'lectureSelection':
            default:
                dispatch(setPopUp(tips.main));
        }
    }, [phase]);

    return {
        isOpen: popUp?.isOpen || false,
        text: popUp?.text || null,
    };
};
