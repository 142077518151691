import { HowlOptions } from 'howler';
import { useEffect } from 'react';
import { T_SoundKey, muteAllSounds } from 'src/howler';
import { playSound } from 'src/howler/playSound';
import { T_Phase } from '../../../../globalTypes';
import { usePhase } from '../quiz';

export const useSoundEffect = ({
    soundKey,
    deps,
    options,
    onPhase,
    condition,
}: {
    soundKey: T_SoundKey;
    deps?: React.DependencyList;
    options?: Omit<HowlOptions, 'src' | 'volume' | 'html5'>;
    onPhase?: T_Phase[];
    condition?: boolean;
}) => {
    const currentPhase = usePhase();
    useEffect(() => {
        if (condition !== undefined && condition !== null && !condition) return;
        if (onPhase && currentPhase && onPhase.includes(currentPhase)) {
            playSound({ src: soundKey, options });
            return;
        }
        return () => {
            muteAllSounds();
        };
    }, [deps, currentPhase]);
};
