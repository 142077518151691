import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { tips } from 'src/config/ui/tips';
import {
    T_AppState,
    T_Dimensions,
    T_LazyMessage,
    T_LazyMessageTypes,
    T_PopScreenId,
    T_PublicConfig,
    T_SchoolData,
    T_SchoolLevel,
    T_Stage,
} from '../../../../globalTypes';
import { settings } from '../../config/settings';
import { appStorage } from '../../functions';

const initialState: T_AppState = {
    appIsLoading: false,
    token: null,
    guestLecturerCode: '',
    schoolList: [],
    activeLectureId: null,
    activeLectureLevel: 'PO',
    message: '',
    messageHistory: [],
    lazyMessage: {
        type: 'initialState',
    },
    showFooterButton: true,
    lectureIsTrial: false,
    lectureIsWithoutCode: false,
    stageHistory: [],
    inHistory: false,
    errorCode: null,
    currentHistoryId: null,
    config: {
        bulkResponses: false,
        teamNamesEnabled: false,
        maxTeams: settings.appConfig.maxTeams,
        displayedTeamNames: settings.appConfig.displayedTeamNames,
        scoreRevealIndices: (settings.appConfig
            .scoreRevealIndices as unknown) as number[],
    },
    buttonCooldown: 0,
    interactionCooldown: 0,
    interactions: {
        nextButton: {
            isOpen: true,
        },
        reconnect: {
            isOpen: false,
        },
        headerDrawer: {
            isOpen: false,
        },
        largeQR: {
            isOpen: false,
        },
        videoPlaying: {
            isOpen: false,
        },
        wonNonLocalGuess: {
            isOpen: true,
        },
        warningPopUp: {
            isOpen: false,
            text: '',
            invertedColors: false,
            options: {
                acceptText: '',
                rejectText: '',
                onAccept: 'next',
                onReject: 'end',
            },
        },
        popUp: {
            isOpen: false,
            text: tips.main,
        },
        popScreen: {
            isOpen: false,
            id: null,
        },
    },
    interactionDimensions: {
        quizCard: {
            width: 0,
        },
    },
    showWhatsappQuestion: false,
    showMockPage: false,
    isAnimated: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        startAppLoading: state => {
            state.appIsLoading = true;
        },
        stopAppLoading: state => {
            state.appIsLoading = false;
        },
        setMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            if (state.messageHistory.length < 25) {
                state.messageHistory = [
                    action.payload,
                    ...state.messageHistory,
                ];
            }
        },
        setLazyMessage: (
            state,
            action: PayloadAction<T_LazyMessage<T_LazyMessageTypes>>,
        ) => {
            state.lazyMessage = action.payload;
        },
        setErrorCode: (state, action: PayloadAction<string>) => {
            state.errorCode = action.payload;
        },
        resetErrorCode: state => {
            state.errorCode = null;
        },
        setGuestLecturerCode: (state, action: PayloadAction<string>) => {
            state.guestLecturerCode = action.payload;
        },
        setConfig: (state, action: PayloadAction<T_PublicConfig>) => {
            state.config = action.payload;
        },
        addStage: (state, action: PayloadAction<T_Stage>) => {
            const stage = action.payload;
            if (stage.phase === 'question') return;
            state.stageHistory = [
                ...state.stageHistory,
                { ...action.payload, id: state.stageHistory.length },
            ];
        },
        resetAppState: state => {
            const token = appStorage.retrieveToken();
            return { ...initialState, token: token ? token : null };
        },
        clearToken: state => {
            state.token = null;
            appStorage.clearToken();
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            appStorage.saveToken(action.payload);
        },
        setButtonCooldown: (state, action: PayloadAction<number>) => {
            state.buttonCooldown = action.payload;
        },
        decreaseButtonCooldown: state => {
            state.buttonCooldown -= 0.5;
        },
        setInteractionCooldown: (
            state,
            action: PayloadAction<number | undefined>,
        ) => {
            state.interactionCooldown =
                action.payload || settings.maxAnimationTime;
        },
        decreaseInteractionCooldown: state => {
            if (state.interactionCooldown > 0) {
                state.interactionCooldown -= 0.1;
            }
        },
        setInteraction: (
            state,
            action: PayloadAction<{
                interactionKey: keyof T_AppState['interactions'];
                change: 'open' | 'close';
                popScreenId?: T_PopScreenId;
            }>,
        ) => {
            const { interactionKey, change, popScreenId } = action.payload;
            if (change === 'open' || change === 'close') {
                if (
                    interactionKey !== 'reconnect' &&
                    state.interactions.reconnect.isOpen
                )
                    return;
                state.interactions[interactionKey].isOpen = change === 'open';
            }
            if (
                interactionKey === 'popScreen' &&
                change === 'open' &&
                popScreenId
            ) {
                state.interactions[interactionKey].id = popScreenId;
            }
            state.interactionCooldown = settings.maxAnimationTime;
        },
        setInteractionDimensions: (
            state,
            action: PayloadAction<{
                interactionKey: keyof T_AppState['interactionDimensions'];
                dimensions: T_Dimensions;
            }>,
        ) => {
            const { interactionKey, dimensions } = action.payload;
            state.interactionDimensions[interactionKey] = dimensions;
        },
        setWarningPopUp: (
            state,
            action: PayloadAction<{
                onAccept: T_AppState['interactions']['warningPopUp']['options']['onAccept'];
                onReject: T_AppState['interactions']['warningPopUp']['options']['onReject'];
                text: string;
                acceptText: string;
                rejectText: string;
                invertedColors?: boolean;
                invertedPosition?: boolean;
            }>,
        ) => {
            const {
                text,
                acceptText,
                rejectText,
                onAccept,
                onReject,
                invertedColors,
                invertedPosition,
            } = action.payload;
            const options = { acceptText, rejectText, onAccept, onReject };
            const warningPopUp = { text, options, isOpen: true };
            state.interactions.warningPopUp = warningPopUp;
            state.interactionCooldown = settings.maxAnimationTime;
            if (invertedColors) {
                state.interactions.warningPopUp.invertedColors = true;
            } else {
                state.interactions.warningPopUp.invertedColors = false;
            }
            if (invertedPosition) {
                state.interactions.warningPopUp.invertedPosition = true;
            } else {
                state.interactions.warningPopUp.invertedPosition = false;
            }
        },
        resetInteractions: state => {
            if (
                state.interactionCooldown > 0 ||
                state.interactions.reconnect.isOpen
            )
                return;
            state.interactionCooldown = 0;
            let newInteractions: T_AppState['interactions'] =
                state.interactions;
            for (const [key, value] of Object.entries(state.interactions)) {
                if (key === 'nextButton') {
                    newInteractions = {
                        ...newInteractions,
                        [key]: { ...value, isOpen: true },
                    };
                    continue;
                }
                newInteractions = {
                    ...newInteractions,
                    [key]: { ...value, isOpen: false },
                };
            }
            state.interactions = newInteractions;
        },
        handleArrivalToken: (
            state,
            action: PayloadAction<{ tokenIsValid: boolean }>,
        ) => {
            if (!action.payload.tokenIsValid) {
                state.token = null;
                state.guestLecturerCode = null;
                appStorage.clearToken();
            }
        },
        setSchoolList: (
            state,
            action: PayloadAction<T_SchoolData<'All'>[]>,
        ) => {
            state.schoolList = action.payload;
        },
        setActiveLectureId: (state, action: PayloadAction<number | null>) => {
            const foundLevel = state.schoolList.find(value => {
                return value.lectures?.some(lecture => {
                    return lecture.id === action.payload;
                });
            })?.level;
            state.activeLectureId = action.payload;
            if (!foundLevel) return;
            state.activeLectureLevel = foundLevel as T_SchoolLevel;
        },
        setActiveLectureLevel: (
            state,
            action: PayloadAction<T_SchoolLevel | null>,
        ) => {
            state.activeLectureLevel = action.payload;
        },
        setActiveLectureLevelFromNumber: (
            state,
            action: PayloadAction<number | null>,
        ) => {
            switch (action.payload) {
                case 1:
                    state.activeLectureLevel = 'PO';
                    break;
                case 2:
                    state.activeLectureLevel = 'MBO';
                    break;
                case 3:
                    state.activeLectureLevel = 'VO';
                    break;
                default:
                    state.activeLectureLevel = null;
                    break;
            }
        },
        setPopUp: (state, action: PayloadAction<string[]>) => {
            state.interactions.popUp.text = action.payload;
        },
        setIsAnimated: (state, action: PayloadAction<boolean>) => {
            state.isAnimated = action.payload;
        },
        toggleMockPage: state => {
            state.showMockPage = !state.showMockPage;
        },
        setShowMockPage: (state, action: PayloadAction<boolean>) => {
            state.showMockPage = action.payload;
        },
        setshowWhatsappQuestion: (state, action: PayloadAction<boolean>) => {
            state.showWhatsappQuestion = action.payload;
        },
        setLectureIsTrial: (state, action: PayloadAction<boolean>) => {
            state.lectureIsTrial = action.payload;
        },
        setLectureIsWithoutCode: (state, action: PayloadAction<boolean>) => {
            state.lectureIsWithoutCode = action.payload;
        },
        setShowFooterButton: (state, action: PayloadAction<boolean>) => {
            state.showFooterButton = action.payload;
        },
    },
});

export const {
    addStage,
    clearToken,
    decreaseButtonCooldown,
    decreaseInteractionCooldown,
    handleArrivalToken,
    resetAppState,
    resetErrorCode,
    resetInteractions,
    setActiveLectureId,
    setActiveLectureLevel,
    setActiveLectureLevelFromNumber,
    setButtonCooldown,
    setConfig,
    setErrorCode,
    setGuestLecturerCode,
    setInteraction,
    setInteractionCooldown,
    setInteractionDimensions,
    setMessage,
    setLazyMessage,
    setSchoolList,
    setShowMockPage,
    setPopUp,
    setToken,
    setWarningPopUp,
    setIsAnimated,
    setshowWhatsappQuestion,
    startAppLoading,
    stopAppLoading,
    toggleMockPage,
    setLectureIsTrial,
    setLectureIsWithoutCode,
    setShowFooterButton,
} = appSlice.actions;

export default appSlice.reducer;
