import { useState } from 'react';
import { calculateTeamRanking } from '../../../functions/helpers/calculations/calculateTeamRanking';
import { useTeams } from '../../../hooks';

import classNames from 'classnames';
import { getTeamColor } from 'src/functions/ui/getters/getTeamColor';
import { getTeamName } from 'src/functions/ui/getters/getTeamName';
import { MainTitle } from '../../../components';

export const QuizResultsPage = () => {
    const teams = useTeams();
    const [_teams] = useState(teams);
    if (!_teams) return null;
    let totalScore = 0;

    const finalRanking = calculateTeamRanking(_teams).map(
        ({
            teamId,
            score,
            position,
        }: {
            teamId: number;
            score: number;
            position: number;
        }) => {
            const teamName = getTeamName({ id: teamId, teams: _teams });
            const teamColor = getTeamColor(teamId);
            totalScore += score;

            const teamRowClass = classNames('team-row');
            const scoreClass = classNames('end-score');
            const positionClass = classNames('position-wrapper');

            return (
                <div
                    className={teamRowClass}
                    style={{ borderColor: teamColor }}
                    key={teamId}
                >
                    <div
                        className={positionClass}
                        style={{ backgroundColor: teamColor }}
                    >
                        <span>{position}</span>
                    </div>

                    <div className="id-wrapper">Team {teamId} </div>

                    {teamName && (
                        <div className="name-wrapper">
                            <span>{teamName}</span>
                        </div>
                    )}

                    <div className={scoreClass}>€{score}</div>
                </div>
            );
        },
    );

    return (
        <>
            <div className="ranking-title">
                <MainTitle text="Ranking" className="mb-24" />
                <p className="undertext">
                    De totaalscore van de klas is
                    <span className="highlight"> {totalScore} euro</span>. Goed
                    gedaan!
                </p>
            </div>
            <div className="final-ranking">{finalRanking}</div>
        </>
    );
};
