import { useState } from 'react';
import { MainTitle } from 'src/components/base';
import { useAppDispatch, useTeams } from 'src/hooks';
import { setTeamDecisionWinner } from 'src/store';
import { T_Team } from '../../../../../globalTypes';
import { StyledOptionBox } from '../questionResponsePage';
import { MPCOptionsWrapper } from '../questionResponsePage/ResponseSection/mpc/MPCOptionsWrapper';

export const TeamPicker = () => {
    const dispatch = useAppDispatch();
    const teams = useTeams();
    const [initialTeams] = useState(teams);
    const teamDecisionWinner = teams?.find((team: T_Team) => team.wonDecision);
    const teamButtons = initialTeams
        ?.filter((team: T_Team) => team.wonDecision)
        .map((team: T_Team) => {
            return (
                <StyledOptionBox
                    selected={team.id === teamDecisionWinner?.id}
                    onClick={() => dispatch(setTeamDecisionWinner(team.id))}
                >
                    {team.id}
                </StyledOptionBox>
            );
        });
    return (
        <>
            <div className="bottom-0 absolute how-to-name-classnames">
                <MainTitle
                    className="text-center"
                    text="Welk team heeft het snelste geantwoord?"
                />
                <MPCOptionsWrapper>{teamButtons}</MPCOptionsWrapper>
            </div>
        </>
    );
};
