import { useState } from 'react';
import { calculateDeltaScore } from 'src/functions';
import { calculateMaxScore } from 'src/functions/helpers/calculations/calculateMaxScore';
import { useQuiz, useTeams } from 'src/hooks';
import { T_Question, T_Team } from '../../../../../globalTypes';
import { ScoreBar } from './ScoreBar';

export const ScoreBars = ({
    isQuizFirstReward,
    currentQuestion,
    revealScore,
}: {
    currentQuestion: T_Question;
    revealScore?: boolean;
    isQuizFirstReward?: boolean;
}) => {
    const teams = useTeams();
    const quiz = useQuiz();
    const [teamState] = useState<T_Team[] | null | undefined>(teams);
    if (!quiz || !teams || !teamState) return null;
    const maxScore = calculateMaxScore(quiz);
    const deltaScores = calculateDeltaScore({
        teams: teamState,
        question: currentQuestion,
    }).map(value => {
        if (isQuizFirstReward) {
            return { ...value, oldScore: 0, recentPoints: 10 };
        }
        return value;
    });
    const scoreDisplay = deltaScores.map(
        ({
            id,
            oldScore,
            recentPoints,
        }: {
            id: number;
            oldScore: number | undefined;
            recentPoints: number | null | undefined;
        }) => {
            const teamName = teams.find(team => team.id === id)?.teamName;
            let score = 0;
            if (oldScore && recentPoints) {
                score = oldScore + recentPoints;
            }
            if (!oldScore && recentPoints) {
                score = recentPoints;
            }
            return (
                <ScoreBar
                    question={currentQuestion}
                    revealScore={revealScore}
                    key={id}
                    teamName={teamName}
                    teamColor={teams.find(team => team.id === id)?.teamColor}
                    oldScore={oldScore || 0}
                    id={id}
                    score={score}
                    maxScore={maxScore}
                    deltaScore={recentPoints || 0}
                />
            );
        },
    );
    return <>{scoreDisplay}</>;
};
