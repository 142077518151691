import { useEffect, useState } from 'react';
import { getRequest } from 'src/api';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import { defaultScaleAnimation } from 'src/config/animation/animations';
import { useAppDispatch } from 'src/hooks';
import { useNavigateLevel } from 'src/hooks/';
import { useIsPreview } from 'src/hooks/time/useIsPreview';
import { setLectureIsTrial, setLectureIsWithoutCode, setPhase } from 'src/store';
import { Picture } from '../base/picture/Picture';
import { BVDKLogo } from './BVDKLogo';
import { CQ24Logo } from './CQ24Logo';
import { NVBLogo } from './NVBLogo';
import { StartButton } from './StartButton';

const MotionDiv = motion.div;
export const StartPage = () => {
    const [serverCheck, setServerCheck] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigateLevel();
    const [searchParams] = useSearchParams();
    const code = searchParams?.get('code');

    useEffect(() => {
        getRequest<{ message: string }>('/server/health').then(res => {
            if (res.message === 'Server is healthy') {
                setServerCheck(true);
            }
        });
    }, []);

    const isDateInRange = useIsPreview();
    const contentwrapperClassnames = classNames('content-wrapper', {
        bottom: isDateInRange,
    });

    const onClickEvent = (isOldQuiz: boolean = false) => {
        if (isOldQuiz) {
            window.location.href =
                'https://cashquiz-geldheld.bankvoordeklas.nl'+(code ? `/?code=${code}` :'');
        } else {
            if (isDateInRange) {
                dispatch(setPhase('trialStart'));
                dispatch(setLectureIsTrial(true));
                dispatch(setLectureIsWithoutCode(true));
                navigate('/speel-proef-versie');
            }
            if (!isDateInRange) {
                dispatch(setPhase('codeStart'));
                navigate('/selection');
            }
        }
    };

    return (
        <>
            <div className="start-page">
                <div className="logo-wrapper top">
                    <CQ24Logo />
                </div>
                <MotionDiv
                    className={contentwrapperClassnames}
                    {...defaultScaleAnimation}
                >
                    {!isDateInRange && (
                        <div className="intro">
                            <Picture
                                src="/landing/icons-comp"
                                alt="Alle cashQuiz karakters samen op een afbeelding"
                            />
                        </div>
                    )}
                    {isDateInRange && (
                        <MotionDiv
                            className="button-wrapper"
                            animate={{
                                opacity: serverCheck ? 1 : 0,
                            }}
                            transition={{
                                duration: 0.5,
                                ease: 'easeInOut',
                            }}
                        >
                            <img src="/assets/vragen/Splash_CQ-01.jpg" alt="" />
                            <StartButton
                                onClickEvent={() => onClickEvent(true)}
                                text={'Speel de quiz'}
                                cq23={true}
                            />
                        </MotionDiv>
                    )}
                    <MotionDiv
                        className="button-wrapper"
                        animate={{
                            opacity: serverCheck ? 1 : 0,
                        }}
                        transition={{
                            duration: 0.5,
                            ease: 'easeInOut',
                        }}
                    >   
                        {!isDateInRange && (
                            <img src="/assets/vragen/typo-landing.png" alt="" />
                        )}
                        {isDateInRange && (
                            <img src="/assets/vragen/Splash_CQ-03.jpg" alt="" />
                        )}
                        {serverCheck && (
                            <StartButton
                                onClickEvent={onClickEvent}
                                text={
                                    !isDateInRange
                                        ? 'Speel: Zo ben jij geldboeven de baas'
                                        : 'Oefen de quiz'
                                }
                                cq23={false}
                            />
                       
                        )}
                        {!isDateInRange && (
                             <StartButton
                             onClickEvent={() => onClickEvent(true)}
                             text={'Speel: Ben jij een held met je geld?'}
                             cq23={true}
                         />
                        )}
                    </MotionDiv>
                </MotionDiv>
                <div className="logo-wrapper">
                    <BVDKLogo />
                    <NVBLogo />
                </div>
            </div>
        </>
    );
};
