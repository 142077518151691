import {
    T_AppDispatch,
    forceConnect,
    getRestoredGameById,
    resetAllState,
    setGuestLecturerCode,
    setPhase,
    startAppLoading,
    stopAppLoading,
    verifyToken, setLectureIsTrial, setLectureIsWithoutCode,
} from '..';

import { NavigateFunction } from 'react-router-dom';
import { URLSearchParams } from 'url';
import { T_RawState } from '../../../../globalTypes';
import { appStorage } from '../../functions';
import { getServerConfig } from './getServerConfig';

//Handles whatever needs to be done when the app first loads from the top level
export const handleAppArrival = ({
    navigate,
    searchParams,
    isPreview,
}: {
    navigate: NavigateFunction;
    searchParams?: URLSearchParams;
    isPreview?: boolean;
}) => async (dispatch: T_AppDispatch, getState: () => T_RawState) => {
    const route = window.location.pathname;
    const code = searchParams?.get('code');
    const isTrial = searchParams?.get('oefensessie');

    if (code && !isPreview) {
        dispatch(setPhase('codeStart'));
        dispatch(setGuestLecturerCode(code));
        navigate('/selection');
        return;
    }
    if (isTrial || isTrial === '') {
        dispatch(setLectureIsTrial(true));
        dispatch(setLectureIsWithoutCode(true));
        dispatch(setPhase('trialStart'));
        navigate('/speel-proef-versie');
    }
    try {
        dispatch(startAppLoading());
        const storedGameId = appStorage.retrieveGameId();
        const storedSessionId = appStorage.retrieveSessionId();
        const { socketId } = appStorage.retrieveSocketStorage();
        const isHost = appStorage.retrieveIsHost();
        if (route.includes('/preview')) {
            return;
        }
        if (route.includes('/speel-proef-versie')) {
            dispatch(setLectureIsTrial(true));
            dispatch(setLectureIsWithoutCode(true));
            return;
        }
        if (!socketId && storedGameId && storedSessionId) {
            //if the quiz is local (no socketId) and the user is host
            await dispatch(getRestoredGameById(storedGameId, storedSessionId));
            const restoredGame = getState().quizState?.gameId;
            //we clear the storage is the game with specified ID is not found by the server
            if (!restoredGame) {
                navigate('..');
                appStorage.clear();
                return;
            }
            navigate('/herverbinden/local');
            return;
        }
        dispatch(forceConnect());
        if (socketId && (!isHost || isHost !== 'true')) {
            navigate('/herverbinden/team');
            return;
        }
        if (
            socketId &&
            isHost === 'true' &&
            !storedSessionId &&
            !storedGameId
        ) {
            navigate('/quiz-digibord');
            dispatch(setPhase('lobby'));
            return;
        }
        if (socketId && isHost === 'true' && storedSessionId && storedGameId) {
            await dispatch(getRestoredGameById(storedGameId, storedSessionId));
            navigate('/herverbinden/host');
            return;
        }
        if (window.location.pathname.includes('team')) {
            return;
        }
        const token = appStorage.retrieveToken();
        if (token && token !== 'undefined') {
            dispatch(verifyToken());
        }
        dispatch(resetAllState());
        await dispatch(getServerConfig());
    } catch (error) {
        console.log(error);
    } finally {
        dispatch(stopAppLoading());
    }
};
