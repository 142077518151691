import {
    T_Module,
    T_Question,
    T_Quiz,
    T_Section,
} from '../../../../globalTypes';
import { sectionIsQuestion } from '../checkers';

//takes a quiz and questionId, returns the question if it exists
export const getQuestionFromQuiz = (
    quiz: T_Quiz,
    id: number,
): T_Question | undefined => {
    let foundQuestion: T_Question | undefined = undefined;
    quiz.modules.map((module: T_Module) => {
        module.sections.map((section: T_Section) => {
            if (!sectionIsQuestion(section)) return;
            if (section.questionId === id) {
                foundQuestion = section;
            }
        });
    });
    return foundQuestion;
};
