
import { useEffect } from 'react';
export const useKeyControls = ({
    keyEvents,
}: {
    keyEvents: { key: string; event: () => void }[];
}) => {
    const activeKeys = keyEvents.map(({ key }) => key);
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const key = e.key.toLocaleUpperCase();
            if (activeKeys.includes(key)) {
                keyEvents.find(({ key: k }) => k === key)?.event();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);
};