import { motion } from 'framer-motion';
import { settings } from 'src/config/settings';
import { useTimedConditionalEffect } from 'src/hooks';
import { ScoreNumberDisplay } from './ScoreNumberDisplay';
const MotionDiv = motion.div;
export const ScorePoints = ({
    scoreProps,
    revealScore,
}: {
    scoreProps: {
        revealScoreAnimationStarted: boolean;
        deltaScore: number;
    };
    revealScore?: boolean;
}) => {
    const { revealAnswerExitTime } = settings;
    const { revealScoreAnimationStarted, deltaScore } = scoreProps;
    const hideScore = useTimedConditionalEffect({
        duration: revealAnswerExitTime,
        condition: revealScoreAnimationStarted && deltaScore > 0,
    });

    if (!revealScoreAnimationStarted || (deltaScore <= 0 && revealScore))
        return null;
    if (!revealScore) {
        const displayedText =
            deltaScore <= 0 ? '0' : '+' + deltaScore.toString();
        return (
            <MotionDiv
                initial={{
                    opacity: 0,
                    y: 5,
                }}
                animate={{
                    opacity: 1,
                    y: 0,
                    scale: 1,
                }}
                exit={{
                    opacity: 0,
                    y: 50,
                    scale: 1,
                }}
                transition={{
                    delay: 0.2,
                    duration: 0.2,
                    ease: 'easeOut',
                }}
                className="score-non-black"
            >
                {displayedText}
            </MotionDiv>
        );
    }
    return (
        <>
            <div className="confetti-wrapper">
                {/* <Confetti scoreBarRef={scoreBarRef} deltaScore={deltaScore} /> */}
            </div>
            <div className="score-number-coin">
                <ScoreNumberDisplay hideScore={hideScore} number={deltaScore} />
            </div>
        </>
    );
};
