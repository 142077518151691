export const getNextOrder = <T extends { order: number }>(
    array: T[],
    currentOrder: number | null,
): number => {
    const highestOrder = currentOrder ? currentOrder : 0;
    const nextOrder = Math.min(
        ...array
            .filter((item: T) => {
                return item.order > highestOrder;
            })
            .map((item: T) => item.order),
    );
    return nextOrder;
};
