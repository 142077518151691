import { useNavigate } from 'react-router-dom';
import { handleWarningPopUp } from 'src/store';
import { useNextEvent, usePreviousEvent } from '../events';
import { useAppDispatch } from '../redux';
import { useSocket } from '../socket';

export const useForcedNavigation = (direction: 'forward' | 'back') => {
    const dispatch = useAppDispatch();
    const previousEvent = usePreviousEvent();
    const nextEvent = useNextEvent();
    const socket = useSocket();
    const navigate = useNavigate();
    if (direction === 'back') {
        return (warningPopUpOpen: boolean) => {
            if (!warningPopUpOpen) {
                previousEvent();
                return;
            }
            dispatch(handleWarningPopUp('accept', { socket, navigate }));
        };
    }
    return (warningPopUpOpen: boolean) => {
        if (!warningPopUpOpen) {
            nextEvent();
            return;
        }
        dispatch(handleWarningPopUp('accept', { socket, navigate }));
    };
};
