import { NavigateFunction } from 'react-router-dom';
import { getRequest } from 'src/api';
import { darkURL } from 'src/config';
import { appStorage } from 'src/functions';
import { formatLectures } from 'src/functions/helpers/format/formatLectures';
import {
    T_AppDispatch,
    T_RootState,
    clearToken,
    setActiveLectureId,
    setErrorCode,
    setMessage,
    setPhase,
    setSchoolList,
} from '..';
import { T_SchoolData } from '../../../../globalTypes';

export const getAssociatedLectures = ({
    navigate,
    asCheck,
}: {
    navigate: NavigateFunction;
    asCheck?: boolean;
}) => async (dispatch: T_AppDispatch, getState: () => T_RootState) => {
    try {
        const code = getState().appState.guestLecturerCode;
        if (!code) return;
        const responseMBO = await getRequest<
            | {
                  msg: string;
              }
            | T_SchoolData<'MBO'>[]
        >(`${darkURL}/lectures/${code}/gastdocent/mbo`, true);
        const responseVO = await getRequest<
            { msg: string } | T_SchoolData<'VO'>[]
        >(`${darkURL}/lectures/${code}/gastdocent/vo`, true);
        const responsePO = await getRequest<
            { msg: string } | T_SchoolData<'PO'>[]
        >(`${darkURL}/lectures/${code}/gastdocent/po`, true);
        const lectures = formatLectures({
            mbo: responseMBO,
            po: responsePO,
            vo: responseVO,
        });
        if (!lectures || lectures.length === 0) {
            appStorage.clearGuestLecturerCode();
            dispatch(clearToken());
            dispatch(setErrorCode('BB-20'));
            if (!lectures) {
                dispatch(
                    setMessage(
                        'Er zijn geen gastlessen gevonden die horen bij deze code.',
                    ),
                );
            }
            if (Array.isArray(lectures) && lectures?.length === 0) {
                dispatch(
                    setMessage('Alle gastlessen zijn voor deze code gebruikt.'),
                );
                return;
            }

            if (asCheck) {
                dispatch(setPhase('preStart'));
                dispatch(clearToken());
                appStorage.clearGuestLecturerCode();
                navigate('/');
            }
            return;
        }
        appStorage.saveGuestLecturerCode(code);
        dispatch(setSchoolList(lectures));
        if (lectures.length === 1) {
            dispatch(setActiveLectureId(lectures[0].id));
            dispatch(setPhase('quizSelection'));
        }
        dispatch(setPhase('lectureSelection'));
        navigate('/selection');
    } catch (error) {
        console.log(error);
        dispatch(setErrorCode('BB-21'));
        dispatch(setMessage('Er is iets misgegaan, probeer het opnieuw.'));
    }
};
