import { sectionIsQuestion } from 'src/functions';
import { T_NormalQuestion } from '../../../../../../../globalTypes';
import { useAppSelector, usePhase, useSection } from '../../../../../hooks';
import { selectDecisionQuestion } from '../../../../../store';
import { GuessAnswerReveal } from './GuessAnswerReveal';

export const GuessDisplay = () => {
    const question = useSection<T_NormalQuestion<'Guess'>>();
    const phase = usePhase();
    const decisionQuestion = useAppSelector(selectDecisionQuestion());
    const inDecision = phase?.includes('decision');
    console.log(inDecision);
    console.log(decisionQuestion);
    console.log(phase);
    if (
        inDecision &&
        decisionQuestion &&
        ['decisionQuestionReveal', 'decisionDraw'].includes(phase)
    ) {
        return (
            <GuessAnswerReveal
                phase={phase}
                question={{
                    ...decisionQuestion,
                    type: 'Guess',
                    typeData: {
                        unitPrepend: decisionQuestion.unitPrepend,
                        unit: decisionQuestion.unit,
                    },
                    correctAnswers: [decisionQuestion.correctAnswers],
                }}
            />
        );
    }
    if (!sectionIsQuestion(question) || !decisionQuestion) return null;
    const { correctAnswers } = question;
    if (
        ['answerReveal', 'decisionQuestionReveal', 'decisionDraw'].includes(
            phase,
        ) &&
        correctAnswers &&
        (typeof correctAnswers === 'number' || correctAnswers.length > 0)
    ) {
        return <GuessAnswerReveal question={question} />;
    }
    return null;
};
