import { usePhase, useQuizIsLocal } from 'src/hooks';
import styled from 'styled-components';
import { T_Phase } from '../../../../../globalTypes';
import { getThemeColor } from '../../../functions';

//Used in app.tsx for when the user arives on the website for the first time
export const TransitionWebEntry = ({
    children,
    key,
}: {
    children: React.ReactNode;
    key?: string;
}) => {
    const phase = usePhase();

    const quizIsLocal = useQuizIsLocal();
    return (
        <Wrapper
            id="0.8.6"
            className="transition-web-entry"
            quizIsLocal={quizIsLocal}
            phase={phase}
            key={key}
        >
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div<{
    phase: T_Phase | undefined;
    quizIsLocal?: boolean;
}>`
    display: flex;
    height: 100dvh;
    flex-direction: column;
    background: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'background',
        })};
    color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeColor: 'primary',
        })};
`;
