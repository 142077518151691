import { getLectureFromSchoolList } from 'src/functions/getters/getLectureFromSchoolList';
import { calculateMaxScore } from 'src/functions/helpers/calculations/calculateMaxScore';
import { T_AppDispatch, T_RootState, setGameId } from '..';
import { T_DatabaseGame } from '../../../../globalTypes';
import { handlePostRequest } from './handlePostRequest';

export const startGame = (gameData: T_DatabaseGame) => async (
    dispatch: T_AppDispatch,
    getState: () => T_RootState,
) => {
    try {
        const { quizState, appState } = getState();
        const { quiz } = quizState;
        const {
            schoolList,
            activeLectureId,
            activeLectureLevel,
            guestLecturerCode,
            lectureIsTrial,
            lectureIsWithoutCode,
        } = appState;
        const foundLecture = getLectureFromSchoolList({
            schoolList,
            id: activeLectureId,
        });
        const {
            email,
            email_teacher,
            firstname,
            prefix,
            lastname,
            teacher,
            yearData,
            school_name,
            bank,
            postcode,
            id,
        } = foundLecture || {};
        const getCode = ({
            lectureIsWithoutCode,
            lectureIsTrial,
        }: {
            lectureIsTrial: boolean;
            lectureIsWithoutCode: boolean;
        }) => {
            if (lectureIsTrial) return '_TRIAL';
            if (lectureIsWithoutCode) return '_NOCODE';
            if (guestLecturerCode) return guestLecturerCode;
            return null;
        };
        await dispatch(
            handlePostRequest<T_DatabaseGame, T_DatabaseGame>({
                postData: {
                    ...gameData,
                    max_score: calculateMaxScore(quiz),
                    //APOLOGIES
                    guestLecturerCode: getCode({
                        lectureIsTrial,
                        lectureIsWithoutCode,
                    }),
                    guestLecturerName:
                        `${firstname ?? ''} ${
                            prefix ? `${prefix} ` : ''
                        }${lastname ?? ''}` ?? null,
                    classLevel: yearData ?? null,
                    schoolLevel: activeLectureLevel,
                    schoolName: school_name,
                    guestLecturerEmail: email ?? null,
                    teacherEmail: email_teacher ?? null,
                    teacherName: teacher ?? null,
                    schoolPostalCode: postcode ?? null,
                    guestLecturerBank: bank ?? null,
                    guestLectureId: id ?? null,
                },
                endPoint: '/games/start',
                dataReducer: setGameId,
            }),
        );
    } catch (error) {
        console.log(error);
    }
};
