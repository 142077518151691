import { mediaConfig } from 'src/config/mediaConfig';

export const MockMobileImage = ({ src }: { src: string }) => {
    return (
        <img
            src={`${mediaConfig.images}/mockMobile/${src}`}
            alt="mockMobile"
            className="mockMobileImage"
        />
    );
};
