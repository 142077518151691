import { T_AppDispatch, setStage } from '../../store';

import { Socket } from 'socket.io-client';
import { T_Stage } from '../../../../globalTypes';
import { Event } from './castEvent';

export const createStageEvent = (
    socket: Socket,
    dispatch: T_AppDispatch,
    payload: T_Stage,
): Event => {
    return {
        socket,
        dispatch,
        reducer: setStage,
        endPoint: 'setStage',
        payload,
    };
};
