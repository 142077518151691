import { LocaLTeamCountSelection } from '../../../components/lobby/LocalLobby/LocalTeamCountSelection';
import { ImageWrapper } from '../quiz/ImageWrapper';
import { LocalLobbyWrapper } from './LocalLobbyWrapper';

import './style.scss';
export const LocalLobby = ({
    teamMessage,
}: {
    teamMessage?: string | null;
}) => {
    return (
        <LocalLobbyWrapper>
            <ImageWrapper />
            <h1>Hoeveel teams doen er mee?</h1>
            <LocaLTeamCountSelection />
            {teamMessage && (
                <p className="w-6_10 text-center font-bungee mt-32">
                    {teamMessage}
                </p>
            )}
        </LocalLobbyWrapper>
    );
};
