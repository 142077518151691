import { AdjustIndexButton } from 'src/components/quiz/questionImagesPage/AdjustIndexButton';
import { QuestionImage } from 'src/components/quiz/questionImagesPage/QuestionImage';
import { useQuestionImages } from 'src/hooks/quiz/useQuestionImages';

export const QuestionImagesPage = () => {
    const {
        images,
        activeSrc,
        incrementImageIndex,
        decrementImageIndex,
    } = useQuestionImages();
    if (!images || activeSrc === null) return null;
    return (
        <>
            <div className="question-images-wrapper">
                <QuestionImage src={activeSrc} />
            </div>
            {decrementImageIndex && (
                <AdjustIndexButton
                    decreaseIndex={decrementImageIndex}
                    text="Even een stapje terug"
                />
            )}
            {incrementImageIndex && (
                <AdjustIndexButton
                    increaseIndex={incrementImageIndex}
                    text="Meer uitleg"
                />
            )}
        </>
    );
};
