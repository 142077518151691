import { motion } from 'framer-motion';
import { getThemeColor, sectionIsQuestion } from 'src/functions';
import styled from 'styled-components';
import {
    T_NormalQuestion,
    T_QuestionType,
    T_QuizPhase,
    T_SlideType,
} from '../../../../../../globalTypes';
import {
    useAppDispatch,
    useAppSelector,
    usePhase,
    useSection,
} from '../../../../hooks';
import { selectSelectedTeamId, setSelectedTeamId } from '../../../../store';
import { TeamResponse } from './TeamResponse';
import { TeamText } from './TeamText';
import './style.scss';

export const TeamSelectionBox = ({ id }: { id: number }) => {
    const phase = usePhase();
    const dispatch = useAppDispatch();
    const selectedTeamId = useAppSelector(selectSelectedTeamId());
    const section = useSection();
    if (
        (!section || !sectionIsQuestion(section)) &&
        phase !== 'decisionResponse'
    )
        return null;

    const questionType = () => {
        const type = section?.type;
        if (
            type === 'Open' &&
            sectionIsQuestion(section) &&
            (section as T_NormalQuestion<'Open'>).typeData?.desiredAnswers !== 1
        ) {
            return 'MPC';
        }
        return type;
    };
    const isSelected = selectedTeamId === id;
    return (
        <div
            className="column-wrapper"
            onClick={() => dispatch(setSelectedTeamId(id))}
        >
            <MotionTeamText
                teamId={id}
                animate={{
                    y: isSelected ? 3 : 0,
                    scale: isSelected ? 1.08 : 1,
                }}
                transition={{
                    duration: 0.25,
                    ease: 'easeInOut',
                }}
            />
            <MotionTeamSelectionBoxWrapper
                animate={{
                    y: isSelected ? 3 : 0,
                    scale: isSelected ? 1.08 : 1,
                }}
                transition={{
                    duration: 0.25,
                    ease: 'easeInOut',
                }}
                className= {(questionType() == 'Thesis' ) ? "team-selection-box-wrapper team-selection-box-wrapper-thesis" : "team-selection-box-wrapper" }
                phase="questionResponse"
                selected={isSelected}
            >
                <StyledTeamResponse
                    className="team-response"
                    type={questionType()}
                >
                    <TeamResponse id={id} />
                </StyledTeamResponse>
            </MotionTeamSelectionBoxWrapper>
        </div>
    );
};

const MotionTeamText = motion(TeamText);

const StyledTeamResponse = styled.p<{ type?: T_QuestionType | T_SlideType }>`
    font-size: ${props => {
        switch (props.type) {
            case 'MPC':
                return '28px';
            case 'Open':
            case 'Closed':
            case 'Thesis':
                return '18px';
            default:
                return '24px';
        }
    }};
`;

const TeamSelectionBoxWrapper = styled.div<{
    phase: T_QuizPhase | undefined;
    selected: boolean;
}>`
    border: 1px solid
        ${props =>
            getThemeColor({
                theme: props.theme,
                themeType: 'colors',
                themeColor: 'primary',
            })};
    background: ${props =>
        props.selected
            ? getThemeColor({
                  theme: props.theme,
                  themeType: 'colors',
                  themeColor: 'input',
              })
            : getThemeColor({
                  theme: props.theme,
                  themeType: 'colors',
                  themeColor: 'coolBackground',
              })};
    color: ${props =>
        props.selected
            ? getThemeColor({
                  theme: props.theme,
                  themeType: 'colors',
                  themeColor: 'coolBackground',
              })
            : getThemeColor({
                  theme: props.theme,
                  themeType: 'colors',
                  themeColor: 'primary',
              })};
    box-shadow: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'boxShadows',
            themeColor: 'primary',
        })};
`;
const MotionTeamSelectionBoxWrapper = motion(TeamSelectionBoxWrapper);
