//used on main pages to display top section e.g. "Inleiding" -
import classNames from 'classnames';
import { renderNoWrap } from 'src/functions';
import { usePhase } from 'src/hooks';
import styled from 'styled-components';
import { Falsy } from '../../../../../globalTypes';

//text is one string, underlined is a part of that string. If underlined is not provided, nothing is underlined.
//the underlined part of the string shall appear in styled <em></em> tags, special shoutout to master Sunny for providing this technique of <em></em> tags
export const MainTitle = ({
    text,
    underlined,
    className,
    heading = 'h1',
}: {
    text: string;
    underlined?: string | Falsy;
    className?: string;
    heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}) => {
    const _Element = heading;
    const phase = usePhase();
    if (!text || !phase) return null;
    if (!underlined)
        return (
            <_Element className={classNames(className)}>
                {renderNoWrap({ element: <>{text}</> })}
            </_Element>
        );
    const underlinedText = underlined ? underlined : '';
    const textArray = underlined ? text.split(underlinedText) : [text];
    const marginPhases = ['question', 'questionWhatsapp', 'answerReveal'];
    return (
        <_Element
            className={classNames(
                { 'mb-32': marginPhases.includes(phase) },
                className,
            )}
        >
            {renderNoWrap({ element: <>{textArray[0]}</> })}
            <Em>{renderNoWrap({ element: <>{underlinedText}</> })}</Em>
            {renderNoWrap({ element: <>{textArray[1]}</> })}
        </_Element>
    );
};

export const Em = styled.em`
    position: relative;
    font-style: normal;
    &::after {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='190' height='7' viewBox='0 0 190 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.99989 4.97257C63.8287 3.9626 125.726 -0.00429375 187.454 4.01225' stroke='%230A804D' stroke-width='4' stroke-linecap='round'/%3E%3C/svg%3E");
        bottom: -6px;
        left: 0px;
        width: 100%;
        height: 7px;
        background-repeat: no-repeat;
    }
`;
