import { useEffect } from 'react';
import { Divider } from 'src/components/lobby/SocketLobby/Divider';
import { NoConnection } from 'src/components/lobby/SocketLobby/NoConnection';
import { Announcer } from 'src/components/team/teamLobby/Announcer';
import { appStorage } from 'src/functions';
import { ScanSection } from '../../../components/lobby/SocketLobby/ScanSection';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { resetTeams, selectRoomId } from '../../../store';
import './style.scss';

export const SocketLobby = () => {
    const roomId = useAppSelector(selectRoomId());
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(resetTeams());
        appStorage.saveHost();
    }, []);
    if (!roomId) return <NoConnection />;

    return (
        <>
            <div className="socket-lobby-wrapper" id={roomId}>
                <ScanSection roomId={roomId} />
                <Divider />
                <Announcer />
            </div>
        </>
    );
};
