export const drawOpenResponseBox = ({
    number,
    desiredAnswers,
}: {
    number: number;
    desiredAnswers: number;
}): string => {
    let text: string = '0';
    if (number > 0 && number < desiredAnswers) {
        text = number.toString();
    }
    if (number >= desiredAnswers) {
        if (desiredAnswers > 1) {
            text = desiredAnswers.toString();
        }
        if (desiredAnswers === 1) {
            text = 'Goed';
        }
    }
    if (number === 0 && desiredAnswers === 1) {
        text = 'Fout';
    }

    return text;
};
