import { T_Role } from 'src/functions';
import { HostRolePage, TeamRolePage } from '.';

export const RolePage = ({ role }: { role: T_Role }) => {
    switch (role) {
        case 'team':
            return <TeamRolePage />;
        case 'host':
        default:
            return <HostRolePage />;
    }
};
