import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ImageWrapper = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e:any) => {
            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;

            const diffX = e.clientX - centerX;
            const diffY = e.clientY - centerY;

            const factor = 0.025;

            const newPositionX = Math.max(Math.min(diffX * factor, 50), -50);
            const newPositionY = Math.max(Math.min(diffY * factor, 50), -50);

            setMousePosition({
                x: newPositionX,
                y: newPositionY
            });
        };

        // Listen for mouse movement
        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            // Clean up event listener
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <ImageWrapperDiv>
            <motion.img
                src="/assets/vragen/bq.png"
                alt=""
                style={{ x: mousePosition.x, y: mousePosition.y }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, x: mousePosition.x - 50, y: mousePosition.y - 50 }}
                transition={{ type: 'spring', stiffness: 100, damping: 10 }}
            />
        </ImageWrapperDiv>
    );
};

const ImageWrapperDiv = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fffaf2;
    z-index: -1;
    h1 {
        margin-bottom: 24px;
    }
    img {
        width: 100%;
        mix-blend-mode: darken;
        object-fit: contain;
    }
`;
