import { T_TeamResponse } from '../../../../../globalTypes';

export const drawAnswerRevealText = (
    teamResponse: T_TeamResponse | undefined | null,
): string | null => {
    const displayedText = () => {
        if (!teamResponse) return 'Geen antwoord ontvangen.';
        switch (teamResponse?.isCorrect) {
            case 'YES':
                return 'Goed geantwoord!';
            case 'NO':
                return 'Fout geantwoord!';
            case 'PARTIAL':
                return 'Gedeeltelijk goed geantwoord!';
            case 'WON_GUESS':
                return 'Goed geraden!';
            case 'LOST_GUESS':
                return 'Fout geraden!';
            case undefined:
            default:
                return null;
        }
    };
    return displayedText();
};
