import { MainTitle } from 'src/components/base/typography/MainTitle';
import { renderText, sectionIsQuestion } from 'src/functions';
import {
    useAppDispatch,
    useIntroText,
    useSection,
    useSocket,
    useTimerReset,
} from 'src/hooks';
import { useIntroTitle } from 'src/hooks/text/useIntroTitle';
import { Image, InfoText } from '../../../../components';
import { castNextEvent } from '../../../../functions/events/castNextEvent';
import { QuestionTextWrapper } from './QuestionTextWrapper';

export const QuestionIntroPage = () => {
    const currentQuestion = useSection();
    const introText = useIntroText();
    const introTitle = useIntroTitle();
    const socket = useSocket();
    const dispatch = useAppDispatch();
    const { text, underlined } = introTitle || {
        text: 'Lees allemaal mee',
        underlined: 'allemaal',
    };
    useTimerReset();
    if (!currentQuestion || !sectionIsQuestion(currentQuestion)) {
        castNextEvent({ socket, dispatch });
        return null;
    }
    const showImage =
        currentQuestion.imageURL && currentQuestion.imagePosition === 'Intro';
    return (
        <QuestionTextWrapper wide={currentQuestion?.introTextWide}>
            <MainTitle text={text} underlined={underlined} />
            {introText && (
                <InfoText
                    textSize={currentQuestion.introTextSize}
                    text={renderText({ text: introText })}
                />
            )}
            {showImage && (
                <Image imageURL={currentQuestion.imageURL as string} />
            )}
        </QuestionTextWrapper>
    );
};
