import classNames from 'classnames';
import { useSelfResponse } from 'src/hooks';
import { useTeamWonGuess } from 'src/hooks/teams/useTeamWonGuess';

export const TeamGuessReveal = () => {
    const teamWonGuess = useTeamWonGuess();
    const response = useSelfResponse();
    const displayedResponse =
        response &&
        Array.isArray(response.response) &&
        typeof response.response[0] === 'number' &&
        response.response[0];

    return (
        <>
            <p
                className={classNames('flex flex-col', {
                    'won-guess': teamWonGuess,
                })}
            >
                {teamWonGuess && 'Jullie antwoord is goed!'}<br/>
                {displayedResponse}
            </p>
        </>
    );
};
