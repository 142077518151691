import { T_Quiz } from '../../../../globalTypes';

//function that retrieves the final Sectionid of a quiz
export const getFinalSectionId = (quiz: T_Quiz | null): number | null => {
    if (!quiz) {
        return null;
    }
    const lastModule = quiz.modules[quiz.modules.length - 1];
    const lastSection = lastModule.sections[lastModule.sections.length - 1];
    return lastSection.sectionId;
};
