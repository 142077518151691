import { getThemeColor } from 'src/functions';
import { useTheme } from 'styled-components';
import { T_ThemeState } from '../../../../../globalTypes';

export const TitleLogo = () => {
    const theme = useTheme() as T_ThemeState;
    const color = getThemeColor({
        theme: theme,
        themeType: 'colors',
        themeColor: 'primary',
    });
    return (
        <svg viewBox="0 0 56 12" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={color}
                d="M2.89 9.29008C0.915 9.29008 0 8.52508 0 6.89508V3.24008C0 1.59508 0.915 0.830078 2.89 0.830078H5.755C6.05 0.830078 6.18 0.960078 6.18 1.25508V2.67508C6.18 2.97008 6.05 3.10008 5.755 3.10008H3.395C2.9 3.10008 2.68 3.29008 2.68 3.68508V6.48008C2.68 6.86508 2.905 7.04508 3.395 7.04508H5.755C6.05 7.04508 6.18 7.17508 6.18 7.47008V8.87008C6.18 9.16508 6.05 9.29508 5.755 9.29508H2.89V9.29008Z"
            />
            <path
                fill={color}
                d="M8.67993 8.90979H7.17493C6.91993 8.90979 6.80493 8.79479 6.80493 8.53979V5.83479C6.80493 5.42479 6.92993 4.91479 7.20493 4.19479L8.09493 1.86979C8.17493 1.63479 8.33993 1.52979 8.61993 1.52979H11.3749C11.6399 1.52979 11.8049 1.63479 11.8849 1.86979L12.7749 4.19479C13.0499 4.90979 13.1749 5.42479 13.1749 5.83479V8.53979C13.1749 8.79479 13.0599 8.90979 12.8049 8.90979H11.2599C10.9849 8.90979 10.8599 8.79479 10.8599 8.53979V7.10478H9.04493V8.53979C9.04493 8.79479 8.92993 8.90979 8.67493 8.90979H8.67993ZM9.14993 5.24479H10.7599L10.2799 3.68979C10.2299 3.56479 10.1649 3.51479 10.0849 3.51479H9.82993C9.74993 3.51479 9.68493 3.56479 9.63493 3.68979L9.15493 5.24479H9.14993Z"
            />
            <path
                fill={color}
                d="M14.5951 9.29016C14.3251 9.29016 14.2051 9.17016 14.2051 8.90016V7.64016C14.2051 7.37016 14.3251 7.25016 14.5951 7.25016H17.4901C17.6751 7.25016 17.7701 7.11016 17.7701 6.93516C17.7701 6.68516 17.6751 6.59016 17.4901 6.57016L15.8751 6.34516C14.8651 6.19516 14.2051 5.76516 14.2051 4.56016V3.43016C14.2051 2.20016 15.0351 1.53516 16.4351 1.53516H19.4501C19.7201 1.53516 19.8401 1.65516 19.8401 1.92516V3.20516C19.8401 3.47516 19.7201 3.59516 19.4501 3.59516H16.9401C16.7551 3.59516 16.6501 3.72516 16.6501 3.92016C16.6501 4.11516 16.7601 4.24516 16.9401 4.26516L18.5451 4.48016C19.5551 4.63016 20.2251 5.06016 20.2251 6.26516V7.39516C20.2251 8.62516 19.3851 9.29016 17.9951 9.29016H14.5901H14.5951Z"
            />
            <path
                fill={color}
                d="M21.3251 8.96006C21.0801 8.96006 20.9751 8.85506 20.9751 8.61006V1.78506C20.9751 1.54006 21.0801 1.43506 21.3251 1.43506H22.8301C23.0751 1.43506 23.1801 1.54006 23.1801 1.78506V3.89506H24.8001V1.78506C24.8001 1.54006 24.9051 1.43506 25.1501 1.43506H26.6551C26.9001 1.43506 27.0051 1.54006 27.0051 1.78506V8.61506C27.0051 8.86006 26.9001 8.96506 26.6551 8.96506H25.1501C24.9051 8.96506 24.8001 8.86006 24.8001 8.61506V6.46006H23.1801V8.61506C23.1801 8.86006 23.0751 8.96506 22.8301 8.96506H21.3251V8.96006Z"
            />
            <path
                fill={color}
                d="M31.465 11.155C31.16 11.155 31.025 11.02 31.025 10.715V9.015C29.12 8.735 28.605 7.835 28.605 6.595V2.515C28.605 1.015 29.355 0 32.425 0C35.495 0 36.23 1.02 36.23 2.515V6.59C36.23 7.83 35.725 8.74 33.8 9.01V10.71C33.8 11.015 33.665 11.15 33.36 11.15H31.47L31.465 11.155ZM32.425 6.9C33.32 6.9 33.455 6.655 33.455 6.325V2.975C33.455 2.655 33.32 2.41 32.425 2.41C31.53 2.41 31.395 2.655 31.395 2.975V6.325C31.395 6.655 31.54 6.9 32.425 6.9Z"
            />
            <path
                fill={color}
                d="M40.2449 9.07016C37.6999 9.07016 37.075 8.23016 37.075 6.98016V1.90516C37.075 1.65016 37.19 1.53516 37.445 1.53516H39.055C39.31 1.53516 39.4249 1.65016 39.4249 1.90516V6.58016C39.4249 6.85516 39.5499 7.06016 40.2649 7.06016C40.9799 7.06016 41.115 6.85516 41.115 6.58016V1.90516C41.115 1.65016 41.215 1.53516 41.485 1.53516H43.0449C43.2999 1.53516 43.415 1.65016 43.415 1.90516V6.98016C43.415 8.23016 42.7899 9.07016 40.2449 9.07016Z"
            />
            <path
                fill={color}
                d="M44.1899 8.45512V7.35512C44.1899 7.12512 44.2899 7.02012 44.5249 7.02012H45.4399V3.91012H44.5249C44.2949 3.91012 44.1899 3.81012 44.1899 3.57512V2.45512C44.1899 2.22512 44.2899 2.12012 44.5249 2.12012H48.4649C48.6949 2.12012 48.7999 2.22012 48.7999 2.45512V3.57512C48.7999 3.80512 48.6999 3.91012 48.4649 3.91012H47.5649V7.02012H48.4649C48.6949 7.02012 48.7999 7.12012 48.7999 7.35512V8.45512C48.7999 8.68512 48.6999 8.79012 48.4649 8.79012H44.5249C44.2949 8.79012 44.1899 8.69012 44.1899 8.45512Z"
            />
            <path
                fill={color}
                d="M49.6649 8.78979C49.3949 8.78979 49.2749 8.66979 49.2749 8.39979V6.95479C49.2749 6.67479 49.3499 6.48979 49.5449 6.28979L52.1599 3.11479H49.8549C49.5849 3.11479 49.4649 2.99479 49.4649 2.72479V1.41979C49.4649 1.14979 49.5849 1.02979 49.8549 1.02979H54.8099C55.0799 1.02979 55.1999 1.14979 55.1999 1.41979V3.02479C55.1999 3.31479 55.1249 3.49979 54.9299 3.70479L52.4299 6.72978H55.0049C55.2749 6.72978 55.3949 6.84979 55.3949 7.11979V8.39979C55.3949 8.66979 55.2749 8.78979 55.0049 8.78979H49.6649Z"
            />
        </svg>
    );
};
