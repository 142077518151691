import { RequireOnlyOne, T_ThemeState } from '../../../../../globalTypes';
import { getThemeColor } from './getThemeColor';

export const getOptionTextColor = ({
    theme,
    selected,
    correct,
    revealAnswer,
}: { theme: T_ThemeState; revealAnswer?: boolean } & RequireOnlyOne<{
    selected?: boolean;
    correct?: boolean;
}>) => {
    if (revealAnswer === false) {
        return getThemeColor({
            theme: theme,
            themeType: 'colors',
            themeColor: 'primary',
        });
    }
    if (correct)
        return getThemeColor({
            theme: theme,
            themeType: 'colors',
            themeColor: 'coolBackground',
        });
    if (selected) {
        return getThemeColor({
            theme: theme,
            themeType: 'colors',
            themeColor: 'white',
        });
    }
    return getThemeColor({
        theme: theme,
        themeType: 'colors',
        themeColor: 'primary',
    });
};
