import { T_DecisionPhase, T_Phase } from '../../../../globalTypes';

//phases were reward ("VERDIEN 15 EURO") is displayed
export const rewardPhases: T_Phase[] = [
    'questionIntro',
    'questionVideo',
    'questionWhatsappInteraction',
    'questionWhatsapp',
    'question',
    'questionResponse',
];

export const questionPhases = [
    'questionIntro',
    'question',
    'questionVideo',
    'question',
    'questionResponse',
    'answerReveal',
    'questionOutro',
    'scoreReveal',
    'sectionTransition',
] as const;

export const noQuizInfoPhases: T_Phase[] = [
    'sectionTransition',
    'quizTips',
    'quizIntro',
    'quizIntroVideo',
];

export const noRewardPhases: T_Phase[] = [
    ...noQuizInfoPhases,
    'quizFirstReward',
];

//phases were useAnimation is ignored when in succession
export const noAnimationPhases: T_Phase[] = [
    'preStart',
    'codeStart',
    'quizSelection',
    'lectureSelection',
    'question', //for socket into answerReveal
    'answerReveal',
];

export const tipsPhases: T_Phase[] = [
    'quizIntro',
    'codeStart',
    'preStart',
    'lectureSelection',
    'quizSelection',
    'localToggle',
    'lobby',
];

export const decisionPhases: T_DecisionPhase[] = [
    'decision',
    'decisionQuestion',
    'decisionResponse',
    'decisionQuestionReveal',
    'decisionDraw',
];

export const teamResultBoxPhases: T_Phase[] = ['question', 'answerReveal'];
