import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { MainTitle } from '../../../components';
import { Picture } from '../../../components/base/picture/Picture';
import { selectQuizState } from '../../../store';
import './style.scss';

export const QuizWrapUpPage = () => {
    const dispatch = useAppDispatch();
    const [currentIndex, setCurrentIndex] = useState(0);
    const vuistregels = [
        'Bescherm je code',
        'Bewaak je pas',
        'Beveilig je apparatuur',
        'Bekijk je afschrijvingen',
        'Bij twijfel, ga naar je ouders',
    ];

    const groupNumber = useAppSelector(selectQuizState());
    const selectedGroupNumber = groupNumber?.quiz?.title;

    const pictureSrc = (() => {
        switch(selectedGroupNumber) {
            case 'Groep 6':
                return "/screen/vuistregels6";
            case 'Groep 7':
                return "/screen/vuistregels7";
            case 'Groep 8':
                return "/screen/vuistregels8";
            default:
                return "/screen/vuistregels6";
        }
    })();

    useEffect(() => {
        // dispatch(postEndResponses());
        const interval = setInterval(() => {
            setCurrentIndex((currentIndex) => {
                const nextIndex = currentIndex + 1;
                if (nextIndex === vuistregels.length) {
                    clearInterval(interval);
                    return currentIndex;
                }
                return nextIndex;
            });
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="vuistregel-screen">
            <div className="vuistregel-image">
                <Picture src={pictureSrc}></Picture>
            </div>
            <div className="vuistregel-content">
                <MainTitle text={'Wat moet je onthouden?'} underlined={'onthouden'} />
                <ol>
                    {vuistregels.slice(0, currentIndex + 1).map((rule, index) => (
                        <li key={index} className="list-item fadeInRight">{rule}</li>
                    ))}
                </ol>
            </div>
        </div>
    );
};
