import { useAppDispatch, useAppSelector, usePhase } from '../../hooks';
import {
    resetInteractions,
    selectInteractions,
    selectRoomId,
} from '../../store';
import { QRCode } from '../lobby';

export const QREnlarged = () => {
    const isOpen = useAppSelector(selectInteractions('largeQR'))?.isOpen;
    const roomId = useAppSelector(selectRoomId());
    const phase = usePhase();
    const dispatch = useAppDispatch();
    if (!isOpen || !roomId || phase !== 'lobby') return null;
    return (
        <div
            className="qr-wrapper"
            onClick={() => dispatch(resetInteractions())}
        >
            <div className="qr-enlarged">
                <QRCode roomId={roomId} large />
                <h5>Klik om te verkleinen</h5>
            </div>
        </div>
    );
};
