import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    T_DatabaseGame,
    T_NormalQuestion,
    T_QuestionType,
    T_Quiz,
    T_QuizPhase,
    T_QuizState,
    T_Room,
    T_ServerRestoredGame,
    T_Stage,
    T_Team,
    T_TeamName,
    T_TeamResponse,
} from '../../../../globalTypes';
import {
    appStorage,
    arrayKeepNumbers,
    calculateAwardedPoints,
    calculateDecisionPoints,
    generateLocalTeams,
    getCurrentSectionInState,
    getNextStage,
    getQuestionFromQuiz,
    sectionIsQuestion,
    teamHasResponseToQuestion,
} from '../../functions';

import { getResponseByQuestionId } from 'src/functions/getters/getResponseByQuestionId';
import { calculateAwardedGuessPoints } from '../../functions/helpers/calculations/calculateAwardedGuessPoints';

const initialState: T_QuizState = {
    quiz: null,
    quizScrollIndex: 2,
    quizIsLocal: true,
    quizIsLocalChosen: false,
    quizInDecision: false,
    quizScrollDirection: 'right',
    quizPhase: 'preStart',
    teamMessage: '',
};
export const quizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        toggleConnection: state => {
            state.quizIsLocal = !state.quizIsLocal;
        },
        forceConnect: state => {
            state.quizIsLocal = false;
        },
        forceDisconnect: state => {
            state.quizIsLocal = true;
        },
        resetQuizState: () => {
            return initialState;
        },
        resetQuiz: state => {
            state.quiz = null;
        },
        resetTeams: state => {
            state.teams = [];
        },
        setRestoredGame: (
            state,
            action: PayloadAction<T_ServerRestoredGame>,
        ) => {
            const {
                id,
                quiz_id,
                quiz_is_local,
                team_count,
                module_id,
                section_id,
                quiz_phase,
                quiz,
                teams,
            } = action.payload;
            state.quiz = action.payload.quiz;
            state.selectedQuizId = quiz_id;
            state.selectedTeamId = 1;
            state.quizList = [quiz];
            state.quizIsLocal = quiz_is_local;
            state.teams = teams;
            state.teamCount = team_count;
            state.gameId = id;
            state.currentModuleId = module_id;
            state.currentSectionId = section_id;
            let newQuizPhase = quiz_phase;
            if (
                quiz_phase === 'question' ||
                (quiz_is_local && quiz_phase === 'questionResponse')
            ) {
                newQuizPhase = 'questionIntro';
            }
            if (
                quiz_phase === 'decisionQuestion' ||
                (quiz_is_local && quiz_phase === 'decisionResponse')
            ) {
                newQuizPhase = 'decision';
            }
            state.quizPhase = newQuizPhase;
        },
        setRoom: (state, action: PayloadAction<T_Room & { quiz: T_Quiz }>) => {
            state.quiz = action.payload.quiz;
            state.currentModuleId = action.payload.currentModuleId;
            state.currentSectionId = action.payload.currentSectionId;
            state.quizPhase = action.payload.quizPhase;
        },
        setTeams: (state, action: PayloadAction<T_Team[]>) => {
            state.teams = action.payload;
        },
        setQuizId: (state, action: PayloadAction<number | null>) => {
            state.selectedQuizId = action.payload;
        },
        setGameId: (state, action: PayloadAction<T_DatabaseGame>) => {
            const { id, session_id } = action.payload;
            state.gameId = id;
            state.sessionId = session_id;
            if (!id) return;
            appStorage.saveGameId(id);
            appStorage.saveSessionId(session_id ? session_id : 'null');
        },
        //Generates quiz, fills quizOrder in state. Not used locally.
        setQuiz: (state, action: PayloadAction<T_Quiz>) => {
            state.quiz = action.payload;
        },
        //Used to set the quiz locally after fetching the quizlist from server
        setQuizFromQuizId: state => {
            if (!state.quizList) return;
            const foundQuiz = state.quizList.find((quiz: T_Quiz) => {
                return quiz.id === state.selectedQuizId;
            });
            if (!foundQuiz) return;
            state.quiz = foundQuiz;
        },
        setQuizList: (
            state,
            action: PayloadAction<{ quizes: T_Quiz[]; messages: any }>,
        ) => {
            if (action.payload.messages.message) {
                state.teamMessage = action.payload.messages.message;
            }
            state.quizList = [...action.payload.quizes];
        },
        //Set a phase WITH an action
        setPhase: (state, action: PayloadAction<T_QuizPhase>) => {
            state.quizPhase = action.payload;
        },
        setStage: (state, action: PayloadAction<T_Stage>) => {
            const { moduleId, sectionId, phase } = action.payload;
            state.currentModuleId = moduleId;
            state.currentSectionId = sectionId;
            state.quizPhase = phase;
        },
        //Set a phase WITHOUT an action
        setNextStage: state => {
            if (
                !state.quiz ||
                !state.currentModuleId ||
                !state.currentSectionId ||
                !state.quizPhase
            )
                return;
            const newStage = getNextStage(state.quiz);
            if (!newStage) return;
            const { moduleId, sectionId, phase } = newStage;
            state.currentModuleId = moduleId;
            state.currentSectionId = sectionId;
            state.quizPhase = phase !== 'goNextSection' ? phase : 'quizIntro';
        },
        setTeamCount: (state, action: PayloadAction<number>) => {
            state.teamCount = action.payload;
        },
        setLocalTeams: state => {
            if (!state.teamCount) return;
            state.teams = generateLocalTeams(state.teamCount);
        },
        setSelectedTeamId: (state, action: PayloadAction<number>) => {
            state.selectedTeamId = action.payload;
            const { teams } = state;
            const currentQuestion = getCurrentSectionInState(state);
            if (state.quizPhase?.includes('decision')) {
                const foundResponse = state.teams?.find((team: T_Team) => {
                    return team.id === state.selectedTeamId;
                })?.decisionResponse;
                if (!foundResponse) {
                    state.rawTeamResponse = null;
                    return;
                }
                state.rawTeamResponse = {
                    type: 'Decision',
                    response: [foundResponse],
                };
                return;
            }
            if (
                !teams ||
                teams.length === 0 ||
                !currentQuestion?.type ||
                !sectionIsQuestion(currentQuestion)
            )
                return;
            const foundResponse = teams
                .find((team: T_Team) => team.id === action.payload)
                ?.responses.find(
                    (teamResponse: T_TeamResponse) =>
                        teamResponse.questionId === currentQuestion.questionId,
                );
            if (!state.quizIsLocal && currentQuestion.type === 'Guess') return;
            if (!foundResponse && foundResponse !== 0) {
                state.rawTeamResponse = null;
                return;
            }

            state.rawTeamResponse = {
                ...foundResponse,
                type: currentQuestion.type,
            };
        },
        //searches for a team without any responses, then sets that team as selectedTeamId.
        findAvailableTeamId: state => {
            if (!state.teams) return;
            const { quizInDecision, quizIsLocal } = state;
            let teams = state.teams;
            if (quizInDecision) {
                teams = state.teams?.filter((team: T_Team) => team.inDecision);
                const team = teams.find((team: T_Team) => {
                    return !teamHasResponseToQuestion({
                        team,
                        question: false,
                        decision: true,
                    });
                });
                state.selectedTeamId = team ? Number(team.id) : null;
                state.rawTeamResponse = null;
                return;
            }

            const currentQuestion = getCurrentSectionInState(state);
            if (!sectionIsQuestion(currentQuestion)) return;
            const newTeam = teams.find((team: T_Team) => {
                //This part is ensuring that in socket games a team is found that the lecturer still needs to check
                if (!quizIsLocal && currentQuestion?.type === 'Open') {
                    const response = getResponseByQuestionId({
                        team,
                        questionId: currentQuestion.questionId,
                    });
                    if (
                        response &&
                        typeof response === 'number' &&
                        response === -1
                    ) {
                        return true;
                    }
                }
                return !teamHasResponseToQuestion({
                    team,
                    question: currentQuestion,
                });
            });
            if (!newTeam) return;
            state.selectedTeamId = Number(newTeam.id);
            state.rawTeamResponse = null;
        },
        setRawTeamResponse: (
            state,
            action: PayloadAction<{
                type: T_QuestionType;
                response: string | number | boolean | (string | number)[];
            }>,
        ) => {
            const { type, response } = action.payload;
            const currentResponse = state.rawTeamResponse?.response;
            const { quizIsLocal } = state;

            if (state.selectedTeamId === null) return;
            if (!state.rawTeamResponse) {
                state.rawTeamResponse = { type, response: null };
            }
            switch (type) {
                case 'MPC':
                    if (typeof response !== 'number') return;
                    const currentQuestion = getCurrentSectionInState(state);
                    if (state.rawTeamResponse?.response) {
                        if (
                            sectionIsQuestion(currentQuestion) &&
                            currentQuestion.typeData &&
                            (currentQuestion as T_NormalQuestion<'MPC'>)
                                ?.typeData?.desiredAnswers === 1
                        ) {
                            state.rawTeamResponse.response = [response];
                            return;
                        }
                        if (Array.isArray(currentResponse)) {
                            const oldResponses = arrayKeepNumbers(
                                currentResponse,
                            );
                            if (oldResponses.includes(response)) {
                                state.rawTeamResponse = {
                                    type: 'MPC',
                                    response: oldResponses.filter(
                                        (_response: number) => {
                                            return response !== _response;
                                        },
                                    ),
                                };
                                return;
                            }
                            const desiredAnswers = (currentQuestion as T_NormalQuestion<
                                'MPC'
                            >)?.typeData?.desiredAnswers;
                            if (
                                sectionIsQuestion(currentQuestion) &&
                                desiredAnswers &&
                                oldResponses.length >= desiredAnswers
                            )
                                return;
                            state.rawTeamResponse = {
                                type: 'MPC',
                                response: [...oldResponses, response],
                            };
                        }
                        return;
                    }
                    state.rawTeamResponse.response = [response];
                    return;
                case 'Start':
                case 'Decision':
                case 'Guess': {
                    if (typeof response !== 'number') return;
                    state.rawTeamResponse.response = [response];
                    return;
                }
                case 'Open': {
                    if (quizIsLocal) return;
                    if (typeof response !== 'string') return;
                    state.rawTeamResponse.response = [response];
                    return;
                }
                case 'Closed':
                case 'Thesis': {
                    if (quizIsLocal) return;
                    if (typeof response !== 'number') return;
                    state.rawTeamResponse.response = response;
                }
            }
        },
        resetRawTeamResponse: state => {
            state.rawTeamResponse = null;
        },
        setTeamResponse: (
            state,
            action: PayloadAction<{
                response: Omit<
                    T_TeamResponse,
                    'teamId' | 'questionId' | 'sectionId'
                > & {
                    questionId?: number;
                    sectionId?: number;
                    teamId?: number;
                };
                asTeamUpdate?: boolean;
            }>,
        ) => {
            const quiz = state.quiz;
            const newResponse = action.payload.response;
            const { asTeamUpdate } = action.payload;
            const teamUpdateId = action.payload.response.teamId;
            const currentQuestion =
                (quiz &&
                    asTeamUpdate &&
                    newResponse.questionId &&
                    getQuestionFromQuiz(quiz, newResponse.questionId)) ||
                getCurrentSectionInState(state);
            if (
                !state.teams ||
                !currentQuestion ||
                !sectionIsQuestion(currentQuestion) ||
                (asTeamUpdate && !teamUpdateId)
            )
                return;
            let teamIndex: number | null = null;
            let teamId: number | undefined | null;
            if (!asTeamUpdate) {
                teamId = state.selectedTeamId;
                teamIndex = state.teams.findIndex(
                    (team: T_Team) => team.id === teamId,
                );
            }
            if (asTeamUpdate) {
                teamIndex = state.teams.findIndex(
                    (team: T_Team) => team.id === teamUpdateId,
                );
                teamId = teamUpdateId;
            }

            if (!teamIndex && teamIndex !== 0) return;
            const foundResponse = state.teams[teamIndex].responses.find(
                (_response: T_TeamResponse) => {
                    if (asTeamUpdate) {
                        return _response.questionId === newResponse.questionId;
                    }
                    return _response.questionId === currentQuestion.questionId;
                },
            );
            //In socket version, store the strings seperately for open questions
            if (
                state.quizIsLocal === false &&
                currentQuestion.type === 'Open' &&
                typeof newResponse.response !== 'number'
            ) {
                state.teams[teamIndex] = {
                    ...state.teams[teamIndex],
                    responses: [
                        ...state.teams[teamIndex].responses,
                        {
                            ...newResponse,
                            response: -1,
                            teamId: teamUpdateId || teamId || 0,
                            questionId: currentQuestion.questionId,
                            sectionId: currentQuestion.sectionId,
                            points: 0,
                            inputAnswers: newResponse.response.toString(),
                            isCorrect: 'NO',
                        },
                    ],
                };
                return;
            }
            //If there is no response, we just add the responses to the responses of that team
            if (!foundResponse) {
                const { points, isCorrect } = calculateAwardedPoints(
                    currentQuestion,
                    newResponse,
                );
                state.teams[teamIndex] = {
                    ...state.teams[teamIndex],
                    responses: [
                        ...state.teams[teamIndex].responses,
                        {
                            ...newResponse,
                            teamId: teamId || teamUpdateId || 0,
                            questionId: currentQuestion.questionId,
                            sectionId: currentQuestion.sectionId,
                            points,
                            isCorrect,
                        },
                    ],
                };
            }
            //For open questions we need to remember the inputAnswers too
            if (
                !state.quizIsLocal &&
                foundResponse &&
                currentQuestion.type === 'Open'
            ) {
                state.teams[teamIndex].responses = state.teams[
                    teamIndex
                ].responses.map((_response: T_TeamResponse) => {
                    if (_response.questionId === currentQuestion.questionId) {
                        const { points, isCorrect } = calculateAwardedPoints(
                            currentQuestion,
                            newResponse,
                        );
                        return {
                            ...newResponse,
                            teamId: teamUpdateId || teamId || 0,
                            questionId: currentQuestion.questionId,
                            sectionId: currentQuestion.sectionId,
                            points,
                            isCorrect,
                            inputAnswers: foundResponse.inputAnswers,
                        };
                    }
                    return _response;
                });
                return;
            }
            //If there is a response, we update the response of that team

            if (foundResponse) {
                state.teams[teamIndex].responses = state.teams[
                    teamIndex
                ].responses.map((_response: T_TeamResponse) => {
                    if (
                        !asTeamUpdate &&
                        _response.questionId === currentQuestion.questionId
                    ) {
                        const { points, isCorrect } = calculateAwardedPoints(
                            currentQuestion,
                            newResponse,
                        );
                        return {
                            ...newResponse,
                            teamId: teamId || 0,
                            questionId: currentQuestion.questionId,
                            sectionId: currentQuestion.sectionId,
                            points,
                            isCorrect,
                        };
                    }
                    if (
                        asTeamUpdate &&
                        _response.questionId === newResponse.questionId
                    ) {
                        const { points, isCorrect } = calculateAwardedPoints(
                            currentQuestion,
                            newResponse,
                        );
                        return {
                            ...newResponse,
                            teamId: teamUpdateId || teamId || 0,
                            questionId: newResponse.questionId,
                            sectionId: newResponse.sectionId || 0,
                            points,
                            isCorrect,
                        };
                    }
                    return _response;
                });
            }

            //If the question is a guess question, we need to compare the responses of every team to each other
            if (currentQuestion.type === 'Guess') {
                state.teams = calculateAwardedGuessPoints(
                    currentQuestion,
                    state.teams,
                );
            }
        },
        setTeamDecision: (state, action: PayloadAction<number[]>) => {
            const teamIds = action.payload;
            state.teams = state.teams?.map(
                (team: T_Team): T_Team => {
                    if (teamIds.includes(team.id)) {
                        return {
                            ...team,
                            inDecision: true,
                            wonDecision: false,
                            decisionResponse: 0,
                        };
                    }
                    return team;
                },
            );
            state.quizInDecision = true;
        },
        setTeamDecisionResponse: (
            state,
            action: PayloadAction<{ response: number; teamId?: number }>,
        ) => {
            let teamId: number | null | undefined = action.payload.teamId;
            if (state.quizIsLocal) {
                teamId = state.selectedTeamId;
            }
            if (!teamId) return;
            const decisionQuestion = state.quiz?.decisionQuestion;
            const newTeams = state.teams?.map(
                (team: T_Team): T_Team => {
                    if (team.id === teamId) {
                        return {
                            ...team,
                            decisionResponse: action.payload.response,
                        };
                    }
                    return team;
                },
            );
            if (!newTeams || !decisionQuestion) return;
            state.teams = calculateDecisionPoints(newTeams, decisionQuestion);
        },
        setTeamDecisionWinner: (state, action: PayloadAction<number>) => {
            if (!state.teams) return;
            const teams = [...state.teams];
            state.teams = teams.map((team: T_Team) => {
                if (team.id === action.payload) {
                    return {
                        ...team,
                        wonDecision: true,
                    };
                }
                return { ...team, wonDecision: false };
            });
        },
        setNameTaken: (
            state,
            action: PayloadAction<{ teamId: number; nameId: number }>,
        ) => {
            const { teamId, nameId } = action.payload;
            state.teams?.map((team: T_Team) => {
                if (team.id === teamId) {
                    return {
                        ...team,
                        availableNames: team.sentTeamNames?.filter(
                            (teamName: T_TeamName) => teamName.id !== nameId,
                        ),
                    };
                }
            });
        },
        setQuizIsLocalChosen: (state, action: PayloadAction<boolean>) => {
            state.quizIsLocalChosen = action.payload;
        },
    },
});

export const {
    forceConnect,
    forceDisconnect,
    setQuiz,
    setQuizId,
    setQuizFromQuizId,
    setQuizList,
    setGameId,
    setPhase,
    setNextStage,
    setRestoredGame,
    resetQuizState,
    resetQuiz,
    resetTeams,
    toggleConnection,
    setLocalTeams,
    setTeamCount,
    setTeams,
    setRoom,
    setTeamResponse,
    setStage,
    setSelectedTeamId,
    setRawTeamResponse,
    setQuizIsLocalChosen,
    resetRawTeamResponse,
    findAvailableTeamId,
    setTeamDecision,
    setTeamDecisionResponse,
    setTeamDecisionWinner,
} = quizSlice.actions;

export default quizSlice.reducer;
