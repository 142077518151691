import { T_AppDispatch } from '..';
import { T_RawState, T_Stage } from '../../../../globalTypes';
import { handlePostRequest } from './handlePostRequest';

export const postStage = (stage: T_Stage) => async (
    dispatch: T_AppDispatch,
    getState: () => T_RawState,
) => {
    try {
        const gameId = getState().quizState?.gameId;
        dispatch(
            handlePostRequest({
                postData: { stage },
                endPoint: `/games/setStage/${gameId}`,
            }),
        );
    } catch (error) {
        console.log(error);
    }
};
