import { useTeams } from 'src/hooks';
import { T_Team } from '../../../../globalTypes';

export const DevShowScore = () => {
    const teams = useTeams();
    return (
        <div
            className="absolute"
            style={{
                padding: '5rem',
                width: '100vw',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.9)',
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '10%',
                }}
            >
                {teams?.map(team => {
                    return <Team team={team} />;
                })}
            </div>
        </div>
    );
};

const Team = ({ team }: { team: T_Team }) => {
    return (
        <div
            style={{
                width: '20%',
            }}
        >
            <h2>{team.id}</h2>
            {team.responses.map((response, index) => {
                return (
                    <div key={index}>
                        <p>{response.points}</p>
                    </div>
                );
            })}
        </div>
    );
};
