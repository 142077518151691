import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'src/components';
import { DevPanel } from 'src/components/dev/DevPanel';
import { useAppDispatch, usePhase } from 'src/hooks';
import { CentralWrapper } from 'src/layout/styledcomponents';
import { getQuizById } from 'src/store';
import { handlePreview } from 'src/store/thunks/handlePreview';
import { RenderedQuizPage } from './quizPage/RenderedQuizPage';

export const PreviewPage = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const phase = usePhase();
    const [previewAccepted, setPreviewAccepted] = useState(false);
    const {quizId, moduleId, sectionId} = params
    useEffect(() => {
        dispatch(getQuizById(Number(quizId)));
        if (!quizId || !previewAccepted) return;
        dispatch(
            handlePreview({
                quizId: Number(quizId),
                moduleId: Number(moduleId),
                sectionId: Number(sectionId),
            }),
        );
    }, [previewAccepted]);
    if (!previewAccepted) {
        return (
            <Button
                onClick={() => {
                    setPreviewAccepted(true);
                }}
                buttonType="primary"
                text="Bekijk voorbeeld"
            />
        );
    }
    return (
        <CentralWrapper enableAutoAnimation={true}>
            <DevPanel />
            <RenderedQuizPage phase={phase} />
        </CentralWrapper>
    );
};
