import { useDispatch } from 'react-redux';
import { castEvent } from 'src/functions';
import { T_ClientToServerEndPoints } from '../../../../globalTypes';
import { useSocket } from '../socket/useSocket';

export const useEvent = ({
    endPoint,
    reducer,
    payload,
    socketPayload,
    reducerPayload,
}: {
    endPoint: T_ClientToServerEndPoints | null;
    reducer: Function | null;
    payload?: any;
    socketPayload?: any;
    reducerPayload?: any;
}) => {
    const socket = useSocket();
    const dispatch = useDispatch();
    return () =>
        castEvent({
            endPoint,
            reducer,
            payload,
            socketPayload,
            reducerPayload,
            socket,
            dispatch,
        });
};
