import { getThemeColor } from 'src/functions';
import styled from 'styled-components';

export const Divider = () => {
    return <StyledDivider className="h-full w-1" />;
};

const StyledDivider = styled.div`
    background-color: ${props =>
        getThemeColor({
          theme: props.theme,
          themeType: 'colors',
          themeColor: 'primary',
        })};
`;
