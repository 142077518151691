import { T_Team } from '../../../../globalTypes';

export const getLowestDecisionTime = (teams: T_Team[]) => {
    let lowestTime = Infinity;
    let lowestIndex = -1;
    for (let i = 0; i < teams.length; i++) {
        const { inDecision, decisionTime } = teams[i];
        if (inDecision && decisionTime && decisionTime < lowestTime) {
            lowestTime = decisionTime;
            lowestIndex = i;
        }
    }
    return teams[lowestIndex];
};
