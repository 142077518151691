import { useEffect, useState } from 'react';
import { MainTitle } from 'src/components/base/typography/MainTitle';
import { outroTitles } from 'src/config/ui/outroTitles';
import {
    getRandomArrayEntry,
    renderText,
    sectionIsQuestion,
} from 'src/functions';
import { useAppDispatch, useOutroText, useSection, useSocket } from 'src/hooks';
import { InfoText } from '../../../../components';
import { castNextEvent } from '../../../../functions/events/castNextEvent';
import { QuestionTextWrapper } from './QuestionTextWrapper';

export const QuestionOutroPage = () => {
    const [outroTitle, setOutroTitle] = useState<{
        text: string;
        underlined: string;
    }>(outroTitles[0]);
    const currentSection = useSection();
    const socket = useSocket();
    const dispatch = useAppDispatch();
    const outroText = useOutroText({ section: currentSection });
    useEffect(() => {
        const randomTitle = getRandomArrayEntry(outroTitles);
        setOutroTitle(randomTitle);
    }, []);
    if (!currentSection || !sectionIsQuestion(currentSection)) {
        castNextEvent({ socket, dispatch });
        return null;
    }

    return (
        <QuestionTextWrapper wide={currentSection?.outroTextWide}>
            <MainTitle
                text={outroTitle.text}
                underlined={outroTitle.underlined}
            />
            {outroText && (
                <InfoText
                    textSize={currentSection.outroTextSize}
                    text={renderText({ text: outroText })}
                />
            )}
        </QuestionTextWrapper>
    );
};
