import classNames from 'classnames';
import { motion } from 'framer-motion';
import { QuestionResponseWrapper } from 'src/components/quiz/questionResponsePage/ResponseSection';
import { ResponseQuestion } from 'src/components/quiz/questionResponsePage/ResponseSection/ResponseQuestion';
import { selectSelectedTeamId } from 'src/store';
import styled from 'styled-components';
import { T_QuestionType, T_QuizPhase } from '../../../../../../globalTypes';
import { ResponseSection } from '../../../../components';
import { getThemeColor } from '../../../../functions';
import { sectionIsQuestion } from '../../../../functions/checkers/sectionIsQuestion';
import {
    useAppSelector,
    useDecisionQuestion,
    useSection,
} from '../../../../hooks';
import './style.scss';

//Component used mainly in local version, allows guest lecturer to enter the responses from all teams. Also used for decision questions.
export const QuestionResponsePage = ({
    phase,
}: {
    phase: 'questionResponse' | 'decisionResponse';
}) => {
    const inDecision = phase.includes('decision');
    const currentQuestion = useSection();
    const decisionQuestion = useDecisionQuestion();
    const selectedTeamId = useAppSelector(selectSelectedTeamId());
    const selectedTeam = useAppSelector(state =>
        state.quizState?.teams?.find(team => team.id === selectedTeamId),
    );
    return (
        <QuestionResponseWrapper
            phase={phase}
            questionType={currentQuestion?.type || 'Guess'}
            questionTextWide={
                (currentQuestion &&
                    'questionTextWide' in currentQuestion &&
                    currentQuestion?.questionTextWide) ||
                false
            }
        >
            <ResponseQuestion
                selectedTeam={selectedTeam}
                question={currentQuestion}
            />
            <StyledResponseWrapper
                phase={phase}
                type={
                    sectionIsQuestion(currentQuestion) && currentQuestion?.type
                        ? currentQuestion?.type
                        : phase === 'decisionResponse'
                        ? 'Guess'
                        : undefined
                }
                className={classNames('question-response-responses-wrapper', {
                    open:
                        phase === 'questionResponse' &&
                        currentQuestion?.type === 'Open',
                    guess:
                        phase === 'questionResponse' &&
                        currentQuestion?.type === 'Guess',
                })}
            >
                {!inDecision &&
                    currentQuestion &&
                    sectionIsQuestion(currentQuestion) && (
                        <ResponseSection question={currentQuestion} />
                    )}
                {inDecision && decisionQuestion && (
                    <ResponseSection
                        question={decisionQuestion}
                        inDecision={true}
                    />
                )}
            </StyledResponseWrapper>
        </QuestionResponseWrapper>
    );
};

export const StyledResponseWrapper = styled(motion.div)<{
    phase?: T_QuizPhase;
    type?: T_QuestionType;
}>`
    //overflow: hidden;
    background: ${props => {
        if (props.type === 'Guess') {
            return getThemeColor({
                theme: props.theme,
                themeType: 'colors',
                themeColor: 'white',
            });
        }
    }};
    border: ${props => {
        if (props.type === 'Guess') {
            return `1px solid ${getThemeColor({
                theme: props.theme,
                themeType: 'colors',
                themeColor: 'primary',
            })}`;
        }
    }};
    box-shadow: ${props => {
        if (props.type === 'Guess') {
            return `1px solid ${getThemeColor({
                theme: props.theme,
                themeType: 'boxShadows',
                themeColor: 'primary',
            })}`;
        }
    }};
    border-radius: 8px;
    height: ${props => {
        if (props.phase !== 'questionResponse' && props.type !== 'Open')
            return '';
        switch (props.type) {
            default:
                return '';
        }
    }};
    max-width: ${props => {
        if (
            (props.phase === 'questionResponse' && props.type === 'Guess') ||
            props.phase === 'decisionResponse'
        ) {
            return '423px';
        }
        return '';
    }};
    padding: ${props => {
        if (props.phase === 'decisionResponse') {
            return '24px';
        }
        return '';
    }};
`;
