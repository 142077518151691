import { Router } from '../../router/Router';
import { SocketReceiver } from '../../socket/SocketReceiver';

export const TeamRolePage = () => {
    return (
        <>
            <SocketReceiver />
            <Router />
        </>
    );
};
