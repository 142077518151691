import React from 'react';
import { getThemeColor } from 'src/functions';
import styled from 'styled-components';
import { T_Phase } from '../../../../../../globalTypes';
import { usePhase } from '../../../../hooks';

export const PrimaryText = ({
    children,
    className,
    type,
    quizIsLocal,
}: {
    children?: React.ReactNode;
    className?: string;
    type?: 'span' | 'h3' | 'h4' | 'h2';
    quizIsLocal?: boolean; //in fact only relevant for the local lobbyPage
}) => {
    const phase = usePhase();
    switch (type) {
        case 'h2':
            return (
                <>
                    <StyledH2
                        quizIsLocal={quizIsLocal}
                        phase={phase}
                        className={className}
                    >
                        {children}
                    </StyledH2>
                </>
            );
        case 'h3':
            return (
                <>
                    <StyledH3
                        quizIsLocal={quizIsLocal}
                        phase={phase}
                        className={className}
                    >
                        {children}
                    </StyledH3>
                </>
            );
        case 'h4':
            return (
                <>
                    <StyledH4
                        quizIsLocal={quizIsLocal}
                        phase={phase}
                        className={className}
                    >
                        {children}
                    </StyledH4>
                </>
            );
        case 'span':
        default:
            return (
                <>
                    <StyledSpan
                        quizIsLocal={quizIsLocal}
                        phase={phase}
                        className={className}
                    >
                        {children}
                    </StyledSpan>
                </>
            );
    }
};

const StyledH4 = styled.h4<{
    phase: T_Phase | undefined;
    quizIsLocal?: boolean;
}>`
    color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeColor: 'primary',
        })};
`;

const StyledH3 = styled.h3<{
    phase: T_Phase | undefined;
    quizIsLocal?: boolean;
}>`
    color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeColor: 'primary',
        })};
`;

const StyledH2 = styled.h2<{
    phase: T_Phase | undefined;
    quizIsLocal?: boolean;
}>`
    color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeColor: 'primary',
        })};
`;

const StyledSpan = styled.span<{
    phase: T_Phase | undefined;
    quizIsLocal?: boolean;
}>`
    color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeColor: 'primary',
        })};
`;
