import { T_Question, T_Team, T_TeamResponse } from '../../../../globalTypes';
export const getTeamResultInPoints = ({
    team,
    question,
}: {
    team: T_Team;
    question: T_Question;
}) => {
    const { responses } = team;
    const { questionId } = question;
    const result = responses.find(
        (response: T_TeamResponse) => response.questionId === questionId,
    );
    if (result) {
        return result.points;
    }
    return null;
};
