import { useRef } from 'react';
import { T_NormalQuestion } from '../../../../globalTypes';
import { renderText } from '../../functions';
import { useAppDispatch, usePopUp, useSection } from '../../hooks';
import { resetInteractions } from '../../store';
import { PopScreenWrapper } from './popScreens/PopScreenWrapper';

export const PopUp = () => {
    const dispatch = useAppDispatch();
    const { isOpen, text } = usePopUp();
    let question = useSection<T_NormalQuestion<'Open'>>();
    const isOpenQuestion = question?.type === 'Open';
    const wrapperRef = useRef(null);

    if (!isOpen || !text || question.type !== 'Open') return null;

    return (
        <>
            <PopScreenWrapper>
                <div className="wrapper m absolute-popup" ref={wrapperRef}>
                    <span
                        className="close"
                        onClick={() => dispatch(resetInteractions())}
                    >
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                                fill="#334D56"
                            />
                        </svg>
                    </span>
                    <div className="text-wrapper">
                        <h2>Mogelijke antwoorden zijn </h2>
                        {isOpenQuestion &&
                            question?.typeData?.openAnswers &&
                            renderText({
                                text: question.typeData?.openAnswers,
                            })}
                    </div>
                </div>
            </PopScreenWrapper>
        </>
    );
};
