import { VideoPlayer } from 'src/components/video/VideoPlayer';
import { useSection } from 'src/hooks';
import { T_Slide } from '../../../../../../globalTypes';
import { Image } from '../../../../components/base/Image';
import { PrimaryText } from '../../../../layout/styledcomponents/wrappers/typography/PrimaryText';

export const SlidePage = () => {
    const currentSlide = useSection();
    if (currentSlide?.sectionType !== 'Slide') {
        return null;
    }

    return (
        <>
            <PrimaryText>{currentSlide.title}</PrimaryText>
            <RenderedSlide slide={currentSlide} />
        </>
    );
};

export const RenderedSlide = (props: { slide: T_Slide }) => {
    const { slide } = props;
    switch (slide.type) {
        case 'Image':
            if (!slide.imageURL) return null;
            return <Image imageURL={slide.imageURL} />;
        case 'Video':
            if (!slide.videoURL) return null;
            return <VideoPlayer url={slide.videoURL} />;
        case 'Text':
            return <>{slide.text}</>;
        default:
            return null;
    }
};
