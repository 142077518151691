import classNames from 'classnames';

export const QuestionTextWrapper = ({
    children,
    wide,
}: {
    children: React.ReactNode;
    wide?: boolean | null;
}) => {
    return (
        <div
            className={classNames('question-text-wrapper', {
                medium: !wide,
                wide: wide,
            })}
        >
            {children}
        </div>
    );
};
