import { T_Question, T_Team } from '../../../../globalTypes';
import { teamHasResponseToQuestion } from './teamHasResponseToQuestion';

export const allTeamsHaveResponded = ({
    teams,
    currentQuestion,
    decision,
}: {
    teams: T_Team[];
    currentQuestion: T_Question;
    decision?: boolean;
}): boolean => {
    const foundTeam = teams.find((team: T_Team) => {
        return !teamHasResponseToQuestion({
            team,
            question: currentQuestion,
            decision,
        });
    });
    if (foundTeam) return false;
    return true;
};
