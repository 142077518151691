import { createSlice } from '@reduxjs/toolkit';
import { playSound } from 'src/howler/playSound';
import { T_TimerState } from '../../../../globalTypes';

const initialState: T_TimerState = {
    timer: 0,
    timeActive: false,
};

export const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        setTime: (state, action) => {
            state.timer = action.payload;
            state.timeActive = true;
        },
        decreaseTime: state => {
            if (state.timer === 5) {
                playSound({
                    src: 'bleep',
                });
            }
            state.timer -= 1;
        },
        resetTime: state => {
            state.timer = 0;
            state.timeActive = true;
        },
        continueTime: state => {
            state.timeActive = true;
        },
        pauseTime: state => {
            state.timeActive = false;
        },
    },
});

export const {
    setTime,
    resetTime,
    decreaseTime,
    continueTime,
    pauseTime,
} = timerSlice.actions;

export default timerSlice.reducer;
