import { T_AppDispatch, restoreGame } from '..';
import { T_RawState, T_ServerRestoredGame } from '../../../../globalTypes';
import { handleGetRequest } from './handleGetRequest';

export const getRestoredGameById = (id: string, sessionId: string) => async (
    dispatch: T_AppDispatch,
    getState: () => T_RawState,
) => {
    try {
        await dispatch(
            handleGetRequest<T_ServerRestoredGame>(
                `/games/restore/${id}/${sessionId}`,
                restoreGame,
            ),
        );
    } catch (error) {
        console.log(error);
    }
};
