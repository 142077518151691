import {
    T_Lecture,
    T_NoCodeLecture,
    T_SchoolData,
} from '../../../../globalTypes';

export const getLectureFromSchoolList = ({
    id,
    schoolList,
}: {
    id: number | null;
    schoolList: T_SchoolData[];
}): T_Lecture | T_NoCodeLecture | null => {
    let foundLecture: T_Lecture | null | T_NoCodeLecture = null;
    if (!id && id !== 0) return foundLecture;
    schoolList.map(value => {
        value.lectures?.map(value => {
            if (value.id === id) {
                foundLecture = value;
            }
        });
    });
    return foundLecture;
};
