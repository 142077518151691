import { useEffect } from 'react';
import { drawTeamNoScreenText } from 'src/functions/ui/drawers/drawTeamNoScreenText';
import { useAppDispatch, usePhase } from '../../../hooks';
import { resetRawTeamResponse } from '../../../store';

//screen used when players should look at the host screen
export const TeamNoScreenPage = () => {
    const phase = usePhase();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(resetRawTeamResponse());
    }, []);
    return (
        <>
            <span className="hero-text">{drawTeamNoScreenText({ phase })}</span>
        </>
    );
};
