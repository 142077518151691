import { UseFormRegister } from 'react-hook-form';
import type { DataFromFormNoCode, InputType, T_InputFields } from '../NoCodeStartPage';
import { T_InputField } from '../NoCodeStartPage';
import { InputField } from './InputField';
import { SelectionField } from './SelectionField';

//KEK TYPE ABUSE

export type T_InputFieldProps<T extends InputType> = {
    field: T_InputField<T>
    fieldKey: keyof T_InputFields
    register: UseFormRegister<DataFromFormNoCode>;
    error?: string;
    tabIndex?: number;
    setter?: (e: string) => void
};

export const EntryField = ({
    field,
    ...props
}: T_InputFieldProps<InputType>) => {
    switch (field.type) {
        case 'input':
            return (
                <InputField
                    field={field}
                    {...props}
                />
            );
        case 'select':
            return (
                <>
                    <SelectionField
                        field={field as T_InputFieldProps<InputType.select>['field']}
                        {...props}
                    />
                </>
            );
        default:
            return null;
    }
};
