export const joinMessages: string[] = [
    '[$Team] [$verb] er klaar voor.',
    'Welkom [$teamNoArt]',
    'Daar [$verb] [$team]!',
    '[$Team] [$verb] klaar voor de vragen.',
    'Hallo, [$teamNoArt].',
    '[$Team] in the building.',
    '[$Team] [$verb] on!',
    '[$Team] [$verb] erbij.',
];
