//takes a hexcode and returns a darker shade of this same hexcode
export const getHexShadow = (
    hex?: string,
    percent?: number,
): string | undefined => {
    let percentage = percent || 25;
    if (!hex) return undefined;
    // Ensure the percent value is within the range of 0 to 100
    percent = Math.min(100, Math.max(0, percentage));

    // Remove any leading '#' character if present
    hex = hex.replace(/^#/, '');

    // Convert the hex code to RGB
    const bigint = parseInt(hex, 16);
    const red = (bigint >> 16) & 255;
    const green = (bigint >> 8) & 255;
    const blue = bigint & 255;

    // Calculate the darker shades by reducing each RGB component
    const darkRed = Math.floor(red * (1 - percent / 100));
    const darkGreen = Math.floor(green * (1 - percent / 100));
    const darkBlue = Math.floor(blue * (1 - percent / 100));

    // Convert the darker RGB components back to hex
    const darkHex = `#${((darkRed << 16) | (darkGreen << 8) | darkBlue)
        .toString(16)
        .padStart(6, '0')}`;

    return darkHex;
};
