import { useAppSelector } from 'src/hooks';
import { TeamModal } from './TeamModal';

export const TeamModals = () => {
    const modals = useAppSelector(state => state.socketState.modals);
    if (modals.length === 0) return null;
    return (
        <div className="flex flex-col-reverse justify-end items-center absolute bottom-0 left-0">
            {modals.map(modal => {
                return <TeamModal modal={modal} key={modal.id} />;
            })}
        </div>
    );
};
