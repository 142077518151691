import classNames from 'classnames';
import { Button } from 'src/components/base';

export const AdjustIndexButton = ({
    increaseIndex,
    decreaseIndex,
    className,
    text,
}: {
    increaseIndex?: (() => void) | null;
    decreaseIndex?: (() => void) | null;
    className?: string;
    text: string;
}) => {
    return (
        <Button
            text={text}
            buttonType="primary"
            className={classNames('adjustIndexButton', className)}
            onClick={() => {
                if (increaseIndex) increaseIndex();
                if (decreaseIndex) decreaseIndex();
            }}
        />
    );
};
