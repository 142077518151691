import {
    getQuestionCountById,
    getThesisCountById,
    getQuizCounts,
    sectionIsQuestion,
} from 'src/functions';
import { useQuiz } from './useQuiz';
import { useSection } from './useSection';

export const useQuestionCount = () => {
    const quiz = useQuiz();
    const currentQuestion = useSection();
    if (!quiz || !currentQuestion || !sectionIsQuestion(currentQuestion))
        return { currentQuestionCount: 0, totalQuestionCount: 0, thesisCount: 0 };
    const { totalQuestionCount, thesisCount } = getQuizCounts(quiz);
    const currentQuestionCount = getQuestionCountById(
        quiz,
        currentQuestion.questionId,
    );
    const currentTheisCount = getThesisCountById(
        quiz,
        currentQuestion.questionId,
    );
    return { currentQuestionCount, totalQuestionCount, thesisCount,currentTheisCount };
};
