import { useAppSelector, useQuizIsLocal } from '../../../hooks';
import { LocalLobby, SocketLobby } from '../lobby';

//Lobby, place where lecturer is able to change settings.
export const LobbyPage = () => {
    const quizIsLocal = useQuizIsLocal();
    const teamMessage = useAppSelector(state => state.quizState.teamMessage);
    if (quizIsLocal) {
        return <LocalLobby teamMessage={teamMessage} />;
    }
    return <SocketLobby />;
};
