import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListScroll } from 'src/components/base/list';
import { schoolLevelList } from 'src/config/constants/lists';
import { isValidSchoolLevel } from 'src/functions/checkers/isValidSchoolLevel';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useSchoolLevel } from 'src/hooks/menu/useSchoolLevel';
import {
    selectActiveLectureLevelAsNumber,
    setActiveLectureLevel,
    setActiveLectureLevelFromNumber,
    setLectureIsTrial, setLectureIsWithoutCode,
    setPhase,
} from 'src/store';
import { T_SchoolLevel } from '../../../../../globalTypes';

export const TrialSelectionPage = () => {
    const level = useSchoolLevel();
    const dispatch = useAppDispatch();
    const lectureIsTrial = useAppSelector(
        state => state.appState.lectureIsTrial,
    );
    const navigate = useNavigate();
    useEffect(() => {
        if (!lectureIsTrial) {
            dispatch(setPhase("trialStart"))
            dispatch(setLectureIsTrial(true));
            dispatch(setLectureIsWithoutCode(true));
            //navigate('/');
        }
        if (!isValidSchoolLevel(level)) {
            dispatch(setActiveLectureLevel(null));
            return;
        }
        dispatch(setActiveLectureLevel(level?.toUpperCase() as T_SchoolLevel));
    }, [lectureIsTrial]);

    return (
        <ListScroll
            cardSelector={selectActiveLectureLevelAsNumber}
            reducer={setActiveLectureLevelFromNumber}
            list={schoolLevelList}
            heading={{
                text: 'Voor welk niveau wordt de quiz gegeven?',
                underlined: 'niveau',
            }}
        />
    );
};
