import React from 'react';
import styled from 'styled-components';
import { T_Phase } from '../../../../../../globalTypes';
import { getThemeColor } from '../../../../functions/ui/getters/getThemeColor';
import { usePhase } from '../../../../hooks';

export const HoleCircle = ({
    children,
    quizIsLocal,
}: {
    children?: React.ReactNode;
    quizIsLocal?: boolean;
}) => {
    const phase = usePhase();
    return (
        <Circle quizIsLocal={quizIsLocal ? true : false} phase={phase}>
            {children}
        </Circle>
    );
};

const Circle = styled.div<{
    phase: T_Phase | undefined;
    quizIsLocal: boolean;
}>`
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    transform: translate(50%, -50%);
    border-radius: 50%;
    background-color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'primary',
        })};
`;
