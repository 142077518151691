import { T_NormalQuestion } from '../../../../../globalTypes';
export const calculatePointsPerAnswer = (
    question: T_NormalQuestion<'MPC' | 'Open'>,
): number => {
    let awardedPointsPerAnswer = 1;
    const { pointsPerAnswer, points, typeData } = question;
    if (pointsPerAnswer) {
        awardedPointsPerAnswer = pointsPerAnswer;
    }
    if (!pointsPerAnswer && points && typeData?.desiredAnswers) {
        awardedPointsPerAnswer = points / typeData.desiredAnswers;
    }
    return Math.ceil(awardedPointsPerAnswer);
};
