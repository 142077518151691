import {
    T_AppDispatch,
    setSelectedTeamId,
    setTeamDecisionResponse,
    setTeamResponse,
} from '..';
import {
    T_RawState,
    T_RawTeamResponse,
    T_ServerRawTeamResponse,
} from '../../../../globalTypes';
import { getCurrentSection, sectionIsQuestion } from '../../functions';

//redux thunk to post an answer to the server &&
//to convert a rawTeamResponse to a teamResponse &&
//to store this teamResponse in the state
export const confirmTeamResponse = (
    socketData?: T_ServerRawTeamResponse & { teamId: number },
) => async (dispatch: T_AppDispatch, getState: () => T_RawState) => {
    const currentQuestion = getCurrentSection();
    const currentPhase = getState().quizState?.quizPhase;

    let rawTeamResponse: T_RawTeamResponse | null | undefined = null;
    if (!socketData) {
        rawTeamResponse = getState().quizState?.rawTeamResponse;
    }
    if (socketData) {
        dispatch(setSelectedTeamId(socketData.teamId));
    }
    if (socketData && sectionIsQuestion(currentQuestion)) {
        rawTeamResponse = {
            response: socketData.response,
            type: currentQuestion.type,
        };
    }
    if (currentPhase?.includes('decision')) {
        if (!rawTeamResponse || !Array.isArray(rawTeamResponse.response))
            return;
        const [decisionResponse] = rawTeamResponse.response;
        if (typeof decisionResponse !== 'number') return;
        dispatch(setTeamDecisionResponse({ response: decisionResponse }));
        return;
    }
    if (!currentQuestion || !rawTeamResponse || !rawTeamResponse.type) return;
    if (currentQuestion.type !== rawTeamResponse.type) return;
    const { response } = rawTeamResponse;
    if (!response) return;
    dispatch(
        setTeamResponse({
            response: {
                response: response,
                questionType: currentQuestion.type,
            },
        }),
    );
};
