import { wait } from 'src/functions/helpers/wait';
import { setTeamResponse, setTeams } from 'src/store';
import { T_Team, T_TeamResponse } from '../../../../globalTypes';
import { useAppDispatch } from '../redux';

export const useTeamsUpdate = () => {
    const dispatch = useAppDispatch();
    return async (teams: T_Team[]) => {
        dispatch(setTeams(teams));
        await wait(50);
        teams.map((team: T_Team) => {
            team.responses.map((response: T_TeamResponse) => {
                dispatch(
                    setTeamResponse({
                        response: { ...response, teamId: team.id },
                        asTeamUpdate: true,
                    }),
                );
            });
        });
        teams.map((team: T_Team) => {
            team.responses.map((response: any) => {
                if (response.type === 'Guess') {
                    dispatch(
                        setTeamResponse({
                            response: { ...response, teamId: team.id },
                            asTeamUpdate: true,
                        }),
                    );
                }
            });
        });
    };
};
