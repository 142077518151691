import { T_NormalQuestion, T_Question } from '../../../../../../../globalTypes';
import { OpenNumberBox } from './OpenNumberBox';
import './style.scss';

export const OpenResponseSection = (props: { question: T_Question }) => {
    const { question: currentQuestion } = props;
    if (currentQuestion?.type !== 'Open') return null;
    let desiredAnswers =
        (currentQuestion as T_NormalQuestion<'Open'>).typeData
            ?.desiredAnswers || 1;
    let numberBoxes: JSX.Element[] = [];
    for (let i = 0; i <= desiredAnswers; i++) {
        const props = {
            key: i,
            number: i,
            desiredAnswers,
        };
        if (desiredAnswers === 1) {
            numberBoxes = [<OpenNumberBox {...props} />, ...numberBoxes];
            continue;
        }
        numberBoxes = [...numberBoxes, <OpenNumberBox {...props} />];
    }
    return <div className="open-numberboxes-wrapper">{numberBoxes}</div>;
};
