import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components';
import { appStorage } from 'src/functions';
import { useAppDispatch } from 'src/hooks';
import { resetAllState } from 'src/store';

//Page used for when the host is not connected to the server, but the player is
export const TeamNoHostPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const clearStorage = () => {
        dispatch(resetAllState());
        appStorage.clear();
        navigate('../team/spelen');
    };
    return (
        <>
            <div className="socket-container">
                <p>Wacht tot het digibord weer actief is.</p>
                <Button
                    id="reject-button"
                    isActive={true}
                    isGhost={true}
                    onClick={() => clearStorage()}
                    className="mt-24"
                    buttonType="sleeping"
                    text="Stoppen"
                />
            </div>
        </>
    );
};
