import { drawOpenQuestionText } from 'src/functions/ui/drawers/drawOpenQuestionText';
import { drawResponseSubText } from 'src/functions/ui/drawers/drawResponseSubText';
import { drawResponseText } from 'src/functions/ui/drawers/drawResponseText';
import {
    T_NormalQuestion,
    T_Section,
    T_Team,
} from '../../../../../../globalTypes';
import { renderText } from '../../../../functions';
import { OpenAnswerRevealButton } from './open/OpenAnswerRevealButton';

//Used to draw the question on the response page
export const ResponseQuestion = ({
    selectedTeam,
    question,
}: {
    question?: T_Section;
    selectedTeam?: T_Team;
}) => {
    if (!question || !selectedTeam) return null;
    const isOpenQuestion = question?.type === 'Open';
    const responseText = isOpenQuestion
        ? drawOpenQuestionText({
              teamId: selectedTeam?.id,
              desiredAnswers:
                  (question as T_NormalQuestion<'Open'>)?.typeData
                      ?.desiredAnswers || 1,
          })
        : drawResponseText(selectedTeam?.id);

    return (
        <div>
            {isOpenQuestion || (
                <p className="question-response-question-subtext">
                    {drawResponseSubText()}
                </p>
            )}
            {isOpenQuestion &&
                (question as T_NormalQuestion<'Open'>).typeData
                    ?.openAnswers && <OpenAnswerRevealButton />}
            <p className="question-response-question-text">
                {renderText({ text: responseText })}
            </p>
        </div>
    );
};
