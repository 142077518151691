import React from 'react';
import { TeamHeader } from 'src/components/header/teamHeader/TeamHeader';

export const TeamWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <TeamHeader />
            {children}
        </>
    );
};
