import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { MainTitle } from 'src/components/base/typography/MainTitle';
import {
    SelectCard,
    SelectCardProps,
} from 'src/components/localToggle/SelectCard';
import { defaultScaleAnimation } from 'src/config/animation/animations';
import { Cardboards, Devices } from 'src/svg';
import { useAppDispatch, useAppSelector, useQuizIsLocal } from '../../../hooks';
import {
    forceConnect,
    forceDisconnect,
    selectSelectedQuizId,
    setQuizIsLocalChosen,
    setTeamCount,
} from '../../../store';

//Page where the host is allowed to choose between a local (cardboard) version or a proper online (socket io <3) version
export const LocalTogglePage = () => {
    const dispatch = useAppDispatch();
    const quizIsLocal = useQuizIsLocal();
    const quizIsLocalChosen = useAppSelector(
        state => state.quizState.quizIsLocalChosen,
    );
    const selectedQuizId = useAppSelector(selectSelectedQuizId());

    useEffect(() => {
        dispatch(setTeamCount(0));
        dispatch(setQuizIsLocalChosen(false));
    }, []);
    const onSelect = (type: 'Digitaal' | 'Bordjes') => {
        dispatch(setQuizIsLocalChosen(true));
        if (type === 'Digitaal') {
            dispatch(forceConnect());
        }
        if (type === 'Bordjes') {
            dispatch(forceDisconnect());
        }
        if (!selectedQuizId) return;
    };
    const cards: SelectCardProps[] = [
         {
            type: 'Bordjes',
            text: 'Antwoordbordjes',
            svg: <Cardboards />,
            isSelected: quizIsLocalChosen && quizIsLocal,
            onClickEvent: () => onSelect('Bordjes'),
        },
        {
            type: 'Digitaal',
            text: 'Telefoons / tablets',
            svg: <Devices />,
            isSelected: quizIsLocalChosen && !quizIsLocal,
            onClickEvent: () => onSelect('Digitaal'),
        },
       
    ];
    return (
        <>
            <MainTitle
                className={'h1'}
                text="Hoe wil je de quiz spelen?"
                underlined="Hoe wil je "
            />
            <MotionDiv
                {...defaultScaleAnimation}
                className="selectionWrapper mt-64"
            >
                {cards.map((card, index) => (
                    <SelectCard key={index} {...card} />
                ))}
            </MotionDiv>
        </>
    );
};

const MotionDiv = motion.div;
