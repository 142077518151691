import classNames from 'classnames';
import { castResponseEvent, getQuestionType } from 'src/functions';
import {
    useAppSelector,
    useDecision,
    usePhase,
    useSection,
    useSelfHasResponded,
    useSocket,
} from 'src/hooks';
import { selectRawTeamResponse } from '../../../../store';
import { Button } from '../../../base';

export const TeamConfirmResponseButton = ({
    className,
}: {
    className?: string;
}) => {
    const socket = useSocket();
    const rawTeamResponse = useAppSelector(selectRawTeamResponse());
    const currentSection = useSection();
    const decision = useDecision();
    const selfHasResponded = useSelfHasResponded();
    const phase = usePhase();
    const onClick = () => {
        if (!getInActive()) return;
        castResponseEvent({
            socket,
            rawTeamResponse,
            currentSection,
            selfHasResponded,
            decision,
        });
    };
    const getInActive = () => {
        if (Array.isArray(rawTeamResponse?.response)) {
            return (
                Number(rawTeamResponse?.response[0]) !== 0 &&
                rawTeamResponse?.response[0].toString().length !== 0
            );
        }
        return rawTeamResponse !== null && rawTeamResponse !== undefined;
    };
    if (
        (!currentSection && !decision) ||
        !['question', 'decisionQuestion'].includes(phase)
    )
        return null;
    const questionType =
        (currentSection && getQuestionType(currentSection)) || 'decision';
    const openClassname = classNames({
        open: questionType === 'Open',
        closed: questionType !== 'Open' && !className,
    });

    return (
        <Button
            isActive={true}
            isGhost={selfHasResponded || !getInActive()}
            buttonType={selfHasResponded ? 'custom' : 'sleeping'}
            className={classNames(
                'socket-confirm-button',
                openClassname,
                {
                    sent: selfHasResponded,
                },
                className,
            )}
            text={selfHasResponded ? 'Verstuurd' : 'Bevestig'}
            onClick={onClick}
        />
    );
};
