import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { settings } from 'src/config/settings';
import { getTeamColor } from 'src/functions/ui/getters/getTeamColor';
import { useQuizIsLocal, useTeamResponse, useTimedEffect } from 'src/hooks';
import useWindowDimensions from 'src/hooks/dimensions/useWindowDimensions';
import styled from 'styled-components';
import { T_Question, T_TeamName } from '../../../../../globalTypes';
import { ScorePoints } from './ScorePoints';
import { TeamAnswer } from './TeamAnswer';
import { TeamDisplay } from './TeamDisplay';
import './style.scss';
export const ScoreBar = ({
    id,
    question,
    oldScore,
    score,
    maxScore,
    deltaScore,
    teamName,
    teamColor,
    revealScore,
}: {
    question: T_Question;
    id: number; //teamIndex of team
    oldScore: number; // previous score of the team
    score: number; //current score of team
    maxScore: number; //highest possible score for the entire quiz
    deltaScore: number; //change in score from previous question, used for animation
    teamName?: T_TeamName;
    teamColor?: string;
    revealScore?: boolean; //is true when the score is revealed (based on scoreRevealIncides that are sent from server)
}) => {
    const quizIsLocal = useQuizIsLocal();
    const { scoreAnimationStart } = settings;
    const teamResponse = useTeamResponse({ id, entireResponse: true });
    const revealScoreAnimationStarted = useTimedEffect(scoreAnimationStart);
    const scoreIsUpdated = useTimedEffect(scoreAnimationStart + 750);
    const scoreBarRef = useRef<HTMLDivElement>(null);
    const { width, height } = useWindowDimensions();

    //REFACTOR THIS STUFF
    const initialHeight = revealScore
        ? (20 + (oldScore / maxScore) * 85).toString() + '%'
        : '128px';
    const finalHeight = revealScore
        ? (20 + ((oldScore + deltaScore) / maxScore) * 85).toString() + '%'
        : initialHeight;
    const displayedScore = scoreIsUpdated ? oldScore + deltaScore : oldScore;

    const [showConfetti, setShowConfetti] = useState<boolean>(false);
    const [runConfetti, setRunConfetti] = useState<boolean>(false);
    useEffect(() => {
        // Check if deltaScore is greater than 0 and then run confetti
        if (deltaScore > 0) {
            setShowConfetti(true);
            setRunConfetti(true);
            // Timer to stop the confetti
            const timer = setTimeout(() => {
                setShowConfetti(false);
            }, 3000); // Adjust the duration as needed

            // Cleanup to clear the timer if the component unmounts
            return () => {
                clearTimeout(timer);
            };
        }
    }, [revealScore]); // Dependency array includes deltaScore to react to its changes

    return (
        <div
            className={classNames('score-bar-new gap-16', {
                reveal: revealScore,
            })}
        >
            <StyledScoreBar
                ref={scoreBarRef}
                $teamColor={teamColor}
                $id={id}
                $score={score}
                $maxScore={maxScore}
                className={classNames('score-bar', {
                    reveal: revealScore,
                    'aReveal flex column justify-center items-center': !revealScore,
                })}
                transition={{
                    duration: 0.2,
                    ease: 'easeOut',
                }}
                initial={{
                    height: initialHeight,
                }}
                animate={{
                    height: revealScoreAnimationStarted
                        ? finalHeight
                        : initialHeight,
                }}
            >
                {revealScore && (
                    <>
                        <TeamAnswer
                            teamResponse={teamResponse}
                            questionType={question.type}
                            typeData={question.typeData}
                            teamId={id}
                        />
                    </>
                )}
                <ScorePoints
                    revealScore={revealScore}
                    scoreProps={{
                        revealScoreAnimationStarted,
                        deltaScore,
                    }}
                />

                <Confetti
                    width={240}
                    height={height}
                    run={runConfetti && revealScoreAnimationStarted}
                    recycle={showConfetti}
                    numberOfPieces={40}
                    initialVelocityX={2}
                    initialVelocityY={7}
                    confettiSource={{
                        w: 10,
                        h: 10,
                        x: 112,
                        y: 200,
                    }}
                />
            </StyledScoreBar>
            <div
                className={classNames('display-wrapper flex column gap-16', {
                    'translateY-50': quizIsLocal && !revealScore,
                    'translateY-80': !quizIsLocal && !revealScore,
                    'translateY-100': quizIsLocal && revealScore,
                    'translateY-130': !quizIsLocal && revealScore,
                })}
            >
                <TeamDisplay teamName={teamName} id={id} />
                {revealScore && (
                    <MotionDiv
                        key={scoreIsUpdated.toString()}
                        initial={{ scale: 0.9, y: -5 }}
                        animate={{ scale: 1, y: 0 }}
                        transition={{
                            duration: 0.2,
                            ease: 'easeOut',
                        }}
                        className="score-wrapper"
                    >
                        €{displayedScore}
                    </MotionDiv>
                )}
            </div>
        </div>
    );
};

const MotionDiv = motion.div;

const StyledScoreBar = styled(motion.div)<{
    $score: number;
    $maxScore: number;
    $id: number;
    $teamColor?: string;
}>`
    background: ${props => getTeamColor(props.$id)};
`;
