import { sectionIsQuestion } from 'src/functions';
import { TeamClosedResponseSection, TeamMPCResponseSection } from '.';
import {
    T_DecisionQuestion,
    T_NormalQuestion,
    T_QuizPhase,
    T_Section,
} from '../../../../../../globalTypes';
import { useAppSelector } from '../../../../hooks';
import { selectDecisionQuestion } from '../../../../store';
import { TeamGuessResponseSection } from './TeamGuessResponseSection';
import { TeamOpenResponseSection } from './TeamOpenResponseSection';
//Divides based on the question type, branches into question specific responseSections, takes decision into account
export const TeamResponseRenderer = ({
    inDecision,
    phase,
    revealTimer,
    section,
}: {
    inDecision?: boolean;
    phase: T_QuizPhase;
    revealTimer: boolean;
    section?: T_Section;
}) => {
    const decisionQuestion = useAppSelector(selectDecisionQuestion());

    const revealAnswer = phase === 'answerReveal' && revealTimer;
    let currentQuestion:
        | T_Section
        | T_DecisionQuestion
        | undefined = section as T_NormalQuestion;
    if (inDecision) {
        //if we are , then we have a decisionQuestion, so we desire guess input
        currentQuestion = decisionQuestion as T_DecisionQuestion;
        return (
            <TeamGuessResponseSection
                inDecision={inDecision}
                revealAnswer={revealAnswer}
                question={currentQuestion}
            />
        );
    }

    if (!currentQuestion || !sectionIsQuestion(currentQuestion)) return null;
    const props = {
        revealAnswer,
        question: currentQuestion,
    };
    switch (currentQuestion.type) {
        case 'MPC':
            return <TeamMPCResponseSection {...props} />;
        case 'Closed':
            return <TeamClosedResponseSection {...props} />;
        case 'Guess':
            return <TeamGuessResponseSection {...props} />;
        case 'Open':
            return <TeamOpenResponseSection question={currentQuestion} />;
        default:
            return null;
    }
};
