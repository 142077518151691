import {
    useAppDispatch,
    usePublicConfig,
    useQuestionCount,
    useSection,
    useSocket,
    useTimerReset,
} from '../../../hooks';

import { ScoreDisplay } from 'src/components';
import { castNextEvent, sectionIsQuestion } from '../../../functions';

export const ScoreRevealPage = ({
    phase,
}: {
    phase: 'quizFirstReward' | 'scoreReveal';
}) => {
    useTimerReset();
    const currentQuestion = useSection();
    const socket = useSocket();
    const dispatch = useAppDispatch();
    const publicConfig = usePublicConfig();
    const { currentQuestionCount } = useQuestionCount();
    const revealScore =
        publicConfig.scoreRevealIndices.includes(currentQuestionCount) ||
        phase === 'quizFirstReward';
    if (!sectionIsQuestion(currentQuestion)) {
        castNextEvent({ socket, dispatch });
        return null;
    }
    return (
        <>
            <ScoreDisplay
                revealScore={revealScore}
                currentQuestion={currentQuestion}
                isQuizFirstReward={phase === 'quizFirstReward'}
            />
        </>
    );
};
