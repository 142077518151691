import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { defaultScaleAnimation } from 'src/config/animation/animations';
import { T_QuizInformation, arrayOnlyUnique } from 'src/functions';
import { useAppDispatch } from 'src/hooks';
import {
    T_RootState,
    setActiveLectureId,
    setActiveLectureLevel,
} from 'src/store';
import { T_SchoolLevel } from '../../../../../globalTypes';
import { SelectionButton } from '../buttons/SelectionButton';
import { ListRow } from './ListRow';
import './style.scss';

const MotionDiv = motion.div;
export type T_ListInformation = Omit<
    T_QuizInformation,
    'moduleTitles' | 'difficulty' | 'description' | 'icon' | 'yOffset'
> & {
    icon?: string;
};
export const SchoolList = ({
    list,
    reducer,
    heading,
    cardSelector,
}: {
    list: (T_ListInformation & { level: T_SchoolLevel })[];
    heading: string;
    reducer: ActionCreatorWithPayload<number | null>;
    cardSelector: () => (state: T_RootState) => number | null | undefined;
}) => {
    const dispatch = useAppDispatch();
    const [selectedLevel, setSelectedLevel] = useState<T_SchoolLevel>('PO');
    const occuringLevels = [...list]
        .map(list => list.level)
        .filter(arrayOnlyUnique)
        .sort((levelA, levelB) => {
            //KEKW
            if (levelA === 'PO') return -1;
            if (levelB === 'PO') return 1;
            if (levelA === 'MBO') return -1;
            if (levelB === 'MBO') return 1;
            if (levelA === 'VO') return -1;
            if (levelB === 'VO') return 1;
            return 0;
        });
    const listTable = [...list]
        .sort((listA, listB) => {
            if (!listA.ordering || !listB.ordering) return 0;
            return listA.ordering - listB.ordering;
        })
        .filter(list => list.level === selectedLevel)
        .map((_list: T_ListInformation, index: number) => {
            const listInformation = { ..._list, originalIndex: index };
            return (
                <ListRow
                    cardSelector={cardSelector}
                    reducer={reducer}
                    totalListCount={list.length}
                    index={{
                        latest: listInformation.originalIndex || index,
                        current: index,
                    }}
                    key={listInformation.id}
                    listInformation={listInformation}
                />
            );
        });
    useEffect(() => {
        dispatch(setActiveLectureId(null));
        setSelectedLevel(occuringLevels[0]);
        dispatch(setActiveLectureLevel(occuringLevels[0]));
    }, []);
    return (
        <MotionDiv {...defaultScaleAnimation} className="selection-wrapper">
            <h2 style={{ marginBottom: '40px', fontSize: '2.5rem' }}>
                {heading}
            </h2>
            <div className="flex selection-wrapper-kek">
                {occuringLevels.length === 1 ||
                    occuringLevels.map((option, index) => {
                        return (
                            <SelectionButton
                                text={
                                    option === 'PO' ? 'Basisonderwijs' : option
                                }
                                key={option}
                                onClick={() => {
                                    setSelectedLevel(option);
                                    dispatch(setActiveLectureId(null));
                                    dispatch(setActiveLectureLevel(option));
                                }}
                                selected={selectedLevel === option}
                            />
                        );
                    })}
            </div>
            <div className="list-table-wrapper">{listTable}</div>
        </MotionDiv>
    );
};
