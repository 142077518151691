import { GuessResponseWrapper } from 'src/components/quiz/questionResponsePage/ResponseSection/guess/GuessResponseWrapper';
import {
    T_DecisionQuestion,
    T_NormalQuestion,
} from '../../../../../../globalTypes';
import { GuessNumberInput } from '../../../quiz/questionResponsePage/ResponseSection/guess/GuessNumberInput';
import { TeamConfirmResponseButton } from './TeamConfirmResponseButton';

export const TeamGuessResponseSection = ({
    question,
    revealAnswer,
    inDecision,
}: {
    question: T_NormalQuestion | T_DecisionQuestion;
    revealAnswer: boolean;
    inDecision?: boolean;
}) => {
    return (
        <>
            <GuessResponseWrapper asTeam>
                <GuessNumberInput
                    question={
                        question as
                            | T_NormalQuestion<'Guess'>
                            | T_DecisionQuestion
                    }
                    inDecision={inDecision}
                />
            </GuessResponseWrapper>
            <TeamConfirmResponseButton />
        </>
    );
};
