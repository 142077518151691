import { useState } from 'react';
import { useKeyControls } from 'src/hooks/controls/useKeyControls';
import { DevShowScore } from './DevShowScore';

export const DevControls = () => {
    const [showScore, setShowScore] = useState(false);
    const [showOnlyPhase, setShowOnlyPhase] = useState(false);
    useKeyControls({
        keyEvents: [
            {
                key: 'Q',
                event: () => setShowScore(prev => !prev),
            },
            {
                key: 'W',
                event: () => setShowOnlyPhase(prev => !prev),
            },
        ],
    });
    return <>{showScore && <DevShowScore />}</>;
};
