import { MotionProps } from 'framer-motion';

export const defaultScaleAnimation: MotionProps = {
    initial: {
        scale: 0.9,
        y: 20,
        opacity: 0,
    },
    animate: {
        scale: 1,
        y: 0,
        opacity: 1,
    },
    exit: {
        scale: 0,
    },
    transition: {
        duration: 0.25,
        ease: 'easeInOut',
    },
} as const;
