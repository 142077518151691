import { useEffect } from 'react';
import { useAppDispatch, usePhase, useSection } from 'src/hooks';
import { TeamJoinPage, TeamNoScreenPage } from 'src/pages/subpages';
import { TeamChooseName } from 'src/pages/subpages/team/TeamChooseName';
import { TeamGuessReveal } from 'src/pages/subpages/team/TeamGuessReveal';
import { TeamNoHostPage } from 'src/pages/subpages/team/TeamNoHostPage';
import { TeamQuestionCount } from 'src/pages/subpages/team/TeamQuestionCount';
import { setInteraction } from 'src/store';

export const RenderedHeroContent = () => {
    const phase = usePhase();
    const section = useSection();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(
            setInteraction({
                interactionKey: 'wonNonLocalGuess',
                change: 'close',
            }),
        );
    }, [phase]);
    switch (phase) {
        case 'preStart': {
            return <TeamJoinPage />;
        }
        case 'question':
            return <TeamQuestionCount />;
        case 'lobby':
            return <TeamChooseName />;
        case 'answerReveal':
        case 'decisionQuestion':
            if (phase === 'answerReveal' && section?.type === 'Guess') {
                return <TeamGuessReveal />;
            }
            return null;
        case 'noHost':
            return <TeamNoHostPage />;
        default:
            return <TeamNoScreenPage />;
    }
};
