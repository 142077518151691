import { T_Module, T_Quiz } from '../../../../../globalTypes';

export type T_QuizInformation = {
    id: number;
    icon: string;
    title: string;
    ordering?: number;
    description: string;
    difficulty: number;
    moduleTitles: string[];
    originalIndex?: number;
    iconOffset?: number;
    tags?: string[];
    cardColor?: string;
};

export const drawQuizInformation = (
    quizList: T_Quiz[],
): T_QuizInformation[] => {
    return quizList.map((quiz: T_Quiz) => {
        const { icon, id, title, description, ordering } = quiz;
        return {
            titleClassName: 'size-24',
            icon,
            id,
            title,
            description,
            difficulty: quiz.difficulty ? quiz.difficulty : 50,
            tags: quiz.tags ? quiz.tags : [],
            ordering,
            cardColor: quiz.cardColor || undefined,
            moduleTitles: quiz.modules.map((module: T_Module) => {
                return module.title;
            }),
        };
    });
};
