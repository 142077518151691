import classNames from 'classnames';
import { getThemeColor } from 'src/functions';
import { usePhase } from 'src/hooks';
import { useTeamConnection } from 'src/hooks/teams/useTeamConnection';
import styled from 'styled-components';
import { T_Phase } from '../../../../../globalTypes';
import './style.scss';
export const TeamBox = ({
    teamIndex,
    noResponse,
}: {
    teamIndex: number;
    noResponse?: boolean;
}) => {
    const phase = usePhase();
    const isConnected = useTeamConnection(teamIndex);
    return (
        <StyledTeamBox
            phase={phase}
            className={classNames('team-status-box', {
                'opacity-50': !isConnected,
            })}
        >
            <StyledTeamNumber
                phase={phase}
                className={classNames('team-status-number', {
                    'opacity-90':
                        phase === 'question' &&
                        noResponse === true &&
                        isConnected,
                })}
                noResponse={noResponse}
            >
                {teamIndex + 1}
            </StyledTeamNumber>
        </StyledTeamBox>
    );
};

const StyledTeamNumber = styled.div<{
    phase: T_Phase | undefined;
    noResponse?: boolean;
}>`
    background-color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'coolBackground',
        })};
    color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'input',
        })};
`;

const StyledTeamBox = styled.div<{ phase: T_Phase | undefined }>`
    background-color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'coolBackground',
        })};
`;
