import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/base/buttons';
import { StateInput } from 'src/components/input/StateInput';
import { defaultScaleAnimation } from 'src/config/animation/animations';
import { appStorage } from 'src/functions';
import {
    useAppDispatch,
    useAppSelector,
    useEnter,
    useErrorCode,
    useLatestMessage,
} from 'src/hooks';
import {
    getAssociatedLectures,
    resetErrorCode,
    setErrorCode,
    setGuestLecturerCode,
    setLectureIsTrial,
    setLectureIsWithoutCode,
    setMessage,
} from 'src/store';
import styled from 'styled-components';
import { NoCodeButton } from './codeInput/NoCodeButton';
import './style.scss';

const MotionDiv = motion.div;
export const CodeInputPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const errorCode = useErrorCode();
    const latestMessage = useLatestMessage();
    const code = useAppSelector(state => state.appState.guestLecturerCode);
    const codeLength = code?.length;
    const submitCode = () => {
        if (code === '863c19aa') {
            dispatch(setErrorCode('BB-23a'));
            dispatch(setMessage('Testcode niet geldig.'));
            return;
        }
        if (!codeLength || codeLength !== 8) {
            dispatch(
                setMessage(
                    'Voer een geldige startcode in. Een startcode bevat 8 karakters.',
                ),
            );
            dispatch(setErrorCode('BB-21'));
            return;
        }
        dispatch(getAssociatedLectures({ asCheck: false, navigate }));
    };
    useEnter(submitCode);
    useEffect(() => {
        dispatch(resetErrorCode());
        dispatch(setLectureIsTrial(false));
        dispatch(setLectureIsWithoutCode(false));
        const guestCode = appStorage.retrieveGuestLecturerCode();
        if (guestCode) {
            dispatch(setGuestLecturerCode(guestCode));
        }
    }, []);

    return (
        <MotionDiv {...defaultScaleAnimation} className="startcode-wrapper">
            <InputWrapper error={errorCode} className="input-wrapper">
                <label htmlFor="">
                    <h5>Voer startcode in</h5>
                </label>
                <div className="form-field">
                    <StateInput
                        reducer={setGuestLecturerCode}
                        selector={() => state =>
                            state.appState.guestLecturerCode}
                        placeholder={'Startcode'}
                        maxLength={8}
                    />
                    {(errorCode === 'BB-20' ||
                        errorCode === 'BB-21' ||
                        errorCode === 'BB-23a') && (
                        <span className="error-message">{latestMessage}</span>
                    )}
                </div>
                <Button
                    text={'Start'}
                    buttonType={
                        classNames({
                            primary: codeLength === 8,
                            disabled: codeLength !== 8,
                        }) as 'primary' | 'disabled'
                    }
                    onClick={submitCode}
                />
            </InputWrapper>
            <div className="no-code-wrapper">
                <div className="wrapper">
                    <h5>Geen startcode?</h5>
                    <NoCodeButton
                        className={'text-link'}
                        text="Speel zonder code"
                        phase="noCodeStart"
                        href="/speel-zonder-code"
                    />
                </div>
                <span className="vertical-line" />
                <div className="wrapper">
                    <h5>Wil je oefenen?</h5>
                    <NoCodeButton
                        onClick={() => {
                            dispatch(setLectureIsTrial(true));
                            dispatch(setLectureIsWithoutCode(true));
                        }}
                        className={'text-link'}
                        text="Speel oefenversie"
                        phase="trialStart"
                        href="/speel-proef-versie"
                    />
                </div>
            </div>
        </MotionDiv>
    );
};

const InputWrapper = styled.div<{
    error: string | null;
}>`
    position: relative;
    flex-direction: column;
    display: flex;
    gap: 8px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #d8d8d8;
    .form-field input {
        border-color: ${props => (props.error ? 'rgb(255 30 3);' : 'none')};
    }
`;
