import type { InputType } from '../NoCodeStartPage';
import { T_InputFieldProps } from './EntryField';
import { Error } from './Error';

export const SelectionField = ({
    field,
    fieldKey,
    register,
    error,
    tabIndex,
    setter
}: T_InputFieldProps<InputType.select>) => {
    return (
        <div className="form-field" key={field.label}>
            <label>{field.label}</label>
            <select
                id={fieldKey}
                {...register(fieldKey)}
                name={fieldKey}
                tabIndex={tabIndex}
                onChange={(e) => setter && e.target.value && setter(e.target.value)}
            >
                {field.options?.map((option, index) => {
                    let text =
                        option === 'PO'
                        ? 'Basisonderwijs'
                        : option === 'VO'
                            ? 'Voortgezet Onderwijs'
                            : option;

                    return <option key={index} value={option}>{text}</option>;
                })}
            </select>
            <Error error={error} />
        </div>
    );
};
