import classNames from 'classnames';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../hooks';
import { setInteraction } from '../../../../../store';

export const OpenAnswerRevealButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const textClassNames = classNames('open-anwser-reveal', { open: isOpen });
    const dispatch = useAppDispatch();

    return (
        <div className="relative">
            <div
                className="open-anwser-reveal-button"
                onClick={() =>
                    dispatch(
                        setInteraction({
                            interactionKey: 'popUp',
                            change: 'open',
                        }),
                    )
                }
            >
                <span className="open-button">?</span>
            </div>
        </div>
    );
};
