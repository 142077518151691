import './style.scss';
export const CardIcon = ({ icon }: { icon: string }) => {
    if (!icon) return null;
    return (
        <div className="card-icon-wrapper">
            <img
                src={`${process.env.REACT_APP_MEDIA_URL}icons/quizCard/${icon}`}
            />
        </div>
    );
};
