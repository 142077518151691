import { gameHasWinner } from '..';
import { T_Team } from '../../../../globalTypes';

export const getDecisionTeams = (teams: T_Team[]): void | number[] => {
    const highestScores = gameHasWinner(teams, true);
    if (!highestScores || typeof highestScores === 'boolean') {
        return;
    }
    return highestScores;
};
