import { Picture } from 'src/components/base/picture/Picture';

export const NoTeamAnnouncer = () => {
    return (
        <>
            <div className="announce">Er is nog niemand ingelogd</div>
            <Picture src="/landing/empty-room" alt="Er is niemand aanwezig" />
        </>
    );
};
