import { motion } from 'framer-motion';
import styled from 'styled-components';
import { T_Phase, T_QuizPhase } from '../../../../../../globalTypes';
import { usePhase } from '../../../../hooks';
import './style.scss';

export const InfoText = ({
    text,
    subText,
    reducesize,
    className,
}: {
    text?: JSX.Element;
    subText?: JSX.Element;
    reducesize?: boolean; //whether the box should be smaller after initial render
    className?: string;
}) => {
    const phase = usePhase();
    return (
        <InfoTextWrapper
            className={className}
            phase={phase}
            $reduced={reducesize}
        >
            <p>{text}</p>
            {subText && (
                <>
                    <br />
                    <p>{subText}</p>
                </>
            )}
        </InfoTextWrapper>
    );
};

export const InfoTextWrapper = styled(motion.div)<{
    phase?: T_Phase;
    $reduced?: boolean;
    hidden?: boolean;
}>`
    padding-right: calc(var(--layout-gap) * 2);
    font-size: ${props => {
        switch (props.$reduced) {
            case true:
                return '24px';
            case false:
            default:
                return '34px';
        }
    }};

    ${props => props.phase && `
        ${props.phase === 'decision' && `
            /* Apply CSS for 'decision' */
            padding-right: calc(var(--layout-gap));
        `}
        
    `}
`;

export const StyledText = styled(motion.p)<{
    phase: T_QuizPhase | undefined;
    $reduceSize: boolean | undefined;
}>`
    color: #000;
    border-radius: 4px;
    line-height: 1.5;
`;
