import { T_AppDispatch, handleDecision, setPhase, setRestoredGame } from '..';
import {
    T_RawState,
    T_ServerRestoredGame,
    T_Stage,
} from '../../../../globalTypes';
import { getDecisionTeams, getNextStage } from '../../functions';

export const restoreGame = (restoredGame: T_ServerRestoredGame) => async (
    dispatch: T_AppDispatch,
    getState: () => T_RawState,
) => {
    try {
        const {
            quiz_phase,
            quiz,
            module_id,
            section_id,
            quiz_is_local: quizIsLocal,
            teams,
        } = restoredGame;
        let newStage: T_Stage | null = null;
        if (quiz_phase === 'quizTips') {
            dispatch(setPhase('quizTips'));
            const nextStage = getNextStage(quiz);
            if (!nextStage || nextStage.phase === 'goNextSection') return;
            newStage = { ...nextStage, phase: nextStage.phase };
        }
        dispatch(
            setRestoredGame({
                ...restoredGame,
                module_id: newStage ? newStage.moduleId : module_id,
                section_id: newStage ? newStage.sectionId : section_id,
                quiz_phase: newStage ? newStage.phase : quiz_phase,
            }),
        );
        const decisionTeams = getDecisionTeams(teams);
        // Hier zit dat beslissing vraag ding
        // if (quizIsLocal && decisionTeams) {
        //     //local games are dispatched right away
        //     dispatch(handleDecision(decisionTeams));
        // }
    } catch (error) {
        console.log(error);
    }
};
