import classNames from 'classnames';
import { motion } from 'framer-motion';
import { defaultScaleAnimation } from 'src/config/animation/animations';
import { settings } from '../../../config/settings';
import { castEvent, createLocalEvent, splitArray } from '../../../functions';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectTeamCount, setTeamCount } from '../../../store';
import './style.scss';

export const LocaLTeamCountSelection = () => {
    const { maxTeams } = settings;
    let selectionArray: number[] | [] = [];
    for (let i = 1; i <= maxTeams; i++) {
        selectionArray = [...selectionArray, i];
    }
    const teamSelection = selectionArray.map((value: number) => {
        return <SelectionBox key={value} number={value} />;
    });
    const [topSelection, bottomSelection] = splitArray(teamSelection);
    return (
        <>
            <div className="square-row">{topSelection}</div>
            <div className="square-row">{bottomSelection}</div>
        </>
    );
};

const SelectionBox = (props: { number: number }) => {
    const currentSelectedTeamCount = useAppSelector(selectTeamCount());
    const dispatch = useAppDispatch();
    const { number } = props;
    const onClickEvent = (id: number) => {
        castEvent(createLocalEvent(dispatch, setTeamCount, id));
    };
    const selected = currentSelectedTeamCount === number;
    const unselected = currentSelectedTeamCount !== number;
    const divClassName = classNames('square-button', {
        selected,
        unselected,
    });
    const textClassName = classNames('local-button-text', {
        selected,
        unselected,
    });
    return (
        <MotionDiv
            {...defaultScaleAnimation}
            animate={{
                scale: selected ? 1.05 : 1,
                opacity: 1,
                y: 0,
            }}
            className={divClassName}
            onClick={() => onClickEvent(number)}
        >
            <h5 className={textClassName}> {number}</h5>
        </MotionDiv>
    );
};

const MotionDiv = motion.div;
