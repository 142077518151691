import { useSection } from '../quiz/useSection';
import { useSelfTeam } from './useSelfTeam';

export const useSelfResponse = () => {
    const selfTeam = useSelfTeam();
    const section = useSection();
    const foundResponse = selfTeam?.responses.find(
        response => response.sectionId === section?.id,
    );
    return foundResponse;
};
