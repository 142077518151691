import { useEffect } from 'react';
import { useInteractionOpen } from '../interactions';
import { useForcedNavigation } from './useForcedNavigation';

export const useArrowNavigation = () => {
    const warningPopUpOpen = useInteractionOpen('warningPopUp');
    const forceForward = useForcedNavigation('forward');
    const forceBack = useForcedNavigation('back');
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft') {
                forceBack(warningPopUpOpen);
            }
            if (e.key === 'ArrowRight') {
                forceForward(warningPopUpOpen);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [warningPopUpOpen]);
};
