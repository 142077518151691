import { DependencyList, useEffect } from 'react';

export const useScrollControls = ({
    scrollEvent,
    dependencies = [],
}: {
    scrollEvent: (scrollY: number) => void;
    dependencies?: DependencyList;
}) => {
    useEffect(() => {
        const handleWheel = (e: WheelEvent) => {
            const scrollY = e.deltaY;
            scrollEvent(scrollY);
        };
        window.addEventListener('wheel', handleWheel);
        return () => window.removeEventListener('wheel', handleWheel);
    }, [...dependencies]);
};
