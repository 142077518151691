import classNames from 'classnames';
import { motion } from 'framer-motion';
import { getThemeColor } from 'src/functions';
import styled from 'styled-components';
import { T_TextSize } from '../../../../../../../globalTypes';
import { MPCLetter } from './MPCLetter';
import './style.scss';

export const MPCOption = ({
  text,
  correct,
  letter,
  answersTextSize,
}: {
    text: string;
    correct?: boolean;
    letter: string;
    answersTextSize: T_TextSize;
}) => {
    const className = classNames('mpc-option', {
        'size-24': answersTextSize === 'small',
        'size-28': answersTextSize === 'medium' || !answersTextSize,
        'size-32': answersTextSize === 'large',
    });
    if (correct === false) {
        return (
            <StyledOption $incorrect className={className}>
                <MPCLetter letter={letter} />
                <p className="incorrect">{text}</p>
            </StyledOption>
        );
    }
    if (correct) {
        return (
            <StyledOption className={className} correct={correct}>
                <MPCLetter letter={letter} /> {text}
            </StyledOption>
        );
    }
    return (
        <StyledOption className={className}>
            <MPCLetter letter={letter} /> {text}
        </StyledOption>
    );
};

const StyledOption = styled(motion.div)<{
    $incorrect?: boolean;
    correct?: boolean;
}>`
    z-index: 0;
    overflow: hidden;
    position: relative;
    border-radius: 16px;
    transition: color 0.2s ease-in-out;
    border: 1px solid ${props =>
            getThemeColor({
                theme: props.theme,
                themeType: 'colors',
                themeColor: 'primary',
            })};
    background: #fff;
    color: ${props =>
            props.correct
                    ? getThemeColor({
                        theme: props.theme,
                        themeType: 'colors',
                        themeColor: 'coolBackground',
                    })
                    : getThemeColor({
                        theme: props.theme,
                        themeType: 'colors',
                        themeColor: 'primary',
                    })};
    box-shadow: ${props =>
            getThemeColor({
                theme: props.theme,
                themeType: 'boxShadows',
                themeColor: 'primary',
            })};

    &:after {
        transition: all 0.3s ease-in-out;
        transform-origin: bottom;
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        transform: ${props =>
                props.correct
                        ? 'translateY(0%)'
                        : 'translateY(100%)'};
        z-index: -1;
        background: ${props =>
                props.correct
                        ? getThemeColor({
                            theme: props.theme,
                            themeType: 'colors',
                            themeColor: 'correct',
                        })
                        : 'initial'};
    }
`;
