import { CentralWrapper } from 'src/layout/styledcomponents';
import { QuestionAnswersPage } from 'src/pages/subpages';
import { WhatsappSection } from '../../../../components/demo/WhatsappSection';
import { useAppSelector } from '../../../../hooks';

export const WhatsappInteraction = ({
    isTeamPage,
}: {
    isTeamPage?: boolean;
}) => {
    const showWhatsappQuestion = useAppSelector(
        state => state.appState.showWhatsappQuestion,
    );
    return (
        <>
            {showWhatsappQuestion ? (
                <CentralWrapper>
                    <QuestionAnswersPage phase="question" />
                </CentralWrapper>
            ) : (
                <WhatsappSection isTeamPage={isTeamPage} />
            )}
        </>
    );
};
