import { useNavigate } from 'react-router-dom';
import { decisionPhases } from 'src/config/ui/phases';
import { drawBackButtonText } from 'src/functions/ui/drawers/drawBackButtonText';
import { useAppDispatch, useQuizIsLocal } from 'src/hooks';
import { resetAllState, setWarningPopUp } from 'src/store';
import { T_DecisionPhase, T_Phase } from '../../../../../globalTypes';
import { Button, ButtonProps } from './Button';
const DefaultBackButton = ({
    phase,
    ...rest
}: { phase?: T_Phase } & ButtonProps) => {
    return (
        <Button
            {...rest}
            isGhost
            id={'cypress-back'}
            buttonType="previous"
            text={drawBackButtonText({ phase })}
        />
    );
};
export const FooterBackButton = ({ phase }: { phase?: T_Phase }) => {
    const dispatch = useAppDispatch();
    const quizIsLocal = useQuizIsLocal();
    const navigate = useNavigate();
    if (phase && decisionPhases.includes(phase as T_DecisionPhase)) {
        //LAZY ASS CODING
        return <div />;
    }
    switch (phase) {
        case 'quizTips':
            return (
                <Button
                    isGhost
                    id={'cypress-back'}
                    buttonType="previous"
                    onClick={() => {
                        dispatch(
                            setWarningPopUp({
                                onAccept: 'endQuiz',
                                onReject: 'end',
                                text: 'Wil je echt stoppen?',
                                acceptText: 'Ja, beëindig quiz',
                                rejectText: 'Nee, verder met quiz',
                                invertedPosition: true,
                            }),
                        );
                    }}
                    text="Stoppen"
                />
            );
        case 'lobby':
            if (!quizIsLocal) {
                return (
                    <Button
                        isGhost
                        id={'cypress-back'}
                        buttonType="previous"
                        onClick={() => {
                            dispatch(
                                setWarningPopUp({
                                    onAccept: 'leaveLobby',
                                    onReject: 'end',
                                    text: 'Wil je echt de lobby verlaten?',
                                    acceptText: 'Ja',
                                    rejectText: 'Nee',
                                    invertedPosition: true,
                                }),
                            );
                        }}
                        text="Stoppen"
                    />
                );
            }
            return <DefaultBackButton phase={phase} />;
        case 'trialStart':
            return (
                <DefaultBackButton
                    onClick={() => {
                        dispatch(resetAllState());
                        navigate('/');
                    }}
                    phase={phase}
                />
            );
        case 'lectureSelection':
            return <DefaultBackButton phase={phase} />;
        case 'quizOutro':
            return null;
        case 'quizThanks':
            return null;
        default:
            return <DefaultBackButton phase={phase} />;
    }
};
