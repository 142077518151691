import { T_ThemeState } from '../../../../../globalTypes';
import { getThemeColor } from './getThemeColor';

export const getTimeColor = ({
    time,
    theme,
}: {
    time?: number;
    theme: T_ThemeState;
}) => {
    if (!time)
        return getThemeColor({
            theme: theme,
            themeType: 'colors',
            themeColor: 'error',
        });
    if (time > 10) {
        return getThemeColor({
            theme: theme,
            themeType: 'colors',
            themeColor: 'primary',
        });
    }
    if (time > 5) {
        return getThemeColor({
            theme: theme,
            themeType: 'colors',
            themeColor: 'warning',
        });
    }
    return getThemeColor({
        theme: theme,
        themeType: 'colors',
        themeColor: 'error',
    });
};
