import { Socket as T_Socket } from 'socket.io-client';
import { T_AppDispatch, resetAllState } from '../../store';
import { castEvent } from './castEvent';

export const castEndEvent = (socket: T_Socket, dispatch: T_AppDispatch) => {
    castEvent({
        socket,
        endPoint: 'endGame',
        dispatch,
        reducer: resetAllState,
    });
};
