import { motion } from 'framer-motion';
import { useAppSelector } from '../../hooks';
import { selectInteractions } from '../../store';
import { PopScreenWrapper } from './popScreens/PopScreenWrapper';
import './style.scss';
import { AcceptButton, RejectButton } from './warningPopUp/index';
const MotionDiv = motion.div;
const MotionPopScreenWrapper = motion(PopScreenWrapper);
export const WarningPopUp = () => {
    const warningPopUp = useAppSelector(selectInteractions('warningPopUp'));

    const isInverted = warningPopUp?.invertedPosition;
    const showWrapper = warningPopUp?.isOpen && warningPopUp?.text;
    const initial = {
        opacity: 0,
        scale: 0.85,
        y: 42.0,
    };
    return (
        <>
            {showWrapper && (
                <MotionPopScreenWrapper>
                    <MotionDiv
                        key={warningPopUp.isOpen.toString()}
                        initial={initial}
                        animate={{
                            opacity: 1,
                            scale: 1,
                            y: 0,
                        }}
                        transition={{
                            duration: 0.54,
                            ease: 'easeInOut',
                        }}
                        className="wrapper m"
                    >
                        <h2 className="center">{warningPopUp?.text}</h2>
                        <div className="button-wrapper">
                            {isInverted || <AcceptButton />}
                            <RejectButton />
                            {isInverted && <AcceptButton />}
                        </div>
                    </MotionDiv>
                </MotionPopScreenWrapper>
            )}
        </>
    );
};
