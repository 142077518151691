import { useEffect, useState } from 'react';
import { sectionIsWhatsappQuestion } from 'src/functions';
import { useWhatsappChat } from 'src/hooks/whatsApp/useWhatsappChat';
import { setShowMockPage } from 'src/store';
import { T_WhatsappMessage } from '../../../../globalTypes';
import {
    useAppDispatch,
    useAppSelector,
    useSection,
    useTimedEffect,
} from '../../hooks';
import { MockPage } from './MockPage';
import { WhatsAppGroupedMessages } from './WhatsAppGroupedMessages';
import { WhatsappAvatar } from './WhatsappAvatar';
import './style.scss';

export const WhatsappSection = ({ isTeamPage }: { isTeamPage?: boolean }) => {
    const section = useSection();
    const dispatch = useAppDispatch();
    const { displayedMessages } = useWhatsappChat();
    const { typing } = useWhatsappChat();
    const showMockPage = useAppSelector(state => state.appState.showMockPage);
    const [time, setTime] = useState<number>(0);
    useEffect(() => {
        setTime(new Date().getTime());
    }, []);
    const hideLogo = useTimedEffect(500);
    if (!section || !sectionIsWhatsappQuestion(section)) return null;
    const showLogo = !isTeamPage || hideLogo;
    //groups the messages by consecutive sender
    const groupedMessages = displayedMessages.reduce(
        (acc: T_WhatsappMessage[][], currentMsg) => {
            const lastGroup = acc[acc.length - 1];

            if (lastGroup && lastGroup[0]?.sender === currentMsg.sender) {
                lastGroup.push(currentMsg);
            } else {
                acc.push([currentMsg]);
            }

            return acc;
        },
        [],
    );

    return (
        <div className={`wa-wrapper ${!isTeamPage && 'desktop'}`}>
            <div
                className={`wa-logo ${showLogo && 'click'}`}
                id="wa-logo"
            ></div>
            <div className={`wa-ui ${showLogo && 'open'}`} id="wa-ui">
                <WhatsappAvatar typing={typing} senderName={'Michelle'} />
                <div className="wa-body">
                    <div className="wa-date">
                        <span> Vandaag </span>
                    </div>

                    <WhatsAppGroupedMessages
                        time={time}
                        isTeamPage={isTeamPage}
                        groupedMessages={groupedMessages}
                    />
                </div>{' '}
            </div>
            {isTeamPage && (
                <div
                    className={`wa-drawer ${showMockPage &&
                        isTeamPage &&
                        'open'} `}
                    id="wa-drawer"
                >
                    <div className="drawer-header">
                        <span
                            className="drawer-back-btn"
                            id="close-drawer"
                            onClick={() => dispatch(setShowMockPage(false))}
                        >
                            ‹ Terug
                        </span>
                        <span className="drawer-title">urlshrt1.ru</span>
                    </div>
                    <div className="drawer-body">
                        {showMockPage && (
                            <>
                                <MockPage html={section.mockPage} />
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
