import { sectionIsQuestion } from 'src/functions';
import {
    useAppDispatch,
    useAppSelector,
    useInteractionOpen,
    useSection,
} from '../../../../hooks';
import { findAvailableTeamId, selectRawTeamResponse } from '../../../../store';
import { confirmTeamResponse } from '../../../../store/thunks/confirmTeamResponse';
import { Button } from '../../../base/buttons';

export const ConfirmResponseButton = () => {
    const dispatch = useAppDispatch();
    const rawTeamResponse = useAppSelector(selectRawTeamResponse());
    const section = useSection();
    const interactionsOpen = useInteractionOpen();
    if (interactionsOpen) return null;
    const onClickEvent = (): void => {
        if (!rawTeamResponse) return;
        dispatch(confirmTeamResponse());
        dispatch(findAvailableTeamId());
    };
    const text =
        sectionIsQuestion(section) && section.type === 'Guess'
            ? 'OK'
            : 'Bevestig';
    const { response } = rawTeamResponse || { response: '' };
    //CLEAN THIS UP AND FIX IT FFS
    const showButton =
        response !== undefined &&
        response !== null &&
        response !== '' &&
        response !== '0' &&
        Array.isArray(response) &&
        response[0] !== 0;
    return (
        <Button
            id={'ignore-cypress'}
            isGhost={!showButton}
            isActive={showButton}
            buttonType="sleeping"
            className={text === 'OK' ? 'guess-button' : undefined}
            text={text}
            onClick={onClickEvent}
        />
    );
};
