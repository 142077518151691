import { T_Team } from '../../../../../globalTypes';
import { calculateFinalScore } from './calculateFinalScore';

//calculates the final position of all the teams and sorts them by points
export const calculateTeamRanking = (teams: T_Team[]) => {
    let position = 1;
    return calculateFinalScore(teams)
        .sort((a, b) => b.score - a.score)
        .map((team, index, array) => {
            if (array[index - 1] && array[index - 1].score !== team.score) {
                position = index + 1;
            }
            return {
                teamId: team.id,
                score: team.score,
                position,
                wonDecision: team.wonDecision,
            };
            //if wonDecision is true for a team, this team should be first in the ranking
        })
        .sort((a, b) => {
            if (teams.find(team => team.id === a.teamId)?.wonDecision) {
                return -1;
            }
            if (teams.find(team => team.id === b.teamId)?.wonDecision) {
                return 1;
            }
            return 0;
        });
};
