import { useEffect } from 'react';
import ReactConfetti from 'react-confetti';
import { useAppDispatch, useDecision } from 'src/hooks';
import useWindowDimensions from 'src/hooks/dimensions/useWindowDimensions';

import { postEndResponses } from 'src/store/thunks/postEndResponses';
import { ImageWrapper } from './ImageWrapper';
import './style.scss';

export const QuizThanksPage = () => {
    const decision = useDecision();
    const dispatch = useAppDispatch();
    const { width, height } = useWindowDimensions();
    //LAZY ASS CODING:
    useEffect(() => {
        dispatch(postEndResponses());
    }, []);
    return (
        <div className="winner-screen">
            <h1>Bedankt voor het spelen!</h1>
            <ImageWrapper/> 
            <div className="confetti-wrapper">
                <ReactConfetti 
                    width={width}
                    height={height}
                />
             </div>
        </div>
    ) 
        
};
