import { T_Team } from '../../../../globalTypes';

export const generateLocalTeams = (teamCount: number): T_Team[] => {
    let teamsArray: T_Team[] | [] = [];
    for (let i = 1; i <= teamCount; i++) {
        const team: T_Team = {
            id: i,
            responses: [],
        };
        teamsArray = [...teamsArray, team];
    }
    return teamsArray;
};
