import { Falsy, T_Question, T_Section } from '../../../../globalTypes';

export const sectionIsQuestion = (
    section: T_Section | Falsy,
): section is T_Question => {
    if (!section) return false;
    return (
        'sectionType' in section &&
        (section.sectionType === 'Question' ||
            section.sectionType === 'WAQuestion' ||
            section.sectionType === 'MMQuestion')
    );
};
