import { useAutoAnimate } from '@formkit/auto-animate/react';
import classNames from 'classnames';
import React from 'react';
import { settings } from 'src/config/settings';
import { noAnimationPhases } from 'src/config/ui/phases';
import styled from 'styled-components';
import { T_Phase } from '../../../../../globalTypes';
import { usePhase, useQuizIsLocal } from '../../../hooks';
import './style.scss';
export const CentralWrapper = ({
    children,
    enableAutoAnimation = true,
}: {
    children?: React.ReactNode;
    enableAutoAnimation?: boolean;
}) => {
    const phase = usePhase();
    const quizIsLocal = useQuizIsLocal();
    const [animationParent] = useAutoAnimate({
        ...settings.autoAnimationConfig,
    });
    const phaseKey =
        phase && [...noAnimationPhases].includes(phase) ? 'quiz' : phase;
    return (
        <Wrapper
            ref={
                phaseKey === 'quiz'
                    ? null
                    : enableAutoAnimation
                    ? animationParent
                    : null
            }
            quizIsLocal={quizIsLocal}
            key={phaseKey}
            id="central-wrapper"
            className={classNames('central-wrapper', {
                'never-vanilla-css-again': phase === 'decisionDraw',
            })}
            phase={phase}
        >
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div<{
    phase: T_Phase | undefined;
    quizIsLocal: boolean;
}>`
    flex-direction: ${props => {
        switch (props.phase) {
            case 'question':
            case 'decisionQuestion':
                return 'column';
            default:
                return 'column';
        }
    }};
    align-items: ${props => {
        switch (props.phase) {
            case 'lobby':
            case 'questionVideo':
                return '';
            case 'question':
            case 'decisionQuestion':
                return 'center';
            default:
                return 'center';
        }
    }};
    padding: ${props => {
        switch (props.phase) {
            case 'quizSelection':
                return '0';
            default:
                return '0 calc(var(--layout-gap) * 3)';
        }
    }};
    @media (max-width: 1600px) {
              padding: ${props => {
                  switch (props.phase) {
                      case 'quizSelection':
                          return '0';
                      default:
                          return '0 calc(var(--layout-gap) * 2)';
                  }
              }};
`;
