import { KeyControls } from '../controls/KeyControls';
import { NumberTeamControls } from '../controls/NumberTeamControls';
import { DevControls } from './DevControls';

export const DevPanel = () => {
    return (
        <>
            <NumberTeamControls />
            <DevControls />
            <KeyControls />
        </>
    );
};
