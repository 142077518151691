import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { T_RootState } from 'src/store';

//input able to change the state
export const StateInput = ({
    reducer,
    selector,
    placeholder,
    maxLength,
}: {
    reducer: ActionCreatorWithPayload<string>;
    selector: () => (state: T_RootState) => string | null;
    placeholder?: string;
    maxLength?: number;
}) => {
    const dispatch = useAppDispatch();
    const selectedValue = useAppSelector(selector());
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (maxLength && e.target.value.length > maxLength) return;
        dispatch(reducer(e.target.value));
    };
    return (
        <input
            value={selectedValue || ''}
            onChange={e => onChange(e)}
            placeholder={placeholder}
        />
    );
};
