import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getRequest } from 'src/api';
import { darkURL } from 'src/config';
import { parseFormToNoCodeLecture } from 'src/functions/helpers/parseFormToNoCodeLecture';
import { useAppDispatch, usePhase } from 'src/hooks';
import { CentralWrapper } from 'src/layout/styledcomponents';
import {
    setActiveLectureId,
    setActiveLectureLevel,
    setLectureIsTrial,
    setLectureIsWithoutCode,
    setPhase,
    setSchoolList,
} from 'src/store';
import { z } from 'zod';
import {
    T_DecisionPhase,
    T_Phase,
    T_QuestionPhase,
    T_QuizImagePhase,
    T_SchoolLevel,
} from '../../../../../globalTypes';
import { Button, MainTitle } from '../../../components';
import { ImageWrapper } from '../quiz/ImageWrapper';
import { EntryField } from './noCodeStart/EntryField';
export enum InputType {
    input = 'input',
    select = 'select',
    fill = 'fill', //ROLANDW
}

export type T_InputField<T extends InputType = InputType.input> = {
    type: T;
    label: string;
    options?: T extends InputType.select ? string[] : never;
};

export type T_InputFields = {
    schoolLevel: T_InputField<InputType.select>;
    bank: T_InputField<InputType.select>;
    guestLecturerName: T_InputField;
    guestLecturerEmail: T_InputField;
    schoolName: T_InputField;
    classLevel: T_InputField;
    postalCode: T_InputField;
    teacherName: T_InputField;
    teacherEmail: T_InputField;
};
const emailMessage = 'Geen geldige email';
const nameMessage = 'Voer een naam in';
const genericFields = {
    bank: z.string().min(3, { message: 'Kies een bank' }),
    guestLecturerName: z.string().min(3, { message: nameMessage }),
    guestLecturerEmail: z.string().email({ message: emailMessage }),
    schoolName: z.string().min(5, { message: 'Voer volledige naam school in' }),
    classLevel: z.string().min(1, {
        message: 'Voer niveau of groep in',
    }),
    postalCode: z
        .string()
        .refine(value => /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/.test(value), {
            message: 'Geen geldige postcode',
        }),
    teacherName: z.string().min(3, nameMessage),
    teacherEmail: z.string().email(emailMessage),
};
const formValidator = z.discriminatedUnion('schoolLevel', [
    z.object({
        schoolLevel: z.literal('PO', {
            required_error: 'Voer een type school in',
        }),
        ...genericFields,
    }),
    z.object({
        schoolLevel: z.literal('MBO'),
        fieldOfStudy: z.string().min(2, { message: 'Voeg studierichting toe' }),
        ...genericFields,
    }),
    z.object({
        schoolLevel: z.literal('VO'),
        level: z.string().min(2, { message: 'Voeg niveau toe' }),
        ...genericFields,
    }),
]);
export type DataFromFormNoCode = z.infer<typeof formValidator>;
export const NoCodeInputPage = () => {
    const dispatch = useAppDispatch();
    const [schoolLevel, setSchoolLevel] = useState<string>('PO');
    const [banks, setBanks] = useState<string[]>([]);
    const navigate = useNavigate();
    const phase = usePhase<
        Exclude<T_Phase, T_QuestionPhase | T_QuizImagePhase | T_DecisionPhase>
    >();
    useEffect(() => {
        if (phase !== 'noCodeStart') {
            navigate('/');
        }
        const fetchBanks = async () => {
            const response = await getRequest<string[]>(
                `${darkURL}/banks`,
                true,
            );
            setBanks(['', ...response]);
        };
        fetchBanks();
    }, []);
    const handleFormSubmit = (data: DataFromFormNoCode) => {
        const newData = parseFormToNoCodeLecture(data);
        //REFACTOR THIS SHIT ONE SHOULD NOT USE REDUX LIKE THIS, NOT MY PROBLEM
        dispatch(setSchoolList([newData]));
        dispatch(setActiveLectureLevel(newData.level as T_SchoolLevel));
        dispatch(setPhase('quizSelection'));
        dispatch(setLectureIsWithoutCode(true));
        dispatch(setLectureIsTrial(false));
        dispatch(setActiveLectureId(0));
        navigate('/selection');
    };
    const {
        register,
        handleSubmit,
        formState: { errors }, // Get formState.errors and make it available
    } = useForm<DataFromFormNoCode>({
        resolver: zodResolver(formValidator),
    });
    const levelLabel = schoolLevel === 'PO' ? 'Groep' : 'Leerjaar';
    const allFields = {
        schoolLevel: {
            type: 'select' as InputType,
            label: 'Onderwijsniveau',
            //LAC
            options: ['PO', 'VO', 'MBO'],
        },
        schoolName: {
            type: 'input' as InputType,
            label: 'Naam van school',
        },
        postalCode: {
            type: 'input' as InputType,
            label: 'Postcode school',
        },
        ...(['PO'].includes(schoolLevel) && {
            teacherName: {
                type: 'input' as InputType,
                label: 'Naam leerkracht',
            },
            teacherEmail: {
                type: 'input' as InputType,
                label: 'E-mail leerkracht',
            },
        }),
        ...(['MBO', 'VO'].includes(schoolLevel) && {
            teacherName: {
                type: 'input' as InputType,
                label: 'Naam docent',
            },
            teacherEmail: {
                type: 'input' as InputType,
                label: 'E-mail docent',
            },
        }),
        classLevel: {
            type: 'input' as InputType,
            label: levelLabel,
        },
        ...(['VO'].includes(schoolLevel) && {
            level: {
                type: 'select' as InputType,
                label: 'Niveau',
                options: [
                    '',
                    'VMBO',
                    'Mavo',
                    'Havo',
                    'Vwo',
                    'Lwoo',
                    'PRO',
                    'Overig',
                ],
            },
        }),
        ...(['MBO'].includes(schoolLevel) && {
            fieldOfStudy: {
                type: 'input' as InputType,
                label: 'Studierichting',
            },
        }),
        bank: {
            type: 'select' as InputType,
            label: 'Bank',
            options: banks,
        },
        guestLecturerName: {
            type: 'input' as InputType,
            label: 'Naam gastdocent',
        },
        guestLecturerEmail: {
            type: 'input' as InputType,
            label: 'E-mail gastdocent',
        },
    };
    const entries = Object.entries({ ...allFields });
    const fillArray = <T, U>(array: T[], fillValue: T) => {
        let _array = [...array];
        while (_array.length % 3 !== 0) {
            _array.push(fillValue);
        }
        return _array;
    };
    const _entries = fillArray(entries, null);
    return (
        <CentralWrapper>
            <ImageWrapper />
            <div className="form-section">
                <MainTitle
                    text="Vul gegevens van de school in"
                    underlined="de school"
                />
                <form
                    className="wrapper m"
                    onSubmit={handleSubmit(handleFormSubmit)}
                >
                    {_entries.map((field, index) => {
                        if (field === null) {
                            return <div className="form-field" />;
                        }
                        return (
                            <EntryField
                                key={field[0]}
                                fieldKey={field[0] as keyof T_InputFields}
                                field={field[1]}
                                register={register}
                                error={
                                    errors[field[0] as keyof T_InputFields]
                                        ?.message as string | undefined
                                }
                                setter={e => {
                                    if (field[0] !== 'schoolLevel') return;
                                    setSchoolLevel(e);
                                }}
                                tabIndex={index}
                            />
                        );
                    })}
                    <Button
                        tabIndex={entries.length + 1}
                        buttonType="primary"
                        type="submit"
                    >
                        Verstuur
                    </Button>
                </form>
            </div>
        </CentralWrapper>
    );
};
