import {
    T_Module,
    T_Quiz,
    T_QuizImagePhase,
    T_QuizPhase,
    T_Section,
    T_Stage,
} from '../../../../globalTypes';
import store from '../../store';
import { getFirstStage } from './getFirstStage';
import { getNextOrder } from './getNextOrder';
import { getNextPhase } from './getNextPhase';
import { T_CheckingStage } from './getPreviousStage';

type T_OutputStage = {
    moduleId: number | null;
    sectionId: number | null;
    phase: T_QuizPhase | 'goNextSection' | T_QuizImagePhase;
};

export const getNextStage = (
    quiz: T_Quiz,
    stage?: T_CheckingStage | null,
): T_OutputStage => {
    const quizState = store.getState().quizState;
    const currentStage = stage || {
        moduleId: quizState.currentModuleId,
        sectionId: quizState.currentSectionId,
        phase: quizState.quizPhase,
    };

    if (!currentStage || currentStage?.phase === 'quizTips') {
        return getFirstStage(quiz) as T_Stage;
    }
    const {
        moduleId: currentModuleId,
        sectionId: currentSectionId,
        phase: currentPhase,
    } = currentStage as T_Stage;
    const currentModule = quiz.modules.find((module: T_Module) => {
        return module.id === currentModuleId;
    });
    const currentSection = currentModule?.sections.find(section => {
        return section.id === currentSectionId;
    });
    const nextPhase = getNextPhase(
        currentPhase,
        currentStage?.phase?.includes('decision') ? undefined : currentSection,
    );
    let outroStage: T_OutputStage = {
        moduleId: null,
        sectionId: null,
        phase: 'quizWrapUp',
    };
    if (nextPhase.includes('decision')) {
        return { ...outroStage, phase: nextPhase };
    }
    if (
        (nextPhase === 'goNextSection' ||
            currentSection?.sectionType === 'Slide') &&
        currentSection
    ) {
        const nextSection = currentModule?.sections.find(
            (section: T_Section, index: number, array: T_Section[]) => {
                return (
                    section.order ===
                    getNextOrder<T_Section>(array, currentSection.order)
                );
            },
        );
        if (!nextSection) {
            const nextModule = quiz.modules.find((module: T_Module) => {
                return (
                    module.order ===
                    getNextOrder<T_Module>(
                        quiz.modules,
                        currentModule?.order || null,
                    )
                );
            });
            if (!nextModule) {
                return outroStage;
            }
            const nextSection = nextModule.sections.find(
                (section: T_Section, index: number, array: T_Section[]) => {
                    return (
                        section.order === getNextOrder<T_Section>(array, null)
                    );
                },
            );
            if (!nextSection) return outroStage;
            return {
                moduleId: nextModule.id,
                sectionId: nextSection.id,
                phase: getNextPhase('endOfSection', nextSection),
            };
        }
        return {
            moduleId: currentModuleId,
            sectionId: nextSection.id,
            phase: getNextPhase('endOfSection', nextSection),
        };
    }
    return {
        moduleId: currentModuleId,
        sectionId: currentSectionId,
        phase: nextPhase,
    };
};
