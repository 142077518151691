import { sectionIsQuestion } from 'src/functions';
import { getTimeColor } from 'src/functions/ui/getters/getTimeColor';
import { useSection, useTimer } from 'src/hooks';
import styled from 'styled-components';
import './style.scss';
export const ProgressBar = () => {
    const section = useSection();
    const time = useTimer();
    if (!section || !sectionIsQuestion(section)) return null;
    const maxTime = section.time;
    const progress = (time / maxTime) * 100;
    return (
        <>
            <ProgressDisplay time={time} progress={progress} />
        </>
    );
};

const ProgressDisplay = styled.div<{
    time: number;
    progress: number;
}>`
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: ${props => {
        return getTimeColor({ time: props.time, theme: props.theme });
    }};
    width: ${props => Math.round(props.progress)}%;
    height: 8px;
    border-radius: 8px;
`;
