import { getModuleFromQuiz } from 'src/functions/getters/getModuleFromQuiz';
import {
    T_AppDispatch,
    forceDisconnect,
    getQuizById,
    setLocalTeams,
    setQuizIsLocalChosen,
    setStage,
    setTeamCount,
} from '..';
import { T_Stage } from '../../../../globalTypes';

export const handlePreview = ({
    quizId,
    moduleId,
    sectionId,
}: {
    quizId: number;
    moduleId?: number;
    sectionId?: number;
}) => (dispatch: T_AppDispatch, getState: Function) => {
    if (!quizId) return;
    dispatch(setTeamCount(3));
    dispatch(setLocalTeams());
    dispatch(setQuizIsLocalChosen(true));
    dispatch(forceDisconnect());
    dispatch(getQuizById(quizId));
    const quiz = getState().quizState.quiz;
    const module = (moduleId && getModuleFromQuiz({ quiz, moduleId })) || null;
    let stage: T_Stage = {
        phase: !moduleId ? 'quizIntro' : 'questionVideo',
        moduleId: moduleId || null,
        sectionId: sectionId || module?.sections[0].id || null,
    };
    dispatch(setStage(stage));
};
