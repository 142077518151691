import styled from 'styled-components';
import { T_QuizPhase } from '../../../../../globalTypes';
import { getThemeColor } from '../../../functions';

export const QuestionDisplayWrapper = styled.div<{
    phase: T_QuizPhase | undefined;
    displayIntroWithQuestion: boolean | null;
}>`
    display: ${props => {
        switch (props.phase) {
            case 'answerReveal':
                return 'flex';
            default:
                return 'block';
        }
    }};
    flex-direction: ${props => {
        switch (props.phase) {
            case 'answerReveal':
                return 'column';
            default:
                return 'row';
        }
    }};
    width: ${props => {
        switch (props.phase) {
            case 'answerReveal':
                return '100%';
            case 'question':
            case 'decisionQuestion':
                return '100%';
            default:
                return '100%';
        }
    }};
    &:after {
        background: ${props => {
            if (props.displayIntroWithQuestion) {
                return `${getThemeColor({
                    theme: props.theme,
                    themeType: 'colors',
                    themeColor: 'dark',
                })}`;
            }
        }};
    }
    padding-left: ${props => {
        if (props.displayIntroWithQuestion) {
            return 'calc(var(--layout-gap) * 2)';
        }
    }};
    &.medium {
        max-width: 800px;
    }
    &.wide {
        max-width: 1000px;
    }
`;
