import { configureStore } from '@reduxjs/toolkit';

import appStateReducer from './appState/slice';
import quizStateReducer from './quizState/slice';
import socketStateReducer from './socketState/slice';
import timerStateReducer from './timerState/slice';

const store = configureStore({
    reducer: {
        quizState: quizStateReducer,
        socketState: socketStateReducer,
        timerState: timerStateReducer,
        appState: appStateReducer,
        // themeState: themeStateReducer,
    },
});

export type T_RootState = ReturnType<typeof store.getState>;
export type T_AppDispatch = typeof store.dispatch;
export * from './appState';
export * from './quizState';
export * from './socketState';
export * from './themeState';
export * from './thunks';
export * from './timerState';
export default store;
