import { InfoText, MainTitle } from 'src/components';
import { renderText } from 'src/functions';
import { IntroWrapper } from 'src/layout/styledcomponents/wrappers/IntroWrapper';
import { ImageWrapper } from './ImageWrapper';

export const QuizTipsPage = () => {
    return (
        <IntroWrapper className="width-tips">
            <ImageWrapper />
            <MainTitle text="Tips voor spelen op een digibord" />
            <InfoText
                textSize={'small'}
                text={renderText({
                    text: `Tip 1: Sluit alle lopende programma’s op de computer af voordat je de link opent.

                /n
                
                Tip 2: Gebruik bij voorkeur de browser Google Chrome.
                
                /n
                
                Tip 3: Houd de handleiding bij de hand en noteer de tussenstand per groepje op papier (loopt het digibord vast of valt de internet verbinding weg, dan kun je de scores handmatig verder bijhouden en het winnende groepje doorgeven via support.bankvoordeklas.nl).
                
                /n
                
                Tip 4: Zet het Digibord in ‘Volledig scherm modus’.

                /n

                Tip 5: Zet het geluid van het digibord aan en op een gemiddeld volume.
                
                `,
                })}
            />
        </IntroWrapper>
    );
};
