import { T_WhatsappMessage } from '../../../../globalTypes';
import { WhatsappMessage } from './WhatsappMessage';

export const WhatsAppGroupedMessages = ({
    groupedMessages,
    isTeamPage,
    time,
}: {
    groupedMessages: T_WhatsappMessage[][];
    isTeamPage?: boolean;
    time: number;
}) => {
    return (
        <>
            {groupedMessages.map(
                (groupedMessage: T_WhatsappMessage[], index) => {
                    const sender = groupedMessage[0].sender;
                    return (
                        <div
                            key={index}
                            className={`msg-group ${
                                sender === 'self' ? 'msg-out' : 'msg-in'
                            }`}
                        >
                            {groupedMessage.map((message, index, array) => {
                                return (
                                    <WhatsappMessage
                                        isTeamPage={isTeamPage}
                                        key={index}
                                        time={time}
                                        {...message}
                                    />
                                );
                            })}
                        </div>
                    );
                },
            )}
        </>
    );
};
