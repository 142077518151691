import { T_Quiz, T_Section } from '../../../../../globalTypes';
import { sectionIsQuestion } from '../../checkers';

//calculates the MAXIMUM amount of points a SINGLE team can get during the entire quiz
export const calculateMaxScore = (quiz?: T_Quiz | null): number => {
    let score = 0;
    if (!quiz) return score;
    quiz.modules.map(module => {
        module.sections.map((section: T_Section) => {
            if (!sectionIsQuestion(section)) return;
            score += section.points;
        });
    });
    return score;
};
