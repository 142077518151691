import {
    addModal,
    resetAllState,
    setInteraction,
    setIsConnected,
    setIsDisconnected,
    setOriginalSocketId,
    setPhase,
    setQuiz,
    setRoom,
    setRoomId,
    setSocketId,
    setStage,
    setStorage,
    setTeamDecision,
    setTeamDecisionResponse,
} from '../store';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTeamsUpdate } from 'src/hooks/teams/useTeamsUpdate';
import { appStorage } from '../functions';
import { useAppDispatch, useSocket } from '../hooks';
import { confirmTeamResponse } from '../store/thunks/confirmTeamResponse';

//Component only rendered when quizIsLocal in T_QuizState is set to false (which happens on connection)
export const SocketReceiver = (props: { testSetter?: Function }) => {
    const { testSetter } = props;
    const dispatch = useAppDispatch();
    const socket = useSocket();
    const navigate = useNavigate();
    const updateTeams = useTeamsUpdate();
    useEffect(() => {
        socket.connect();
        socket.on('connect', () => {
            appStorage.remove('reloadCount');
            appStorage.place('reloadCount', '0');
            dispatch(setIsConnected());
        });
        socket.on('disconnect', () => {
            dispatch(setIsDisconnected());
        });
        socket.on('setRoomId', roomId => {
            dispatch(setRoomId(roomId));
        });
        socket.on('setQuiz', quiz => {
            dispatch(setQuiz(quiz));
        });
        socket.on('setSocketId', socketId => {
            dispatch(setSocketId(socketId));
        });
        socket.on('setOriginalSocketId', originalSocketId => {
            dispatch(setOriginalSocketId(originalSocketId));
        });
        socket.on('setTeamDecision', teamIndices => {
            dispatch(setTeamDecision(teamIndices));
        });
        socket.on('setTeamDecisionResponse', response => {
            dispatch(setTeamDecisionResponse(response));
        });
        socket.on('setPhase', quizPhase => {
            dispatch(setPhase(quizPhase));
        });
        socket.on('setStage', stage => {
            dispatch(setStage(stage));
        });
        socket.on('setStorage', data => {
            dispatch(setStorage(data));
        });
        socket.on('updateTeams', teams => {
            updateTeams(teams);
        });
        socket.on('updateRoom', room => {
            dispatch(setRoom(room));
        });
        socket.on('teamConfirmResponse', response => {
            dispatch(confirmTeamResponse(response));
        });
        socket.on('teamSetGuessCorrect', () => {
            dispatch(
                setInteraction({
                    interactionKey: 'wonNonLocalGuess',
                    change: 'open',
                }),
            );
        });
        socket.on('resetStorage', () => {
            appStorage.clear();
        });
        socket.on('endLobby', () => {
            appStorage.clear();
            dispatch(resetAllState());
            navigate('../team');
        });
        socket.on('endGame', () => {
            appStorage.clear();
            dispatch(resetAllState());
            navigate('../einde-quiz');
        });
        socket.on('resetStorageRequest', () => {
            const storage = appStorage.retrieveSocketStorage();
            const { socketId, roomId, originalSocketId } = storage;
            if (!socketId || !roomId) {
                appStorage.clear();
                return;
            }
            socket.emit('reconnect', {
                originalSocketId,
                oldSocketId: socketId,
                roomId,
                confirm: true,
            });
        });
        socket.on('syncPage', () => {
            navigate('../team');
        });
        socket.on('getState', data => {
            if (!testSetter) return;
            testSetter(data);
        });
        socket.on('addModal', data => {
            dispatch(addModal(data));
        });

        return () => {
            socket.removeAllListeners();
        };
    }, []);
    return null;
};
