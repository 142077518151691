import { useState } from 'react';
import { sectionIsQuestion } from 'src/functions';
import { arrayOrder } from 'src/functions/helpers/arrays/arrayOrder';
import { usePhase, useSection } from '.';

const nullReturn = {
    images: null,
    activeSrc: null,
    incrementImageIndex: null,
    decrementImageIndex: null,
};

export const useQuestionImages = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const phase = usePhase();
    const section = useSection();
    if (!phase.endsWith('-images') || !sectionIsQuestion(section))
        return nullReturn;
    const images = section.questionImages?.filter(image => {
        return image.phase === phase.replace('-images', '');
    });
    if (!images) return nullReturn;
    const sortedImages = arrayOrder(images);
    const currentImageSrc = sortedImages[currentImageIndex].src;
    const finalIndex = images.length - 1;
    return {
        images: sortedImages,
        activeSrc: currentImageSrc,
        incrementImageIndex:
            currentImageIndex === finalIndex
                ? null
                : () => {
                      setCurrentImageIndex(currentImageIndex + 1);
                  },
        decrementImageIndex:
            currentImageIndex === 0
                ? null
                : () => {
                      setCurrentImageIndex(currentImageIndex - 1);
                  },
    };
};
