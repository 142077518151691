export const WhatsappAvatar = ({
    senderName,
    typing,
}: {
    senderName: string;
    typing: boolean;
}) => {
    return (
        <div className="wa-header">
            <div className="wa-avatar">
                <span className="wa-thumb">
                    <img
                        src={`assets/whatsApp/${senderName}.jpg`}
                        alt={senderName}
                        width="240"
                        height="240"
                    />
                </span>
                <div className="wa-info-wrapper">
                    <span className="wa-name">{senderName}</span>
                    <span className="wa-status" id="wa-status">
                        {typing ? 'Aan het typen...' : 'Online'}
                    </span>
                </div>
            </div>
        </div>
    );
};
