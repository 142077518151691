import {
    T_DatabaseResponseCorrectType,
    T_Question,
    T_Team,
    T_TeamResponse,
} from '../../../../../globalTypes';

//Compares the teams with each other in other to deduce who should receive points. Points update in real time.
export const calculateAwardedGuessPoints = (
    question: T_Question,
    teams: T_Team[],
) => {
    const currentQuestion = question;
    const correctResponse = currentQuestion.correctAnswers;
    if (!correctResponse || correctResponse.length === 0) return;
    const foundResponses = teams.map((team: T_Team) => {
        const foundResponse = team.responses.find(
            (_response: T_TeamResponse) => {
                return _response.questionId === currentQuestion.questionId;
            },
        );
        if (!foundResponse || !Array.isArray(foundResponse.response)) return;
        return {
            value: Number(foundResponse.response),
            teamId: foundResponse.teamId,
        };
    });
    type Response = { value: number | string; teamId: number } | undefined;
    const deltaArray = foundResponses
        .filter((response: Response) => {
            if (!response) return false;
            return true;
        })
        .map((response: Response) => {
            return {
                delta: Math.abs(
                    Number(correctResponse) - (response?.value as number),
                ),
                teamId: response?.teamId,
            };
        })
        .sort((response, _response) => {
            return response.delta - _response.delta;
        });
    let smallestDelta: number | null = null;
    const awardedTeams = deltaArray
        .filter((response: { delta: number; teamId: number | undefined }) => {
            const { delta, teamId } = response;
            if (teamId === undefined || !response) return false;
            if (smallestDelta === null) {
                smallestDelta = delta;
                return true;
            }
            if (smallestDelta === delta) {
                return true;
            }
            return false;
        })
        .map((response: { teamId: number | undefined }) => {
            return response.teamId;
        });
    return teams.map((team: T_Team) => {
        return {
            ...team,
            responses: team.responses.map((_response: T_TeamResponse) => {
                if (_response.questionId === currentQuestion.questionId) {
                    if (awardedTeams.includes(Number(team.id))) {
                        return {
                            ..._response,
                            points: currentQuestion.points,
                            isCorrect: 'WON_GUESS' as T_DatabaseResponseCorrectType,
                        };
                    }
                    return {
                        ..._response,
                        points: 0,
                        isCorrect: 'LOST_GUESS' as T_DatabaseResponseCorrectType,
                    };
                }
                return _response;
            }),
        };
    });
};
