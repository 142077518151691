import { T_Team } from '../../../../../globalTypes';
import { getJoinMessage } from '../getters/getJoinMessage';

export const drawJoinMessage = ({ team }: { team: T_Team }): string => {
    let text = getJoinMessage({ team });
    if (!text || !team.teamName) {
        return 'Een nieuw team speelt mee.';
    }
    text = text.replace(
        '[$team]',
        (team.teamName?.name).charAt(0).toLowerCase() +
            (team.teamName?.name).slice(1),
    );
    text = text.replace('[$Team]', team.teamName?.name);
    text = text.replace(
        '[$teamNoArt]',
        team.teamName?.name.replace('De ', '').replace('Het ', ''),
    );
    if (
        (team.teamName?.name.toLowerCase().endsWith('en') ||
            team.teamName?.name.toLowerCase().endsWith('s') ||
            team.teamName?.name.toLowerCase().endsWith('ci')) &&
        !team.teamName.name.includes('Het')
    ) {
        return text.replace('[$verb]', 'zijn');
    }
    return text.replace('[$verb]', 'is');
};
