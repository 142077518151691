import classNames from 'classnames';
import { settings } from 'src/config/settings';
import styled from 'styled-components';
import { T_Hitbox } from '../../../../globalTypes';

export const MockMobileHitboxes = ({
    hitboxes,
    setCurrentPage,
}: {
    hitboxes: T_Hitbox[] | null;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
    if (!hitboxes) {
        return (
            <StyledHitbox
                className="absolute"
                setCurrentPage={setCurrentPage}
                hitbox={{
                    x: 0,
                    y: 0,
                    height: settings.mockMobileSize.y,
                    width: settings.mockMobileSize.x,
                }}
            />
        );
    }
    return (
        <>
            {hitboxes.map((hitbox: T_Hitbox) => {
                return (
                    <StyledHitbox
                        className="absolute"
                        key={hitbox.x + hitbox.y}
                        setCurrentPage={setCurrentPage}
                        hitbox={hitbox}
                    />
                );
            })}
        </>
    );
};

const HitBox = ({
    setCurrentPage,
    className,
}: {
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    className?: string;
}) => {
    return (
        <div
            className={classNames('hitbox', className)}
            style={{}}
            onClick={() => {
                setCurrentPage(currentPage => currentPage + 1);
            }}
        />
    );
};

const StyledHitbox = styled(HitBox)<{ hitbox: T_Hitbox }>`
    left: ${({ hitbox }) => hitbox.x}px;
    top: ${({ hitbox }) => hitbox.y}px;
    height: ${({ hitbox }) => hitbox.height}px;
    width: ${({ hitbox }) => hitbox.width}px;
`;
