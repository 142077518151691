import {
    introLessMainTitles,
    introLessMainTitlesFirstQuestion,
    introMainTitles,
} from 'src/config/ui/introTitles';
import { thesisIntroTitles } from 'src/config/ui/thesisIntroTitles';
import { getRandomInteger } from 'src/functions/getters';
import { T_Question } from '../../../../../globalTypes';

export const drawQuestionIntroTitle = ({
    question,
    isFirstQuestion,
}: {
    question: T_Question;
    isFirstQuestion?: boolean;
}) => {
    const { introText } = question;
    if (!introText) {
        if (isFirstQuestion) {
            return introLessMainTitlesFirstQuestion[
                getRandomInteger(0, introLessMainTitlesFirstQuestion.length - 1)
            ];
        }
        if (question.type === 'Thesis') {
            return thesisIntroTitles[
                getRandomInteger(0, thesisIntroTitles.length - 1)
            ];
        }
        return introLessMainTitles[
            getRandomInteger(0, introLessMainTitles.length - 1)
        ];
    }
    return introMainTitles[getRandomInteger(0, introMainTitles.length - 1)];
};
