import { T_AppDispatch, setConfig } from '..';
import { T_PublicConfig, T_RawState } from '../../../../globalTypes';
import { handleGetRequest } from './handleGetRequest';

export const getServerConfig = () => async (
    dispatch: T_AppDispatch,
    getState: () => T_RawState,
) => {
    try {
        await dispatch(
            handleGetRequest<T_PublicConfig>(`/server/config/`, setConfig),
        );
    } catch (error) {
        console.log(error);
    }
};
