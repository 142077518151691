import { motion } from 'framer-motion';
import { settings } from 'src/config/settings';
import { Falsy, T_QuizPhase } from '../../../../../globalTypes';
import { Button } from './Button';
const MotionDiv = motion.div;
export const FooterButton = ({
    isActive: active,
    onClickEvent,
    text,
    phase,
    additionalText,
    isGhost,
    disableWrapperAnimation,
    disableButtonAnimation,
}: {
    isActive?: boolean;
    onClickEvent?: () => void;
    text?: string;
    additionalText?: string | Falsy;
    isGhost?: boolean;
    warningCallback?: () => void;
    phase?: T_QuizPhase;
    disableWrapperAnimation?: boolean;
    disableButtonAnimation?: boolean;
}) => {
    const initialDiv = disableWrapperAnimation
        ? { x: 0, opacity: 1 }
        : { x: 300, opacity: 0 };
    const initialButton = disableButtonAnimation
        ? { x: 0, opacity: 1 }
        : { x: 100, opacity: 0 };
    const isActive = active !== undefined ? active : true;
    return (
        <MotionDiv
            initial={initialDiv}
            key={phase}
            animate={{
                x: 0,
                opacity: 1,
            }}
            transition={{
                duration: settings.buttonCooldown,
                ease: 'easeInOut',
                delay: (phase === 'questionVideo') ? 4 : 0, 
            }}
            className="flex column space s priority-100 relative"
        >
            <span className="additional-text">
                {additionalText && additionalText}
            </span>
            <Button
                isGhost={isGhost}
                initial={initialButton}
                animate={{ x: 0, y: 0, opacity: 1 }}
                
                exit={{ x: 100, opacity: 0 }}
                text={text ? text : 'Verder'}
                buttonType="sleeping"
                isActive={isActive}
                onClick={() => {
                    onClickEvent && onClickEvent();
                }}
            />
        </MotionDiv>
    );
};
