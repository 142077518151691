import { QuizHeader, TeamHeader } from '.';
import { HeaderInnerWrapper } from './HeaderInnerWrapper';
import { HeaderWrapper } from './HeaderWrapper';
import { HeaderLogo } from './headerLogo/HeaderLogo';
import { RewardDisplay } from './rewardDisplay/RewardDisplay';
const TopBar = ({
    isQuiz,
    isMenu,
    isTeam,
}: {
    isQuiz?: boolean;
    isMenu?: boolean;
    isTeam?: boolean;
}) => {
    if (isQuiz) {
        return <QuizHeader />;
    }
    if (isMenu) {
        return null;
    }
    if (isTeam) {
        return <TeamHeader />;
    }
    return null;
};
export const Header = ({
    ...topbarProps
}: {
    isQuiz?: boolean;
    isMenu?: boolean;
    isTeam?: boolean;
}) => {
    return (
        <HeaderWrapper>
            <TopBar {...topbarProps} />
            <HeaderInnerWrapper>
                <HeaderLogo />
                <RewardDisplay />
            </HeaderInnerWrapper>
        </HeaderWrapper>
    );
};
