import classNames from 'classnames';

export const MPCOptionsWrapper = ({
    className,
    children,
}: {
    className?: string;
    children: React.ReactNode;
}) => {
    return (
        <div className={classNames(className, 'mpc-options-wrapper response')}>
            {children}
        </div>
    );
};
