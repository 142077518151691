import { Howl, HowlOptions } from 'howler';
import { T_SoundKey, defaultSoundOptions, sounds } from '../config/sound';
export const playSound = ({
    src,
    time,
    onend,
    options,
}: {
    src: T_SoundKey;
    time?: number;
    onend?: () => void;
    options?: Omit<HowlOptions, 'src' | 'volume' | 'html5'>;
}) => {
    const sound = new Howl({
        src: `/assets/sounds/${sounds[src]}`,
        onend: function() {
            if (!onend) return;
            onend();
        },
        ...defaultSoundOptions,
        ...options,
    });
    if (time) {
        sound.seek(time);
    }
    sound.play();
    return;
};
