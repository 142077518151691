import {
    useAppDispatch,
    useAppSelector,
    useLock,
    usePhase,
    useSection,
    useSelfHasResponded,
    useSelfResponse,
} from 'src/hooks';

import { sectionIsQuestion } from 'src/functions';
import { T_NormalQuestion } from '../../../../../../globalTypes';
import { selectRawTeamResponse, setRawTeamResponse } from '../../../../store';
import { StyledTeamButton } from './base/StyledTeamButton';

export const TeamClosedResponseOptionBox = ({
    text,
    id,
    revealAnswer,
    thesis,
}: {
    text: string | undefined;
    id: number;
    revealAnswer: boolean;
    thesis?: boolean;
}) => {
    const selfHasResponded = useSelfHasResponded();
    const rawTeamResponse = useAppSelector(selectRawTeamResponse());
    const currentSection = useSection<T_NormalQuestion<'Closed'>>();
    const selfResponse = useSelfResponse();
    const { lock } = useLock();
    const phase = usePhase();

    let correctAnswer: number | undefined;
    if (sectionIsQuestion(currentSection) && currentSection?.typeData) {
        correctAnswer = currentSection?.typeData?.leftCorrect === true ? 1 : 2;
    }
    const isCorrectAnswer = correctAnswer === selfResponse?.response;
    const selected =
        (Number.isInteger(rawTeamResponse?.response) &&
            rawTeamResponse?.response === id) ||
        (Number.isInteger(selfResponse?.response) &&
            selfResponse?.response === id);
    const dispatch = useAppDispatch();
    const onClickEvent = (id: number) => {
        lock();
        if (selfHasResponded || phase !== 'question') return;
        dispatch(
            setRawTeamResponse({
                type: thesis ? 'Thesis' : 'Closed',
                response: id,
            }),
        );
    };

    return (
        <StyledTeamButton
            selected={selected}
            revealAnswer={revealAnswer}
            isCorrectAnswer={isCorrectAnswer}
            className="socket-button"
            onClick={() => onClickEvent(id)}
        >
            {text}
        </StyledTeamButton>
    );
};
