import { questionTypes } from 'src/config/constants';
import {
    T_QuestionType,
    T_Quiz,
    T_TeamResponse,
} from '../../../../globalTypes';
import { getQuestionCountById } from './getQuestionCountById';

export const getResponseIndices = ({
    quiz,
    responses,
}: {
    quiz: T_Quiz;
    responses: T_TeamResponse[];
}): T_TeamResponse[] => {
    return responses.map(value => {
        if (!questionTypes.includes(value.questionType as T_QuestionType))
            return {
                ...value,
                positionIndex: 'NAQ',
            };
        return {
            ...value,
            positionIndex: getQuestionCountById(
                quiz,
                value.questionId,
            ).toString(),
        };
    });
};
