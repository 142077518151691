import { Fragment } from 'react';

//checks for newlines (/n) and creates a new <p></p> for each line
export const renderDrawNewLines = ({ text }: { text: string }) => {
    const newLines = Array(text).map(
        (child: React.ReactNode, _index: number) => {
            if (typeof child !== 'string') return <>{child}</>;
            if (child.includes('/n')) {
                const splitChild = child.split('/n');
                const elements = splitChild.map(
                    (child: string, index: number) => {
                        return <p key={index}>{child}</p>;
                    },
                );
                return <Fragment key={_index}>{elements}</Fragment>;
            }
            return <p key={_index}>{child}</p>;
        },
    );
    return <>{newLines}</>;
};
