import { NavigateFunction } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import { settings } from 'src/config/settings';
import { T_Phase } from '../../../../globalTypes';
import store, {
    T_AppDispatch,
    pauseTime,
    postStage,
    setButtonCooldown,
    setPhase,
    setQuizIsLocalChosen,
    setTime,
    setWarningPopUp,
} from '../../store';
import { sectionIsQuestion } from '../checkers';
import { getCurrentSectionInState, getPreviousStage } from '../getters';
import { appStorage } from '../helpers';
import { castEvent } from './castEvent';
import { createStageEvent } from './createStageEvent';

//used in components to move pages. Named "nextEvent" cause of the "next" button ("verder").
export const castPreviousEvent = ({
    socket,
    dispatch,
    navigate,
    forced,
}: {
    socket: Socket;
    dispatch: T_AppDispatch;
    navigate?: NavigateFunction;
    forced?: boolean;
}) => {
    const _buttonCooldown = settings.buttonCooldown;
    const { quizState, timerState, appState } = store.getState();
    const { quiz, quizPhase: currentPhase, quizIsLocal } = quizState;
    const { lectureIsTrial } = appState;
    if (currentPhase === 'quizSelection') {
        if (!lectureIsTrial) {
            dispatch(setPhase('lectureSelection'));
            return;
        }
        dispatch(setPhase('trialStart'));
        navigate && navigate('/speel-proef-versie');
        return;
    }
    if (
        currentPhase &&
        ['codeStart', 'lectureSelection'].includes(currentPhase)
    ) {
        dispatch(setPhase('preStart'));
        appStorage.clear();
        navigate && navigate('/');
        return;
    }

    if (currentPhase === 'lobby' && quizIsLocal) {
        dispatch(setPhase('quizSelection'));
        dispatch(setQuizIsLocalChosen(false));
        navigate && navigate('/selection');
        return;
    }
    if (currentPhase === 'localToggle') {
        dispatch(setQuizIsLocalChosen(false));
        dispatch(setPhase('quizSelection'));
        return;
    }
    if (currentPhase === 'noCodeStart') {
        dispatch(setPhase('codeStart'));
        appStorage.clear();
        navigate && navigate('/selection');
        return;
    }
    if (!quiz) return;
    const previousStage = getPreviousStage(quiz);
    if (!previousStage) return;

    const { moduleId, sectionId, phase: previousPhase } = previousStage;
    const currentQuestion = getCurrentSectionInState(
        store.getState().quizState,
    );
    const { buttonCooldown } = appState;
    if (buttonCooldown > 0 && !forced) return;
    dispatch(setButtonCooldown(_buttonCooldown));
    if (currentPhase === 'question') {
        if (
            timerState.timer > 0 &&
            !appState.interactions.warningPopUp.isOpen
        ) {
            dispatch(
                setWarningPopUp({
                    onAccept: 'previous',
                    onReject: 'continueQuestion',
                    acceptText: 'Ja, ga terug',
                    rejectText: 'Nee, verder spelen',
                    text: 'Weet je zeker dat je terug wilt gaan?',
                    invertedPosition: true,
                }),
            );
            dispatch(pauseTime());
            return;
        }
    }
    if (previousPhase === 'question') {
        if (!currentQuestion || !sectionIsQuestion(currentQuestion)) {
            castEvent(
                createStageEvent(socket, dispatch, {
                    moduleId: moduleId || null,
                    sectionId: sectionId || null,
                    phase: previousPhase,
                }),
            );
            dispatch(
                postStage({
                    moduleId: moduleId || null,
                    sectionId: sectionId || null,
                    phase: previousPhase,
                }),
            );
            return;
        }
        dispatch(setTime(currentQuestion.time));
    }
    if (previousPhase === 'goPreviousSection' || !previousPhase) return;
    const newStage = {
        moduleId: moduleId || null,
        sectionId: sectionId || null,
        phase: previousPhase as T_Phase,
    };
    castEvent(createStageEvent(socket, dispatch, newStage));
    if (previousStage.phase === 'quizSelection' && navigate) {
        dispatch(setQuizIsLocalChosen(false));
        navigate('/selection');
        return;
    }
    dispatch(postStage(newStage));
};
