import { calculateTeamRanking } from 'src/functions';
import { T_AppDispatch } from '..';
import { T_RawState } from '../../../../globalTypes';
import { handlePostRequest } from './handlePostRequest';

export const postEndResponses = () => async (
    dispatch: T_AppDispatch,
    getState: () => T_RawState,
) => {
    try {
        const { activeLectureLevel, activeLectureId } =
            getState().appState || {};
        const { teams, gameId } = getState().quizState || {};
        if (!teams || teams.length === 0 || !gameId)
            throw new Error('No teams found');
        const teamRankings = calculateTeamRanking(teams);
        const teamEndResponses = teamRankings.map(teamRanking => {
            const { teamId, score, position } = teamRanking;
            return {
                id: teamId,
                index: teamId,
                totalPoints: score,
                isWinner: position === 1 ? true : false,
            };
        });
        const decisionWinnerIndex =
            teams.find(team => team.wonDecision)?.id || null;
        dispatch(
            handlePostRequest({
                postData: {
                    responses: teamEndResponses,
                    gameId,
                    decisionWinnerIndex,
                    activeLectureId,
                    activeLectureLevel,
                },
                endPoint: '/responses/postEndResponses',
            }),
        );
        if (!activeLectureId || !activeLectureLevel) {
            throw new Error(
                'No active lecture found:' +
                    { activeLectureId, activeLectureLevel }.toString(),
            );
        }
    } catch (error) {
        console.log(error);
    }
};
