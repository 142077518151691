import { settings } from 'src/config/settings';
import { confirmTeamResponse } from 'src/store/thunks/confirmTeamResponse';
import { arrayKeepNumbers } from '../../../../../functions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import {
    findAvailableTeamId,
    selectRawTeamResponse,
    setRawTeamResponse,
} from '../../../../../store';
import { StyledOptionBox } from '../base/OptionBox';

export const MPCOptionBox = ({
    id,
    letter,
}: {
    id: number;
    letter: string;
}) => {
    const { responseDelayTime } = settings;
    const rawTeamResponse = useAppSelector(selectRawTeamResponse());
    const dispatch = useAppDispatch();
    const selected =
        Array.isArray(rawTeamResponse?.response) &&
        arrayKeepNumbers(rawTeamResponse?.response).includes(id);
    const onClickEvent = (): void => {
        dispatch(setRawTeamResponse({ type: 'MPC', response: id }));
        setTimeout(() => {
            dispatch(confirmTeamResponse());
            dispatch(findAvailableTeamId());
        }, responseDelayTime);
    };
    return (
        <StyledOptionBox selected={selected} onClick={() => onClickEvent()}>
            {letter}
        </StyledOptionBox>
    );
};
