import {
    T_Module,
    T_Quiz,
    T_QuizImagePhase,
    T_QuizPhase,
    T_RawState,
    T_Section,
    T_Team,
    T_TeamResponse,
} from '../../../../globalTypes';

import {
    getCurrentSection,
    getCurrentSectionInState,
    getNextStage,
    sectionIsQuestion,
} from '../../functions';

export const selectSelectedQuizId = () => (state: T_RawState) => {
    return state.quizState?.selectedQuizId;
};

//The current section is not directly stored in state, instead we store the moduleId and sectionId and then use this to find the proper question
//in the quiz
export const selectCurrentSection = () => (state: T_RawState) => {
    return state.quizState?.quiz?.modules
        .find(
            (module: T_Module) =>
                module.id === state.quizState?.currentModuleId,
        )
        ?.sections.find(
            (section: T_Section) =>
                section.id === state.quizState?.currentSectionId,
        );
};

export const selectNextSection = () => (state: T_RawState) => {
    const quiz = state.quizState?.quiz;
    if (!quiz) return;
    const nextStage = getNextStage(quiz);
    return state.quizState?.quiz?.modules
        .find((module: T_Module) => module.id === nextStage.moduleId)
        ?.sections.find(
            (section: T_Section) => section.id === nextStage.sectionId,
        );
};

export const selectDecisionQuestion = () => (state: T_RawState) => {
    return state.quizState?.quiz?.decisionQuestion;
};

export const selectCurrentModuleName = () => (state: T_RawState) => {
    return state.quizState?.quiz?.modules.find((module: T_Module) => {
        return module.id === state.quizState?.currentModuleId;
    })?.title;
};

export const selectCurrentStage = () => (state: T_RawState) => {
    return {
        moduleId: state.quizState?.currentModuleId,
        sectionId: state.quizState?.currentSectionId,
        phase: state.quizState?.quizPhase,
    };
};
export const selectQuizState = () => (state: T_RawState) => {
    return state.quizState;
};

export const selectQuizPhase = () => (
    state: T_RawState,
): T_QuizPhase | T_QuizImagePhase | undefined => {
    return state.quizState?.quizPhase;
};

export const selectQuizList = () => (state: T_RawState) => {
    return state.quizState?.quizList;
};

export const selectQuiz = () => (state: T_RawState): T_Quiz | null => {
    if (!state.quizState) return null;
    return state.quizState.quiz;
};

export const selectQuizIsLocal = () => (state: T_RawState) => {
    if (!state.quizState) return true;
    return state.quizState.quizIsLocal;
};

export const selectTeams = () => (state: T_RawState) => {
    if (state.quizState?.quizPhase?.includes('decision')) {
        return state.quizState?.teams?.filter(
            (team: T_Team) => team.inDecision,
        );
    }
    if (!state.quizState?.teams || state.quizState.teams.length === 0)
        return null;
    return state.quizState.teams;
};

export const selectTeamCount = () => (state: T_RawState) => {
    if (state.quizState?.quizIsLocal) return state.quizState.teamCount;
    return state.quizState?.teams?.filter((team: T_Team) => {
        return team.teamName?.id !== 0;
    }).length;
};

export const selectSelectedTeamId = () => (state: T_RawState) => {
    return state.quizState?.selectedTeamId;
};

export const selectRawTeamResponse = () => (state: T_RawState) => {
    return state.quizState?.rawTeamResponse;
};

//dirty selector to select a closed answer from a team in a different way
export const selectClosedResponseFromTeam = () => (state: T_RawState) => {
    if (!state.quizState) return;
    const currentQuestion = getCurrentSectionInState(state.quizState);
    if (!currentQuestion || !sectionIsQuestion(currentQuestion)) return;
    const foundTeam = state.quizState.teams?.find((team: T_Team) => {
        return team.id === state.quizState?.selectedTeamId;
    });
    if (!foundTeam) return;
    const foundResponse = foundTeam.responses.find(
        (response: T_TeamResponse) =>
            response.questionId === currentQuestion.questionId,
    );
    if (!foundResponse) return;
    return foundResponse.response;
};

export const selectOpenReponseFromTeam = () => (state: T_RawState) => {
    if (!state.quizState) return;
    const { selectedTeamId } = state.quizState;
    const currentQuestion = getCurrentSectionInState(state.quizState);
    if (!currentQuestion || !sectionIsQuestion(currentQuestion)) return;
    if (!selectedTeamId) return '';
    const openResponse = state.quizState.teams
        ?.find((team: T_Team) => team.id === state.quizState?.selectedTeamId)
        ?.responses.find(
            (response: T_TeamResponse) =>
                response.questionId === currentQuestion.questionId,
        )?.inputAnswers;
    if (openResponse && typeof openResponse === 'string') {
        return openResponse;
    }
    return 'Geen antwoord verstuurd';
};

export const selectTeamHasResponded = (id: number) => (
    state: T_RawState,
): boolean => {
    const currentPhase = state.quizState?.quizPhase;
    if (currentPhase?.includes('decision')) {
        const currentTeam = state.quizState?.teams?.find(
            (team: T_Team) => team.id === id,
        );
        if (!currentTeam) return false;
        return currentTeam.decisionResponse !== 0;
    }
    const currentTeam = state.quizState?.teams?.find(
        (team: T_Team) => team.id === id,
    );
    const currentQuestion = getCurrentSection();
    if (!currentTeam || !sectionIsQuestion(currentQuestion)) return false;
    const responsesByTeam = currentTeam.responses.map(
        (teamResponse: T_TeamResponse) => {
            const { questionId } = teamResponse;
            //Remove open responses that have not been answered by the guest lecturer
            if (
                teamResponse.questionType === 'Open' &&
                teamResponse.response === -1
            ) {
                return -1;
            }
            return questionId;
        },
    );
    return responsesByTeam.includes(currentQuestion.questionId);
};
