import { useState } from 'react';
import ReactConfetti from 'react-confetti';
import { getTeamColor } from 'src/functions/ui/getters/getTeamColor';
import useWindowDimensions from 'src/hooks/dimensions/useWindowDimensions';
import { calculateTeamRanking } from '../../../functions/helpers/calculations/calculateTeamRanking';
import { useTeams } from '../../../hooks';


export const TeamResults = ({ decision }: { decision?: boolean }) => {
    const teams = useTeams();
    const [_teams] = useState(teams);
    const { width, height } = useWindowDimensions();
    if (!_teams) return null;

    const sortedTeams = calculateTeamRanking(_teams).sort(
        (a, b) => b.score - a.score,
    );
    const topThreeTeams = sortedTeams.slice(0, 3);
    const endScores = topThreeTeams.map((team, index) => {
        const newPosition = index + 1; // This makes sure positions are 1, 2, 3
        return (
            <div className={`team-column pos-${newPosition}`} key={team.teamId}>
                <div className="id-wrapper">Team {team.teamId}</div>
                <div
                    className="position-wrapper"
                    style={{ backgroundColor: getTeamColor(team.teamId) }}
                >
                    <span style={{color: 'white'}}>#{newPosition}</span>
                </div>
                <div
                    className="score-wrapper"
                    style={{ border: `1px solid ${getTeamColor(team.teamId)}` }}
                >
                    €{team.score}
                </div>
            </div>
        );
    });

    return (
        <>
            {endScores}
            <div className="confetti-wrapper">
                <ReactConfetti 
                    width={width}
                    height={height}
                />
            </div>
        </>
    );
};
