import { T_Lecture } from '../../../../../globalTypes';

export const formatLecture = <TG_Type extends 'VO' | 'MBO' | 'PO' | 'All'>(
    lecture: T_Lecture<TG_Type>,
): T_Lecture<'All'> => {
    let yearData: string | null = null;
    if (lecture.studierichting) {
        //MBO
        yearData = lecture.jaar + ' ' + lecture.studierichting;
    }
    if (lecture.groepnumber) {
        //PO
        yearData = lecture.groepnumber + ' ' + lecture.groepnaam;
    }
    if (lecture.niveau) {
        //VO
        yearData = lecture.jaar + ' ' + lecture.niveau;
    }
    if (!yearData) {
        yearData = ' ';
    }

    return {
        yearData,
        bank: lecture.bank,
        code: lecture.code,
        email: lecture.email,
        email_teacher: lecture.email_teacher,
        prefix: lecture.prefix,
        firstname: lecture.firstname,
        lastname: lecture.lastname,
        hash: lecture.hash,
        id: lecture.id,
        internal_notes: lecture.internal_notes,
        phone: lecture.phone,
        postcode: lecture.postcode,
        school_id: lecture.school_id,
        school_name: lecture.school_name,
        teacher: lecture.teacher,
        teacher_code: lecture.teacher_code,
        tel_teacher: lecture.tel_teacher,
    };
};
