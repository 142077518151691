import classNames from 'classnames';
import React from 'react';
import { sectionIsQuestion, sectionIsSlide } from 'src/functions';
import { useSection } from 'src/hooks';
import { InfoTextWrapper } from 'src/layout/styledcomponents/wrappers/typography/InfoText';
import styled from 'styled-components';
import type { Falsy, T_TextSize } from '../../../../../globalTypes';
import {
    T_QuestionType,
    T_QuizPhase,
    T_Section,
} from '../../../../../globalTypes';
import { MainTitle } from '../../base/typography/MainTitle';
import { AnswerDisplay } from '../questionAnswerPage';
import { QuestionDisplayWrapper } from './QuestionDisplayWrapper';

import './style.scss';
//Displays the text of each question e.g. "Welke koe is dit?"
export const QuestionDisplay = ({
    questionText,
    underlinedText,
    phase,
    textSize,
    questionTextWide
}: {
    phase: T_QuizPhase;
    questionText: string | undefined | null;
    underlinedText?: string | Falsy
    textSize?: T_TextSize
    questionTextWide: boolean | null
}) => {
    const section = useSection();
  
        
    if (!questionText || sectionIsSlide(section)) return null;
    const phaseDisplaysQuestion = phase === 'question' || phase === 'decisionQuestion' || phase === "questionWhatsapp" || phase === "answerReveal"
    return (
        <PhaseWrapper phase={phase} section={section}>


            {phase === 'decisionQuestion' && (
                <>  
                    <MainTitle text="Beslissingsvraag" />
                    <InfoTextWrapper phase={'decision'} className="info-text text-center ">
                        {questionText}
                    </InfoTextWrapper>
                </>
              
                          
                         
            )}


            {phase !== 'decisionQuestion' && (
                <QuestionDisplayWrapper
                className={classNames({
                    'medium': !questionTextWide,
                    'wide': questionTextWide
                })}
                    phase={phase}
                    displayIntroWithQuestion={
                        section?.displayIntroWithQuestion || false
                    }
                >
                    {phaseDisplaysQuestion && (
                        <MainTitle
                            className={classNames({
                                'size-32': textSize === 'small',
                                'size-36': textSize === 'medium',
                                'size-40': textSize === 'large',
                            })}
                            underlined={underlinedText}
                            text={questionText}
                        />
                    )}
                        <>
                            {!phaseDisplaysQuestion && (
                                <MainTitle className="centered-text-compact" key={phase}  underlined={underlinedText}
                                text={questionText}
                            />                              
                            )}
                            <AnswerDisplay phase={phase} />
                        </>
                </QuestionDisplayWrapper>
            )}

            


            
        </PhaseWrapper>
    );
};

const PhaseWrapper = ({
    phase,
    children,
    section,
}: {
    phase?: T_QuizPhase;
    children: React.ReactNode;
    section?: T_Section;
}) => {
    if (!sectionIsQuestion(section)) return <>{children}</>;
    if (phase === 'answerReveal')
        return (
            <>
                <AnswerRevealWrapper
                    phase={phase}
                    className="answer-reveal-wrapper"
                    type={section.type}
                >
                    {children}
                </AnswerRevealWrapper>
            </>
        );
    return <>{children}</>;
};

export const AnswerRevealWrapper = styled.div<{
    phase: T_QuizPhase | undefined;
    type?: T_QuestionType;
}>`
    gap: 120px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`;
