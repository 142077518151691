import { useEffect, useState } from 'react';
import { sectionIsQuestion } from 'src/functions';
import { T_Phase, T_Question } from '../../../../globalTypes';
import { usePhase, useQuestionCount, useSection } from '../quiz';

export const useSectionText = <
    T extends string | { text: string; underlined?: string }
>({
    drawer,
}: {
    drawer: ({
        question,
        phase,
        isFirstQuestion,
    }: {
        question: T_Question;
        phase?: Partial<T_Phase>;
        isFirstQuestion?: boolean;
    }) => T;
}) => {
    const section = useSection();
    const phase = usePhase();
    const { currentQuestionCount } = useQuestionCount();
    const [sectionText, setSectionText] = useState<T | null>(null);
    useEffect(() => {
        if (sectionText) return;
        if (sectionIsQuestion(section)) {
            setSectionText(() =>
                drawer({
                    question: section,
                    phase,
                    isFirstQuestion: currentQuestionCount === 1,
                }),
            );
        }
    }, [section]);
    return sectionText;
};
