import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useSectionTransition } from 'src/hooks/quiz/useSectionTransition';
import { RewardDisplayCircle } from '../../../../components/header/rewardDisplayCircle/RewardDisaplyCircle';
import './style.scss';

export const SectionFirstQuestion = () => {
    const {
        questionCount,
        totalQuestionCount,
        questionText,
        reward,
        startAnimation,
    } = useSectionTransition();

    const questionTextAsClass = questionText?.toLowerCase().replace(' ', '-');

    return (
        <div className={`transition-wrapper ${questionTextAsClass}`}>
            <img src="" alt="" />
            {/*{`${questionCount} ${totalQuestionCount} ${questionText} ${reward} ${startAnimation} `}*/}
            <div className="current-question-wrapper">
                <div className="current-number">
                    <strong>{questionCount}/</strong>
                </div>
                <div className="total-number">{totalQuestionCount}</div>
            </div>
            <motion.div className={classNames('quizmaster-wrapper')}>
                <span>Verdien</span>
                <RewardDisplayCircle points={reward} animate={true} />
            </motion.div>
        </div>
    );
};
