import { getRandomInteger } from 'src/functions/getters/getRandomInteger';
import {
    T_NormalQuestion,
    T_Phase,
    T_Question,
} from '../../../../../globalTypes';
//ENNE?
export const drawQuestionIntro = ({
    question,
    phase,
    isFirstQuestion,
}: {
    question: T_Question;
    phase?: T_Phase;
    isFirstQuestion?: boolean;
}) => {
    let text = '';
    if (question.introText) {
        text = question.introText;
    }
    if (
        question.displayIntroWithQuestion &&
        phase === 'question' &&
        !['Closed', 'Thesis'].includes(question.type)
    ) {
        return text;
    }
    const nextString = isFirstQuestion ? 'eerste' : 'volgende';
    const nextOpenQuestionTexts: string[] = [
        `${
            nextString === 'eerste' ? 'Deze vraag is' : 'Er volgt nu'
        } een open vraag, schrijf je antwoord op.`,
        'Deze vraag is een open vraag.',
    ];
    const nextGuessQuestionTexts: string[] = [
        'Er volgt nu een open vraag.',
        'Deze vraag is een schattingsvraag.',
    ];

    switch (question.type) {
        case 'Open':
            text += `${question.introText ? ' /n' : ''}${
                nextOpenQuestionTexts[
                    getRandomInteger(0, nextOpenQuestionTexts.length - 1)
                ]
            }`;
            break;
        case 'Guess':
            text += `${question.introText ? ' /n' : ''}${
                nextGuessQuestionTexts[
                    getRandomInteger(0, nextGuessQuestionTexts.length - 1)
                ]
            } Het team dat het dichtst in de buurt zit van het juiste antwoord krijgt punten.`;
            break;
        case 'MPC':
            if (!question.introText) {
                text = `De ${nextString} vraag is een meerkeuzevraag.`;
            }
            break;
        case 'Closed': {
            const { closedLeft, closedRight } =
                (question.typeData as T_NormalQuestion<'Closed'>['typeData']) ||
                {};
            text += `${
                question.introText ? ' /n' : ''
            }Is de volgende stelling  @[b${closedLeft?.toLowerCase() ||
                'waar'}]@ of @[b${closedRight?.toLowerCase() ||
                'niet waar'}]@?`;
            break;
        }
        case 'Thesis': {
            const { closedLeft, closedRight } =
                (question.typeData as T_NormalQuestion<'Thesis'>['typeData']) ||
                {};
            text += `${
                question.introText ? ' /n' : ''
            }Er volgt nu een stelling, er zijn geen punten te verdienen. Zijn jullie het @[b${closedLeft?.toLowerCase()}]@ of @[b${closedRight?.toLowerCase()}]@ met de volgende stelling?`;
            break;
        }
        default:
            break;
    }
    return text;
};
