import { settings } from 'src/config/settings';
import './style.scss';
export const MockMobileWrapper = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <div
            className="mockMobileWrapper"
            style={{
                height: settings.mockMobileSize.y,
                width: settings.mockMobileSize.x,
            }}
        >
            {children}
        </div>
    );
};
