import { T_AppDispatch } from '../../store';
import { Event } from './castEvent';
//Used to create an event in NON socket components
export const createLocalEvent = (
    dispatch: T_AppDispatch,
    reducer: Function,
    reducerPayload?: any,
): Event => {
    return { socket: null, endPoint: null, dispatch, reducer, reducerPayload };
};
