import classNames from 'classnames';

export const ClosedOptionsWrapper = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) => {
    return (
        <div className={classNames('closed-options-wrapper', className)}>
            {children}
        </div>
    );
};
