import { drawWelcomeMessage } from 'src/functions/ui/drawers/drawWelcomeMessage';
import { useSelfTeam } from 'src/hooks';

export const TeamChooseName = () => {
    const selfTeam = useSelfTeam();
    const showChooseName = !selfTeam?.teamName?.id && selfTeam?.sentTeamNames;
    if (showChooseName) {
        return <p>Kies jullie teamnaam!</p>;
    }
    return <p>{drawWelcomeMessage({ team: selfTeam })}</p>;
};
