import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { T_ThemeColors, T_ThemeStateHistory } from '../../../../globalTypes';
import { defaultColors } from '../../config/settings';

const initialState: T_ThemeStateHistory = {
    ...defaultColors,
    themeHistory: [],
    inTransition: false,
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        resetThemeState: state => {
            return initialState;
        },
        setTheme: (
            state,
            action: PayloadAction<{
                key: keyof Omit<T_ThemeStateHistory, 'inTransition'>;
                theme: T_ThemeColors;
            }>,
        ) => {
            const { key, theme } = action.payload;
            if (key === 'themeHistory') return;
            state[key].colors = theme.colors;
            state[key].boxShadows = theme.boxShadows;
        },
        // setKey: (
        //     state,
        //     action: PayloadAction<{
        //         key: keyof Omit<T_ThemeState, 'inTransition'>;
        //         colorKey: keyof T_ThemeColors;
        //         color: string;
        //     }>,
        // ) => {
        //     const { key, color, colorKey } = action.payload;
        //     if (key === 'themeHistory') return;
        //     state[key][colorKey] = color;
        // },
        // generateThemeList: (state, action: PayloadAction<T_Quiz>) => {
        //     const quiz = action.payload;
        //     const questionIds = getQuestionIdsFromQuiz(quiz);
        //     let previousThemes: {
        //         sectionId: number;
        //         themeId: number;
        //     }[] = [];
        //     const themeList = questionIds.map((id: number, index: number) => {
        //         if (index === 0) {
        //             previousThemes = [
        //                 ...previousThemes,
        //                 { sectionId: id, themeId: 20 },
        //             ];
        //             return { sectionId: id, themeId: 20 };
        //         }
        //         const randomTheme = getRandomTheme(previousThemes);
        //         previousThemes = [
        //             ...previousThemes,
        //             { sectionId: id, themeId: randomTheme.id },
        //         ];
        //         return { sectionId: id, themeId: randomTheme.id };
        //     });
        //     state.themeHistory = themeList;
        // },
        setInTransition: state => {
            state.inTransition = true;
        },
        unsetInTransition: state => {
            state.inTransition = false;
        },
    },
});

export const {
    setTheme,
    resetThemeState,
    setInTransition,
    unsetInTransition,
} = themeSlice.actions;

export default themeSlice.reducer;
