import { useEffect, useState } from 'react';
import { castEvent, createSocketEvent } from '../../../functions';
import {
    useAppDispatch,
    useAppSelector,
    usePhase,
    useQuizIsLocal,
    useSocket,
    useTimedCallback,
} from '../../../hooks';
import { forceConnect, selectRoomId } from '../../../store';

import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components';

export const TeamJoinPage = () => {
    const [roomIdInput, setRoomIdInput] = useState<string>('');
    const [showPage, setShowPage] = useState<boolean>(false);
    useTimedCallback({ callback: () => setShowPage(true), duration: 2000 });
    //LAZY ASS CODING, pagina wordt 2000 ms later weergegeven zodat je bij het reconnecten de button niet ziet LOL, fix dit als je iets slimmers weet, BruceW
    const socket = useSocket();
    const phase = usePhase();
    const roomId = useAppSelector(selectRoomId());
    const { roomId: roomParams } = useParams();
    const quizIsLocal = useQuizIsLocal();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (quizIsLocal) {
            dispatch(forceConnect());
            socket.connect();
        }
        if ((roomId && roomId === roomParams) || phase === 'lobby') {
            navigate('../team');
        }
        if (roomId && roomId !== roomParams) {
            navigate(`../team/${roomId}`);
        }
    }, [roomId, roomParams, phase]);

    useEffect(() => {
        if (roomParams && socket) {
            if (roomParams.toLowerCase() === 'spelen') {
                setRoomIdInput(() => '');
                return;
            }
            setRoomIdInput(() => roomParams);
            castEvent(
                createSocketEvent(socket, 'joinRoom', roomParams.toUpperCase()),
            );
        }
    }, [roomParams, socket]);

    const onClickEvent = () => {
        if (roomIdInput.length !== 5) return;
        castEvent(createSocketEvent(socket, 'joinRoom', roomIdInput));
    };
    if (roomId && roomId !== roomParams)
        return null;
    return (
        <>
            <motion.div
                style={{ opacity: showPage ? 1 : 0, y: showPage ? 0 : 100 }}
                className="socket-start-wrapper"
            >
                <p className="font-sans weight-700 size-28 text-center">
                    Vul de code in die op het scherm staat
                </p>
                <input
                    className="socket-start-input"
                    maxLength={5}
                    value={roomIdInput}
                    onChange={e => setRoomIdInput(e.target.value.toUpperCase())}
                />
                <Button
                    isGhost={roomIdInput.length !== 5}
                    isActive={roomIdInput.length === 5}
                    buttonType="sleeping"
                    text={'Doe mee!'}
                    onClick={onClickEvent}
                />
            </motion.div>{' '}
        </>
    );
};
