import { T_Phase, T_ThemeColors, T_ThemeState } from '../../../globalTypes';
import { appConfig } from './constants';
import { tipsPhases } from './ui/phases';
const microSettings: {
    timerDivider: number;
    tipsPhases: T_Phase[];
} = {
    timerDivider: 10,
    tipsPhases,
};

export const settings = {
    timerMultiplier: 4,
    timerDecrease: 1 / microSettings.timerDivider,
    timerSpeed: 1000 / microSettings.timerDivider,
    decisionTime: 60,
    maxTeams: 10,
    maxAnimationTime: 0.3,
    MPCLetterArray: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'],
    openQuestionMaxInputLength: 120,
    responseDelayTime: 200, //time in MS it takes to confirm a response at the questionResponsePage
    revealAnswerTime: 4000, //time it takes to show the correct answer at the answerRevealPage
    revealAnswerExitTime: 2500, //time it takes to exit the answerRevealPage once the correct answer is shown
    hidePlayButtonTime: 3300, //time it takes to hide the playButton for a video
    thesisDelayTime: 500, //time in MS that it takes to change the color of the box for theses at the answerRevealPage
    outroAnimationStart: 3750, //time it takes to show the outro text, excluding answerRevealTime
    buttonCooldown: 1.3, //amount of seconds it takes to allow the client to press the nextButton again, aka maximaCooldown named after the Argentinian woman
    scoreAnswerRevealStart: 1750, //time it takes to show the response score at the answerRevealPage
    scoreAnimationStart: 2000, //time it takes to update the score at the scoreRevealPage
    teamButtonToggleTime: 1500, //time it takes in MS to toggle between confirmButton and changeAnswerButton during teamPages
    decisionRevealStart: 600, //time it takes to show the decision teams at decision
    tipsPhases: microSettings.tipsPhases,
    sectionTransitionTime: 3000,
    animationExitTime: 500, //time in MS that it takes to exit the demo animation
    autoAnimationConfig: { duration: 250 },
    appConfig: {
        ...appConfig,
    },
    mockMobileSize: {
        x: 213,
        y: 444,
    },
} as const;

export const boxColors = {
    correct: '#0A804D',
    incorrect: '#FE5804',
    selected: '#1691BE',
    unselected: '#FDFBFE',
};

export const mainColors = {
    primary: '#334D56',
    warning: '#FFB800',
    error: '#FE5804',
    correct: '#0A804D',
    ctaDark: '#9D4C00',
    coolBackground: '#FDFBFE',
};

const mainTheme: T_ThemeColors = {
    colors: {
        id: 1337,
        primary: mainColors.primary,
        creamyBackground: mainColors.coolBackground,
        coolBackground: '#FDFBFE',
        ctaPrimary: '#FF7C03',
        warning: mainColors.warning,
        error: mainColors.error,
        white: '#FFFFFF',
        input: '#1691BE',
        correct: mainColors.correct,
        ctaDark: mainColors.ctaDark,
        background: '#FFFAF2',
        dark: '#533D4C',
        border: '#db9aca',
    },
    boxShadows: {
        primary: `0px 4px 0px 0px ${mainColors.primary}`,
        warning: `0px 4px 0px 0px ${mainColors.warning}`,
        correct: `0px 4px 0px 0px ${mainColors.correct}`,
        error: `0px 4px 0px 0px ${mainColors.error}`,
        ctaDark: `0px 4px 0px 0px ${mainColors.ctaDark}`,
        coolBackground: `0px 4px 0px 0px ${mainColors.coolBackground}`,
    },
};
//DONT REMOVE THIS IT WILL BREAK THE WHOLE APP
export const randomThemes = [{ ...mainTheme }];

export const defaultColors: Omit<
    T_ThemeState,
    'themeHistory' | 'inTransition'
> = {
    main: mainTheme,
};

// export const allThemeColors = randomThemes
//     .map(theme => {
//         return [
//             theme.primary,
//             theme.background,
//             theme.dark,
//             theme.border,
//             theme.gradient,
//         ];
//     })
//     .flat();

export const teamColors: { [key: string]: string } = {
    yellow: '#F09E22',
    blue: '#1691BE',
    pink: '#C64B8D',
    green: '#0A804D',
};

export const confettiColors = ['#F09E22', '#1691BE', '#C64B8D', '#0A804D'];
