import { T_ListInformation } from 'src/components/base/list/ListScroll';
import { T_SchoolData } from '../../../../../globalTypes';
import { getSchoolColor } from '../getters/getSchoolColor';

type SchoolData = T_ListInformation & { level: 'VO' | 'MBO' | 'PO' };
export const drawSchoolInformation = (
    schoolData: T_SchoolData<'All'>[],
): SchoolData[] => {
    let ordering = 0;
    return schoolData
        .map((schoolData: T_SchoolData<'All'>, index) => {
            return schoolData?.lectures
                ?.slice()
                ?.sort((a, b) =>
                    a.yearData.toString().localeCompare(b.yearData.toString()),
                )
                ?.map(lecture => {
                    ordering += 1;
                    return {
                        id: lecture.id,
                        title: schoolData.school_name,
                        tags: [lecture.yearData],
                        cardColor: getSchoolColor(index),
                        ordering,
                        level: schoolData.level,
                    };
                });
        })
        .flat()
        .filter(lecture => lecture !== undefined) as SchoolData[];
};
