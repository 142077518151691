import { T_Team } from '../../../../globalTypes';

const place = (keyName: string, keyValue: string) => {
    localStorage.setItem(keyName, keyValue);
};

const retrieve = (keyName: string): string | null => {
    return localStorage.getItem(keyName);
};

const clear = (clearToken?: boolean): void => {
    const token = retrieve('token');
    const guestLecturerCode = retrieve('guestLecturerCode');
    localStorage.clear();
    if (token && !clearToken) {
        place('token', token);
    }
    if (guestLecturerCode) {
        place('guestLecturerCode', guestLecturerCode);
    }
};

const remove = (keyName: string): void => {
    localStorage.removeItem(keyName);
};

export const appStorage = {
    place,
    retrieve,
    clear,
    clearToken: () => {
        clear(true);
    },
    clearGuestLecturerCode: () => {
        remove('guestLecturerCode');
    },
    saveGuestLecturerCode: (code: string) => {
        place('guestLecturerCode', code);
    },
    saveToken: (token: string) => {
        place('token', token);
    },
    saveGameId: (id: string) => {
        place('gameId', id.toString());
    },
    saveSessionId: (id: string) => {
        place('sessionId', id.toString());
    },
    saveTeam: (team: T_Team) => {
        place('teamIndex', team.id.toString());
        if (team.socketId) {
            place('socketId', team.socketId);
            place('originalSocketId', team.originalSocketId || team.socketId);
        }
    },
    saveSocketId: (socketId: string) => {
        place('socketId', socketId);
    },
    saveOriginalSocketId: (originalSocketId: string) => {
        place('originalSocketId', originalSocketId);
    },
    saveTeamIndex: (teamIndex: number) => {
        place('teamIndex', teamIndex.toString());
    },
    saveRoomId: (roomId: string) => {
        place('roomId', roomId);
    },
    saveHost: () => {
        place('isHost', 'true');
    },
    remove: (keyName: string) => {
        remove(keyName);
    },
    retrieveGuestLecturerCode: (): string | null => {
        return retrieve('guestLecturerCode');
    },
    retrieveToken: (): string | null => {
        return retrieve('token');
    },
    retrieveGameId: (): string | null => {
        return retrieve('gameId');
    },
    retrieveSessionId: (): string | null => {
        return retrieve('sessionId');
    },
    retrieveSocketStorage: (): {
        roomId: string | null;
        originalSocketId: string | null;
        teamIndex: number | null;
        socketId: string | null;
    } => {
        const roomId = retrieve('roomId');
        const teamIndex = Number(retrieve('teamIndex'));
        const socketId = retrieve('socketId');
        const originalSocketId = retrieve('originalSocketId');
        return { roomId, teamIndex, socketId, originalSocketId };
    },
    retrieveTeam: (): { teamIndex: number | null; socketId: string | null } => {
        const teamIndex = retrieve('teamIndex');
        const socketId = retrieve('socketId');
        return { teamIndex: Number(teamIndex), socketId };
    },
    retrieveIsHost: (): string | null => {
        return retrieve('isHost');
    },
};
