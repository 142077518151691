import classNames from 'classnames';
import { motion } from 'framer-motion';
import { usePhase, useSection } from 'src/hooks';
import { noRewardPhases, rewardPhases } from '../../../config/ui/phases';
import { sectionIsQuestion } from '../../../functions';
import { RewardDisplayCircle } from '../rewardDisplayCircle/RewardDisaplyCircle';
//shows the amount of points that a team can earn for the specific question
export const RewardDisplay = () => {
    const section = useSection();
    const phase = usePhase();
    if (!sectionIsQuestion(section) || !phase || noRewardPhases.includes(phase))
        return null;
    const showReward = rewardPhases.includes(phase);

    return (
        <motion.div
            className={classNames('quizmaster-wrapper')}
            animate={{
                opacity: showReward ? 1 : 0,
                x: showReward ? 0 : 100,
            }}
        >
            <span>Verdien</span>
            <RewardDisplayCircle points={section.points} animate={showReward} />
        </motion.div>
    );
};
