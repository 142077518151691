import { Fragment } from 'react';

//checks for all "@[" and "]@" and wraps them in no-wrap component. Recursive component so any level of nesting should be possible.
export const renderNoWrap = ({ element }: { element: JSX.Element }) => {
    if (!element?.props || !Array.isArray(element.props.children)) {
        if (typeof element.props.children === 'string') {
            const elements = element.props.children
                .split('@')
                .map((child: string, index: number) => wrap(child, index));

            return <element.type>{elements}</element.type>;
        }
        return <element.type>{element}</element.type>;
    }

    const noWrapText: JSX.Element = element?.props.children.map(
        (child: JSX.Element, index: number) => {
            if (!child) return <Fragment key={index}>{child}</Fragment>;
            if (child.props.children && Array.isArray(child.props.children)) {
                return (
                    <Fragment key={index}>
                        {renderNoWrap({ element: child })}{' '}
                    </Fragment>
                );
            }
            if (typeof child.props.children !== 'string')
                return <Fragment key={index}>{child}</Fragment>;
            const elements = child.props.children
                .split('@')
                .map((child: string, index: number) => wrap(child, index));
            return <child.type key={index}>{elements}</child.type>;
        },
    );
    return <element.type>{noWrapText}</element.type>;
};

export const wrap = (child: string, index?: number) => {
    if (child.startsWith('[b') && child.endsWith(']')) {
        return (
            <span className="no-wrap bold" key={index}>
                {child.replace('[b', '').replace(']', '')}
            </span>
        );
    }
    if (child.startsWith('[') && child.endsWith(']')) {
        return (
            <span className="no-wrap" key={index}>
                {child.replace('[', '').replace(']', '')}
            </span>
        );
    }
    return <Fragment key={index}>{child}</Fragment>;
};
