import React from 'react';
import styled from 'styled-components';
import { T_Phase } from '../../../../globalTypes';
import { usePhase } from '../../hooks';

export const FooterWrapper = ({
    children,
    quizIsLocal,
}: {
    children?: React.ReactNode;
    quizIsLocal?: boolean;
}) => {
    const phase = usePhase();
    return (
        <Wrapper quizIsLocal={quizIsLocal} phase={phase}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.footer<{
    phase: T_Phase | undefined;
    quizIsLocal?: boolean;
}>`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-basis: 0;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    padding: var(--layout-gap);
    ${({ phase }) =>
        phase === 'quizOutro' &&
        `
         justify-content: flex-end;;
    `}
`;
