import { useEffect } from 'react';
import { MainTitle } from 'src/components/base/typography/MainTitle';
import { appStorage } from 'src/functions';
import { useSocket } from 'src/hooks';

export const NoConnection = () => {
    const socket = useSocket();
    useEffect(() => {
        const {
            socketId,
            roomId,
            originalSocketId,
        } = appStorage.retrieveSocketStorage();
        if (!socketId || !roomId) return;
        socket.emit('reconnect', {
            oldSocketId: socketId,
            roomId,
            originalSocketId,
        });
    }, [socket]);
    return (
        <div className="socket-lobby-wrapper">
            <MainTitle
                text="Er kon geen verbinding worden gemaakt met de server..."
                underlined="geen verbinding"
            />
        </div>
    );
};
