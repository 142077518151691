import { T_Team } from '../../../../../globalTypes';
import {
    sectionIsQuestion,
    teamHasResponseToQuestion,
} from '../../../functions';
import { useSection, useTeams } from '../../../hooks';
import { TeamBox } from './TeamBox';
import './style.scss';

export const TeamsStatus = () => {
    const teams = useTeams();
    const currentQuestion = useSection();
    if (!teams || !currentQuestion || !sectionIsQuestion(currentQuestion))
        return null;
    const teamsWithResponse = teams.filter((team: T_Team) => {
        return teamHasResponseToQuestion({
            team,
            question: currentQuestion,
            forStatusBox: true,
        });
    });
    const teamList = [...teams]
        ?.sort((teamA: T_Team, teamB: T_Team) => {
            return teamA.id - teamB.id;
        })
        .map((team: T_Team, index: number) => {
            if (teamsWithResponse.includes(team)) {
                return (
                    <TeamBox key={index} teamIndex={index} noResponse={false} />
                );
            }
            return <TeamBox key={index} teamIndex={index} noResponse={true} />;
        });
    return (
        <div className="team-status-wrapper-absolute">
            <div className="team-status-wrapper">{teamList}</div>
        </div>
    );
};
