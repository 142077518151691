import {
    GuessNumberInputBox,
    GuessNumberInputBoxProps,
} from './GuessNumberInputBox';

export const GuessNumberRow = ({
    number,
    ...props
}: GuessNumberInputBoxProps) => {
    //LOL?
    const numbers = [number, number && number + 1, number && number + 2];
    return (
        <>
            {numbers.map(number => {
                return (
                    <GuessNumberInputBox
                        {...props}
                        key={number}
                        number={number}
                    />
                );
            })}
        </>
    );
};
