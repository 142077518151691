//Component used to display the text shown BEFORE a quiz is starting.
import { InfoText } from 'src/components';
import { MainTitle } from 'src/components/base/typography/MainTitle';
import { renderText } from 'src/functions/ui/renderers/renderText';
import { IntroWrapper } from 'src/layout/styledcomponents/wrappers/IntroWrapper';
import { drawQuizIntro } from '../../../functions';
import { useQuiz } from '../../../hooks';
import { ImageWrapper } from './ImageWrapper';

//It's purpose is to provide general information about the quiz system.
export const QuizIntroPage = () => {
    const quiz = useQuiz();
    if (!quiz) return null;
    return (
        <IntroWrapper className="width-intro">
            <ImageWrapper />
            <MainTitle text="Speluitleg" />
            <InfoText
                text={renderText({ text: drawQuizIntro(quiz) })}
                subText={<>Veel succes!</>}
            />
        </IntroWrapper>
    );
};
