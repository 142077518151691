import { T_QuestionAnswerPhases } from 'src/pages/subpages';
import {
    T_DecisionQuestion,
    T_Question,
    T_QuizPhase,
    T_Section,
    T_TextSize,
} from '../../../../../globalTypes';
import { QuestionDisplay } from '../questionPage';
import './style.scss';
export const QuestionAnswerDisplay = ({
    inDecision,
    decisionQuestion,
    currentQuestion,
    phase,
}: {
    inDecision?: boolean;
    decisionQuestion?: T_DecisionQuestion;
    phase: T_QuestionAnswerPhases;
    currentQuestion?: T_Section;
}) => {
    const usedQuestion = inDecision
        ? {
              ...decisionQuestion,
              underlinedText: '',
              questionTextSize: 'medium',
              questionTextWide: false,
          }
        : (currentQuestion as T_Question);
    const { underlinedText } = usedQuestion;
    return (
        <>
            <QuestionDisplay
                textSize={
                    (usedQuestion.questionTextSize as T_TextSize) || 'medium'
                }
                questionTextWide={usedQuestion.questionTextWide}
                phase={phase as T_QuizPhase}
                questionText={
                    inDecision ? decisionQuestion?.text : currentQuestion?.text
                }
                underlinedText={underlinedText}
            />
        </>
    );
};
