import { sectionIsQuestion } from 'src/functions';
import { useSection } from 'src/hooks';
import { useSoundEffect } from 'src/hooks/sounds/useSoundEffect';
import { useTeamIndicator } from 'src/hooks/teams/useTeamIndicator';

export const SoundPlayer = () => {
    const section = useSection();
    const isTeamPage = useTeamIndicator();
    useSoundEffect({
        soundKey: 'questionAmbient',
        onPhase: ['question'],
        options: {
            loop: true,
            rate: 1.1,
        },
    });
    useSoundEffect({
        soundKey: 'drumRoll',
        onPhase: ['answerReveal'],
        options: {
            loop: false,
            rate: 1.02,
        },
        condition:
            sectionIsQuestion(section) &&
            section.type !== 'Thesis' &&
            !isTeamPage,
    });
    useSoundEffect({
        condition:
            sectionIsQuestion(section) &&
            section.type !== 'Thesis' &&
            isTeamPage,
        soundKey: 'musul',
        onPhase: ['answerReveal'],
        options: {
            loop: false,
            rate: 1.02,
        },
    });
    return null;
};
