import { getRandomInteger } from 'src/functions/getters/getRandomInteger';

// responsible for the amount of increment for the guess answerReveal,
// since answers can be any integer,
// such a function is needed to prevent the answerReveal from being too slow or too fast
export const getGuessIncrement = ({
    displayedAnswer,
    correctAnswer,
}: {
    displayedAnswer: number;
    correctAnswer: number;
}) => {
    const delta = Math.abs(displayedAnswer - correctAnswer);
    let increment = 1;
    if (delta > 10) increment = getRandomInteger(1, 3);
    if (delta > 50) increment = getRandomInteger(5, 25);
    if (delta > 100) increment = 10;
    if (delta > 1000) increment = 100;
    if (delta > 10000) increment = 1000;
    if (delta > 100000) increment = getRandomInteger(10000, 99999);
    if (delta > 1000000) increment = getRandomInteger(100000, 999999);
    return increment;
};
