import { T_Module, T_Quiz } from '../../../../globalTypes';
import { sectionIsQuestion } from '../checkers';
import { useSectionTransition } from '../../hooks/quiz/useSectionTransition';

export const getQuestionCountById = (
    quiz: T_Quiz,
    currentQuestionId: number,
): number => {
    let questionCount = 0;
    let thesisCount = 0;
    let questionIsFound = false;
    quiz.modules.map((module: T_Module) => {
        module.sections.map(section => {
            if (
                !questionIsFound &&
                sectionIsQuestion(section)
                // && section.type !== 'Thesis'
                //TODO: Checken waarom dit nodig is, wanneer deze wordt weggehaald tellen de vragen weer goed op
            ) {
                if (section.questionId === currentQuestionId) {
                    questionIsFound = true;
                }
                if (section.type === 'Thesis') {
                    thesisCount++;
                } else {
                    questionCount++;
                }
            }
        });
    });
    return questionCount;
};
