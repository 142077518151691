import classNames from 'classnames';
import { HTMLMotionProps, motion } from 'framer-motion';
import { useNextEvent, usePreviousEvent } from 'src/hooks';
export type ButtonProps = {
    text?: string;
    buttonType?:
        | 'next'
        | 'previous'
        | 'primary'
        | 'sleeping'
        | 'custom'
        | 'transparent'
        | 'disabled';
    isActive?: boolean;
    isHidden?: boolean;
    isGhost?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
    HTMLMotionProps<'button'>;

export const Button = ({
    text,
    buttonType,
    className,
    onClick,
    isActive,
    isHidden,
    isGhost,
    children,
    ...rest
}: ButtonProps) => {
    const nextEvent = useNextEvent();
    const previousEvent = usePreviousEvent();
    const isCustomButton = buttonType === 'custom';
    const isPreviousButton = buttonType === 'previous';
    if (buttonType === 'sleeping' && !isActive && !isGhost) return null;
    return (
        <MotionButton
            {...rest}
            onClick={e => {
                if (buttonType === 'next' && !onClick) nextEvent();
                if (buttonType === 'previous' && !onClick) previousEvent();
                if (onClick) onClick(e);
            }}
            className={classNames('button', className, {
                primary:
                    !isCustomButton &&
                    (buttonType === 'primary' ||
                        (buttonType === 'sleeping' && isActive && !isGhost)),
                inactive: !isCustomButton && (isGhost || isPreviousButton),
                hidden: isHidden,
                transparent: isPreviousButton,
                'primary-disabled': buttonType === 'disabled',
            })}
        >
            {children}
            {text}
        </MotionButton>
    );
};
const MotionButton = motion.button;
