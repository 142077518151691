import { AnimatePresence, motion } from 'framer-motion';
import { getThemeColor } from 'src/functions';
import styled from 'styled-components';
export const ScoreNumberDisplay = ({
    number,
    hideScore,
}: {
    number: number;
    hideScore: boolean;
}) => {
    return (
        <AnimatePresence>
            {hideScore || (
                <motion.div
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                        y: [40, -20],
                        scale: 1.5,
                    }}
                    exit={{
                        opacity: 0,
                        y: 50,
                        scale: 1,
                    }}
                    transition={{
                        delay: 0,
                        duration: 0.4,
                        ease: 'easeOut',
                    }}
                    className="score-number-display"
                >
                    <StyledText className="score-number">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8 14.006C8 10.0098 8 6.00632 8 2.006"
                                stroke="#334D56"
                                strokeWidth="4"
                                strokeLinecap="round"
                            />
                            <path
                                d="M2 9.006C5.99621 8.56726 9.99968 8.26951 14 8.006"
                                stroke="#334D56"
                                strokeWidth="4"
                                strokeLinecap="round"
                            />
                        </svg>
                        {number}
                    </StyledText>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

const StyledText = styled.p<{}>`
    color: ${props =>
        getThemeColor({
            theme: props.theme,
            themeType: 'colors',
            themeColor: 'primary',
        })};
`;
