import { T_AppState, T_RawState } from '../../../../globalTypes';

export const selectQuizIsLoading = () => (state: T_RawState) => {
    return state.appState?.appIsLoading;
};

export const selectLatestMessage = () => (state: T_RawState) => {
    return state.appState?.messageHistory[0] || 'Geschiedenis leeg';
};

export const selectMessageHistory = () => (state: T_RawState) => {
    return state.appState?.messageHistory;
};

export const selectToken = () => (state: T_RawState) => {
    return state.appState?.token;
};

export const selectInteractions = <T extends keyof T_AppState['interactions']>(
    key: T,
) => (state: T_RawState) => {
    return state.appState?.interactions[key];
};

export const selectInteractionCooldown = () => (state: T_RawState) => {
    return state.appState?.interactionCooldown;
};

export const selectButtonCooldown = () => (state: T_RawState) => {
    return state.appState?.buttonCooldown;
};

export const selectActiveLectureId = () => (state: T_RawState) => {
    return state.appState?.activeLectureId;
};

export const selectActiveLectureLevel = () => (state: T_RawState) => {
    return state.appState?.activeLectureLevel;
};

export const selectActiveLectureLevelAsNumber = () => (state: T_RawState) => {
    switch (state.appState?.activeLectureLevel) {
        case 'PO':
            return 1;
        case 'MBO':
            return 2;
        case 'VO':
            return 3;
        default:
            return 0;
    }
};
