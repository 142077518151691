import { useEffect, useState } from 'react';
import { sectionIsWhatsappQuestion } from 'src/functions';
import { arrayOrder } from 'src/functions/helpers/arrays/arrayOrder';
import { T_WhatsappMessage } from '../../../../globalTypes';
import { useSection } from '../quiz';

export const useWhatsappChat = () => {
    const [typing, setTyping] = useState(false); //displays "aan het typen"
    const [endOfChat, setEndOfChat] = useState(false); //true when all messages are displayed
    const [displayedMessages, setDisplayedMessages] = useState<
        T_WhatsappMessage[]
    >([]); //messages that are displayed
    const section = useSection();
    const [messageIndex, setMessageIndex] = useState(0); //index of current message that the hook is handling
    useEffect(() => {
        if (!sectionIsWhatsappQuestion(section)) return;
        const messages = arrayOrder(section.messages);
        //if there are no more messages, stop the hook
        if (messageIndex >= messages.length) {
            setTyping(false);
            setEndOfChat(true);
            return;
        }
        const nextMessage = messages[messageIndex];
        const timeToNextMessage = nextMessage.interval;
        if (nextMessage.sender !== 'self') {
            setTyping(true);
        }
        const typingTimeout = setTimeout(() => {
            setTyping(false);
        }, timeToNextMessage - 300);
        const timeout = setTimeout(() => {
            setDisplayedMessages(prev => [...prev, nextMessage]);
            setMessageIndex(prev => prev + 1);
        }, timeToNextMessage);
        return () => {
            clearTimeout(timeout);
            clearTimeout(typingTimeout);
        };
    }, [messageIndex]);
    return { typing, displayedMessages, endOfChat };
};
