import { T_Module, T_QuizState, T_Section } from '../../../../globalTypes';
import store from '../../store';

//This is the function that returns a questionId based on moduleId and sectionId, used in react
export const getCurrentSection = (): T_Section | null | undefined => {
    return findSection(store.getState().quizState);
};

//This is the function that returns a questionId based on moduleId and sectionId, used in reducers. Takes the state as parameter.
export const getCurrentSectionInState = (
    state: T_QuizState,
): T_Section | null | undefined => {
    return findSection(state);
};

const findSection = (state: T_QuizState) => {
    const { currentModuleId, currentSectionId, quiz } = state;
    if (!currentModuleId || !currentSectionId || !quiz) return null;
    return quiz.modules
        .find((module: T_Module) => module.id === currentModuleId)
        ?.sections.find(
            (section: T_Section) => section.id === currentSectionId,
        );
};
