import { T_NormalQuestion } from '../../../../globalTypes';
import { sectionIsQuestion } from './sectionIsQuestion';

//naming KEK
export const allMPCAnswersAreCorrect = (question?: T_NormalQuestion<'MPC'>) => {
    if (
        !sectionIsQuestion(question) ||
        !question ||
        question.type !== 'MPC' ||
        !question.answers ||
        !question.correctAnswers
    )
        return false;
    return question.answers.length === question.correctAnswers.length;
};
