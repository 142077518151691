import React from 'react';
import { setInteraction } from '../../../store';
import { useAppDispatch } from '../../../hooks';

export const PopScreenWrapper = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const dispatch = useAppDispatch();

    return (
        <div className="centered-pop-up">
            {children}
            <div className="blur-background" onClick={() =>
                dispatch(
                    setInteraction({
                        interactionKey: 'popUp',
                        change: 'close',
                    }),
                )
            }></div>
        </div>
    );
};
